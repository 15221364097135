import "./channel.scss";

import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";

import { Link } from "react-router-dom";
import { useState } from "react";
import Datatable from "../../components/studio/datatable/Datatable";
import ChannelsComp from "../../components/studio/channel/ChannelsComp";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import { Button } from "@mui/material";
import React, { useEffect, useRef } from "react";

const AllChannels = () => {

  useEffect(() => {
    window.localStorage.setItem('can_update', 'yes')

  }, []);

 
  return (
    <div className="list" style={{ backgroundColor: "#fff" }}>
      {/* <Sidebar /> */}
      <HeaderV2 />
      <div className="listContainer" >
        {/* <Navbar /> */}
        {/* <div className="datatable"> */}
         {/* <div className="top" style={{ color: "#000",marginTop: 20 }}>
          
        </div> */}

      
        <ChannelsComp link={`/channels/approved`} title='Explore All Channels On Chillarx' />
      </div>
    </div>
  );
};

export default AllChannels;
