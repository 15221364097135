import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router";
import { format, formatDistance, subDays } from "date-fns";
import "../../../components/comment/comment.css";

import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router";
import { Grid, makeStyles } from "@material-ui/core";
import "./detail.scss";
// import CastList from "./CastList";
import CastList from "../../detail/CastList";
// import VideoList from "./VideoList";

import VideoList from "../../detail/VideoList";
import VideoListMap from "../../detail/VideoListMap";
import MovieGridMap from "../../../components/movie-grid/MovieGridMap";
import MovieList from "../../../components/movie-list/MovieList";

import SeriesList from "../../../components/movie-list/SeriesList";
import SeriesListMap from "../../../components/movie-list/SeriesListMap";
import SeriesGrid from "../../../components/movie-grid/SeriesGrid";
import SeriesGridMap from "../../../components/movie-grid/SeriesGridMap";

// import Modal, { ModalContent } from "../../../moviesComponents/modal/Modal";
// import Button, {
//   OutlineButton,
// } from "../../../moviesComponents/button/Button";

import Button, { OutlineButton } from "../../../components/button/Button";

// import MovieList from "../../../../moviesComponents/movie-list/MovieList";
// import MovieList from "../../../components/movie-list/MovieList";
import MovieListMap from "../../../components/movie-list/MovieListMap";
// import SeriesListMap from "../../../components/movie-list/SeriesListMap";

import { Tooltip } from "@material-ui/core";

import ShowMoreText from "react-show-more-text";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import EpisodeCard from "../../../components/movies/EpisodeCard";
import CommentComponent from "../../../components/comment/CommentComponent";
// import { Player } from "video-react";
import FavComment from "../../../components/comment/FavComments";
import { usePaystackPayment } from "react-paystack";
import Alert from "@mui/material/Alert";
// import ShareMovie from "../../../components/ShareMovie";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import LikersModal from "../../../components/others/LikersModal";
import ImageComponent from "../../../components/others/ImageComponent";
import ImageComponentDiv from "../../../components/others/ImageComponentDiv";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AllMovies from "../../../components/movies/AllMovies";
import { Snackbar } from "@material-ui/core";
import ReactPlayer from "react-player";
import FeatherIcon from "feather-icons-react";
import { Visibility } from "@material-ui/icons";
import ShareIcon from "@mui/icons-material/Share";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import AddCardIcon from "@mui/icons-material/AddCard";
import GroupIcon from "@mui/icons-material/Group";
import Header from "../../../components/header/Header";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import AddTaskIcon from "@mui/icons-material/AddTask";
import DisApproveMovie from "../../../components/studio/channel/DisApproveMovie";
import ApproveMonetization from "../../../components/studio/channel/ApproveMonetization";

const useStyles = makeStyles((theme) => ({
  pc: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const SeriesDetail = (props) => {
  const [copied, setCopied] = useState(false);
  const classes = useStyles();
  //   const { category, id } = useParams();
  const { slug } = useParams(["slug"]);
  const { url } = useParams(["url"]);
  const [isOpen, setOpen] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  const series = props.series;
  const seriesMovies = props.seriesMovies;
  const comments = props.comments;
  const ratings = props.ratings;
  const userName = window.localStorage.getItem("user_name");
  const video = props.video;
  const trailer = props.trailer;
  const [item, setItem] = useState(null);
  let history = useHistory();
  const [isLiking, setIsLiking] = useState(false);
  const [showMovie, setShowMovie] = useState(false);
  const [paymentPrompt, setPaymentPrompt] = useState(false);
  const [chillarxPaymentPrompt, setChillarxPaymentPrompt] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [chillarxSubSuccess, setChillarxSubSuccess] = useState(false);
  const [channelSubSuccess, setChannelSubSuccess] = useState(false);
  const [subPrompt, setSubPrompt] = useState(false);
  const [subMessage, setSubMessage] = useState("");
  const [content, setContent] = useState([]);
  const userId = window.localStorage.getItem("user_id");
  //   const [movie, setMovie] = useState([]);
  const [hovered, setHovered] = useState(false);
  //   const url = "https://image.tmdb.org/t/p/w300";
  const [openComment, setOpenComment] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [approving, setApproving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [openDisapproval, setOpenDisapproval] = useState(false);
  const [message, setMessage] = useState("");
  const [disApprovalMessage, setDisApprovalMessage] = useState("");
  // const [userPic, setUserPic] = useState("");
  // const [userId, setUserId] = useState("");
  // const [userEmail, setUserEmail] = useState("");
  const userEmail = window.localStorage.getItem("email");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  function closeSnack() {
    setCopied(false);
  }

  async function purchasedSeries(id) {
    // setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("movie_id", series.id);
    formData.append("type", "series");

    axiosFetch
      .post(`/movie_buyers/series/${series.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        props.functionProp();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function subscribeToChillarx(
    type,
    description,
    amount,
    reference,
    transaction,
    status,
  ) {
    // setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();

    formData.append("movie_id", series.id);
    formData.append("type", type);
    formData.append("description", description);
    formData.append("amount", amount);
    formData.append("reference", reference);
    formData.append("transaction", transaction);
    formData.append("status", status);
    
    formData.append("content_type", "series");


    axiosFetch
      .post(`/channels/chillarx_subscribe`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        props.functionProp();
        console.log(resp.data);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function handleSubscription(id) {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(`/channels/${id}/subscribe`, id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);
        videoSubscription();
        props.functionProp();
        // setChannelSubSuccess(true);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function videoSubscription(id) {
    const access_token = await window.localStorage.getItem("access_token");

    const formData = new FormData();
    formData.append("movie_id", series.id);
    formData.append("type", "series");

    axiosFetch
      .post(`/channels/series/subscribe/${series.id}`, formData, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        // console.log(resp.data)
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function recordTransaction(
    type,
    description,
    amount,
    reference,
    transaction,
    status,
    commission,
    payout,
    willExpire,
    payType
  ) {
    const access_token = await window.localStorage.getItem("access_token");

    // alert([type, description, amount, reference, commission, payout])
    const formData = new FormData();

    formData.append("channel_id", series.channel.id);
    formData.append("movie_id", series.id);
    formData.append("channel_name", series.channel.name);
    formData.append("type", type);
    formData.append("content_type", "series");
    formData.append("description", description);
    formData.append("amount", amount);
    formData.append("reference", reference);
    formData.append("transaction", transaction);
    formData.append("status", status);
    formData.append("commission", commission);
    formData.append("payout", payout);
    formData.append("will_expire", willExpire);
    formData.append("pay_type", payType);
    formData.append("movie_id", series.id);
    formData.append("content_type", "series");

    axiosFetch
      .post(`/users/add_transaction`, formData, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);

        props.functionProp();
        // setChannelSubSuccess(true);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  const config = {
    reference: new Date().getTime().toString(),
    email: userEmail,
    amount: series.price * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
    subaccount: props.subAccount,
    // publicKey: props.publicKey,
  };

  const chillarxConfig = {
    reference: new Date().getTime().toString(),
    email: userEmail,
    amount: 1000 * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
  };

  const channelConfig = {
    reference: new Date().getTime().toString(),
    email: userEmail,
    amount: props.channelPrice * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
    subaccount: props.subAccount,
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    purchasedSeries();
    setPaymentPrompt(false);
    recordTransaction(
      "Paid Series Purchase",
      `Payment to get access to watch ${series.title}`,
      series.price,
      reference.reference,
      reference.transaction,
      `${reference.status} - ${reference.message}`,
      "10",
      series.price,
      // will expire and payment type
      "false",
      "channel"
    );
    setPaymentSuccess(true);
  };

  const onSuccessChill = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    subscribeToChillarx(
      "Chillarx Monthly Subscription",
      `Subsciption to get access to premium contents for chillarx subscribers from - ${series.title}`,
      1000,
      reference.reference,
      reference.transaction,
      `${reference.status} - ${reference.message}`,
    );
    setChillarxPaymentPrompt(false);
    recordTransaction(
      "Chillarx Monthly Subscription",
      `Subsciption to get access to premium contents for chillarx subscribers from - ${series.title}`,
      1000,
      reference.reference,
      reference.transaction,
      `${reference.status} - ${reference.message}`,
      "10",
      1000,
      // will expire and payment type
      "true",
      "chillarx"
    );
    setChillarxSubSuccess(true);
    // window.location.reload();
  };

  const onSuccessChannel = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    setSubPrompt(false);
    handleSubscription(series.channel.id);
    recordTransaction(
      "Paid Channel Subscription",
      `Subsciption to get access to watch ${series.title}`,
      series.channel.price,
      reference.reference,
      reference.transaction,
      `${reference.status} - ${reference.message}`,
      "10",
      series.channel.price,
      // will expire and payment type
      "true",
      "channel"
    );
    setChannelSubSuccess(true);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const handleClickOpen = (scrollType) => () => {
    setOpenComment(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpenComment(false);
    setSubPrompt(false);
    setPaymentSuccess(false);
    setChillarxSubSuccess(false);
    setChannelSubSuccess(false);
    setSaved(false);
    setRemoved(false);
    setOpenDisapproval(false);
  };
  const closeVideo = () => {
    setShowMovie(false);
  };

  const initializePayment = usePaystackPayment(config);

  const initializePaymentChillarx = usePaystackPayment(chillarxConfig);

  const initializePaymentChannel = usePaystackPayment(channelConfig);

  const toogleVideo = () => {
    if (series.pricing == "free") {
      setShowMovie(true);
    }
    if (series.pricing == "chill_sub" && props.isChillarxSub == "true") {
      setShowMovie(true);
    }

    if (series.pricing == "chill_sub" && props.isChillarxSub == "false") {
      setChillarxPaymentPrompt(true);
      // setPaymentMessage(
      //   `This series is for chillarx subscribers only. Subscribe to Chillarx now and watch now for N700 monthly. If you've previously subscribed, and you're seeing this, it means your past subscription has expired. Click subscribe now to proceed`
      // );
    }

    if (series.pricing == "paid" && props.isPurchased == "false") {
      setPaymentPrompt(true);
    }
    if (series.pricing == "paid" && props.isPurchased == "true") {
      setShowMovie(true);
    }

    if (series.pricing == "subscription" && props.isSub == "false") {
      // if (props.isSub == "false") {
      setSubPrompt(true);
      setSubMessage(
        "This series is for channel subscribers only. You need to subscribe first to enjoy its episodes. Click the subscribe button to subscribe now."
      );
      setShowMovie(false);
    }

    if (series.pricing == "subscription" && props.isSub == "true") {
      setShowMovie(true);
    }
  };

  // async function getProfile() {
  //   setIsFetching(true);
  //   const access_token = await window.localStorage.getItem("access_token");
  //   // console.log(`user profile ${access_token}`);
  //   axios
  //     .get("https://alert-lumen-api.herokuapp.com/api/users/profile", {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + access_token,
  //       },
  //     })
  //     .then((resp) => {
  //       // console.log(JSON.stringify(resp.data.user.profile_pic));
  //       setUserId(resp.data.user.id);
  //       setUserEmail(resp.data.user.email);
  //       setUserPic(resp.data.user.profile_pic);
  //       setIsFetching(false);
  //     })
  //     .catch((error) => console.error(`Error Seen : ${error}`));
  // }

  // async function likeSeries(id) {
  //   setIsLiking(true);
  //   const access_token = await window.localStorage.getItem("access_token");
  //   const authId = await window.localStorage.getItem("user_id");
  //   axiosFetch
  //     .post(
  //       `/movie/like/series/${id}`,
  //       id,
  //       {
  //         headers: {
  //           Accept: "*/*",
  //           "Access-Control-Allow-Origin": "*",
  //           "Content-Type": "text/html",
  //           Authorization: "Bearer " + access_token,
  //         },
  //         // method: 'POST',
  //       }
  //     )
  //     .then((resp) => {
  //       const alerts = resp.data;
  //       props.functionProp();
  //       setIsLiking(false);
  //       console.log(`hiii ${resp.data}`);
  //     })
  //     .catch((error) => console.error(`Error Seen : ${error}`));
  // }

  async function watchLater(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/series/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }
  async function removeWatchLater(id) {
    setRemoving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/series/un_save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setRemoving(false);
        setRemoved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function approveContent(id) {
    setApproving(true);
    // alert('approving, click ok to continue')
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/series/approve_series/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        alert(`approved successfuly. Wait a few secs`);
        setApproving(false);
        // setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function disApproveContent(id) {
    if (disApprovalMessage == "") {
      alert("type in your reason for dis-approving first");
    } else {
      setApproving(true);
      // alert('approving, click ok to continue')
      const access_token = await window.localStorage.getItem("access_token");
      const authId = await window.localStorage.getItem("user_id");

      const formData = new FormData();
      formData.append("message", disApprovalMessage);

      axiosFetch
        .post(`/movie/series/dis/approve_movie/${id}`, id, {
          headers: {
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/html",
            Authorization: "Bearer " + access_token,
          },
          // method: 'POST',
        })
        .then((resp) => {
          const alerts = resp.data;
          props.functionProp();
          // alert('relaerted!')
          alert(`dis-approved successfuly. Wait few sec`);
          setApproving(false);
          // setSaved(true);
        })
        .catch((error) => console.error(`Error Seen : ${error}`));
    }
  }

  async function postComment() {
    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    // const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("message", message);

    axiosFetch
      .post(`/movie/series/comment/${series.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(`sent message ${resp.data}`);
        setMessage("");
        setIsCreating(false);
        // setMessage('');
        props.functionProp();
        // alert(`response ${JSON.stringify(resp.data)}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }

  async function likeSeries(id) {
    setIsLiking(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/like/series/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        props.functionProp();
        setIsLiking(false);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  const descriptionElementRef = useRef(null);

  function handleContextMenu(e) {
    e.preventDefault();

    // ...
  }
  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);
    // getProfile();
    if (openComment) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    // if (url == "series") {
    //   console.log("it is a series ooo!");
    // }
    // if (url == "movie") {
    //   console.log("it is a movie 0000!");
    // }
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [openComment]);

  //   return (
  if (series.length == 0) {
    return "";
  } else {
    return (
      <>
        <Header />
        {series && (
          <>
            <ImageComponentDiv class="banner" image={series.image} />
            <div style={{ paddingTop: 60 }}></div>
            {/* <div
              className="banner"
              style={{
                backgroundImage: `url("${series.image}")`,
              }}
            ></div> */}
            <div className="mb-3 movie-content container">
              <div className="movie-content__poster">
                <ImageComponentDiv
                  class="movie-content__poster__img"
                  image={series.cover_image}
                />
                {/* <div
                  className="movie-content__poster__img"
                  style={{
                    backgroundImage: `url("${series.cover_image}")`,
                  }}
                ></div> */}
                <br />
                <br />
                <div className="clearfix"></div>
                <div className="star d-block w-100 text-left mt-2">
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                  />
                  <img
                    src="/assets/images/star-disable.png"
                    alt="star"
                    className="w15 float-left me-2"
                  />
                </div>
                <p className="review-link font-xssss fw-600 text-white lh-3 mb-0">
                  ({ratings} ratings ) {comments.length} comments
                </p>
                <div className="clearfix"></div>
                <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                  <i className="btn-round-sm bg-greylight me-1">
                    <GroupIcon icon="eye" size="24" color="green" />
                  </i>{" "}
                  {props.casts.length} casts
                </h5>
                {/* <h5 className="mt-4 mb-4 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                    <i className="btn-round-sm bg-greylight ti-rss-alt text-grey-500 me-1"></i>{" "}
                    Active Internet
                  </h5> */}
                {series.pricing == "paid" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight  text-grey-500 me-1">
                      <AddCardIcon icon="eye" size="24" />
                    </i>{" "}
                    This {series.type} is not free. Buy and watch now for N
                    {series.price} and unlock its episodes
                  </h5>
                ) : (
                  ""
                )}
                {series.pricing == "subscription" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight  text-grey-500 me-1">
                      <AddCardIcon icon="eye" size="24" />
                    </i>{" "}
                    Pricing: This {series.type} is only accessible to channel
                    subscribers
                  </h5>
                ) : (
                  ""
                )}
                {series.pricing == "free" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight  text-grey-500 me-1">
                      <AddCardIcon icon="eye" size="24" />
                    </i>{" "}
                    Free: No payment required! This {series.type} is free to be
                    watched by all
                  </h5>
                ) : (
                  ""
                )}
                {series.pricing == "chill_sub" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                      <AddCardIcon icon="eye" size="24" />
                    </i>{" "}
                    Pricing: This {series.type} is only accessible to chillarx
                    subscribers
                  </h5>
                ) : (
                  ""
                )}

                <br />
                <br />
                <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Explore :{" "}
                  <span style={{ textDecoration: "underline" }}>
                    {" "}
                    <a href={`/channel/${props.channelSlug}/series`}>
                      {" "}
                      {props.channelName}
                    </a>{" "}
                  </span>
                </h4>
                {/* <OutlineButton
                  onClick={() =>
                    // alert(props.channelSlug)
                    history.push(`/channel/${props.channelSlug}/movies`)
                  }
                >
                  Explore Channel
                </OutlineButton> */}
                <br />

                <div className="clearfix"></div>
              </div>
              <div className="movie-content__info">
                <Grid item sm={12} className={classes.mobile}>
                  <ImageComponentDiv
                    class="movie-content__poster__img"
                    image={series.image}
                  />
                  <br />
                </Grid>
                <h1
                  style={{
                    color: "#fff",

                    fontSize: "4vw",
                    lineHeight: 1,
                    textAlign: "start",
                  }}
                >
                  {series.title}
                </h1>

                <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Channel :{" "}
                  <span style={{ textDecoration: "underline" }}>
                    {" "}
                    <a href={`/channel/${props.channelSlug}/series`}>
                      {" "}
                      {props.channelName}
                    </a>{" "}
                  </span>
                </h4>
                <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Status : {series.status}{" "}
                  {formatDistance(new Date(series.created_at), new Date(), {
                    addSuffix: true,
                  })}
                </h4>

                <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Pricing : {series.pricing} {series.price}
                </h4>

                <h5 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Type : {series.type}{" "}
                </h5>
                <div
                  className="genres clearfix"
                  style={{ overFlow: "auto", display: "block" }}
                >
                  {series.tags &&
                    series.tags.slice(0, 5).map((tag, i) => (
                      <span
                        key={i}
                        className="genres__item"
                        style={{
                          padding: "0.5rem 1.5rem",

                          color: "#fff",
                          display: "inline-block",
                          marginTop: 5,
                        }}
                      >
                        {tag.name}
                      </span>
                    ))}
                  <div className="clearfix"></div>
                </div>

                <Grid item sm={12} className={classes.mobile}>
                  <div className="star d-block w-100 text-left mt-2">
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                    />
                    <img
                      src="/assets/images/star-disable.png"
                      alt="star"
                      className="w15 float-left me-2"
                    />
                  </div>
                  <p className="review-link font-xssss fw-600 text-white lh-3 mb-0">
                    ({ratings} ratings ) {comments.length} comments
                  </p>
                  <div className="clearfix"></div>
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                    <i className="btn-round-sm bg-greylight me-1">
                      <GroupIcon icon="eye" size="24" color="green" />
                    </i>{" "}
                    {props.casts.length} casts
                  </h5>
                  {/* <h5 className="mt-4 mb-4 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                    <i className="btn-round-sm bg-greylight ti-rss-alt text-grey-500 me-1"></i>{" "}
                    Active Internet
                  </h5> */}
                  {series.pricing == "paid" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight  text-grey-500 me-1">
                        <AddCardIcon icon="eye" size="24" />
                      </i>{" "}
                      This {series.type} is not free. Buy and watch now for N
                      {series.price} and unlock its episodes
                    </h5>
                  ) : (
                    ""
                  )}
                  {series.pricing == "subscription" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight  text-grey-500 me-1">
                        <AddCardIcon icon="eye" size="24" />
                      </i>{" "}
                      Pricing: This {series.type} is only accessible to channel
                      subscribers
                    </h5>
                  ) : (
                    ""
                  )}
                  {series.pricing == "free" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight  text-grey-500 me-1">
                        <AddCardIcon icon="eye" size="24" />
                      </i>{" "}
                      Free: No payment required! This {series.type} is free to
                      be watched by all
                    </h5>
                  ) : (
                    ""
                  )}
                  {series.pricing == "chill_sub" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                        <AddCardIcon icon="eye" size="24" />
                      </i>{" "}
                      Pricing: This {series.type} is only accessible to chillarx
                      subscribers
                    </h5>
                  ) : (
                    ""
                  )}
                  <br /> <br />
                  {/* <OutlineButton
                    onClick={() =>
                      // alert(props.channelSlug)
                      history.push(`/channel/${props.channelSlug}/movies`)
                    }
                  >
                    Explore Channel
                  </OutlineButton>{" "}
                  <br />
                  <br /> */}
                </Grid>

                <ShowMoreText
                  style={{ color: "#fff", fontWeight: 700 }}
                  lines={3}
                  more="Read more"
                  less="Show less"
                  className="overview text-white"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  {series.overview}
                </ShowMoreText>

                {props.isAdmin == true && (
                  <>
                    {series.is_approved == "false" ? (
                      <>
                        <p>This hasn't been approved </p>
                        {/* <OutlineButton
                          onClick={() => approveContent(series.id)}
                        >
                          Approve Series
                        </OutlineButton> */}
                        <br />
                      </>
                    ) : (
                      <>
                        <p>
                          This has been approved already. To disapprove, use the
                          red icon{" "}
                        </p>
                        {/* <OutlineButton
                          onClick={() => approveContent(series.id)}
                        >
                          Dis-Approve Series
                        </OutlineButton> */}
                        <br />
                      </>
                    )}
                  </>
                )}

                {approving ? (
                  <>
                    <br />
                    <p className="text-white">
                      {" "}
                      Approving Series... This will take a few seconds{" "}
                    </p>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}

                <h3 style={{ color: "#fff" }}>
                  Scroll down to explore episodes in this series
                </h3>

                <div className="btns">
                  {/* <button
                  style={{
                    border: "3px solid #fff",
                    backgroundColor: "transparent",
                    color: "#fff",
                    boxShadow: "unset",
                    padding: '15px 50px',
                    borderRadius:40,
                    marginRight:10,
                    transition: "color 0.3s ease, #000 0.3s ease",
                  }}
                >
                  Watch Now
                </button> */}
                  {series.pricing == "paid" && props.isPurchased == "false" ? (
                    <>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                        This {series.type} is not free. Buy now and watch its
                        episodes for N{series.price}
                      </h4>
                      <br />
                      <div style={{ paddingBottom: 20 }}>
                        <OutlineButton
                          onClick={() => {
                            initializePayment(onSuccess, onClose);
                          }}
                        >
                          Buy Now
                        </OutlineButton>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {series.pricing == "subscription" && props.isSub == "true" ? (
                    <>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                        Hurray! Series is for channel subscribers only and you
                        are subscribed to this channel. See its Episodes below
                        and enjoy!
                      </h4>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  {series.pricing == "subscription" &&
                  props.isSub == "false" ? (
                    <>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                        This {series.type} is for channel subscribers only. You
                        need to subscribe first to enjoy this. Click go to
                        channel button to do so now
                      </h4>
                      <br />
                      {series.channel.type == "free" ? (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={() => {
                              handleSubscription(series.channel.id);
                              // setSubPrompt(false);
                              setChannelSubSuccess(true);
                            }}
                          >
                            Subscribe Free
                          </OutlineButton>
                        </div>
                      ) : (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={
                              () =>
                                initializePaymentChannel(
                                  onSuccessChannel,
                                  onClose
                                )
                              // testing history.push("/channel/" + props.channelSlug)
                            }
                          >
                            Subscribe Now
                          </OutlineButton>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {subPrompt ? (
                    <>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                        {subMessage}
                      </h4>

                      <div style={{ paddingBottom: 20 }}>
                        {series.channel.type == "free" ? (
                          <div style={{ paddingBottom: 20 }}>
                            <OutlineButton
                              onClick={() => {
                                handleSubscription(series.channel.id);
                                setSubPrompt(false);
                                setChannelSubSuccess(true);
                              }}
                            >
                              Subscribe Free
                            </OutlineButton>
                          </div>
                        ) : (
                          <OutlineButton
                            onClick={
                              () =>
                                initializePaymentChannel(
                                  onSuccessChannel,
                                  onClose
                                )
                              // history.push(`/channel/${props.channelSlug}/movies`)
                            }
                          >
                            Subscribe Now
                          </OutlineButton>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {chillarxPaymentPrompt ? (
                    <>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                        This series is for chillarx subscribers only. Subscribe
                        to Chillarx now and watch now for N1000 monthly. If
                        you've previously subscribed, and you're seeing this, it
                        means your past subscription has expired. Click
                        subscribe now to proceed
                      </h4>
                      <br />
                      {series.channel.type == "free" ? (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={() =>
                              handleSubscription(series.channel.id)
                            }
                          >
                            Subscribe Now
                          </OutlineButton>
                        </div>
                      ) : (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={() => {
                              initializePaymentChillarx(
                                onSuccessChill,
                                onClose
                              );
                            }}
                          >
                            Subscribe Now
                          </OutlineButton>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {chillarxSubSuccess ? (
                    <>
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        Awesome! You've activated your chillarx monthly
                        subscription successfully! You now have access to all
                        premium entertainments on chillarx. Enjoy!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  {channelSubSuccess ? (
                    <>
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        Awesome! You've subscribed to {series.channel.name}{" "}
                        successfully! You now have access to all premium
                        entertainments on their channel. Enjoy!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  {paymentSuccess ? (
                    <>
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        Hurray! You've purchased {series.title} successfully!
                        You can always comeback to watch its episodes here, or
                        view them in your purchased movies from your profile.
                        Start enjoying its episodes below!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  <div style={{ marginRight: 20, marginBottom: 20 }}>
                    {video === null || video === "null" ? (
                      ""
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            setShowMovie(true);
                            toogleVideo();
                          }}
                          // onClick={() => setOpen2(true)}
                          // onClick={toogleVideo} setShowMovie(false);
                        >
                          Watch Trailer
                        </Button>

                        {/* <ModalVideo
                          channel="custom"
                          autoplay
                          isOpen={isOpen2}
                          url={video}
                          onClose={() => setOpen2(false)}
                        /> */}
                      </>
                    )}
                  </div>

                  {showMovie ? (
                    <>
                      <br />
                      {/* {series.video.includes("youtu") ? ( */}
                      <ReactPlayer
                        url={series.video}
                        controls={true}
                        config={{
                          file: { attributes: { controlsList: "nodownload" } },
                        }}
                        playsinline={true}
                        width="100%"
                        light={series.image}
                      />
                      {/* ) : (
                        <Player
                        playsInline
                        poster={series.image}
                        src={series.video}
                        fluid
                      />
                      )} */}

                      <br />
                      <br />

                      <br />
                      <Button onClick={closeVideo}>Close</Button>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item sm={12} className={classes.mobile}>
                    <br />
                  </Grid>

                  {trailer === null || trailer === "null" ? (
                    ""
                  ) : (
                    <>
                      {/* <p>jlllllll</p> */}
                      <OutlineButton
                        onClick={() => setOpen(true)}
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                        Watch Second Trailer
                      </OutlineButton>
                      <br />
                      <br />

                      {isOpen ? (
                        <>
                          <br />
                          {/* {trailer.includes("youtu") ? ( */}
                          <ReactPlayer
                            url={trailer}
                            controls={true}
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            playsinline={true}
                            width="100%"
                            light={series.image}
                          />
                          {/* // ) : (
                      //   <Player
                      //   playsInline
                      //   poster={series.cover_image}
                      //   src={trailer}
                      //   fluid
                      // />
                      // )} */}

                          <br />
                          <br />

                          <Button onClick={() => setOpen(false)}>Close</Button>
                          <br />
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {/* <ModalVideo
                        channel="custom"
                        autoplay
                        isOpen={isOpen}
                        url={trailer}
                        onClose={() => setOpen(false)}
                      />*/}
                    </>
                  )}

                  <div>
                    <a
                      onClick={handleClickOpen("paper")}
                      // data-uk-toggle="target: #my-id" type="button"
                      // href="/defaulthoteldetails"
                      className="d-block p-2 lh-32 text-center bg-greylight fw-600 font-xssss text-grey-900 rounded-3"
                    >
                      Add a Review / Comment
                    </a>
                    <Dialog
                      open={openComment}
                      onClose={handleClose}
                      scroll={scroll}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                    >
                      <DialogTitle id="scroll-dialog-title">
                        Series Comments and Feedbacks
                      </DialogTitle>
                      <DialogContent dividers={scroll === "paper"}>
                        <br />
                        <p> {props.favComments.length} Pinned Comments </p>
                        <FavComment
                          Id={series.id}
                          url={"movie/series/comment"}
                          // favUrl="movie/series"
                          favUrl={`${series.id}/series`}
                          movieId={series.id}
                          adminId={props.adminId}
                          userId={props.userId}
                          favComments={props.favComments}
                          functionProp={props.functionProp}
                        />
                        <br />
                        <DialogContentText
                          id="scroll-dialog-description"
                          ref={descriptionElementRef}
                          tabIndex={-1}
                        >
                          {comments.map((c, id) => (
                            <CommentComponent
                              comments={c}
                              id={id}
                              url="movie"
                              favUrl={`${series.id}/series`}
                              deleteUrl={`comment`}
                              likeUrl={`movie`}
                              adminId={props.adminId}
                              userId={props.userId}
                              movieId={series.id}
                              functionProp={props.functionProp}
                            />
                          ))}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        {/* <Button onClick={handleClose}>Subscribe</Button> */}
                      </DialogActions>
                    </Dialog>
                  </div>
                  <br />

                  <div style={{ display: "flex" }}>
                    {isLiking ? (
                      <Tooltip title="Like" aria-label="add">
                        <a
                          style={{ border: "#fff solid 2px" }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3"
                        >
                          <HourglassEmptyRoundedIcon
                            style={{ color: "#fff", fontSize: 30 }}
                          />
                        </a>
                      </Tooltip>
                    ) : (
                      <>
                        {props.likedStatus == "true" ? (
                          <Tooltip title="Unlike" aria-label="add">
                            <>
                              <a
                                style={{ border: "#fff solid 2px" }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3"
                              >
                                <FavoriteRoundedIcon
                                  onClick={() => likeSeries(series.id)}
                                  style={{ color: "#fff", fontSize: 30 }}
                                />

                                <span>
                                  {" "}
                                  <p className="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss">
                                    {props.likers} likes
                                  </p>
                                  {/* <h3 className="text-white">
                                    <LikersModal
                                      color="#fff"
                                      likers={series.likers}
                                      text="likes"
                                      type="series"
                                      class="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss"
                                    />
                                  </h3>{" "} */}
                                </span>
                              </a>
                            </>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Like" aria-label="add">
                            <>
                              <a
                                style={{ border: "#fff solid 2px" }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3"
                              >
                                <FavoriteBorderRoundedIcon
                                  onClick={() => likeSeries(series.id)}
                                  style={{ color: "#fff", fontSize: 30 }}
                                />

                                <span>
                                  {" "}
                                  <p className="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss">
                                    {props.likers} likes
                                  </p>
                                  {/* <h3 className="text-white">
                                    <LikersModal
                                      color="#fff"
                                      likers={series.likers}
                                      text="likes"
                                      type="series"
                                      class="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss"
                                    />
                                  </h3>{" "} */}
                                </span>
                              </a>
                            </>
                          </Tooltip>
                        )}
                      </>
                    )}

                    <Tooltip title="Views" aria-label="add">
                      <>
                        <a
                          style={{ border: "#fff solid 2px" }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3"
                        >
                          <Visibility style={{ color: "#fff", fontSize: 30 }} />
                          {/* <FeatherIcon icon="eye" size="24" color="black" /> */}
                          <i
                            className="text-white rounded-full p-2 transition -mr-1"
                            style={{ fontSize: 25 }}
                          ></i>{" "}
                          <span style={{ color: "#fff" }}>
                            {" "}
                            <b>
                              <h3
                                style={{ color: "#fff" }}
                                className="text-white font-xssss fw-600"
                              >
                                {" "}
                                {props.views} Views
                              </h3>{" "}
                            </b>
                          </span>
                        </a>
                      </>
                    </Tooltip>

                    <Tooltip title="Share Link" aria-label="add">
                      <>
                        <CopyToClipboard
                          // text={window.location.href}
                          text={`${process.env.REACT_APP_URL}/signin${window.location.pathname}?${queryParams}`}
                          onCopy={() => setCopied(true)}
                        >
                          <a
                            style={{ border: "#fff solid 2px" }}
                            className="btn-round-lg ms-2 d-inline-block rounded-3"
                          >
                            <ShareIcon
                              style={{ color: "#fff", fontSize: 30 }}
                            />
                            <FeatherIcon icon="eyme" size="24" color="black" />
                            <i
                              className="text-white rounded-full p-2 transition -mr-1"
                              style={{ fontSize: 25 }}
                            ></i>{" "}
                          </a>
                        </CopyToClipboard>
                        <br />
                        {copied ? (
                          <>
                            <Snackbar
                              open={copied}
                              autoHideDuration={3000}
                              onClose={closeSnack}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Alert onClose={closeSnack} severity="success">
                                Link Copied Successfully
                              </Alert>
                            </Snackbar>
                          </>
                        ) : null}
                      </>
                    </Tooltip>

                    {props.isFavorited == "true" ? (
                      <Tooltip title="Remove from chill list" aria-label="add">
                        <a
                          onClick={() => removeWatchLater(series.id)}
                          // href="/defaulthoteldetails"
                          style={{
                            border: "#fff solid 2px",
                            backgroundColor: "#FF0000",
                          }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                        >
                          <BookmarkRemoveIcon
                            style={{ color: "#fff", fontSize: 30 }}
                          />
                          <FeatherIcon icon="e" size="24" color="black" />
                          <i
                            style={{ color: "#fff" }}
                            className=" font-sm text-white"
                          ></i>{" "}
                        </a>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add To chill list" aria-label="add">
                        <a
                          onClick={() => watchLater(series.id)}
                          // href="/defaulthoteldetails"
                          style={{ border: "#fff solid 2px" }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                        >
                          <BookmarkAddedIcon
                            style={{ color: "#fff", fontSize: 30 }}
                          />
                          <FeatherIcon icon="eyke" size="24" color="black" />
                          <i className=" font-sm text-white"></i>{" "}
                        </a>
                      </Tooltip>
                    )}

                    {props.isAdmin == true && (
                      <>
                        {series.is_approved == "false" ? (
                          <>
                            <Tooltip title="Approve Movie" aria-label="add">
                              <a
                                onClick={() => approveContent(series.id)}
                                style={{
                                  border: "#fff solid 2px",
                                  backgroundColor: "green",
                                }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                              >
                                <AddTaskIcon
                                  style={{ color: "#fff", fontSize: 30 }}
                                />
                                <FeatherIcon
                                  icon="eyme"
                                  size="24"
                                  color="black"
                                />
                                <i
                                  style={{ color: "#fff" }}
                                  className=" font-sm text-white"
                                ></i>{" "}
                              </a>
                            </Tooltip>
                            {/* <OutlineButton onClick={() => approveContent(movie.id)}>
                          Approve Content
                        </OutlineButton> */}
                            {/* <br /> */}
                          </>
                        ) : (
                          <>
                            <DisApproveMovie
                              movie={series}
                              link="movie/series/dis/approve_movie"
                              functionProp={props.functionProp}
                            />
                            <ApproveMonetization
                              movie={series}
                              link="movie/series/update_monetization"
                              cpvLink="movie/series/update_cpv"
                              //  sponsoredLink='movie/smake_sponsored'
                              functionProp={props.functionProp}
                              url="series/analytics"
                            />
                            {/* <p>This has been approved </p>
                            <OutlineButton
                              onClick={() => approveContent(movie.id)}
                            >
                              Dis-Approve Content
                            </OutlineButton> */}
                            {/* <br /> */}
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div>
                    {saving ? (
                      <>
                        <br />
                        <br />
                        <br />
                        <p className="text-white">
                          {" "}
                          Adding to chill list... This will take a few seconds{" "}
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {saved ? (
                      <>
                        <br />
                        <br />
                        <br />
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          action={
                            <>
                              <a
                                onClick={() => setSaved(false)}
                                className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                              >
                                close
                              </a>
                            </>
                          }
                        >
                          You've added this series to your chill list
                          successfully.
                          <a href={`/chill-list`}>
                            Click here to view it in your chill list
                          </a>
                        </Alert>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div>
                    {removing ? (
                      <>
                        <br />
                        <br />
                        <br />
                        <p className="text-white">
                          {" "}
                          Removing from chill list... This will take a few
                          seconds{" "}
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {removed ? (
                      <>
                        <br />
                        <br />
                        <br />
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          action={
                            <>
                              <a
                                onClick={() => setRemoved(false)}
                                className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                              >
                                close
                              </a>
                            </>
                          }
                        >
                          You've sucessfully removed this series from your chill
                          list.
                        </Alert>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <Tooltip title="Share This Movie" aria-label="add">
                    <a
                      href="/defaulthoteldetails"
                      style={{ border: "#fff solid 2px" }}
                      className="btn-round-lg ms-2 d-inline-block rounded-3 "
                    >
                      <i className="feather-share-2 font-sm text-white"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title="Add To Wacth List" aria-label="add">
                    <a
                      href="/defaulthoteldetails"
                      style={{ border: "#fff solid 2px" }}
                      className="btn-round-lg ms-2 d-inline-block rounded-3 bg-danger"
                    >
                      <i className="feather-bookmark font-sm text-white"></i>{" "}
                    </a>
                  </Tooltip> */}

                  {/* <a
                    href="/defaulthoteldetails"
                    className="bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-3 d-inline-block mt-0 p-2 lh-34 text-center ls-3 w200"
                  >
                    BUY NOW
                  </a> */}
                  {/* </div> */}
                </div>

                <div className="cast">
                  {/* <div className="section__header">
                    <h2 style={{ color: "#fff" }}>Casts</h2> <br />
                    <br />
                  </div> */}
                  <CastList id={"580489"} casts={props.casts} />
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="container">
              <div className="section mb-3">
                {seriesMovies.length === 0 ? (
                  <p className="text-white">
                    No Episode In Series For Now - Check Back Later
                  </p>
                ) : (
                  <>
                    <h1 style={{ color: "#fff" }}>
                      Watch Episodes {seriesMovies.length} In the {series.title}{" "}
                      Series
                    </h1>
                    {/* <MovieList link={`series/${series.id}`} route="series/episode" type='Series'/> */}
                    {/* {seriesMovies.map((movie, id) => ( */}
                    <>
                      {/* <p>video list is here</p> */}
                      <MovieList
                        movies={seriesMovies}
                        id={series.id}
                        channelId={series.channel_id}
                      />
                      {/* <EpisodeCard
                        movies={movie}
                        id={id}
                        link={`series/${series.id}`}
                        route="series/episode"
                        type="Series"
                        adminId={props.adminId}
                        channelSlug={props.channelSlug}
                      /> */}
                    </>
                    {/* ))} */}
                  </>
                )}
                {/* <EpisodeCard /> */}
              </div>
              <div className="section mb-3">
                {/* <MovieListMap
                  heading=" Watch More Series In Channel"
                  link={`channels/${props.channelId}/series`}
                  seriesLink='true'
                  // route="series"
                  // type="Series"
                  // channelId={props.channelId}
                  // url="series"
                  // channelSlug={props.channelSlug}
                  // adminId={props.adminId}
                  // functionProp={props.functionProp}
                /><br/> */}
                <SeriesGridMap
                  heading="Watch More Series In Channel"
                  link={`channels/${props.channelId}/series`}
                  seriesLink="true"
                />
              </div>
              <div className="section mb-3">
                {/* <div className="section__header mb-2">
                  <h2 style={{ color: "#fff" }}>Watch More Channel Movies</h2>
                </div> */}
                <MovieListMap
                  heading="Watch Channel Contents"
                  link={`channels/${props.channelId}/contents`}
                />

                <SeriesListMap
                  heading="Explore Trending Series"
                  link={`movie/view/all_trending/series`}
                />
                {/* <MovieGridMap
                  heading="Watch More Channel Content"
                  link={`channels/${props.channelId}/contents`}
                  
                /> */}
              </div>
              <div className="section mb-3">
                {/* <div className="section__header mb-2">
                  <h2 style={{ color: "#fff" }}>Watch More Channel Videos</h2>
                </div> */}
                <SeriesListMap
                  heading="Explore Latest Series"
                  link={`movie/view/all_latest/series`}
                  // route="view/more/series"
                  // type="Video"
                  // channelId={props.channelId}
                  // url="video"
                  // channelSlug={props.channelSlug}
                  // adminId={props.adminId}
                  // functionProp={props.functionProp}
                />
              </div>
            </div>
            <br />
            {/* <AllMovies /> */}
          </>
        )}
        {/* {video.map((item, i) => (
        <TrailerModal key={i} item={item} />
      ))} */}
      </>
    );
  }
  //   );
};

export default SeriesDetail;
