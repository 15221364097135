import { Component, useState, useEffect } from "react";
import React from "react";
import "./online.css";
import axios from "axios";
import Load from "../others/Load";
import { useHistory, Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";

export default function UserReferrals(props) {
  const [following, setFollowing] = useState([]);
  const user_name = window.localStorage.getItem("user_name");
  const [fetching, setFetching] = useState(false);
  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState("");

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  //   useEffect(() => {
  //     getFollowing();
  //     // getTags();
  //     // getAlertCategories();
  //   }, []);
  function closeSnack() {
    setCopied(false);
  }

  if (props.referrals.length == 0) {
    return (
      <>
        <p className="text-center"  style={{color: '#000'}}>
          Seems like you have no referrals yet. When friends signup with your
          referral link, you earn 50 chill points. Points allows you get access to premium contents on Chillarx. Copy your link now and start sharing!
        </p>

        <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 py-4 px-6 dark:border-gray-800">
          <h2 className="font-semibold text-lg"  style={{color: '#000'}}>Your Referral Link</h2>
          <p  style={{color: '#000'}}>
            {process.env.REACT_APP_URL}/signup?ref={user_name}
          </p>
          <CopyToClipboard
            text={`I'm inviting you to sign up on Chillarx with this link. ${process.env.REACT_APP_URL}/signup?ref=${user_name} . Chillarx gives you unlimited entertainment from top content creators in Nigeria and beyond. Visit https://about.chillarx.com today to know more. Using this link gives you 100 chill points to access premium contents for free! Sign up now`}
            onCopy={() => setCopied(true)}
          >
            <button  style={{color: '#000'}}>Copy</button>
          </CopyToClipboard>
          <br />

          {copied ? (
            <>
              <Snackbar
                open={copied}
                autoHideDuration={3000}
                onClose={closeSnack}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Alert onClose={closeSnack} severity="success">
                  Link Copied Successfully
                </Alert>
              </Snackbar>
            </>
          ) : null}
        </div>

        <br />
        <br />

        {/* <Load/> */}
      </>
    );
  } else {
    return (
      <>
        <div className="bg-white dark:bg-gray-900 shadow-md rounded-md">
          <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 py-4 px-6 dark:border-gray-800">
            <h2 className="font-semibold text-lg "  style={{color: '#000'}}>
              Total Points: {props.totalPoints}.
            </h2>
            <h2 className="font-semibold text-lg"  style={{color: '#000'}}>Your Referral Link</h2>
            <p  style={{color: '#000'}}>
              {process.env.REACT_APP_URL}/signup?ref={user_name}
            </p>
            <CopyToClipboard
               text={`I'm inviting you to sign up on Chillarx with this link. ${process.env.REACT_APP_URL}/signup?ref=${user_name} . Chillarx gives you unlimited entertainment from top content creators in Nigeria and beyond. Visit https://about.chillarx.com today to know more. Using this link gives you 100 chill points to access premium contents for free! Sign up now`}
              // text={`${process.env.REACT_APP_URL}/signup?ref=${user_name}`}
              onCopy={() => setCopied(true)}
            >
              <button  style={{color: '#000'}}>Copy Link</button>
            </CopyToClipboard>
            <br />

            {copied ? (
              <>
                <Snackbar
                  open={copied}
                  autoHideDuration={3000}
                  onClose={closeSnack}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Alert onClose={closeSnack} severity="success">
                    Link Copied Successfully
                  </Alert>
                </Snackbar>
              </>
            ) : null}
          </div>
          <br />
          <p className="text-center"  style={{color: '#000'}}>
          Chill Points grants you access to premium contents on Chillarx. Invite more friends
              to Chillarx with your link to get more free points
          </p>

          {/* <br />
        <br /> */}

          {props.referrals.map((referral) => (
            <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100">
              <div className="flex items-center justify-between py-3">
                <div className="flex flex-1 items-center space-x-4">
                  <a href={`/profile/${referral.user.user_name}`}>
                    <img
                      src={referral.user.profile_pic}
                      className="bg-gray-200 rounded-full w-10 h-10"
                      onError={fixImage}
                    />
                  </a>
                  <a href={`/profile/${referral.user.user_name}`}>
                    <div className="flex flex-col">
                      <span className="block capitalize font-semibold" style={{color: '#000'}}>
                        {" "}
                        {referral.user.first_name} {referral.user.last_name}
                      </span>
                      <span className="block capitalize text-sm" style={{color: 'blue'}}>
                        {" "}
                        @{referral.user.user_name}{" "}
                      </span>
                    </div>
                  </a>
                </div>

                <a
                  href={`/profile/${referral.user.user_name}`}
                  // onClick={toogleFollow(user.user_name)}
                  style={{color: '#000'}}
                  className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-pink-600 hover:text-white hover:border-pink-600 dark:border-gray-800"
                >
                  {" "}
                  VIEW{" "}
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* <h4 className="rightbarTitle">Recently Following</h4>
        <ul className="rightbarFriendList">
      
          <Online user={following} />
        
        </ul> */}
      </>
    );
  }
}
