import { Component, useState, useEffect } from "react";
import React from "react";
import axios from "axios";

import Load from "../others/Load";
import { useHistory, Link } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { format, formatDistance, subDays } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axiosFetch from "../../config/Interceptors";
import { Button } from "@mui/material";
import FeatherIcon from "feather-icons-react";

export default function ChillarxEarnings(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [earnings, setEarnings] = useState([]);
  const [allEarnings, setAllEarnings] = useState([]);
  const [earningsCount, setEarningsCount] = useState("");
  const [allEarningsCount, setAllEarningsCount] = useState("");
  const [totalEarningsCount, setTotalEarningsCount] = useState("");
  const [fetching, setFetching] = useState([]);
  const [month, setMonth] = React.useState("1");

  const handleChange = (event) => {
    console.log(event.target.value);
    setMonth(event.target.value);
  };

  async function getChannelTransactions() {
    setFetching(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/${props.link}/${month}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setEarnings(resp.data.earnings);
        setEarningsCount(resp.data.earningsCount);
        setAllEarningsCount(resp.data.allEarningsCount);
        setTotalEarningsCount(resp.data.totalEarningsCount);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function switchTransactions() {
    setFetching(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/${props.link}/${month}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        setEarnings(resp.data.allEarnings);
        setEarningsCount(resp.data.earningsCount);
        setAllEarningsCount(resp.data.allEarningsCount);
        setTotalEarningsCount(resp.data.totalEarningsCount);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getChannelTransactions();
  }, [console.log(month, " -> state")]);

  //   const TAX_RATE = 0.07;
  const TAX_RATE = 0.1;

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  function priceRow(qty, unit) {
    return qty * unit;
  }

  function createRow(desc, qty, unit) {
    const price = priceRow(qty, unit);
    return { desc, qty, unit, price };
  }

  // function subtotal(items) {
  //   return items.reduce((sum, i) => sum + i, 0);
  // }
  function subtotal(items) {
    return items.map(({ amount }) => amount).reduce((sum, i) => sum + i, 0);
  }

  const rows = [
    createRow("Paperclips (Box)", 100, 1.15),
    createRow("Paper (Case)", 10, 45.99),
    createRow("Waste Basket", 2, 17.99),
  ];

  const invoiceSubtotal = subtotal(earnings);
  // const invoiceSubtotal = subtotal(earnings.length);
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceTotal = invoiceSubtotal - invoiceTaxes;
  //   const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  return (
    <>
     <h3 style={{color:'#000'}} className='text-center'>Estimated Chillarx Earnings </h3>
                    {/* <h5 style={{color:'#000'}} className='text-center'>Channel's Current Commission Per View (CPV) is N{channels.cpv} </h5> */}
                    <h5 style={{color:'#000'}} className='text-center'>Kindly note that Commission Per View (CPV) is not static. It changes in relation to the number of views by Chillarx subscribers </h5>
      <br/>
      <h5 className="text-center" style={{ color: "#000" }}>
        Hello admin! {props.title}
      </h5>{" "}
      <br />
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Sort</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={month}
          label="Sort"
          onChange={handleChange}
        >
          <MenuItem value={1}>One Month</MenuItem>
          <br />
          <MenuItem value={2}>Two Months</MenuItem>
          <br />
          <MenuItem value={3}>Three Months</MenuItem>
          <br />
          <MenuItem value={4}>Four Months</MenuItem>
          <br />
          <MenuItem value={5}>Five Months</MenuItem>
          <br />
          <MenuItem value={6}>Six Months</MenuItem>
          <br />
          <MenuItem value={7}>Seven Months</MenuItem>
          <br />
          <MenuItem value={8}>Eight Months</MenuItem>
          <br />
          <MenuItem value={9}>Nine Months</MenuItem>
          <br />
          <MenuItem value={10}>Ten Months</MenuItem>
          <br />
          <MenuItem value={11}>Eleven Months</MenuItem>
          <br />
          <MenuItem value={12}>Twelve Months</MenuItem>
          <br />
        </Select>
        <FormHelperText>
          Select a number to get only earnings you've made within the past
          months
        </FormHelperText>
      </FormControl>
      <Button onClick={getChannelTransactions}>Get Earnings</Button>
      <Button onClick={switchTransactions}>See Total Earnings</Button>
      <br />
      {fetching ? (
        <Load bg="#fff" color="#000" />
      ) : (
        <>
        <div className='overflow-scroll'>
          <TableRow
          // key={index}
          // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>
              <div
                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                style={{ background: `#e5f6ff` }}
              >
                <div className="card-body d-flex p-0">
                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                    <FeatherIcon
                      icon="users"
                      style={{ size: 10, fontSize: 10 }}
                      className="btn-round d-inline-block me-2 ps-2 text-white"
                    />
                  </i>
                  <h4 className="text-success font-xl fw-700">
                    {totalEarningsCount}
                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                      Total Views
                    </span>
                  </h4>
                </div>
              </div>
            </TableCell>
            {/* <TableCell align="right">{analytics.channel_name}</TableCell> */}
            <TableCell>
              <div
                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                style={{ background: `#e5f6ff` }}
              >
                <div className="card-body d-flex p-0">
                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                    <FeatherIcon
                      icon="users"
                      style={{ size: 10, fontSize: 10 }}
                      className="btn-round d-inline-block me-2 ps-2 text-white"
                    />
                  </i>
                  <h4 className="text-success font-xl fw-700">
                    {allEarningsCount}
                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                      Views between now and {month} month ago
                      {/* between now and {month} month
                                      ago{" "} */}
                    </span>
                  </h4>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div
                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                style={{ background: `#e5f6ff` }}
              >
                <div className="card-body d-flex p-0">
                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                    <FeatherIcon
                      icon="users"
                      style={{ size: 10, fontSize: 10 }}
                      className="btn-round d-inline-block me-2 ps-2 text-white"
                    />
                  </i>
                  <h4 className="text-success font-xl fw-700">
                    {earningsCount}
                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                      Views exactly {month} month ago
                      {/* {month} month ago */}
                    </span>
                  </h4>
                </div>
              </div>
            </TableCell>
          </TableRow>
          </div>
          <br />
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, width: "100%" }}
              stickyHeader
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">User</TableCell>
                  {/* <TableCell align="right">Channel</TableCell> */}
                  <TableCell align="right">Channel</TableCell>
                  <TableCell align="right">Movie</TableCell>
                  <TableCell align="right">Movie CPV </TableCell>
                  <TableCell align="right">Movie Pricing </TableCell>

                  <TableCell align="right">Date </TableCell>
                  <TableCell align="right">View Analytics</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {earnings.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index}
                    </TableCell>
                    <TableCell align="right">
                      {row.user.user_name}
                    </TableCell>
                    {/* <TableCell align="right">{row.channel_name}</TableCell> */}
                    <TableCell align="right">{row.channel.name}</TableCell>
                    <TableCell align="right"><a style={{color: 'blue'}} href={`/chillarx/watch/${row.movie.u_id}`}>{row.movie.title}</a></TableCell>
                    <TableCell align="right">{row.movie.cpv}</TableCell>
                    <TableCell align="right"> {row.movie.pricing} {row.movie.price}</TableCell>
                    
                    <TableCell align="right">
                      {" "}
                      {formatDistance(new Date(row.created_at), new Date(), {
                        addSuffix: true,
                      })}
                    </TableCell>
                    <TableCell align="right"><a style={{color: 'blue'}} href={`/content/analytics/${row.movie.u_id}`}> View Content Analytics</a></TableCell>
                  </TableRow>
                ))}

                {/* <TableRow>
                  <TableCell colSpan={5} />
                  <TableCell colSpan={2} align="right">
                    Subtotal
                  </TableCell>
                  <TableCell colSpan={1} align="right">
                    {ccyFormat(invoiceSubtotal)}
                  </TableCell>
                  
                </TableRow> */}
                {/* <TableRow>
                  <TableCell colSpan={5} />
                  <TableCell colSpan={2} align="right">
                    Commission
                  </TableCell>
                  <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
                    0
                  )} %`} Movie's CPV x No Of Views</TableCell>
                
                </TableRow> */}
                <TableRow>
                  <TableCell colSpan={4} />
                  <TableCell colSpan={3} align="right">
                    Estimated Payout
                  </TableCell>
                  <TableCell align="right">Movie's CPV x No Of Chillarx Subscibers Views</TableCell>
                  {/* <TableCell align="right"></TableCell> */}
                  {/* <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell> */}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <br />
      <br />
      <br />
      {earnings.length == 0 && (
        <h6>
          {" "}
          Seems you have none at the moment. If you are a Chillarx Partner,
          whenever you grant Chillarx subscribers access to your contnet, you
          earn a percentage.
        </h6>
      )}
    </>
  );
}
