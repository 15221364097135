import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./movie-list.scss";
import Slider from "react-slick";
import { SwiperSlide, Swiper } from "swiper/react";
// import { Autoplay } from 'swiper';
// import 'swiper/css/autoplay';
import { Link } from "react-router-dom";

import Button from "../button/Button";

import tmdbApi, { category } from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";

import MovieCard from "../movie-card/MovieCard";
import MovieCardMap from "../movie-card/MovieCardMap";
import axiosFetch from "../../config/Interceptors";

const MovieListMap = (props) => {
  const [items, setItems] = useState([]);
  const movies = props.movies;
  const seriesLink = props.seriesLink

  const [content, setContent] = useState([]);
  const id = props.channelId;
  const movieType = props.movieType;

  const hotelsettings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 600,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    centerMode: false,
    variableWidth: false,
    spaceBetween: 10,
    // responsive: [
    //   {
    //     breakpoint: 800,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 420,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };

  async function getMovies() {
    const id = props.channelId;
    const link = props.link;
    // console.log(`hii ${id} ${link}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/${link}`, {
        method: "GET",
        // delay: 500000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(resp.data)
        setContent(resp.data);
      })
      .catch((error) => alert(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getMovies();
    // const getList = async () => {
    //     let response = null;
    //     const params = {};

    //     if (props.type !== 'similar') {
    //         switch(props.category) {
    //             case category.movie:
    //                 response = await tmdbApi.getMoviesList(props.type, {params});
    //                 break;
    //             default:
    //                 response = await tmdbApi.getTvList(props.type, {params});
    //         }
    //     } else {
    //         response = await tmdbApi.similar(props.category, props.id);
    //     }
    //     setItems(response.results);
    // }
    // getList();
  }, []);

  if (content.length == 0) {
    return "";
  } else {
    return (
      <div className="movie-list">
        <br />
        <br />
        <div className="section__header mb-2 text-center">
          <h2 className="text-center" style={{ color: "#fff" }}>
            {props.heading}
          </h2>
        </div>
        <br />
        <Swiper grabCursor={true} spaceBetween={10} 
        slidesPerView={"auto"}  // modules={[Autoplay]}
        autoplay={true}
        >
          {content.map((content, i) => (
            <SwiperSlide key={i}>
              <MovieCardMap movie={content} category={props.category} seriesLink={seriesLink} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
};

MovieListMap.propTypes = {
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MovieListMap;
