import React, { Component } from "react";
import { useState, useEffect, useRef } from "react";

import Sidebar from "../../../components/studio/sidebar/Sidebar";
import Navbar from "../../../components/studio/navbar/Navbar";
import "./home.scss";
import Widget from "../../../components/studio/widget/Widget";
import Featured from "../../../components/studio/featured/Featured";
import Chart from "../../../components/studio/chart/Chart";
import Table from "../../../components/studio/table/Table";

const HomeDash = () => {

  const bg = window.localStorage.getItem("bgColor")
  const [bgColor, setBgColor] = useState('');

  // useEffect(() => {
  //   if(bg == 'white'){
  //     setBgColor('#fff')
  //   }
  //   else{
  //     setBgColor('#000')
  //   }
  // }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer" style={{backgroundColor: '#fff'}} >
      {/* style={{backgroundColor: '#fff'}} */}
        <Navbar />
        <div className="widgets">
          <Widget type="user" />
          <Widget type="order" />
          <Widget type="earning" />
          <Widget type="balance" />
        </div>
        <div className="charts">
          <Featured />
          <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
        </div>
        <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default HomeDash;
