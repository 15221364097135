// import React from 'react';
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

import { OutlineButton } from "../components/button/Button";
import HeroSlide from "../components/hero-slide/HeroSlide";
import MovieList from "../components/movie-list/MovieList";
import MyMovieList from "../components/movie-list/MyMovieList";
import MySeriesList from "../components/movie-list/MySeriesList";
import MovieGrid from "../components/movie-grid/MovieGrid";

import SeriesList from "../components/movie-list/SeriesList";
import SeriesGrid from "../components/movie-grid/SeriesGrid";

import axiosFetch from "../config/Interceptors";

import { category, movieType, tvType } from "../api/tmdbApi";
import Load from "../components/others/Load";

const Home = () => {
  // const [movieItems, setMovieItems] = useState([]);
  const firstName = window.localStorage.getItem("first_name");
  const [latestMovies, setLatestMovies] = useState([]);
  const [latestSeries, setLatestSeries] = useState([]);
  const [freeMovies, setFreeMovies] = useState([]);
  const [freeSeries, setFreeSeries] = useState([]);
  const [movies, setMovies] = useState([]);
  const [comics, setComics] = useState([]);
  const [videos, setVideos] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [series, setSeries] = useState([]);
  const [trending, setTrending] = useState([]);
  const [trendingSeries, setTrendingSeries] = useState([]);
  const [chillHistory, setChillHistory] = useState([]);
  const [seriesChillHistory, setSeriesChillHistory] = useState([]);
  const [sponsoredMovies, setSponsoredMovies] = useState([]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(20);
  const [getExpiry, setGetExpiry] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [fecthingMovies, setFetchingMovies] = useState(false);

  async function getMoviesCatalogue() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movie/${skip}/${take}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setLatestMovies(resp.data.latestMovies);
        setLatestSeries(resp.data.latestSeries);
        setTrending(resp.data.trending);
        setTrendingSeries(resp.data.trendingSeries);
        setChillHistory(resp.data.chillHistory);
        setSeriesChillHistory(resp.data.seriesChillHistory);
        setFreeMovies(resp.data.freeMovies);
        setFreeSeries(resp.data.freeSeries);
        setSeries(resp.data.latestSeries);
        setMovies(resp.data.movies);
        setComics(resp.data.comics);
        setVideos(resp.data.videos);
        setEpisodes(resp.data.episodes);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getSponsoredMovies() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`movie/sponsored/view/all`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setSponsoredMovies(resp.data);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getExpiryDate() {
    // setFetchingMovies(true);/welcome/sponsored/view/all
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/expiry_left`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        // alert(JSON.stringify(resp.data));
        setGetExpiry(resp.data);
        // setMovieItems(resp.data);
        // setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  function closeError() {
    setError(false);
    console.log("closed");
  }

  useEffect(() => {
    getMoviesCatalogue();
    getSponsoredMovies();
    getExpiryDate();
    // getSeries();
  }, []);

  if (fecthingMovies) {
    return (
      <>
        <br />
        <br /> <br />
        <br /> <br />
        {/* <br />
        <h4 style={{ color: "#fff" }} className="text-center">
          {" "}
          Just A Few Seconds ...
        </h4>
         <br /> */}
        <br />
        <Load bg="#000" color="#fff" message="Just A Few Seconds ..." />
        <br />
        <br />
      </>
    );
  }

  if (sponsoredMovies.length == 0) {
    return (
      <div style={{ marginBottom: 60 }}>
        <br />
        <br /> <br />
        <br /> <br />
        {/* <br />
        <h4 style={{ color: "#fff" }} className="text-center">
          {" "}
          Just A Few Seconds ...
        </h4>
         <br /> */}
        <br />
        <Load bg="#000" color="#fff" message={`Almost Done ... ${firstName}`} />
        {/* <h4 style={{ color: "#fff" }} className="text-center">
          {" "}
          Almost Done ...
        </h4> */}
        <br />
        <br />
      </div>
    );
  } else {
    return (
      <>
        <HeroSlide movieItems={sponsoredMovies} isloggedIn="true" />
        <div className="container" style={{ backgroundColor: "#000" }}>
          <div className="section mb-3">
            <div className="section__header ">
              <h2 style={{ color: "#fff" }}>Free To Watch</h2>
              <Link to="/chillarx/movies">
                <OutlineButton className="small">View more</OutlineButton>
              </Link>
            </div>
            {freeSeries.length !== 0 && (
              <>
                <h3 style={{ color: "#fff" }}>Free Series</h3>
                <SeriesList
                  series={freeSeries}
                  category={category.movie}
                  type={movieType.popular}
                />
                <br />
              </>
            )}
            <br />
            {freeMovies.length !== 0 && (
              <>
                <h3 style={{ color: "#fff" }}>Free Movies</h3>
                <MovieList movies={freeMovies} category={category} />
              </>
            )}
          </div>

          {trendingSeries.length !== 0 && (
            <div className="section mb-3">
              <div className="section__header ">
                <h2 style={{ color: "#fff" }}>Trending Series</h2>
                <Link to="/chillarx/series">
                  <OutlineButton className="small">View more</OutlineButton>
                </Link>
              </div>
              <SeriesList
                series={trendingSeries}
                category={category.movie}
                type={movieType.top_rated}
              />
            </div>
          )}
          <br />
          <br />
          {trending.length !== 0 && (
            <div className="section mb-3">
              <div className="section__header">
                <h2 style={{ color: "#fff" }}>Trending Movies</h2>
                <Link to="/chillarx/movies">
                  <OutlineButton className="small">View more</OutlineButton>
                </Link>
              </div>
              <MovieList
                movies={trending}
                category={category.tv}
                type={tvType.popular}
              />
            </div>
          )}
          <br />
          <br />
          {latestMovies.length !== 0 && (
            <div className="section mb-3">
              <div className="section__header ">
                <h2 style={{ color: "#fff" }}>Explore Latest</h2>
                <Link to="/chillarx/series">
                  <OutlineButton className="small">View more</OutlineButton>
                </Link>
              </div>
              <MovieList
                movies={latestMovies}
                category={category.tv}
                type={tvType.top_rated}
              />
            </div>
          )}

          <br />
          <br />
          {latestSeries.length !== 0 && (
            <>
              <h3 style={{ color: "#fff" }}>Latest Series</h3>
              <SeriesList series={latestSeries} category={category} />
            </>
          )}
          <div className="section mb-3">
            <div className="section__header">
              <h2 style={{ color: "#fff" }}>Suggested For You</h2>
              <Link to="/chillarx/movies">
                <OutlineButton className="small">View more</OutlineButton>
              </Link>
            </div>
            <br />
            <br />
            {comics.length !== 0 && (
              <>
                <h3 style={{ color: "#fff" }}>Latest Comics</h3>
                <MovieList
                  movies={comics}
                  category={category.tv}
                  type={tvType.popular}
                />
              </>
            )}
            <br />
            <br />
            {episodes.length !== 0 && (
              <>
                <h3 style={{ color: "#fff" }}>Latest Episodes</h3>
                <MovieList movies={episodes} category={category} />
              </>
            )}
            <br />
            <br />
            {movies.length !== 0 && (
              <>
                <h3 style={{ color: "#fff" }}>Latest Movies</h3>
                <MovieList movies={movies} category={category} />
              </>
            )}
            <br />
            <br />
            {videos.length !== 0 && (
              <>
                <h3 style={{ color: "#fff" }}>Latest Videos</h3>
                <MovieList movies={videos} category={category} />
              </>
            )}
          </div>

          <br />
          <br />

          {chillHistory.length !== 0 && (
            <div className="section mb-3">
              <div className="section__header ">
                <h2 style={{ color: "#fff" }}>Your Watch History</h2>
                <Link to="/chill-history">
                  <OutlineButton className="small">View history</OutlineButton>
                </Link>
              </div>
              <h3 style={{ color: "#fff" }}> Videos you've explored</h3>
              <MyMovieList
                movies={chillHistory}
                category={category.movie}
                type={movieType.popular}
              />
            </div>
          )}
          <br />
          <br />
          {seriesChillHistory.length !== 0 && (
            <div className="section mb-3">
              <h3 style={{ color: "#fff" }}> Series you've explored</h3>
              <MySeriesList
                series={seriesChillHistory}
                category={category.movie}
                type={movieType.popular}
              />
            </div>
          )}

         
        </div>
      </>
    );
  }
};

export default Home;
