import React from "react";
import { Component, useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import BoltIcon from "@mui/icons-material/Bolt";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Select from "react-select";
import { Country, State, City, ICity } from "country-state-city";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import StandardInput from '@material-ui/core/StandardInput';
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
import MuiAlert from "@material-ui/lab/Alert";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import Load from '../../components/others/Load';

function Copyright() {
  return (
    <>
      <br />
      <Typography variant="body2" color="textSecondary" align="center">
        {"For Help, "}
        <Link color="primary" href="/help">
          Click Here!
        </Link>{" "}
        {"."}
      </Typography>

      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="primary" target="_blank" href="https://about.chillarx.com/">
          Chillarx
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        A Product Of
        <Link
          style={{ paddingLeft: 3 }}
          color="primary"
          target="_blank"
          href="https://soaenterprise.com/"
        >
          SOA Digital Enterprise
        </Link>
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "190vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  image2: {
    backgroundImage:  "url(/assets/images/footer-bg.jpg)",
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundPosition: "cover",
    // 'center',
    width: "100vw",
    height: "180vh",
    backgroundSize: "contain",
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
      backgroundSize: "cover",
    },
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#000'
    // theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    color: "#eee",
    padding: 0,
    margin: 0,
  },
  input: {
    color: "red",
    padding: 0,
    margin: 0,
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [passwordShown, setPasswordShown] = useState(false);
  const cCode = JSON.stringify(window.sessionStorage.getItem("cCode"));
  const sCode = JSON.stringify(window.sessionStorage.getItem("sCode"));

  // const [values, setValues] = React.useState({
  //   password: "",
  //   showPassword: false,
  // });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [countryCode, setCountryCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [signUpMessage, setSignUpMessage] = useState("Creating Your Account");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [referrer, setReferrer] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [pword, setPword] = useState("");
  const [pword2, setPword2] = useState("");
  const [userNameStatus, setUserNameStatus] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userState, setUserState] = useState('');
  const [lga, setLga] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [regError, setRegError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const reff = "";

  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));
  const updatedStates = () =>
    State.getStatesOfCountry(userCountry.isoCode).map((state) => ({
      label: state.name,
      value: state.id,
      ...state,
    }));

  const updatedCities2 = () =>
    City.getCitiesOfCountry(userCountry.isoCode).map((state) => ({
      label: state.name,
      value: state.id,
      ...state,
    }));

  const updatedCities3 = () =>
    City.getCitiesOfState({ cCode, sCode }).map((state) => ({
      label: state.name,
      value: state.id,
      ...state,
    }));

    const updatedCities = () =>
    // setFetchingCities(true)
      City.getCitiesOfState(userState.countryCode, userState.isoCode).map((state) => ({
        label: state.name,
        value: state.id,
        ...state,
      }))

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEmptyFields(false);
    setRegError(false);
    setPasswordMismatch(false);
    // setOpenAlert(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  async function validate() {
    // alert(` mmm  ${lga.name}`);
    if (
      !firstName ||
      !lastName ||
      !userName ||
      !email ||
      !pword ||
      !userCountry
    ) {
      setEmptyFields(true);
      return <Alert severity="warning">Please type in all fields</Alert>;
    }

    // if(pword !== pword2){
    //   setPasswordMismatch(true)
    // }
    else {
      // alert('hi')
      signUp();
    }
  }

  // just need to update something

  async function signUp() {
    setIsRegistering(true);
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/${process.env.REACT_APP_REG_URL}`,
        {
          method: "POST",
          user_name: userName,
          email: email,
          first_name: firstName,
          referrer: referrer,
          last_name: lastName,
          phone_number: phoneNo,
          country: userCountry.name,
          // state: userState.name,
          // closest_lga: lga.name,
          country_code: countryCode,
          password: pword,
        }
      )
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        window.localStorage.setItem("access_token", resp.data.access_token);
        // window.localStorage.setItem("refresh_token", resp.data.refresh_token);
        const token = window.localStorage.getItem("access_token");
        //  console.log(`session stored token ${token}`)
        setSignUpMessage("Logging You In");
        subscribeToChillarx()
        sendVerification();
        getProfile();

        setUserName("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setReferrer("");
        setUserCountry("");
        setPword("");
        setPhoneNo("");
      })
      .catch((error) => {
        setIsRegistering(false);
        setRegError(true);
        setErrorMessage(error.response.data.message);
        console.log(error);
      });
  }

  function getProfile() {
    setSignUpMessage("Redirecting To Dashboard");
    const access_token = window.localStorage.getItem("access_token");
    console.log(`user profile ${access_token}`);
    axios
      .get(`${process.env.REACT_APP_LIVE_URL}/users/profile`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(resp.data);
        window.localStorage.setItem("first_name", resp.data.user.first_name);
        window.localStorage.setItem("user_id", resp.data.user.id);
        window.localStorage.setItem("last_name", resp.data.user.last_name);
        window.localStorage.setItem("user_name", resp.data.user.user_name);
        window.localStorage.setItem(
          "verified",
          resp.data.user.email_verified_at
        );
        window.localStorage.setItem("email", resp.data.user.email);
        window.localStorage.setItem("profile_pic", resp.data.user.profile_pic);
        window.localStorage.setItem("au", resp.data.user.is_admin);
        window.localStorage.setItem("friends", resp.data.friends);
        // window.localStorage.setItem("bio", JSON.stringify(resp.data.user.bio));
        const userID = window.localStorage.getItem("user_id");
        const userFN = window.localStorage.getItem("first_name");
        const userLN = window.localStorage.getItem("last_name");
        const userUN = window.localStorage.getItem("user_name");
        const userEM = window.localStorage.getItem("user_email");
        const userPic = window.localStorage.getItem("profile_pic");

        console.log(userID, userUN, userFN, userLN);

        setIsRegistering(false);
        history.push("/");
        setIsRegistering(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function sendVerification() {
    // setSending(true);
    setSignUpMessage("Sent Your Verification Link");
    const access_token = await window.localStorage.getItem("access_token");

    let result = await axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/email/verification-notification`,
        access_token,
        {
          headers: {
            Accept: "*/*",
            // 'Content-Type': 'multipart/form-data',
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        // setResponse(true);
        // setMessage(resp.data.message);
        // setSending(false);
      })
      .catch((error) => {
        // setSending(false);
        console.log(error);
        // setResponse(true);
        // setMessage(error.message);
      });
  }

  async function subscribeToChillarx(
  ) {
    // setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();

    formData.append("movie_id", 14);
    
    axiosFetch
      .post(`/channels/chillarx_trial`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // props.functionProp();
        console.log(resp.data);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  function redirect() {
    if (window.localStorage.getItem("access_token")) {
      history.push("/");
    }
  }
  useEffect(() => {
    redirect();
    // const count = Country.getAllCountries()
    // console.log('all country are ', count);
    // console.log(State.getAllStates());
    const queryParams = new URLSearchParams(window.location.search);
    console.log(`hiii ${queryParams}`);
    const reff = queryParams.get("ref");
    setReferrer(reff);
    console.log("ref is " + reff);
    // localStorage.setItem('params', queryParams);
    // checkUserName()
    // userCountry, userState, lga
  }, [
    console.log("state and lga is " + userCountry, userState, lga),
    userCountry,
    userState,
    lga,
    cCode,
    sCode,
  ]);

  return (
    <Grid container component="main" className={classes.root} style={{backgroundColor: '#fff'}}>
      <CssBaseline />
      {/* <Grid item xs={12} sm={6} md={6} className={classes.image2} /> */}
      <Grid item xs={false} sm={6} md={6}>
        <br />
        <Typography
          component="h1"
          variant="overline"
          className="text-center"
          style={{ color: "#2516c7" }}
        >
          {" "}
          Welcome! Please always accept the notification prompt
          when asked for a better experience on Chillarx. Scroll Down
        </Typography>
        <div className={classes.image2}></div>
      </Grid>
      <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        {/* <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square> */}
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PlayCircleOutlineIcon style={{ fontSize: 25, color: '#FFD700' }} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>{" "}
          <br />
          <p className="text-center">
            {/* Use your active 11 digits number for SMS delivery ( SMS delivery is currently only available in Nigeria. Other country SMS support will be integrated and announced soon) */}
            Welcome! You are in for tons of unlimited entertainemnt brought to your fingertips!
            
            Additional chill points are awarded to you when you sign up using
            a referral link{" "}
          </p>{" "}
          <br />
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div className="form-group icon-input mb-0.5">
                  <i className="font-sm feather-user text-grey-500 pe-8"></i>

                  <input
                    style={{ paddingLeft: 40 }}
                    type="text"
                    placeholder="Your First Name"
                    className="style2-input form-control text-grey-900 font-xsss ls-3"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="form-group icon-input mb-0.5">
                  <i className="font-sm feather-user text-grey-500 pe-0"></i>

                  <input
                    style={{ paddingLeft: 40 }}
                    type="text"
                    placeholder="Last Name"
                    className="style2-input form-control text-grey-900 font-xsss ls-3"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                {/* <TextField
                variant="standard"
                required
                fullWidth
                // id="lastName"
                label="Last Name"
                // name="lastName"
                // autoComplete="lname"
                // size="small"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              /> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="form-group icon-input mb-0.5">
                  <i className="font-sm feather-at-sign text-grey-500 pe-0"></i>

                  <input
                    style={{ paddingLeft: 40 }}
                    type="text"
                    placeholder="Preferred Username"
                    className="style2-input form-control text-grey-900 font-xsss ls-3"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="form-group icon-input mb-0.5">
                  <i className="font-sm feather-phone text-grey-500 pe-0"></i>

                  <input
                    style={{ paddingLeft: 40 }}
                    type="text"
                    placeholder="Active Phone"
                    className="style2-input form-control text-grey-900 font-xsss ls-3"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                  />
                  {/* <p>
                    {" "}
                    Your active number for SMS delivery ( Leave empty for now if
                    you are not in Nigeria. If you're in Nigeria, use your 11
                    digits only){" "}
                  </p> */}
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="form-group icon-input mb-0.5">
                  <i className="font-sm feather-mail text-grey-500 pe-0"></i>

                  <input
                    style={{ paddingLeft: 40 }}
                    type="email"
                    placeholder="Your Email"
                    className="style2-input form-control text-grey-900 font-xsss ls-3"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Grid>

              {/* <Grid item xs={12} sm={6}>
            <div className="form-group icon-input mb-0.5">
            <i className="font-sm feather-at-sign text-grey-500 pe-0"></i>

              <input
                 style={{paddingLeft: 40}}
                disabled
                type="text"
                placeholder="Referrer Username"
                className="style2-input form-control text-grey-900 font-xsss ls-3"
                value={referrer}
                onChange={(e) => setReferrer(e.target.value)}
              />
            </div>
            <p>This is gotten from your referral link. Use one now if you have</p>
            </Grid> */}

              <Grid item xs={12} sm={6}>
                <div className="form-group icon-input mb-0.5">
                  {passwordShown ? (
                    <i
                      className="font-sm feather-eye-off text-grey-500 pe-0"
                      onClick={togglePassword}
                    ></i>
                  ) : (
                    <i
                      className="font-sm feather-eye text-grey-500 pe-0"
                      onClick={togglePassword}
                    ></i>
                  )}

                  <input
                    style={{ paddingLeft: 40 }}
                    type={passwordShown ? "text" : "password"}
                    className="style2-input form-control text-grey-900 font-xsss ls-3"
                    placeholder="Your Password"
                    value={pword}
                    onChange={(e) => setPword(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Select
                  id="country"
                  name="country"
                  label="country"
                  options={updatedCountries}
                  placeholder="Search country"
                  value={userCountry}
                  onChange={(value) => {
                    console.log("selected ", JSON.stringify(value));
                    setUserCountry(value);
                    setCountryCode(value.phonecode)
                  }}
        
                />
              </Grid>

              {userCountry == "" ? (
                ""
              ) : (
                ''
                // <>
                //   <Grid item xs={12} sm={6}>
                //     <Select
                //       id="state2"
                //       name="state2"
                //       label="state2"
                //       options={updatedStates()}
                //       value={userState}
                //       placeholder="Search State"
                //       onChange={(value) => {
                //         console.log(
                //           "selected state is ",
                //           JSON.stringify(value)
                //         );
                //         setUserState(value);
                //       }}
                //       // onChange={(value) => {
                //       //   setValues({ state: value, city: null }, false);
                //       // }}
                //     />
                //   </Grid>

                //   <Grid item xs={12} sm={6}>
                //     {/* <p onClick={() =>  console.log('previous state choosen is ', userState.isoCode, userState.countryCode)}> Hiii </p> */}
                //     <Select
                //       id="city"
                //       name="city"
                //       label="city"
                //       options={updatedCities(userState ? userState.value : null)}
                //       value={lga}
                //       placeholder="City / LGA"
                //       onChange={(value) => {
                //         console.log("selected lga ", JSON.stringify(value));
                //         setLga(value);
                //       }}
                //       // onClick={() =>  console.log('previous state choosen is ', userState.isoCode, userState.countryCode)}
                //     />
                //   </Grid>
                // </>
              )}

              {/* <Grid item xs={12} sm={6}>
            <div className="form-group icon-input mb-0.5">
            {passwordShown ? (
                <i
                  className="font-sm feather-eye-off text-grey-500 pe-0"
                  onClick={togglePassword}
                ></i>
              ) : (
                <i
                  className="font-sm feather-eye text-grey-500 pe-0"
                  onClick={togglePassword}
                ></i>
              )}

              <input
                 style={{paddingLeft: 40}}
                type={passwordShown ? "text" : "password"}
                placeholder="Confirm password"
                className="style2-input form-control text-grey-900 font-xsss ls-3"
                value={pword2}
                onChange={(e) => setPword2(e.target.value)}
              />
             
            </div>
               {/* <TextField
                variant="standard"
                required
                fullWidth
                // name="password"
                label="Password"
                type={passwordShown ? "text" : "password"}
                helperText="Confirm your password"
                value={pword2}
                onChange={(e) => setPword2(e.target.value)}
              />
              <span>
           
            {passwordShown ? <VisibilityIcon onClick={togglePassword}/> : <VisibilityOffIcon onClick={togglePassword}/>}

            </span> */}
              {/* </Grid> */}

              <Grid item xs={12}>
                <br />
                <p>
                  {" "}
                  After registration, a link will be sent to you. Use the email
                  verification link to complete your account creation and also
                  earn addition chill points{" "}
                </p>
                {/* <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I agree to receive real time alert notifications via email and sms."
                />{" "} */}
              </Grid>
            </Grid>
            {isRegistering ? (
              <>
                <Button
                  disabled
                  fullWidth
                  variant="contained"
                  // color="black"
                  style={{backgroundColor: '#000', color: '#FFD700'}}
                  className={classes.submit}
                  // onClick={validate}
                >
                  {signUpMessage}
                </Button>
                <br />
                <Load message='Creating Your Account...' bg='#fff' color='#000' />
                
              </>
            ) : (
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                // color="black"
                style={{backgroundColor: '#000', color: '#FFD700'}}
                className={classes.submit}
                onClick={validate}
                // onClick={
                //   () => {
                //   alert(userCountry.name, userState.name)
                //   console.log(City.getCitiesOfState("NG", "LA"))
                //   // console.log('previous state choosen is ', userState.isoCode, userState.countryCode)
                // }}
              >
                Get 14 Days Free Trial
              </Button>
            )}
            {emptyFields ? (
              <div>
                <Alert severity="warning" onClose={handleClose}>
                  Please type in the fields above and select your country
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}
            {passwordMismatch ? (
              <div>
                <Alert severity="warning" onClose={handleClose}>
                  Your passwords are not the same. Kindly retype them
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}

            {regError ? (
              <div>
                <Alert severity="error" onClose={handleClose}>
                  There was an issue creating your account. [ {errorMessage} ].
                  Also ensure your phone number is valid and 11 digits only
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}
            {/* <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid> */}
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  Forgot Password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign In
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
