import React, { Component, Fragment, useState, useEffect } from "react";
import "./channel.scss";
import { Route, useParams, useHistory, useLocation } from "react-router-dom";
import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import { Link } from "react-router-dom";
import Datatable from "../../components/studio/datatable/Datatable";
import axiosFetch from "../../config/Interceptors";
import UserSavedMovies from "../../components/studio/channel/UserSavedMovies";
import Load from "../../components/others/Load";
import MyMoviesGrid from "../../components/movie-grid/MyMoviesGrid";
import MySeriesGrid from "../../components/movie-grid/MySeriesGrid";
import Button from "../../components/button/Button";
import InfiniteScroll from "react-infinite-scroll-component";

const ChillHistory = () => {
  const slug = useParams();
  const [fetching, setFetching] = useState(false);
  const [videos, setVideos] = useState([]);
  const [movies, setMovies] = useState([]);
  const [series, setSeries] = useState([]);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(50);
  const [skip2, setSkip2] = useState(0);
  const [take2, setTake2] = useState(50);

  async function getVideos() {
    setFetching(true);
    // console.log(` 1 ${fetching}`)
    const access_token = await window.localStorage.getItem("access_token");
    // setUserId(window.localStorage.getItem("user_id"));
    console.log(window.localStorage.getItem("user_id"));
    axiosFetch
      .get(`/users/chill_history/${skip}/${take}/${skip2}/${take2}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setVideos(resp.data);
        setMovies(resp.data.movies);
        setSeries(resp.data.series);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  function getNext() {
    setSkip(skip + 2);
    // alert(skip)
    getNextMovies();
  }
  
  function getPrev() {
    setSkip(skip - 2);
    // alert(skip)
    getNextMovies();
  }

  function getNext2() {
    setSkip2(skip2 + 2);
    // alert(skip)
    getNextMovies();
  }

  async function getNextMovies() {
    // setFetching(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
    .get(`/users/chill_history/${skip}/${take}/${skip2}/${take2}`, {
        // .get(`/posts/all/${ski}/${tak}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setVideos(resp.data);
        // setMovies((movies) => movies.concat(resp.data.movies))
        // setSeries((series) => series.concat(resp.data.series))
        setMovies([...movies, ...resp.data.movies]);
        setSeries([...series, ...resp.data.series]);

        // setMovieItems([...movieItems, ...resp.data]);
        // setFetchingMovies(false);
      })
      .catch((error) => {
        // setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getVideos();
    console.log('skip is ', skip, 'skip 2 is ', skip2)
  }, [skip, skip2]);

  if (fetching) {
    return (
      <>
        <Load bg="#fff" color="#000" />
      </>
    );
  } else {
    return (
      <div
        className="list  col-xl-12"
        style={{ backgroundColor: "#000", minHeigh: "100%" }}
      >
        <div
          className="listContainer"
          style={{ backgroundColor: "#000", minHeight: "100%" }}
        >
          {/* <ChannelCard slug={slug.slug}/> */}
          <HeaderV2 />

          <div
            className="homeContainer vh-200"
            style={{ backgroundColor: "#000", minHeight: "100%" }}
          >
            <div
              className="main-content"
              style={{ backgroundColor: "#000", minHeight: "100%" }}
            >
              <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                  <div>
                    <div
                      className="card border-0 shadow-xs p-0 mb-4"
                      style={{ backgroundColor: "#000", minHeight: "100%" }}
                    >
                      <div
                        style={{
                          marginRight: 20,
                          marginLeft: 20,
                          marginBottom: 50,
                        }}
                      >
                        <h3 className="text-center" style={{ color: "#fff" }}>
                          <br />
                          <br />
                          Your Chill History -> Contents You've Explored
                        </h3>
                        <br />
                        <br />
                        <h4 className="text-center" style={{ color: "#fff" }}>
                          {" "}
                          You have explored {series.length} series so far
                        </h4>
                        <br />
                        <br />
                        <MySeriesGrid series={series} />
                        <br />
                        {/* <Button onClick={getNext2}>View More</Button> */}
                        <br /><br/>
                        <h4 className="text-center" style={{ color: "#fff" }}>
                          {" "}
                          You have watched {movies.length} movies so far
                        </h4>{" "}
                        <br />
                        <MyMoviesGrid movies={movies} />
                    
                        <br/>
                        <div style={{display: 'flex'}}> 
                        {/* <Button onClick={getPrev}>View Previous</Button>
                        <Button onClick={getNext}>View Next</Button> */}
                        </div>
                        <br/><br/>
                      </div>
                      {/* <UserSavedMovies
                        movies={movies}
                        series={series}
                        url="subscriptions"
                        message="You Haven't Watched Any Movie / Series For Later"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ChillHistory;
