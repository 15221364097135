import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
// import { Select } from "antd";
import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import { AutoComplete } from "antd";
import MuiAlert from "@material-ui/lab/Alert";

import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import GroupAddOutlined from "@mui/icons-material/GroupAddOutlined";

export default function ContentBuyers(props) {
  const [emptyFields, setEmptyFields] = useState(false);

  const [isCreating, setIsCreating] = useState(false);
  const [userId, setUserId] = useState("");
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [castRole, setCastRole] = useState("");
  const [casts, setCasts] = useState([]);

  const [open, setOpen] = React.useState(false);
  const { Option } = AutoComplete;

  const handleClose = () => {
    setOpen(false);
    setEmptyFields(false);
  };
  const handleClose2 = () => {
    // setOpen(false);
    setEmptyFields(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };


  useEffect(() => {
    // searchUsers()
  }, []);

  return (
    <>
      <Tooltip
        className="d-inline-block"
        title={`People that have purchased your ${props.movieType}`}
        onClick={handleClickOpen}
      >
        <IconButton>
          <ShoppingCartIcon
            //  className="btn-outline"
            className="d-inline-block"
            onClick={() => console.log("video")}
            style={{
              color: props.color,
              fontSize: 30,
              marginLeft: 5,
            //   marginRight: 10,
            }}
          />{" "}
        </IconButton>
      </Tooltip>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {" "}
            People that bought {props.movieTitle} 
          </DialogTitle>
          <DialogContent>
            <DialogContentText> View people who have bought this {props.movieType} </DialogContentText>
            {props.buyers.length == 0 ? (
              ""
            ) : (
              <>
                {props.buyers.map((user) => (
                  <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100">
                    <div className="flex items-center justify-between py-3">
                      <div className="flex flex-1 items-center space-x-4">
                        <a href={`/profile/${user.user_name}`}>
                          <img
                            src={user.profile_pic}
                            className="bg-gray-200 rounded-full w-10 h-10"
                            // onError={fixImage}
                          />
                        </a>
                        <Link to={`/profile/${user.user_name}`}>
                          <div className="flex flex-col">
                            <span className="block capitalize font-semibold">
                              {" "}
                              {user.first_name} {user.last_name}
                            </span>
                            <span className="block capitalize text-sm">
                              {" "}
                              @{user.user_name}{" "}
                            </span>
                          </div>
                        </Link>
                      </div>

                    </div>
                  </div>
                ))}
              </>
            )}

           
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            
          </DialogActions>
        </Dialog>{" "}
      </Box>
    </>
  );
}
