import React, { Component, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";

// import Header from "../components/Header";
// import Leftnav from "../components/Leftnav";
// import Rightchat from "../components/Rightchat";
// import Appfooter from "../components/Appfooter";
// import Popupchat from "../components/Popupchat";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

export default function Helpbox() {
  // class Helpbox extends Component {
  // render() {
  // const history = useHistory();
  // useEffect(()=> {
  //     if(window.localStorage.getItem("access_token"))
  //     {
  //       history.push("/")
  //     }
  //   },[])

  return (
    // <Fragment>
    //     <Header />
    //     <Leftnav />
    //     <Rightchat />

    // <div className="main-content right-chat-active">
    //     <div className="middle-sidebar-bottom">
    //         <div className="middle-sidebar-left"> f5f5f5
    <div style={{ backgroundColor: "#000" }}>
      <div style={{ marginLeft: 20, marginRight: 20, paddingTop: 30 }}>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div
              className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
              style={{ backgroundImage: `url("assets/images/help-bg.png")` }}
            >
              <div
                className="card-body p-md-5 p-4 text-center"
                style={{ backgroundColor: "#FFD700" }}
              >
                <h2 className="fw-700 display2-size text-white display2-md-size lh-2">
                  Welcome to Chillarx Help Center!
                </h2>
                <p className="font-xsss ps-lg-5 pe-lg-5 lh-28 text-black">
                  Explore our frequently asked questions below and get quick
                  answers to questions you might have. If you need further
                  assitance, have a complaint, or wish to pass accross a vital
                  information to us, Send us a message using the button below
                  and we'll respond shortly.
                </p>
                {/* <div className="form-group w-lg-75 mt-4 border-light border p-1 bg-white rounded-3 ms-auto me-auto">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group icon-input mb-0">
                        <i className="ti-search font-xs text-grey-400"></i>
                        <input
                          type="text"
                          className="style1-input border-0 ps-5 font-xsss mb-0 text-grey-500 fw-500 bg-transparent"
                          placeholder="Search anything.."
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <a
                        href="helpbox"
                        className="w-100 d-block btn bg-current text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase "
                      >
                        Search
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
              <Accordion
                className="accodion-style--1 accordion"
                preExpanded={"0"}
              >
                <AccordionItem className="card shadow-xss">
                  <AccordionItemHeading className="card-header">
                    <AccordionItemButton>
                      <h5 className="fw-600 pt-2 pb-2 mb-0">
                        What is Chillarx ?
                      </h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="card-body">
                    <p>
                      Chillarx is an entertainment streaming application where
                      tons of engaging and comic contents are made readily at
                      your fingertips. When you want to take a chill ... use
                      Chillarx
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="card shadow-xss">
                  <AccordionItemHeading className="card-header">
                    <AccordionItemButton>
                      <h5 className="fw-600 pt-2 pb-2 mb-0">
                        What Do The Pricing Mean ?
                      </h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="card-body">
                    <p>
                      Contents on Chillarx have several pricing model. It all
                      depends on the content creator. Free contents are availble
                      to all for free. Channel subscribers only content are
                      exclusively for the channel's subscribers. Paid contents
                      are premium contents that are only available after
                      purchase. Lastly, contents tagged 'chillarx subscribers'
                      means anyone subscribed to chillarx itself has access to
                      that content.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="card shadow-xss">
                  <AccordionItemHeading className="card-header">
                    <AccordionItemButton>
                      <h5 className="fw-600 pt-2 pb-2 mb-0">
                        How can I monetize my contents better
                      </h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="card-body">
                    <p>
                      If you are content creator looking to make more profit
                      from your works, Chillarx is the best application for you.
                      All you need to do is create your channel, and we will
                      walk you through how to optimize our algorithm better.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                {/* <AccordionItem className="card shadow-xss">
                  <AccordionItemHeading className="card-header">
                    <AccordionItemButton>
                      <h5 className="fw-600 pt-2 pb-2 mb-0">
                        What are reward points ?
                      </h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="card-body">
                    <p>
                      Reward points are equivalent to SMS points. Sms points
                      enables you send instant sms to friends from Alarrt. You
                      are awarded sms points when you create your account on
                      Alarrt. You get additional points if you signup with a
                      friend's referral link, when friends signup with your
                      referral link, when you verify your email address and much
                      more scenerios. Your referral link can be gotten from your
                      profile.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem> */}
              </Accordion>
              <br />
              <br />
              <a
                href="/contact-us"
                className="bg-success text-center flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block mr-4 px-4 py-2 rounded shado text-white"
              >
                Need More Info? Click To Send Us A Message
              </a>{" "}
              <br />
              <a
                href="https://about.chillarx.com"
                className="bg-success text-center flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
              >
                Know more about Chillarx here
              </a>{" "}
              <br />
              <a
                href="/signin"
                className="bg-success text-center flex font-bold hover:bg-success text-center hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
              >
                Go Home
              </a>{" "}
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    //         </div>
    //     </div>
    // </div>

    // <Popupchat />
    // <Appfooter />

    // </Fragment>
  );
}
// }
