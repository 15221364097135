import { Component, useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import Load from "../others/Load";

import { format, formatDistance, subDays } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axiosFetch from "../../config/Interceptors";
import { Button } from "@mui/material";


export default function UserTransactions(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [userTransactions, setUserTransactions] = useState([]);
  const [fetching, setFetching] = useState([]);
  const [month, setMonth] = React.useState("1");

  const handleChange = (event) => {
    console.log(event.target.value);
    setMonth(event.target.value);
  };

  async function getUsersTransactions() {
    setFetching(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/my_transactions/${month}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setUserTransactions(resp.data);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getUsersTransactions();

    var d = new Date("January 14, 2012");
console.log('1 ',d.toLocaleDateString());
d.setMonth(d.getMonth() + 3);
console.log('2 ',d.toLocaleDateString());

  }, []);

  return (
    <>
      <h5 className="text-center" style={{ color: "#000" }}>
        Hello {props.userName}! Below are the transactions you've made on
        Chillarx{" "}
      </h5>{" "}
      <br />
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Sort</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={month}
          label="Sort"
          onChange={handleChange}
        >
          <MenuItem value={1}>One Month</MenuItem>
          <br />
          <MenuItem value={2}>Two Months</MenuItem>
          <br />
          <MenuItem value={3}>Three Months</MenuItem>
          <br />
          <MenuItem value={4}>Four Months</MenuItem>
          <br />
          <MenuItem value={5}>Five Months</MenuItem>
          <br />
          <MenuItem value={6}>Six Months</MenuItem>
          <br />
          <MenuItem value={7}>Seven Months</MenuItem>
          <br />
          <MenuItem value={8}>Eight Months</MenuItem>
          <br />
          <MenuItem value={9}>Nine Months</MenuItem>
          <br />
          <MenuItem value={10}>Ten Months</MenuItem>
          <br />
          <MenuItem value={11}>Eleven Months</MenuItem>
          <br />
          <MenuItem value={12}>Twelve Months</MenuItem>
          <br />
        </Select>
        <FormHelperText>
          Select a number to get transactions you've made within the past months
        </FormHelperText>
      </FormControl>
      <Button onClick={getUsersTransactions}>Get Transactions</Button>
      <br />
      {fetching ? (
        <Load bg="#fff" color="#000" />
      ) : (
        <TableContainer component={Paper} style={{ zIndex: -20 }}>
          <Table
            sx={{ minWidth: 650 }}
            stickyHeader
            size="small"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Channel</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Reference</TableCell>
                <TableCell align="right">Transaction</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userTransactions.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index}
                  </TableCell>
                  <TableCell align="right">{row.channel_name}</TableCell>
                  <TableCell align="right">{row.type}</TableCell>
                  <TableCell align="right">{row.description}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="right">{row.reference}</TableCell>
                  <TableCell align="right">{row.transaction}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align="right">
                    {" "}
                    {formatDistance(new Date(row.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <br />
      <br />
      <br />
      {userTransactions.length == 0 && (
        <h6>
          {" "}
          Seems you have made none at the moment. Whenever you buy any content
          or make a paid subscription, your transactions history can be found
          here.
        </h6>
      )}
    </>
  );
}
