import React, { Component, useEffect, useState } from "react";
// import { Select } from "antd";
import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import { Link } from "react-router-dom";
import { MenuItem } from "@material-ui/core";
import { format, formatDistance, subDays } from "date-fns";
import { useTheme } from "@mui/material/styles";
import { useHistory, useParams } from "react-router";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import EpisodeList from "../movie-list/EpisodeList";
import ShowMoreText from "react-show-more-text";
import ChannelsSeriesEdit from "../channel/ChannelsSeriesEdit";
import ChannelsEpisodesModal from "../channel/ChannelsEpisodesModal";
// import Typography from '@mui/material/Typography';
import Tooltip from "@mui/material/Tooltip";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddCasts from "../channel/AddCasts";
import ContentBuyers from "../channel//ContentBuyers";
import ImageComponentDiv from "../../others/ImageComponentDiv";
import MovieList from "../../movie-list/MovieList";
import InsightsIcon from "@mui/icons-material/Insights";

export default function SeriesCardChannels(props) {
  const theme = useTheme();
  const [showEpisodes, setShowEpisodes] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [currentSeries, setCurrentSeries] = useState('');
  const channelSlug = props.channelSlug;
  const channelId = props.channelId;
  const adminId = props.adminId;
  let history = useHistory();
  const [redirect, setRedirect] = useState("/chillarx/watch/series");
  const seriesLink = props.seriesLink;

  const handleClose = () => {
    setConfirmDelete(false);
  };

  function confirmDeletion(id) {
    if (confirmDelete == true) {
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
    }
  }

  async function deleteSeries(id) {
    const access_token = await window.localStorage.getItem("access_token");
    // const userID = await window.localStorage.getItem("user_id");
    // alert(id)
    axiosFetch
      .delete(
        `/movie/series/${id}`,
        // id,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        // window.location.reload(true);
        setConfirmDelete(false);
        props.functionProp();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }

  function toogleEpisodes() {
    if (showEpisodes == true) {
      setShowEpisodes(false);
    } else {
      setShowEpisodes(true);
    }
  }

  useEffect(() => {
    if (seriesLink) {
      setRedirect("/chillarx/watch/series");
    } else {
      setRedirect("/chillarx/watch");
    }
  }, []);

  return (
    <>
      {props.series.map((series, id) => (
        <div>
          <Card sx={{ display: "flex" }} style={{ marginBottom: 15 }}>
            <div style={{ width: "40%", height: "100%" }}>
              <ImageComponentDiv
                image={series.image}
                class="card-body position-relative h300 bg-image-cover bg-image-center coverImg"
              />
            </div>

            {/* <CardMedia
              component="img"
              sx={{ width: 50 }}
              // image="/assets/images/post/img3.jpg"
              image={series.image}
              alt="content image"
            /> */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  {series.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  <span style={{ paddingRight: 1 }}> Released </span>
                  {formatDistance(new Date(series.created_at), new Date(), {
                    addSuffix: true,
                  })}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Status: {series.status}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Is Approved: {series.is_approved}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Pricing: {series.pricing} {series.price}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Chillarx Monetized: {series.chillarx_monetized}
                </Typography>
                <br />
                <ShowMoreText
                  style={{ color: "#000", fontWeight: 600 }}
                  lines={4}
                  more="Read more"
                  less="Show less"
                  className="overview text-black"
                  //    className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  {series.overview}
                </ShowMoreText>
              </CardContent>

              {props.isAdmin ? (
                <>
                  <Box
                    sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
                  >
                    <ChannelsSeriesEdit
                      className="d-inline-block"
                      title={series.title}
                      overview={series.overview}
                      tags={series.tags}
                      pricing={series.pricing}
                      price={series.price}
                      image={series.image}
                      cover_image={series.cover_image}
                      type={series.type}
                      status={series.status}
                      isPartner={props.isPartner}
                      cpv={series.cpv}
                      monetized={series.chillarx_monetized}
                      seriesId={series.id}
                      tags={series.tags}
                      color="#2516c7"
                      channelId={props.channelId}
                      functionProp={props.functionProp}
                      functionProp2={props.functionProp2}
                      // functionProp={() => props.functionProp()}
                    />
                    {/* <Link */}
                    {/* // to={{ */}
                    {/* // pathname: `series/${series.slug}?channel=${channelSlug}&w=${series.id}&a=${adminId}`,
                      // pathname: `series/${series.slug}`,
                      // state: {
                      //   channelSlug: props.channelSlug, */}
                    {/* //   id: series.id,
                      //   adminId: props.adminId,
                      // },
                    // }}
                  // > */}
                    <IconButton
                      aria-label="play/pause"
                      onClick={() => {
                        history.push({
                          pathname: `/chillarx/watch/series/${series.u_id}`,
                        });
                        window.location.reload();
                      }}
                    >
                      <PlayArrowIcon
                        style={{ height: 35, width: 35, color: "#2516c7" }}
                      />
                    </IconButton>
                    {/* </Link> */}
                    {/* <AddCasts movieId={movie.id} color='#fff' getUrl='casts/movie/get' addUrl='casts' removeUrl='casts' type='movie' channelId={props.channelId} movieType ={movie.type}/> */}
                    <AddCasts
                      movieId={series.id}
                      color="#2516c7"
                      getUrl="casts/series"
                      addUrl="casts"
                      removeUrl="casts/series"
                      type="series"
                      channelId={props.channelId}
                      movieType={series.type}
                    />
                  </Box>

                  <Box
                    sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
                  >
                    {series.pricing == "paid" ? (
                      <ContentBuyers
                        buyers={series.buyers}
                        movieTitle={series.title}
                        color="#2516c7"
                        movieType={series.type}
                      />
                    ) : (
                      ""
                    )}

                    <Tooltip title="Delete Content">
                      <IconButton>
                        <DeleteForeverOutlinedIcon
                          onClick={() => confirmDeletion(series.id)}
                          style={{ color: "#2516c7", fontSize: 30 }}
                        />{" "}
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Series Analytics">
                <IconButton>
                  <InsightsIcon
                    onClick={() => {
                      history.push({
                        pathname: `/series/analytics/${series.u_id}`,
                        state: {adminId: 5}
                      });
                      // window.location.reload();
                    }}
                    style={{ color: "#2516c7", fontSize: 30 }}
                  />{" "}
                </IconButton>
              </Tooltip>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
                >
                  <IconButton aria-label="previous">
                    {theme.direction === "rtl" ? (
                      <SkipNextIcon />
                    ) : (
                      <SkipPreviousIcon />
                    )}
                  </IconButton>
                  {/* <Link
                    to={{
                      pathname: `series/${series.slug}?channel=${channelSlug}&w=${series.id}&a=${adminId}`,
                     
                    }}
                  > */}
                  <IconButton
                    aria-label="play/pause"
                    onClick={() => {
                      history.push({
                        pathname: `/chillarx/watch/series/${series.u_id}`,
                      });
                      window.location.reload();
                    }}
                  >
                    <PlayArrowIcon style={{ height: 40, width: 40 }} />
                  </IconButton>
                  {/* </Link> */}
                  <IconButton aria-label="next">
                    {theme.direction === "rtl" ? (
                      <SkipPreviousIcon />
                    ) : (
                      <SkipNextIcon />
                    )}
                  </IconButton>
                </Box>
              )}
              <CardActions>
                {/* <Link
                  to={{
                    pathname: `series/${series.slug}?channel=${props.channelSlug}&w=${series.id}&a=${props.adminId}`,
                  }}
                  onClick={() => {
                    window.location.reload();
                  }}
                > */}
                {/* <a href={`/channel/series/episode/${series.slug}`}> */}

                <Button
                  size="small"
                  onClick={() => {
                    history.push({
                      pathname: `/chillarx/watch/series/${series.u_id}`,
                    });
                    window.location.reload();
                  }}
                >
                  VIEW SERIES
                </Button>
                {/* </a> */}
                {/* </Link> */}
                {/* <br /> */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {showEpisodes && currentSeries == series.id ? (
                    <Button size="small" onClick={() => {
                      setCurrentSeries(series.id)
                      toogleEpisodes()}}>
                      CLOSE {series.movies.length} EPISODES{" "}
                    </Button>
                  ) : (
                    <Button size="small" 
                    onClick={() => {
                      setCurrentSeries(series.id)
                      toogleEpisodes()}}>
                    {/* // onClick={toogleEpisodes}> */}
                      VIEW {series.movies.length} EPISODES{" "}
                    </Button>
                  )}
                </Box>
                {/* <Button size="small">SHARE </Button> */}
              </CardActions>
              <CardActions>
                {props.isAdmin ? (
                  <ChannelsEpisodesModal
                    className="d-inline-block"
                    seriesId={series.id}
                    title={series.title}
                    channelId={props.channelId}
                    functionProp={props.functionProp}
                    functionProp2={props.functionProp2}
                    // functionProp={() => props.functionProp()}
                  />
                ) : (
                  ""
                )}

                {/* <Button size="small">SHARE </Button> */}
              </CardActions>
            </Box>
          </Card>

          {confirmDelete ? (
            <>
              <p>
                Deleteing a series will also delete all of its episodes. You can
                set it as draft instead
              </p>
              <MuiAlert
                onClose={handleClose}
                severity="error"
                action={
                  <>
                    <a
                      onClick={handleClose}
                      className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                    >
                      No, Cancel.
                    </a>

                    <a
                      onClick={() => deleteSeries(series.id)}
                      className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                    >
                      Yes, delete it.
                    </a>
                  </>
                }
              >
                Are you sure you want to delete this series ?
              </MuiAlert>
            </>
          ) : (
            ""
          )}
         
          <div>
            {showEpisodes && currentSeries == series.id ? (
              <>
                <br />
                {props.isAdmin ? (
                  <>
                  <h4> Hello Admin, Kindly view this is desktop mode or your pc for a better experience</h4>
                    {/* <MovieList movies={series.movies} /> */}
                    <EpisodeList
                  episodes={series.movies}
                  channelId={props.id}
                  type={props.type}
                  url={props.url}
                  channelSlug={props.channelSlug}
                  isAdmin={props.isAdmin}
                  adminId={props.adminId}
                  functionProp={props.functionProp}
                  functionProp2={props.functionProp2}
                />
                  </>
                ) : (
                  <>
                    <MovieList movies={series.movies} />
                    {/* {series.movies.map((c, id) => (
                      <p style={{ color: "red" }}>{c.title}</p>
                    ))} */}
                  </>
                )}

                {/* <EpisodeList
                  episodes={series.movies}
                  channelId={props.id}
                  type={props.type}
                  url={props.url}
                  channelSlug={props.channelSlug}
                  isAdmin={props.isAdmin}
                  adminId={props.adminId}
                  functionProp={props.functionProp}
                  functionProp2={props.functionProp2}
                /> */}
                <br />
                <br />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ))}
    </>
  );
}
