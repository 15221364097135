import axios from "axios";
import dayjs from 'dayjs';

const access_token = window.localStorage.getItem('access_token')

const axiosFetch = axios.create({
    
  //https://chillarx-dev-api.herokuapp.com https://alarrt-dev-api.herokuapp.com/api https://alarrt-dev-api.herokuapp.com/api   alarrt-dev-api.herokuappprocess.env.REACT_APP_LIVE_URL
    baseURL:  process.env.REACT_APP_LIVE_URL ,
    headers: {
      Accept: 'application/json',
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + access_token,
    },
  });


axiosFetch.interceptors.request.use(
    (request) => {
      request.headers.common['Accept'] = `application/json`;
    
      console.log('auth fetch request sent');
      
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  axiosFetch.interceptors.response.use(
    (response) => {
      console.log('got response');
      return response;
    },
    (error) => {
      console.log(error.response);
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        // do something
        originalRequest._retry = true;
        console.log('auth axios error FOUND getting refresh');

        axios
        .post(`${process.env.REACT_APP_REFRESH_URL}/oauth/token`, {
          grant_type: "refresh_token",
          refresh_token: window.localStorage.getItem("refresh_token"),
          client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
          client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
        })
        .then((response) => {
          {console.log(JSON.stringify(response))}
          window.localStorage.setItem("access_token", response.data.access_token);
         
          window.location.reload()
          console.log("New Token Recieved");
           return axiosFetch();
        })
        .catch((err) => {
          console.log('seems it has expired')
          window.localStorage.clear();    
          window.location.replace("/signin");
          window.location.reload()
        
          localStorage.setItem("auth_timeout", 'true');
          return Promise.reject(err);
        });

      }

      return Promise.reject(error);
    }
  );

  export default axiosFetch;