import { Component, useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import Load from "../others/Load";
import { useHistory, Link } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { format, formatDistance, subDays, addMonths, getMonth, setMonth } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function UserDueSoon(props) {

    const Due = props.chillarxDue;

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      description: "This column has a value getter and is not sortable.",
    },
    {
      field: "channel",
      headerName: "Channel",
      width: 200,
      description: "This column has a value getter and is not sortable.",
    },
    {
      field: "type",
      headerName: "Transaction Type",
      width: 180,
      description: "This column has a value getter and is not sortable.",
    },
    {
      field: "description",
      headerName: "Description",
      width: 230,
      description: "This column has a value getter and is not sortable.",
    },
    {
      field: "amount",
      headerName: "Amount Paid",
      description: "This column has a value getter and is not sortable.",
      type: "number",
      width: 100,
    },
    {
      field: "date",
      headerName: "Date Paid",
      description: "This column has a value getter and is not sortable.",
      width: 160,
    },
    {
        field: "due",
        headerName: "Days Left",
        description: "This column has a value getter and is not sortable.",
        width: 160,
      }
    // {
    //   field: "total",
    //   headerName: "Total",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.channel || ""} ${params.row.type || ""}`,
    // },
  ];


  const rowData = props.dueSoon.map((trans, index) => {
    return {
      id: index,
      channel: trans.channel_name,
      type: trans.type,
      description: trans.description,
      amount: trans.amount,
      date:  formatDistance(new Date(trans.created_at), new Date(), {
        addSuffix: true,
      }),
      due:  formatDistance(new Date(trans.created_at), new Date(), {
        addSuffix: true,
      })
    };
  });


  return (
    <>
      <h5 className="text-center" style={{ color: "#000" }}>
        Hello {props.userName}! Below are the transactions you've made on
        Chillarx{" "}
      </h5>{" "}
      <br />
    
      {/* {props.chillarxDue.length == 0 && (
        <h6>
          You have no transactions expiring soon.
          Seems you have made no transactions at the moment. Whenever you buy any content
          or make a paid subscription, your transactions expiry can be found
          here.
        </h6>
      )} */}
      <br/>
      <TableContainer component={Paper} style={{ zIndex: -20 }}>
          <Table
            sx={{ minWidth: 650 }}
            stickyHeader
            size="small"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Channel</TableCell>
                <TableCell align="right">Transaction Type</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Amount Paid</TableCell>
                <TableCell align="right">Renewal</TableCell>
                <TableCell align="right">Date Paid</TableCell>
                <TableCell align="right">Due Date </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {/* {props.chillarxDue.map((row, index) => ( */}
                <TableRow
                  key={Due.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {/* {Due.id} */}
                  </TableCell>
                  <TableCell align="right">{Due.channel_name}</TableCell>
                  <TableCell align="right">{Due.type}</TableCell>
                  <TableCell align="right">{Due.description}</TableCell>
                  <TableCell align="right">{Due.amount}</TableCell>
                  <TableCell align="right">Monthly</TableCell>
                  <TableCell align="right">
                    {" "}
                    {formatDistance(new Date(Due.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </TableCell> 
                  <TableCell align="right">
                    {" "}
                   {formatDistance(addMonths(new Date(Due.created_at), 1), new Date(), { addSuffix: true })}
                  </TableCell>
                </TableRow>
              {/* ))} */}
              {props.dueSoon.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index}
                  </TableCell>
                  <TableCell align="right">{row.channel_name}</TableCell>
                  <TableCell align="right">{row.type}</TableCell>
                  <TableCell align="right">{row.description}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="right">Quaterly</TableCell>
                  <TableCell align="right">
                    {" "}
                    {formatDistance(new Date(row.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </TableCell> 
                  <TableCell align="right">
                    {" "}
                    {formatDistance(addMonths(new Date(row.created_at), 3), new Date(), { addSuffix: true })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        { props.dueSoon.length == 0 ? 
        <>
        <br/><br/>
        <h6>
          {" "}
          Seems you haven't subscribed to a paid channel yet. Your subscription's due data can be found
          here.
        </h6>
        </>
        :
        ''
      }
      {/* <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rowData}
        //   rows={props.transactions}
          columns={columns}
          getRowId={(rows) => rows.id}
          pageSize={5}
          rowsPerPageOptions={[5]}
          autoHeight
        //   checkboxSelection
        />
      </div> */}
    </>
  );
}
