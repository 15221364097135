import React, { Component, useState, Fragment, useEffect } from "react";
// import Header from '../components/Header';
import ProfileCard from "../../components/others/ProfileCard";

import Load from "../../components/others/Load";
import { Route, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import axiosFetch from "../../config/Interceptors";
import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import "../studio/channel.scss";
// import { useParams } from 'react-router-dom';

// class Authorpage extends Component {
export default function Authorpage() {
  const [fetchingProfile, setFetchingProfile] = useState(false);
  const [profile, setProfile] = useState([]);
  const [status, setStatus] = useState("");
  const [action, setAction] = useState("");
  const { user_name } = useParams(["user_name"]);
  // alert(user_name)

  // async function getUserDetails()  {
  //     setFetchingProfile(true);
  //     // console.log(` 1 ${fetchingProfile}`)
  //     const access_token = await window.localStorage.getItem('access_token')
  //     axios.get(`https://alert-lumen-api.herokuapp.com/api/users/show/${user_name}`, {
  //      method: 'GET',
  //      headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + access_token,

  //     },
  //     }).then(resp => {

  //         const data = JSON.stringify(resp.data)
  //          setProfile(resp.data.user);
  //          setStatus(resp.data.followStatus);
  //          setAction(resp.data.action);
  //         // this.setState({profile: resp.data })
  //         // console.log(`user:data = ${profile}`)
  //         console.log(`user:data 2 = ${JSON.stringify(resp.data.user.user_name)}`)
  //         setFetchingProfile(false);
  //         //  console.log(` 2 ${fetchingProfile}`)
  //     })
  //     .catch(error => alert(`Error Seen : ${error}`));
  //     // }
  // }
  // useEffect(() => {
  //     // getUserDetails()

  // }, []);
  // const firstName = JSON.stringify(profile.first_name);
  const firstName = profile.last_name;
  return (
    <Fragment>
      <div className="list col-xl-12" style={{ backgroundColor: "#fff" }}>
        {/* <Sidebar /> */}
        <div className="listContainer">
          {/* <Navbar /> */}
          <HeaderV2 />

            <ProfileCard />
          
        </div>
      </div>
      {/* <HeaderSidebar< /> */}
      {/* <Leftnav /> */}
      {/* <Rightchat /> */}
      {/* {console.log(`profile is ${JSON.stringify(profile.first_name)}`)}
                {console.log(`profile is 2 ${profile.first_name}`)} */}

      {/* <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0"> */}
      <div className="row overflow-scroll">
        <div className="col-xl-12">
          {/* <ProfilecardTwo dataParentToChild = {data} user_name={this.state.user_name} /> */}
          {/* {firstName} */}
          {/* <ProfileCard profile={profile} action={action} status={status} /> */}
        </div>
        {/* <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                    <Profiledetail profile={profile} />
                                    <Profilephoto />
                                    <Events />
                                </div> */}
        {/* <div className="col-xl-8 col-xxl-9 col-lg-8">
                                    <Createpost />
                                    <UserPosts user_name={user_name} id="31" postvideo="" postimage="post.png" avater="user.png" user="David Goria" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                </div> */}
      </div>
      {/* </div>
                    </div>
                </div> */}

      {/* <Popupchat />
                <Appfooter />  */}
    </Fragment>
  );
  // }
}

// export default Authorpage;
