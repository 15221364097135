import React, { Component, useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';

export default function Load(props) {
    const [bg, setBg] = useState("#fff");
    const [color, setColor] = useState("#000");

    useEffect(() => {
      if(props.bg){
          setBg(props.bg)
          setColor(props.color)
      }else{
        setBg("#000")
        setColor('#FFD700')
        // setColor('#FFD700')
      }
      }, []);

  return (
    <div
      style={{ color: "#000", backgroundColor: bg }}
      className="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3"
    >
      {props.message ? (
        <>
          <p style={{ color: color }}> {props.message} </p>
          <br />
        </>
      ) : (
        <>
          <p style={{ color: color }}> Just a few seconds </p>
          <br />
        </>
      )}

      <div
        className="snippet mt-2 ms-auto me-auto"
        data-title=".dot-typing"
        style={{ color: color }}
      >
        <div className="stage" style={{ color: color }}>
        <CircularProgress style={{ color: color }} />
          {/* <div className="dot-typing" style={{ color: color }}></div> */}
        </div>
      </div>
    </div>
  );
}
// }

// export default Load;
