import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import FeatherIcon from 'feather-icons-react';

// import Header from "../components/Header";
// import Leftnav from "../components/Leftnav";
// import Rightchat from "../components/Rightchat";
// import Appfooter from "../components/Appfooter";
// import Popupchat from "../components/Popupchat";


class Settings extends Component {
  render() {
    return (
      <Fragment>
        {/* <Header /> */}
        <HeaderV2 />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="middle-wrap">
                <div className="card  vh-180 w-100 border-0 bg-white shadow-xs p-0 mb-4">
                  <div className="card-body p-lg-5 p-4 w-100 border-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                          Settings
                        </h4>
                        <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2">
                          General
                        </div>
                        <ul className="list-inline mb-4">
                          <li className="list-inline-item d-block border-bottom me-0">
                            <Link
                              to="/profileinformation"
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                               
                              <i className="btn-round-md bg-gold-gradiant text-white font-md me-3" style={{display: 'flex', fontSize: 20, justifyContent: 'center', alignItems:'center'}}>
                              <FeatherIcon icon="home" size="24" color='#fff' /></i>{" "}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                Update Profile
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                          {/* <li className="list-inline-item d-block border-bottom me-0"><Link to="/contactinformation" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-gold-gradiant text-white feather-map-pin font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Contact Information</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
                          {/* <li className="list-inline-item d-block me-0"><Link to="/socialaccount" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-red-gradiant text-white feather-twitter font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Social Acount</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
                        </ul>

                        {/* <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                          Account Settings
                        </div> */}
                        {/* <ul className="list-inline mb-4">
                          <li className="list-inline-item d-block me-0">
                            <Link
                              to="/social_accounts"
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                               
                              <i className="btn-round-md bg-gold-gradiant text-white font-md me-3" style={{display: 'flex', fontSize: 20, justifyContent: 'center', alignItems:'center'}}>
                              <FeatherIcon icon="twitter" size="24" color='#fff' /></i>{" "}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                Social Networks
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                         
                        </ul> */}

                        <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                          Other
                        </div>
                        <ul className="list-inline">
                          <li className="list-inline-item d-block border-bottom me-0">
                            <a
                              href="/help"
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                              
                              <i className="btn-round-md bg-gold-gradiant text-white font-md me-3" style={{display: 'flex', fontSize: 20, justifyContent: 'center', alignItems:'center'}}>
                              <FeatherIcon icon="help-circle" size="24" color='#fff'/></i>{" "}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                FAQ
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </a>
                          </li>
                          <li className="list-inline-item d-block border-bottom me-0">
                            <a
                              href="/contact-us"
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                              
                              <i className="btn-round-md bg-gold-gradiant text-white font-md me-3" style={{display: 'flex', fontSize: 20, justifyContent: 'center', alignItems:'center'}}>
                              <FeatherIcon icon="mail" size="24" color='#fff'/></i>{" "}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                Contact Us
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </a>
                          </li>
                          <li className="list-inline-item d-block border-bottom me-0">
                            <Link
                              to="/notifications"
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                             
                              <i className="btn-round-md bg-gold-gradiant text-white font-md me-3" style={{display: 'flex', fontSize: 20, justifyContent: 'center', alignItems:'center'}}>
                              <FeatherIcon icon="bell" size="24" color='#fff'/></i>{" "}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                Your Notifications
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>
                          <li className="list-inline-item d-block border-bottom me-0">
                            <a
                              href='https://wa.me/message/3VHYR6VAI3MSD1'
                              target='_blank'
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                              
                              <i className="btn-round-md bg-gold-gradiant text-white font-md me-3" style={{display: 'flex', fontSize: 20, justifyContent: 'center', alignItems:'center'}}>
                              <FeatherIcon icon="message-circle" size="24" color='#fff' /></i>{" "}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                Whatsapp Support
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </a>
                          </li>
                          <li className="list-inline-item d-block border-bottom me-0">
                            <Link
                              to="/support"
                              target="_blank"
                              className="pt-2 pb-2 d-flex align-items-center"
                            >
                              
                              <i className="btn-round-md bg-gold-gradiant text-white font-md me-3" style={{display: 'flex', fontSize: 20, justifyContent: 'center', alignItems:'center'}}>
                              <FeatherIcon icon="help-circle" size="24" color='#fff'/></i>{" "}
                              <h4 className="fw-600 font-xsss mb-0 mt-0">
                                Get Technical Support
                              </h4>
                              <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            </Link>
                          </li>

                          {/* <li className="list-inline-item d-block me-0"><a href="/login" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Logout</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></a></li> */}
                        </ul>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  }
}

export default Settings;
