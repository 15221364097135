import React,{Component, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Load from "../../others/Load";
import ShowMoreText from "react-show-more-text";


export default function ChannelDetails(props) {
    const [isFollowing, setIsFollowing] = useState(false);
    
    function executeOnClick(isExpanded) {
        // console.log(isExpanded);
    }
    // render() {
        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-block p-4">
                    <h4 className="fw-700 mb-3 font-xsss text-grey-900">About Channel</h4>
                    {/* <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{props.profile.bio}</p> */}
                    {/* <ShowMoreText
                      
                        lines={3}
                        more="Read more"
                        less="Show less"
                        // className="fw-500 text-grey lh-26 font-xssss w-100 mb-2"
                        className="content-css"
                        anchorClass="my-anchor-css-class"
                        onClick={executeOnClick}
                        expanded={false}
                        // width={480}
                        truncatedEndingComponent={"... "}
                        style={{color: '#000'}}
                    >
                       {props.channel.description}
                        
                    </ShowMoreText> */}
                    <p style={{color: '#000'}}>{props.channel.description}</p>
                </div>
                <div className="card-body border-top-xs d-flex">
                    <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">Type <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Channel membership is {props.channel.type}</span></h4>
                </div>

                <div className="card-body d-flex pt-0">
                    <i className="feather-eye text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">Visblilty <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Anyone can subscribe</span></h4>
                </div>
                <div className="card-body d-flex pt-0">
                    <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Location:
                    { !props.admin.state && props.admin.country === null ?
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Nigeria</span> 
                    :
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{props.admin.state} {props.admin.country}</span> 
                    }
                    </h4>
                </div>
                <div className="card-body d-flex pt-0">
                    <i className="feather-users text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Admin: {props.admin.user_name}</h4>
                </div>
            </div>
        );
    // }
}

// export default Profiledetail;