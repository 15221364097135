import React, { Component, useEffect, useState } from "react";
import "./comment.css";
import { Link } from "react-router-dom";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import ShowMoreText from "react-show-more-text";
import { format, formatDistance, subDays } from "date-fns";
import CreateReply from "./CreateReply";
import SingleReply from "./SingleReply";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
import Alert from "@mui/material/Alert";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { Snackbar } from "@material-ui/core";

export default function CommentComponent(props) {
  const [replyOpen, setReplyOpen] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const [isLiking, setIsLiking] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isFavoriting, setIsFavoriting] = useState(false);
  const [message, setMessage] = useState("");
  const [userPic, setUserPic] = useState("");
  const comment = props.comments;
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  function toggleReply() {
    replyOpen == false ? setReplyOpen(true) : setReplyOpen(false);
  }
  function toggleReplyView() {
    showReplies == false ? setShowReplies(true) : setShowReplies(false);
  }
  function closeReply() {
    setReplyOpen(false)
  }
  function openReplyView() {
    // showReplies == false ? setShowReplies(true) : setShowReplies(false);
    setShowReplies(true);
  }

  function handleClose2() {
    setIsLiked(false);
    setIsDeleted(false);
    setIsFavorited(false);
  }

  async function likeComment(id) {
    setIsLiking(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .post(`/${props.url}/like/comment/${id}`, id, {
        headers: {
          Accept: "*/*",
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsLiking(false);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function deleteComment(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .delete(`/comment/${id}/comment`, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsDeleting(false);
        setIsDeleted(true);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function favComment(id) {
    setIsFavoriting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .post(`/comment/fav/${id}/${props.favUrl}`, id, {
        headers: {
          Accept: "*/*",
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsFavorited(true);
        
        setIsFavoriting(false);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  // {console.log(JSON.stringify(props.comments))}
  return (
    <>
      {/* {props.comments.map((comment) => ( */}
      <>
        {isFavorited ? (
          <Snackbar
            open={isFavorited}
            autoHideDuration={2000}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={handleClose2} variant="filled" severity="success">
              Comment added to favorites successfully!
            </Alert>
          </Snackbar>
        ) : (
          ""
        )}

        {isDeleted ? (
          <Snackbar
            open={isDeleted}
            autoHideDuration={2000}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={handleClose2} variant="filled" severity="success">
              Comment deleted successfully!
            </Alert>
          </Snackbar>
        ) : (
          ""
        )}

        {isLiked ? (
          <Snackbar
            open={isLiked}
            autoHideDuration={2000}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={handleClose2} variant="filled" severity="success">
              That was successful!
            </Alert>
          </Snackbar>
        ) : (
          ""
        )}
        <br />
        <div key={comment.comment.id}>
          <div
            className="d-flex"
            style={{ marginLeft: 10, marginRight: 10, marginTop: 15 }}
          >
            {/* <p> Admin id {props.adminId} UserId {props.userId}</p> */}
            <Link to={`/profile/${comment.comment.user.user_name}`}>
              <img
                src={comment.comment.user.profile_pic}
                alt=""
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: 10,
                  border: "2px solid #045de9",
                }}
              />
            </Link>
            <div className="flex">
              <div
                style={{ width: "100%" }}
                className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 h-full relative lg:ml-5 ml-2 lg:mr-15  dark:bg-gray-800 dark:text-gray-100"
              >
                <div className="d-flex">
                  <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                    <Link to={`/profile/${comment.comment.user.user_name}`}>
                      @{comment.comment.user.user_name}
                    </Link>
                  </h4>
                </div>
                <p className="leading-6">
                  <span style={{ marginRight: 20 }}>
                    <ShowMoreText
                      lines={1}
                      more="Read more"
                      less="Show less"
                      // className="leading-6"
                      className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
                      anchorClass="my-anchor-css-class"
                      width={300}
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      {comment.comment.message}
                    </ShowMoreText>
                  </span>
                </p>

                <span className="fw-500 text-grey-600 font-xssss mt-1 pointer">
                  {formatDistance(
                    new Date(comment.comment.created_at),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}{" "}
                  |
                </span>

                <span className="fw-500 text-grey-600 font-xssss mt-1 pointer">
                  {" "}
                  <b>{comment.comment.likes.length}</b> likes |
                </span>

                {replyOpen ? (
                  <span
                    className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                    onClick={toggleReply}
                  >
                    Close Reply |
                  </span>
                ) : (
                  <span
                    className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                    onClick={toggleReply}
                  >
                    Add Reply |
                  </span>
                )}

                {comment.comment.replies.length === 0 ? (
                  ""
                ) : (
                  <>
                    {showReplies ? (
                      <span
                        className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                        onClick={toggleReplyView}
                      >
                        Close {comment.comment.replies.length} Replies
                      </span>
                    ) : (
                      <span
                        data-uk-toggle="target: #reply"
                        className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                        onClick={toggleReplyView}
                      >
                        View {comment.comment.replies.length} Replies
                      </span>
                    )}
                  </>
                )}

                <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
              </div>
            </div>

            <div className="flex">
              {isLiking && currentComment == comment.comment.id ? (
                <HourglassEmptyRoundedIcon />
              ) : (
                <>
                  {comment.likedComment == "true" ? (
                    <>
                      {/* <p>iii</p> */}
                      <FavoriteOutlinedIcon
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          setCurrentComment(comment.comment.id);
                          likeComment(comment.comment.id);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {/* <p>uuuu</p> */}
                      <FavoriteBorderIcon
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          setCurrentComment(comment.comment.id);
                          likeComment(comment.comment.id);
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            {isFavoriting && currentComment == comment.comment.id ? (
              <HourglassEmptyRoundedIcon />
            ) : (
              <>
                {props.adminId == props.userId[0] ? (
                  <>
                    <PushPinOutlinedIcon
                      onClick={() => {
                        setCurrentComment(comment.comment.id);
                        favComment(comment.comment.id);
                      }}
                    />
                    {/* <DeleteIcon
                      onClick={() => deleteComment(comment.comment.id)}
                    /> */}
                  </>
                ) : (
                  ""
                )}
              </>
            )}

            {isDeleting && currentComment == comment.comment.id ? (
              <HourglassEmptyRoundedIcon />
            ) : (
              <>
                {props.adminId == props.userId[0] ? (
                  <>
                    <DeleteIcon
                     onClick={() => {
                      setCurrentComment(comment.comment.id);
                      deleteComment(comment.comment.id);
                    }}
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>

          {replyOpen ? (
            <CreateReply
              Id={props.movieId}
              commentId={comment.comment.id}
              url1="movie"
              url2={"reply"}
              functionProp={props.functionProp}
              openReplyView={() => openReplyView()}
                closeReply={() => closeReply()}
            />
          ) : (
            ""
          )}

          {showReplies ? (
            <>
              {/* <CreateReply
                Id={props.Id}
                commentId={comment.comment.id}
                url1={props.url1}
                url2={"comment"}
                functionProp={props.functionProp}
                func2={toggleReply}
              /> */}
              <br />
              <div style={{ marginLeft: 80 }}></div>
              <SingleReply
                replies={comment.comment.replies}
                functionProp={props.functionProp}
                authId={props.adminId}
                userId={props.userId[0]}
                likeUrl={props.likeUrl}
                favUrl={props.favUrl}
                deleteUrl={`comment`}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </>
      {/* //   ))} */}
    </>
  );
}
