import "./channel.scss";

import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";
import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Datatable from "../../components/studio/datatable/Datatable";
import ChannelsComp from "../../components/studio/channel/ChannelsComp";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import axiosFetch from "../../config/Interceptors";
import { Button } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const SingleChannelAdmin = () => {
  const history = useHistory();
  const animatedComponents = makeAnimated();
  const [subAccountCode, setSubAccountCode] = useState("");
  const slug = useParams("");
  const [message, setMessage] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [status, setStatus] = useState("");
  const [cpv, setCpv] = useState("");
  const [isError, setIsError] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [channel, setChannel] = useState("");
  const [error, setError] = useState(false);

  const statusSelect = [
    { value: "true", label: "True" },
    { value: "false", label: "False" },
    { value: "approved", label: "Approved" },
    { value: "declined", label: "Declined" },
  ];
  const selectCpv = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  // const statusSelect = [
  //   { value: "true", label: "True" },
  //   { value: "false", label: "False" },
  //   { value: "approved", label: "Approved" },
  //   { value: "declined", label: "Declined" },
  // ];

  async function checkProfile() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/profile`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data.user.is_admin);
        if (resp.data.user.is_admin !== "true") {
          history.push("/welcome");
          history.go();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getChannel() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/channels/show/${slug.slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setChannel(resp.data.channel);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleClose = (event, reason) => {
    setIsSuccess(false);
    setEmptyFields(false);
  };
  function closeError() {
    setError(false);
    console.log("closed");
  }
  function changeStatus(value) {
    console.log(`selected ${JSON.stringify(value.value)}`);
    setStatus(value.value);
  }
  function changeCpv(value) {
    console.log(`selected ${JSON.stringify(value.value)}`);
    setCpv(value.value);
  }

  async function updatePatnershipStatus() {
    setIsCreating(true);

    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("partnerStatus", status);

    axiosFetch
      .post(`/channels/update_partnership/${channel.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp);
        setIsSuccess(true);
        setIsCreating(false);
        getChannel();
      })
      .catch((error) => {
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function updateCpv() {
    setIsCreating(true);

    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("cpv", cpv);

    axiosFetch
      .post(`/channels/update_cpv/${channel.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp);
        setIsSuccess(true);
        setIsCreating(false);
        getChannel();
      })
      .catch((error) => {
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function validate() {
    if (!subAccountCode) {
      setEmptyFields(true);
      return;
    } else {
      approveChannel();
    }
  }

  async function validateDisapproval() {
    if (!message) {
      setEmptyFields(true);
      return;
    } else {
      disApproveChannel();
    }
  }

  async function approveChannel() {
    setIsCreating(true);

    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("subAccountCode", subAccountCode);

    axiosFetch
      .post(`/channels/approve_channel/${channel.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp);
        setIsSuccess(true);
        setIsCreating(false);
        getChannel();
      })
      .catch((error) => {
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function disApproveChannel() {
    setIsCreating(true);

    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("message", message);

    axiosFetch
      .post(`/channels/dis/approve_channel/${channel.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp);
        setIsSuccess(true);
        setIsCreating(false);
        getChannel();
      })
      .catch((error) => {
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    checkProfile();
    getChannel();
    // alert(JSON.stringify(slug));
  }, [console.log('cpv is ', cpv, 'status is ', status)]);

  return (
    <div className="list" style={{ backgroundColor: "#fff" }}>
      {/* <Sidebar /> */}
      <HeaderV2 />
      <div
        className="listContainer overflow main-content"
        style={{ margin: 30, height: "100%", minHeight: "100%" }}
      >
        {/* <Navbar /> */}
        {/* <div className="datatable"> */}
        <Button
          onClick={() => {
            history.goBack();
          }}
          variant="contained"
          color="primary"
        >
          Go Back
        </Button>
        <br />
        <br />

        <div className="top" style={{ color: "#000" }}>
          <h1>Approve {channel.name}</h1>
        </div>

        <div
          className="datatableTitle"
          style={{ color: "#000", marginBottom: 20 }}
        >
          Is-Approved: {channel.is_approved}
          <br />
          Previous Acc Code : {channel.subaccount_code}
        </div>

        <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="form-group">
              <label className="mont-font text-black fw-600 font-xsss mb-2">
                Add / Update Channel Sub Account Code
              </label>
              {/* value={this.state.first_name} */}
              <input
                type="text"
                value={subAccountCode}
                onChange={(e) => {
                  setSubAccountCode(e.target.value);
                }}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          {isCreating ? (
            <>
              <Button disabled variant="contained" color="primary">
                Approving Channel
              </Button>
              <br />{" "}
              <p style={{ color: "#000" }}>This may take a quick minute.</p>
            </>
          ) : (
            // <a className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" disabled>Updating Your Profile</a> */}
            <Button
              onClick={validate}
              // fullWidth
              variant="contained"
              color="primary"
            >
              Approve Channel{" "}
            </Button>
            // <a
            //   onClick={validate}
            //   className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
            // >
            //   Create Channel
            // </a>
          )}
          <br />
          {/* <br />
                      <br /> */}
          {isCreating ? (
            <>
              <Button disabled variant="contained" color="primary">
                Dis Approving Channel
              </Button>
              <br />{" "}
              <p style={{ color: "#000" }}>This may take a quick minute.</p>
            </>
          ) : (
            <>
              <br />
              <div className="top" style={{ color: "#000" }}>
                <h3>Dis Approve {channel.name}</h3>
              </div>

              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="form-group">
                    <label className="mont-font text-black fw-600 font-xsss mb-2">
                      Type the reason for disapproving. This is what is
                      displayed to channel admin.
                    </label>
                    {/* value={this.state.first_name} */}
                    <input
                      type="text"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <Button
                onClick={validateDisapproval}
                // fullWidth
                variant="contained"
                color="primary"
              >
                Dis Approve Channel{" "}
              </Button>
            </>
          )}
          <br />
          <br />

          <h3 style={{ color: "#000" }}>Make Channel A Partner</h3>
          <br/>
          <h5>Previous CPV is {channel.cpv}</h5>
          <h5> Select status below. Currently set to {channel.chillarx_partner} </h5><br/>
          <div style={{ display: "flex" }}>
            <Select
              // isClearable
              components={animatedComponents}
              defaultValue={status}
              options={statusSelect}
              onChange={changeStatus}
              placeholder="Select Chillarx Monetiation Partnership"
              color={"#000"}
              style={{ color: "#000" }}
            />
            <div style={{ paddingLeft: 20 }}></div>
            <Select
              // isClearable
              components={animatedComponents}
              defaultValue={cpv}
              options={selectCpv}
              onChange={changeCpv}
              color='#000'
              placeholder="Select Cost Per View"
              style={{ backgroundColor: "#000" }}
            />
          </div>
          <br />
          {isCreating ? (
            <>
              <Button disabled variant="contained" color="primary">
                Updating ...
              </Button>
              <br />{" "}
              <p style={{ color: "#000" }}>This may take a quick sec.</p>
            </>
          ) : (
            <>
          <Button
            onClick={updatePatnershipStatus}
            // fullWidth
            variant="contained"
            color="primary"
          >
            Update Monetization
          </Button>
          <span style={{marginLeft: 20}}></span>
          <Button
            onClick={updateCpv}
            // fullWidth
            variant="contained"
            color="primary"
          >
            Update Cpv
          </Button>
          </>
          )}

          <div style={{ marginBottom: 450 }}></div>

          {emptyFields ? (
            <>
              {/* <br />
                          <br /> */}
              <Alert onClose={handleClose} severity="warning">
                To dis/approve a channel, you need to ensure it has a sub
                account code / message
              </Alert>
            </>
          ) : (
            ""
          )}

          {error ? (
            <div>
              <Alert
                severity="error"
                onClose={handleClose}
                action={
                  <>
                    <CancelIcon onClick={closeError} />
                  </>
                }
              >
                There was an issue creating your channel. Kindly try again [{" "}
                {errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}

          {isSuccess ? (
            <Snackbar
              open={isSuccess}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Alert onClose={handleClose} variant="filled" severity="success">
                Channel was dis/approved sucessfully!
              </Alert>
            </Snackbar>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleChannelAdmin;
