import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import Alert from "@mui/material/Alert";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function ApproveMonetization(props) {
  const [disApprovalMessage, setDisApprovalMessage] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [sponsoredStatus, setSponsoredStatus] = useState("");
  const [cpv, setCpv] = useState("");
  const [openDisapproval, setOpenDisapproval] = useState(false);
  const [approving, setApproving] = useState(false);
  const [open, setOpen] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleOpenApproval = () => {
    setOpenDisapproval(true);
  };

  function handleClose() {
    setOpenDisapproval(false);
  }
  const handleApprovalUpdate = (event) => {
    console.log(event.target.value);
    setApprovalStatus(event.target.value);
  };

  const handleSponsoredUpdate = (event) => {
    console.log(event.target.value);
    setSponsoredStatus(event.target.value);
  };

  const handleCpvUpdate = (event) => {
    console.log(event.target.value);
    setCpv(event.target.value);
  };

  async function upateSponsorship(id) {
    if (sponsoredStatus == "") {
      alert("type in sponsored status first");
    } else {
    setApproving(true);
    // alert('approving, click ok to continue')
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("sponsoredStatus", sponsoredStatus);

    axiosFetch
      .post(`/${props.sponsoredLink}/${id}`, formData, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();

        // alert('relaerted!')
        alert(`updated successfuly. Wait few sec`);
        setApproving(false);
        setOpenDisapproval(false);
        // setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    }
  }

  async function approveContent(id) {
    if (approvalStatus == "") {
      alert("type in status first");
    } else {
    setApproving(true);
    // alert('approving, click ok to continue')
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("monetizedStatus", approvalStatus);

    axiosFetch
      .post(`/${props.link}/${id}`, formData, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();

        // alert('relaerted!')
        alert(`updated successfuly. Wait few sec`);
        setApproving(false);
        setOpenDisapproval(false);
        // setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    }
  }

  async function updateCpv(id) {
    if (cpv == "") {
      alert("type in new CPV first");
    } else {
    setApproving(true);
    // alert('approving, click ok to continue')
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("cpv", cpv);

    axiosFetch
      .post(`/${props.cpvLink}/${id}`, formData, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();

        // alert('relaerted!')
        alert(`updated successfuly. Wait few sec`);
        setApproving(false);
        setOpenDisapproval(false);
        // setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    }
  }

  const cpvCount = 
     [
      1,2,3,4,5,6,7,8,9,10,12,14,16,18,20,22,24,26,28,30,32,34,36,38,40,42,44,46,48,50,52,54,56,58,60,62,64,66,68,70,
      
    ];
  

  return (
    <>
      <Tooltip title="Approve Monetization" aria-label="add">
        <a
          onClick={() => handleOpenApproval()}
          style={{
            border: "#fff solid 2px",
            backgroundColor: "green",
          }}
          className="btn-round-lg ms-2 d-inline-block rounded-3"
        >
          <CreditScoreIcon style={{ color: "#fff", fontSize: 30 }} />
          <FeatherIcon icon="eyek" size="24" color="black" />
          <i style={{ color: "#fff" }} className=" font-sm text-white"></i>{" "}
        </a>
      </Tooltip>

      <Dialog onClose={handleClose} open={openDisapproval}>
        <DialogTitle>
          Approve {props.movie.title} for extra monetization{" "}
        </DialogTitle>

        <div style={{ padding: 30 }}>
          <h5>
            Current Monetization status is {props.movie.chillarx_monetized}.
            Current CPV is {props.movie.cpv}. Current Sponsored Status is{" "}
            {props.movie.is_sponsored}
          </h5>

          <a
            href={`/${props.url}/${props.movie.u_id}`}
            target="_blank"
            //  onClick={() => {
            //   history.push({
            //     pathname: `/${props.url}/${props.movie.u_id}`,
            //     state: {adminId: 5}
            //   });
            // window.location.reload();
            // }}
          >
            {" "}
            <span className="widgetLgName" style={{ color: "blue" }}>
              View {props.movie.title} analytics first
            </span>{" "}
          </a>
          <br />

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={approvalStatus}
                  style={{ width: "50%" }}
                  label="Sort"
                  onChange={handleApprovalUpdate}
                >
                  <MenuItem value={"false"}>False</MenuItem>
                  <br />
                  <MenuItem value={"true"}>True</MenuItem>
                  <br />
                  <MenuItem value={"approved"}>Approved</MenuItem>
                  <br />
                  <MenuItem value={"declined"}>Decline</MenuItem>
                </Select>
                <FormHelperText>Update Monetization Status</FormHelperText>
              </div>
            </div>
          </div>
          <br />

          {approving ? (
            <Button
              variant="contained"
              disabled
              color="primary"
              //   onClick={() => updateCpv(props.movie.u_id)}
            >
              Updating ...
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => approveContent(props.movie.u_id)}
            >
              Update Status Now
            </Button>
          )}
          <br />
          <br />

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={cpv}
                  style={{ width: "50%" }}
                  label="Sort"
                  onChange={handleCpvUpdate}
                >
                  {cpvCount.map((cpv) => (
                    
                    <MenuItem value={cpv}>{cpv}</MenuItem>
                  // <br />
                  //   </>
                  ))}
                  {/* <MenuItem value={1}>1</MenuItem>
                  <br />
                  <MenuItem value={2}>2</MenuItem>
                  <br />
                  <MenuItem value={3}>3</MenuItem>
                  <br />
                  <MenuItem value={4}>4</MenuItem>
                  <br />
                  <MenuItem value={5}>5</MenuItem> */}
                </Select>
                <FormHelperText>Update CPV</FormHelperText>
              </div>
            </div>
          </div>

          <br />
          {approving ? (
            <Button
              variant="contained"
              disabled
              color="primary"
              //   onClick={() => updateCpv(props.movie.u_id)}
            >
              Updating ...
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => updateCpv(props.movie.u_id)}
            >
              Update CPV Now
            </Button>
          )}
          <br />
          <br />

          {/* {props.sponsoredLink && ( */}
            <> 
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={sponsoredStatus}
                      style={{ width: "50%" }}
                      label="Sort"
                      onChange={handleSponsoredUpdate}
                    >
                      <MenuItem value={"true"}>Yes</MenuItem>
                      <br />
                      <MenuItem value={"false"}>No</MenuItem>
                    </Select>
                    <FormHelperText>Update Sponsorship Status</FormHelperText>
                  </div>
                </div>
              </div>
              <br />
              <br />
              {approving ? (
                <Button
                  variant="contained"
                  disabled
                  color="primary"
                  //   onClick={() => updateCpv(props.movie.u_id)}
                >
                  Updating ...
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => upateSponsorship(props.movie.u_id)}
                >
                  Make Content Premium
                </Button>
              )}
            </>
          {/* )} */}

          {approving ? (
            <>
              <br />
              <p className="text-green">
                {" "}
                Updating Content... This will take a few seconds{" "}
              </p>
              <br />
              <br />
            </>
          ) : (
            ""
          )}
        </div>
      </Dialog>
    </>
  );
}
