import "./channel.scss";

import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import Load from "../../components/others/Load";
import { Button } from "@mui/material";
import axiosFetch from "../../config/Interceptors";
import FeatherIcon from "feather-icons-react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SeriesAnalytics1(props) {
  const history = useHistory();
  const location = useLocation();
  const u_id = useParams();
  //   const [data, setData] = useState(userRows);

  const [value, setValue] = React.useState("1");
  const adminId = window.localStorage.getItem("cad");
  const userId = window.localStorage.getItem("user_id");
  const [message, setMessage] = useState("");
  const [getting, setGetting] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [analytics, setAnalytics] = useState([]);

  const [userTransactions, setUserTransactions] = useState([]);
  const [videoTransactions, setVideoTransactions] = useState([]);
  const [allVideoTransactions, setAllVideoTransactions] = useState([]);
  const [fetching, setFetching] = useState([]);
  const [month, setMonth] = React.useState("1");

  const handleTransactionChange = (event) => {
    console.log(event.target.value);
    setMonth(event.target.value);
  };

  function getAnalytics() {
    setGetting(true);
    const access_token = window.localStorage.getItem("access_token");
    const userId = window.localStorage.getItem("user_id");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get(`/movie/series_analytics/get/${u_id.u_id}/${month}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        setAnalytics(resp.data);
        setGetting(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getAnalytics();
    // getChannelTransactions();
    console.log("admin is ", window.localStorage.getItem("cad"));
    // alert(JSON.stringify(u_id.u_id))
  }, []);

  if (analytics.length == 0) {
    return (
      <Fragment>
        <HeaderV2 />
        <div className="bg-white main-content">
          <div className="">
            <div className="" style={{ marginTop: 100 }}>
              <Load bg="#fff" color="#000" />
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  } else {
    return (
      <>
        <div>
          <h5 className="text-center" style={{ color: "#000", marginTop:20 }}>
            Hello admin! Below are the activities on {analytics.movie.title}{" "}
            over the months
          </h5>{" "}
          <br />
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Sort</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={month}
              label="Sort"
              onChange={handleTransactionChange}
            >
              <MenuItem value={1}>One Month</MenuItem>
              <br />
              <MenuItem value={2}>Two Months</MenuItem>
              <br />
              <MenuItem value={3}>Three Months</MenuItem>
              <br />
              <MenuItem value={4}>Four Months</MenuItem>
              <br />
              <MenuItem value={5}>Five Months</MenuItem>
              <br />
              <MenuItem value={6}>Six Months</MenuItem>
              <br />
              <MenuItem value={7}>Seven Months</MenuItem>
              <br />
              <MenuItem value={8}>Eight Months</MenuItem>
              <br />
              <MenuItem value={9}>Nine Months</MenuItem>
              <br />
              <MenuItem value={10}>Ten Months</MenuItem>
              <br />
              <MenuItem value={11}>Eleven Months</MenuItem>
              <br />
              <MenuItem value={12}>Twelve Months</MenuItem>
              <br />
            </Select>
            <FormHelperText>
              Select a number to get only transactions you've made within the
              past months
            </FormHelperText>
          </FormControl>
          <Button onClick={getAnalytics}>Get Transactions</Button>
          {getting ? (
            <>
              <Load bg="#fff" color="#000" />
            </>
          ) : (
            <>
            <div style={{ marginTop: 50 }}></div>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 450, width: "100%" }}
                  stickyHeader
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Total</TableCell>
                      <TableCell>Between now and {month} month ago</TableCell>
                      <TableCell>Exactly {month} month ago</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {analytics.map((analytics, index) => ( */}
                    <TableRow>
                      <TableCell>
                        {" "}
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e5f6ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="eye"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.totalViews}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Impressions
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e5f6ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="eye"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.allViews}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Impressions 
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e5f6ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="eye"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block  me-2 ps-2 bg-success text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.views}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Impressions 
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#f6f3ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                              <FeatherIcon
                                icon="thumbs-up"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-secondary font-xl fw-700">
                              {analytics.totalLikes}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Likes
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#f6f3ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                              <FeatherIcon
                                icon="thumbs-up"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-secondary font-xl fw-700">
                              {analytics.allLikes}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Likes 
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#f6f3ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                              <FeatherIcon
                                icon="thumbs-up"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-secondary font-xl fw-700">
                              {analytics.likes}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Likes
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#fff0e9` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                              <FeatherIcon
                                icon="users"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-warning font-xl fw-700">
                              {analytics.totalSubscribers}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Subscribers
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#fff0e9` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                              <FeatherIcon
                                icon="user-check"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-warning font-xl fw-700">
                              {analytics.allSubscribers}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                New Subscribers 
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#fff0e9` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                              <FeatherIcon
                                icon="user-plus"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-warning font-xl fw-700">
                              {analytics.subscribers}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                New Subscribers
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e5f6ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="shopping-bag"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.totalBuyers}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Content Purchase (if paid)
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e5f6ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="credit-card"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.allBuyers}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Content Purchase 
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e5f6ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="credit-card"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.buyers}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Content Purchase 
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#f6f3ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                              <FeatherIcon
                                icon="film"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-secondary font-xl fw-700">
                              {/* {analytics.admin_movies_count} */}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Chillarx Earnings
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e2f6e9` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="tv"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {/* {analytics.admin_series_count} */}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Chillarx Earnings
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e2f6e9` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="tv"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {/* {analytics.admin_series_count} */}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Chillarx Earnings
                              </span>
                            </h4>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                    <TableCell>l</TableCell>
                    <TableCell>l</TableCell>
                    <TableCell>l</TableCell>
                  </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <div className="row" style={{ height: "100%" }}>
                <div className="col-lg-3 pe-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e5f6ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                        <FeatherIcon
                          icon="video"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                        />
                      </i>
                      <h4 className="text-success font-xl fw-700">
                        {analytics.totalViews}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Total Views
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e5f6ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                        <FeatherIcon
                          icon="film"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                        />
                      </i>
                      <h4 className="text-success font-xl fw-700">
                        {analytics.allViews}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Views between now and {month} month ago{" "}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e5f6ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                        <FeatherIcon
                          icon="video"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block  me-2 ps-2 bg-success text-white"
                        />
                      </i>
                      <h4 className="text-success font-xl fw-700">
                        {analytics.views}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Views {month} month ago
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#f6f3ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                        <FeatherIcon
                          icon="thumbs-up"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 text-white"
                        />
                      </i>
                      <h4 className="text-secondary font-xl fw-700">
                        {analytics.totalLikes}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Total Likes
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#f6f3ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                        <FeatherIcon
                          icon="thumbs-up"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 text-white"
                        />
                      </i>
                      <h4 className="text-secondary font-xl fw-700">
                        {analytics.allLikes}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Likes between now and {month} month ago
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#f6f3ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                        <FeatherIcon
                          icon="thumbs-up"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 text-white"
                        />
                      </i>
                      <h4 className="text-secondary font-xl fw-700">
                        {analytics.likes}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Likes {month} month ago
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <br />
                <div className="col-lg-3 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#fff0e9` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                        <FeatherIcon
                          icon="users"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 text-white"
                        />
                      </i>
                      <h4 className="text-warning font-xl fw-700">
                        {analytics.totalSubscribers}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Total Subscribers
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#fff0e9` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                        <FeatherIcon
                          icon="user-check"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 text-white"
                        />
                      </i>
                      <h4 className="text-warning font-xl fw-700">
                        {analytics.allSubscribers}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          New Subscribers between now and {month} month ago
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#fff0e9` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                        <FeatherIcon
                          icon="user-plus"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 text-white"
                        />
                      </i>
                      <h4 className="text-warning font-xl fw-700">
                        {analytics.subscribers}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          New Subscribers {month} month ago
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <br />
                <div className="col-lg-3 pe-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e5f6ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                        <FeatherIcon
                          icon="shopping-bag"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 text-white"
                        />
                      </i>
                      <h4 className="text-success font-xl fw-700">
                        {analytics.totalBuyers}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Total Content Purchase (if paid)
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e5f6ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                        <FeatherIcon
                          icon="credit-card"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 text-white"
                        />
                      </i>
                      <h4 className="text-success font-xl fw-700">
                        {analytics.allBuyers}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Content Purchase between now and {month} month ago
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e5f6ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                        <FeatherIcon
                          icon="credit-card"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 text-white"
                        />
                      </i>
                      <h4 className="text-success font-xl fw-700">
                        {analytics.buyers}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Content Purchase {month} month ago
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#f6f3ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                        <FeatherIcon
                          icon="film"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 text-white"
                        />
                      </i>
                      <h4 className="text-secondary font-xl fw-700">
                       
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Total Chillarx Earnings
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e2f6e9` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                        <FeatherIcon
                          icon="tv"
                          style={{ size: 10, fontSize: 10 }}
                          className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                        />
                      </i>
                      <h4 className="text-success font-xl fw-700">
                        
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Chillarx Earnings
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <br />

                <div className="col-lg-12 mb-3">
                  {/* <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                width="100%"
                />
            </div> 
                 
                </div>
              </div> */}
            </>
          )}
        </div>
      </>
    );
  }
}
