import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import axiosFetch from "../../config/Interceptors";
import { useLongPress, LongPressDetectEvents } from "use-long-press";

import "./footer.scss";

import { Link } from "react-router-dom";

import bg from "../../assets/footer-bg.jpg";
import logo from "../../assets/tmovie.png";
import SearchBar from "../movie-grid/searchBar";


const FooterGuest = () => {
  const [genres, setGenres] = useState([]);
  const history = useHistory();
  const [getExpiry, setGetExpiry] = useState([]);
  const firstName = window.localStorage.getItem("first_name");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [fecthingMovies, setFetchingMovies] = useState(false);

  const [enabled, setEnabled] = useState(true);
  const callback = useCallback((event) => {
    alert("View Movie Details?");
  }, []);

  async function getGenres() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/tags/welcome`, {
        method: "GET",
        // delay: 500000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(resp.data)
        setGenres(resp.data);
      })
      .catch((error) => alert(`Error Seen : ${error}`));
  }

  async function getExpiryDate() {
    // setFetchingMovies(true);/welcome/sponsored/view/all
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/expiry_left`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        // alert(JSON.stringify(resp.data));
        setGetExpiry(resp.data);
        // setMovieItems(resp.data);
        // setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getGenres();
    // getExpiryDate();
  }, []);

  return (
    <div className="footer" style={{ backgroundImage: `url(${bg})` }}>
      <div className="footer__content container">
        <div className="footer__content__logo">
          <div className="logo">
            <img src={logo} alt="" />
            <Link to="/">Chillarrx!</Link>
          </div>
        </div>

        <SearchBar />
        <br/><br/>
        <h2
          style={{
            fontSize: "2rem",
            color: "#fff",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {" "}
          Trending Genres For You{" "}
        </h2>{" "}
        <br />
        <br />
        <div className="footer__content__menus">
          {genres.map((genre, i) => (
            <Link
              style={{ fontSize: "2rem", fontWeight: 600 }}
              onClick={() => {
                history.push({
                  pathname: `/chillarx/genre/${genre.slug}`,
                });
                window.location.reload();
              }}
            >
              {" "}
              {genre.name}{" "}
              <span style={{ fontSize: "1rem", fontWeight: 600 }}>
                {" "}
                ( {genre.count} ){" "}
              </span>
            </Link>
          ))}
        </div>
        <div style={{ marginTop: 20 }}>
        
          <br />

          <h5
            className="text-center"
            style={{ fontSize: "1.7rem", fontWeight: 600, color: '#fff' }}
          >
            {" "}
            {"Copyright © "}
            <Link
              color="primary"
              target="_blank"
              href="https://about.chillarx.com/"
            >
              CHILLARX
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </h5>
        </div>
        {/*  <div className="footer__content__menus">
          <Link to="/" style={{ fontSize: "1rem", fontWeight: 600, marginTop:20 }}>
            {" "}
            Home
          </Link>
          <Link to="/">Contact us</Link>
          <Link to="/">Term of services</Link>
          <Link to="/">About us</Link>
           <Link to="/">You must watch</Link>
          <Link to="/">Recent release</Link> */}
        {/* <Link to="/">Top Rated</Link>
          <Link to="/">You must watch</Link>
          <Link to="/">Recent release</Link>
          <Link to="/">Top Rated</Link> 
          </div>
          <div className="footer__content__menus">
            <Link to="/">Live</Link>
            <Link to="/">FAQ</Link>
            <Link to="/">Premium</Link>
            <Link to="/">Privacy policy</Link>
          </div>
          <div className="footer__content__menus">
            <Link to="/">You must watch</Link>
            <Link to="/">Recent release</Link>
            <Link to="/">Top Rated</Link> 
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default FooterGuest;
