import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import "../../../components/comment/comment.css";
import { format, formatDistance, subDays } from "date-fns";

import axiosFetch from "../../../config/Interceptors";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { useHistory, useLocation } from "react-router";
import { Grid, makeStyles } from "@material-ui/core";
import "./detail.scss";
// import CastList from "./CastList";
import CastList from "../../detail/CastList";
import VideoListMap from "../../detail/VideoListMap";
// import VideoList from "./VideoList";
import Modal, { ModalContent } from "../../../components/modal/Modal";
import Header from "../../../components/header/Header";
// import Button, {
//   OutlineButton,
// } from "../../../moviesComponents/button/Button";

import Button, { OutlineButton } from "../../../components/button/Button";
import MovieList from "../../../components/movie-list/MovieList";
import MovieListMap from "../../../components/movie-list/MovieListMap";

import SeriesList from "../../../components/movie-list/SeriesList";
import SeriesListMap from "../../../components/movie-list/SeriesListMap";
import SeriesGrid from "../../../components/movie-grid/SeriesGrid";
import SeriesGridMap from "../../../components/movie-grid/SeriesGridMap";

import Tooltip from "@mui/material/Tooltip";
import { InfoOutlined, PlayArrow, Visibility } from "@material-ui/icons";
import ShareIcon from "@mui/icons-material/Share";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AddCardIcon from "@mui/icons-material/AddCard";
import GroupIcon from "@mui/icons-material/Group";
import ShowMoreText from "react-show-more-text";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";

import SendRoundedIcon from "@material-ui/icons/SendRounded";
import CommentComponent from "../../../components/comment/CommentComponent";
import ImageComponent from "../../../components/others/ImageComponent";
import ImageComponentDiv from "../../../components/others/ImageComponentDiv";

// import { Player } from "video-react";
import FavComment from "../../../components/comment/FavComments";
import { usePaystackPayment } from "react-paystack";

// import ShareButtons from "../../../components/ShareButtons";
// import ShareMovie from "../../../components/ShareMovie";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import Alert from "@mui/material/Alert";
import LikersModal from "../../../components/others/LikersModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@material-ui/core";
import ReactPlayer from "react-player";
import MovieGrid from "../../../components/movie-grid/MovieGrid";
import MovieGridMap from "../../../components/movie-grid/MovieGridMap";
import FeatherIcon from "feather-icons-react";
import DisApproveMovie from "../../../components/studio/channel/DisApproveMovie";
import ApproveMonetization from "../../../components/studio/channel/ApproveMonetization";

const useStyles = makeStyles((theme) => ({
  pc: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const Detail = (props) => {
  const [copied, setCopied] = useState(false);
  const location = useLocation();
  const classes = useStyles();
  //   const { category, id } = useParams();

  const [totalSeconds, setTotalSeconds] = useState("");
  const [watchCount, setWatchCount] = useState(1);
  const [hasViewed, setHasViewed] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [chillCount, setChillCount] = useState(1);

  const { slug } = useParams(["slug"]);
  const { url } = useParams(["url"]);
  const [isOpen, setOpen] = useState(false);
  const movie = props.movie;
  const comments = props.comments;
  const ratings = props.ratings;
  const userName = window.localStorage.getItem("user_name");
  const channelSlug = props.channelSlug;
  const seriesMovies = props.seriesMovies;
  const [isLiking, setIsLiking] = useState(false);
  const video = props.video;
  const trailer = props.trailer;
  const [item, setItem] = useState(null);
  let history = useHistory();
  const [content, setContent] = useState([]);
  const [payRef, setPayRef] = useState("");
  const [disApprovalMessage, setDisApprovalMessage] = useState("");
  //   const [movie, setMovie] = useState([]);
  const [showMovie, setShowMovie] = useState(false);
  const [paymentPrompt, setPaymentPrompt] = useState(false);
  const [chillarxPaymentPrompt, setChillarxPaymentPrompt] = useState(false);
  const [isChillAction, setIsChillAction] = useState(false);
  const [chillMessage, setChillMessage] = useState("");
  const [paymentMessage, setPaymentMessage] = useState("");
  const [seriesPaymentPrompt, setSeriesPaymentPrompt] = useState(false);
  const [seriesSubPrompt, setSeriesSubPrompt] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [subAction, setSubAction] = useState("");
  const [subLink, setSubLink] = useState("");
  const [payAction, setPayAction] = useState("");
  const [payLink, setPayLink] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [chillarxSubSuccess, setChillarxSubSuccess] = useState(false);
  const [channelSubSuccess, setChannelSubSuccess] = useState(false);
  const [subPrompt, setSubPrompt] = useState(false);
  const [subMessage, setSubMessage] = useState("");
  //   const url = "https://image.tmdb.org/t/p/w300";
  const [openComment, setOpenComment] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [approving, setApproving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [openDisapproval, setOpenDisapproval] = useState(false);
  const [message, setMessage] = useState("");
  // const [userPic, setUserPic] = useState("");
  // const [userId, setUserId] = useState("");
  // const [userEmail, setUserEmail] = useState("");
  const userEmail = window.localStorage.getItem("email");
  const userPic = window.localStorage.getItem("profile_pic");
  const userId = window.localStorage.getItem("user_id");
  const queryParams = new URLSearchParams(location.search);

  const handleClickOpen = (scrollType) => () => {
    setOpenComment(true);
    setScroll(scrollType);
  };

  const handleOpenApproval = () => {
    setOpenDisapproval(true);
  };

  function closeSnack() {
    setCopied(false);
  }

  async function watchLater(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }
  async function removeWatchLater(id) {
    setRemoving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/un_save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setRemoving(false);
        setRemoved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function approveContent(id) {
    setApproving(true);
    // alert('approving, click ok to continue')
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/approve_movie/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        alert(`approved successfuly. Wait few sec`);
        setApproving(false);
        // setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function disApproveContent(id) {
    if (disApprovalMessage == "") {
      alert("type in your reason for dis-approving first");
    } else {
      setApproving(true);
      // alert('approving, click ok to continue')
      const access_token = await window.localStorage.getItem("access_token");
      const authId = await window.localStorage.getItem("user_id");

      const formData = new FormData();
      formData.append("message", disApprovalMessage);

      axiosFetch
        .post(`/movie/dis/approve_movie/${id}`, id, {
          headers: {
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/html",
            Authorization: "Bearer " + access_token,
          },
          // method: 'POST',
        })
        .then((resp) => {
          const alerts = resp.data;
          props.functionProp();
          // alert('relaerted!')
          alert(`dis-approved successfuly. Wait few sec`);
          setApproving(false);
        })
        .catch((error) => console.error(`Error Seen : ${error}`));
    }
  }

  const handleClose = () => {
    setOpenComment(false);
    setSubPrompt(false);
    setPaymentSuccess(false);
    setChillarxSubSuccess(false);
    setChannelSubSuccess(false);
    setSaved(false);
    setRemoved(false);
    setOpenDisapproval(false);
  };
  const closeVideo = () => {
    setShowMovie(false);
  };

  const toogleVideo = () => {
    if (movie.type == "episode") {
      // alert('this is a episode')
      if (movie.type == "episode" && props.seriesPricing == "free") {
        // setShowMovie(true);
        setPaymentMessage(` Series is free! Enjoy its episodes below`);
        setIsAction(false);
        setShowMovie(true);
      }
      if (
        movie.type == "episode" &&
        props.seriesPricing == "paid" &&
        props.purchasedSeries == "true"
      ) {
        // setShowMovie(true);
        setSeriesPaymentPrompt(true);
        setPaymentMessage(
          ` Hurray! You've purchased this series already. See more Episodes below and
          enjoy!`
        );
        setShowMovie(true);
      }
      if (
        movie.type == "episode" &&
        props.seriesPricing == "paid" &&
        props.purchasedSeries == "false"
      ) {
        setPayAction("");
        setPayLink("");
        setSeriesPaymentPrompt(true);
        setPaymentMessage(
          `This ${movie.type} is not free. Buy the series first to enjoy this episode and others`
        );
        setIsAction(true);
        setPayAction("Buy Series");
        // pathname: `/channel/${movie.channel.slug}/series/${movie.series.slug}?channel=${movie.channel.slug}&w=${movie.series.id}&a=${movie.channel.admin.id}`,
        setPayLink(`/chillarx/watch/series/${props.seriesId}`);
        // setPayLink(`/channel/series/${props.seriesSlug}`);
      }

      // chillarx monetization CHECK
      if (
        movie.type == "episode" &&
        movie.chillarx_monetized == "approved" &&
        props.isChillarxSub == "true"
      ) {
        setShowMovie(true);
        setChillarxPaymentPrompt(true);
        setChillMessage(
          `Awesome! You also have access to this ${movie.type} as a chillarx subscriber.`
        );
      }
      if (
        movie.type == "episode" &&
        movie.chillarx_monetized == "approved" &&
        props.isChillarxSub == "false"
      ) {
        setChillarxPaymentPrompt(true);
        setIsChillAction(true);
        setChillMessage(
          `This ${movie.type} can also be accessed by chillarx subscribers. Subscribe to Chillarx now to watch this and many more for N1000 monthly.`
        );
      }

      if (
        movie.type == "episode" &&
        movie.pricing == "chill_sub" &&
        props.isChillarxSub == "true"
      ) {
        setShowMovie(true);
      }
      if (
        movie.type == "episode" &&
        movie.pricing == "chill_sub" &&
        props.isChillarxSub == "false"
      ) {
        setChillarxPaymentPrompt(true);
        setPaymentMessage(
          `This ${movie.type} is for chillarx subscribers. Subscribe to Chillarx now and watch now for N700 monthly. If you've previously subscribed, and you're seeing this, it means your past subscription has expired. Click subscribe now to proceed`
        );
      }

      if (
        movie.type == "episode" &&
        props.seriesPricing == "subscription" &&
        props.isSub == "false"
      ) {
        setSubAction("");
        setSubLink("");
        setSeriesSubPrompt(true);
        setPaymentMessage(
          `This ${movie.type} is for channel subscribers. You need to
          subscribe first to enjoy this episode and its series. Click the subscribe button to subscribe now.`
        );
        setIsAction(true);
        setSubAction("Subscribe To Channel Now");
        setSubLink(`/channel/${props.channelSlug}/movies`);
      }

      if (
        movie.type == "episode" &&
        props.seriesPricing == "subscription" &&
        props.isSub == "true"
      ) {
        setSeriesSubPrompt(true);
        setPaymentMessage(
          ` Hurray! Series is for channel subscribers and you are subscribed to this channel. See more episodes below and
          enjoy!`
        );
        setIsAction(false);
        // setShowMovie(true);
        setShowMovie(true);
      }

      // alert('this is a episode')
    } else {
      if (movie.pricing == "free") {
        setShowMovie(true);
      }
      // CHILLARX MONETIZATION CHECK
      if (
        movie.chillarx_monetized == "approved" &&
        props.isChillarxSub == "true"
      ) {
        // setSubPrompt(false);
        setShowMovie(true);
        setChillarxPaymentPrompt(true);
        setIsChillAction(false);
        setChillMessage(
          `Awesome! You also have access to this ${movie.type} as a chillarx subscriber.`
        );
      }
      if (
        movie.chillarx_monetized == "approved" &&
        props.isChillarxSub == "false"
      ) {
        setChillarxPaymentPrompt(true);
        setIsChillAction(true);
        setChillMessage(
          `This ${movie.type} can also be accessed by chillarx subscribers. Subscribe to Chillarx now to watch this and many more for N1000 monthly.`
        );
      }

      // CHILLARX SUBSCRIBERS CHECK
      if (movie.pricing == "chill_sub" && props.isChillarxSub == "true") {
        setShowMovie(true);
        setChillarxPaymentPrompt(true);
        setChillMessage(
          `Awesome! You also have access to this ${movie.type} as a chillarx subscriber.`
        );
      }
      if (movie.pricing == "chill_sub" && props.isChillarxSub == "false") {
        setChillarxPaymentPrompt(true);
        setIsAction(true);
        setChillMessage(
          `This ${movie.type} is for chillarx subscribers. Subscribe to Chillarx now and watch now for N700 monthly. If you've previously subscribed, and you're seeing this, it means your past subscription has expired. Click subscribe now to proceed`
        );
      }

      // PAID
      if (movie.pricing == "paid" && props.isPurchased == "false") {
        setPaymentPrompt(true);
        setPaymentMessage(`This ${movie.type} is not free. Buy and watch now for N
      ${movie.price}`);
      }
      if (movie.pricing == "paid" && props.isPurchased == "true") {
        setShowMovie(true);
      }

      if (movie.pricing == "subscription" && props.isSub == "false") {
        // if (props.isSub == "false") {
        setSubPrompt(true);
        setSubMessage(
          `This ${movie.type} is for channel subscribers. You need to
          subscribe first to enjoy this. Click the subscribe 
          button to subscribe now.`
        );
        // setShowMovie(false);
      }

      if (movie.pricing == "subscription" && props.isSub == "true") {
        setShowMovie(true);
      }
    }
  };

  async function purchasedMovie(id) {
    // setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("movie_id", movie.id);
    formData.append("type", "movie");

    axiosFetch
      .post(`/movie_buyers/${movie.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        props.functionProp();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function subscribeToChillarx(
    type,
    description,
    amount,
    reference,
    transaction,
    status,
  ) {
    // setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();

    formData.append("movie_id", movie.id);
    formData.append("type", type);
    formData.append("description", description);
    formData.append("amount", amount);
    formData.append("reference", reference);
    formData.append("transaction", transaction);
    formData.append("status", status);
    
    formData.append("content_type", "movie");

    axiosFetch
      .post(`/channels/chillarx_subscribe`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        props.functionProp();
        console.log(resp.data);
        // CAll chillarx earnings axios
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function handleSubscription(id) {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(`/channels/${id}/subscribe`, id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);
        console.log("channel subscription stat ", data2);
        videoSubscription();
        props.functionProp();
        // setChannelSubSuccess(true);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function videoSubscription(id) {
    const access_token = await window.localStorage.getItem("access_token");

    const formData = new FormData();
    formData.append("movie_id", movie.id);
    formData.append("type", "movie");

    axiosFetch
      .post(`/channels/video/subscribe/${movie.id}`, formData, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        // console.log(resp.data)
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function recordTransaction(
    type,
    description,
    amount,
    reference,
    transaction,
    status,
    commission,
    payout,
    willExpire,
    payType
  ) {
    const access_token = await window.localStorage.getItem("access_token");

    // alert([type, description, amount, reference, commission, payout])
    const formData = new FormData();

    formData.append("channel_id", movie.channel.id);
    formData.append("movie_id", movie.id);
    formData.append("channel_name", movie.channel.name);
    formData.append("type", type);

    formData.append("description", description);
    formData.append("amount", amount);
    formData.append("reference", reference);
    formData.append("transaction", transaction);
    formData.append("status", status);
    formData.append("commission", commission);
    formData.append("payout", payout);
    formData.append("will_expire", willExpire);
    formData.append("pay_type", payType);
    formData.append("movie_id", movie.id);
    formData.append("content_type", "movie");

    axiosFetch
      .post(`/users/add_transaction`, formData, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);

        props.functionProp();
        // setChannelSubSuccess(true);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  const config = {
    reference: new Date().getTime().toString(),
    email: userEmail,
    amount: movie.price * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
    // 'pk_live_cc47dc307b9d60385a97247bb1438f1ef7138b03',
    // props.publicKey,
    subaccount: props.subAccount,
    // 'ACCT_cf5v5zviwmpplm3'
  };

  const chillarxConfig = {
    reference: new Date().getTime().toString(),
    email: userEmail,
    amount: 1000 * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
  };

  const channelConfig = {
    reference: new Date().getTime().toString(),
    email: userEmail,
    amount: props.channelPrice * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
    subaccount: props.subAccount,
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    purchasedMovie();
    setPaymentPrompt(false);
    setChillarxPaymentPrompt(false);
    recordTransaction(
      "Paid Content Purchase",
      `Payment to get access to watch ${movie.title}`,
      movie.price,
      reference.reference,
      reference.transaction,
      `${reference.status} - ${reference.message}`,
      "10",
      movie.price,
      // will expire and payment type
      "false",
      "channel"
    );
    setPaymentSuccess(true);
    // window.location.reload(); {reference: '1653940794987', trans: '1857386196', status: 'success', message: 'Approved', transaction: '1857386196', …}
  };

  const onSuccessChill = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);

    subscribeToChillarx(
      "Chillarx Monthly Subscription",
      `Subsciption to get access to premium contents for chillarx subscribers from - ${movie.title}`,
      1000,
      reference.reference,
      reference.transaction,
      `${reference.status} - ${reference.message}`,
    );
    setChillarxPaymentPrompt(false);

    recordTransaction(
      "Chillarx Monthly Subscription",
      `Subsciption to get access to premium contents for chillarx subscribers from - ${movie.title}`,
      1000,
      reference.reference,
      reference.transaction,
      `${reference.status} - ${reference.message}`,
      "10",
      1000,
      // will expire and payment type
      "true",
      "chillarx"
    );
    setChillarxSubSuccess(true);
    // window.location.reload();
  };
  const onSuccessChannel = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    setSeriesSubPrompt(false);
    setSubPrompt(false);
    handleSubscription(movie.channel.id);
    recordTransaction(
      "Paid Channel Subscription",
      `Subsciption to get access to watch ${movie.title}`,
      movie.channel.price,
      reference.reference,
      reference.transaction,
      `${reference.status} - ${reference.message}`,
      "10",
      movie.channel.price,
      // will expire and payment type
      "true",
      "channel"
    );
    setChannelSubSuccess(true);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const descriptionElementRef = useRef(null);
  const initializePayment = usePaystackPayment(config);

  const initializePaymentChillarx = usePaystackPayment(chillarxConfig);
  const initializePaymentChannel = usePaystackPayment(channelConfig);

  function handleProgress(state) {
    // console.log('onProgress', state)
    console.log("played secs is ", state.playedSeconds);
    const dividedDuration = totalSeconds / 2;
    const viewed = state.playedSeconds;
    // console.log('divided is ', dividedDuration )
    setPlayedSeconds(playedSeconds + 1);
    console.log("total time watched is ", playedSeconds);

    // ADD TO MOVIE VIEWS
    if (viewed > dividedDuration) {
      console.log("user has viewed beyond half");
      if (watchCount == 1) {
        console.log("adding now");
        markAsWatched();
        setWatchCount(2);
      }
      // ADD TO CHILLARX VIEWS
      if (playedSeconds > dividedDuration) {
        if (chillCount == 1) {
          console.log("adding to chillarx views now");
          markAsChillarxViewed();
          setChillCount(2);
        }

        // else {
        //   console.log("viewed, but no need to pass");
        // }
      }
    }
  }
  function handleDuration(duration) {
    console.log("total video duration is ", duration);
    setTotalSeconds(duration);
    // this.setState({ duration })
  }
  async function markAsWatched() {
    const access_token = await window.localStorage.getItem("access_token");

    const formData = new FormData();
    formData.append("type", "movie");

    axiosFetch
      .post(`/movie/viewed_movie/${movie.id}`, formData, {
        method: "POST",
        // delay: 500000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

    async function markAsChillarxViewed() {
    const access_token = await window.localStorage.getItem("access_token");

    const formData = new FormData();
    formData.append("type", "movie");

    axiosFetch
      .post(`/movie/chillarx_mark/asviewed/${movie.id}`, formData, {
        method: "POST",
        // delay: 500000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function likeMovie(id) {
    setIsLiking(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/like/movie/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsLiking(false);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }
  // useEffect(() => {
  //   // getProfile();
  // }, []);

  function handleContextMenu(e) {
    e.preventDefault();

    // ...
  }
  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);
    {
      console.log(window.location.href);
    }
    {
      console.log(window.location.pathname);
    }

    console.log(`hiii ${queryParams}`);
    // getProfile();
    if (openComment) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    // if (url == "series") {
    //   console.log("it is a series ooo!");
    // }
    // if (url == "movie") {
    //   console.log("it is a movie 0000!");
    // }
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [openComment]);

  //   return (
  if (movie.length == 0) {
    return "";
  } else {
    return (
      <div style={{ backgroundColor: "#000" }}>
        <Header />
        {movie && (
          <>
            <ImageComponentDiv class="banner" image={movie.cover_image} />
            <div style={{ paddingTop: 60 }}></div>

            <div className="mb-3 movie-content container">
              <div className="movie-content__poster">
                <ImageComponentDiv
                  class="movie-content__poster__img"
                  image={movie.image}
                />
                {/* <div
                  className="movie-content__poster__img"
                  style={{
                    backgroundImage: `url("${movie.image}")`,
                  }}
                ></div> */}
                <br />
                <br />
                <div className="clearfix"></div>

                <div className="star d-block w-100 text-left mt-2">
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star-disable.png"
                    alt="star"
                    className="w15 float-left me-2"
                    style={{ height: 20, width: 20 }}
                  />
                </div>
                <p className="review-link font-xssss fw-600 text-white lh-3 mb-0">
                  ({ratings} ratings ) {comments.length} comments
                </p>
                <div className="clearfix"></div>
                <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                  <i className="btn-round-sm bg-greylight me-1">
                    <GroupIcon icon="eyee" size="24" color="green" />
                  </i>{" "}
                  {props.casts.length} casts
                </h5>

                {/* {props.seriesPricing } */}

                {movie.pricing == "paid" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                      <AddCardIcon icon="eyee" size="24" />
                    </i>{" "}
                    This {movie.type} is not free. Buy and watch now for N
                    {movie.price}
                  </h5>
                ) : (
                  ""
                )}

                {movie.pricing == "subscription" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                      <AddCardIcon icon="eyee" size="24" />
                    </i>{" "}
                    Pricing: This {movie.type} is only accessible to channel
                    subscribers
                  </h5>
                ) : (
                  ""
                )}

                {movie.pricing == "free" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                      <AddCardIcon icon="eyee" size="24" />
                    </i>{" "}
                    Free: No payment required! This {movie.type} is free to be
                    watched by all
                  </h5>
                ) : (
                  ""
                )}

                {movie.pricing == "chill_sub" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                      <AddCardIcon icon="eyee" size="24" />
                    </i>{" "}
                    Pricing: This {movie.type} is only accessible to chillarx
                    subscribers
                  </h5>
                ) : (
                  ""
                )}

                <br />
                <br />
                <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Explore :{" "}
                  <span style={{ textDecoration: "underline" }}>
                    {" "}
                    <a href={`/channel/${props.channelSlug}/comics`}>
                      {" "}
                      {props.channelName}
                    </a>{" "}
                  </span>
                </h4>
                {/* <OutlineButton
                  onClick={() =>
                    // alert(props.channelSlug)
                    history.push(`/channel/${props.channelSlug}/movies`)
                  }
                >
                  Explore Channel
                </OutlineButton> */}
                <br />

                <div className="clearfix"></div>
              </div>
              <div className="movie-content__info">
                <Grid item sm={12} className={classes.mobile}>
                  <ImageComponentDiv
                    class="movie-content__poster__img"
                    image={movie.image}
                  />

                  <br />
                </Grid>
                <h1
                  style={{
                    color: "#fff",

                    fontSize: "4vw",
                    lineHeight: 1,
                    textAlign: "start",
                  }}
                >
                  {movie.title}
                </h1>

                <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Channel :{" "}
                  <span style={{ textDecoration: "underline" }}>
                    {" "}
                    <a href={`/channel/${props.channelSlug}/movies`}>
                      {" "}
                      {props.channelName}
                    </a>{" "}
                  </span>
                </h4>

                <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Status : {movie.status}{" "}
                  {formatDistance(new Date(movie.created_at), new Date(), {
                    addSuffix: true,
                  })}
                </h4>

                <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Pricing : {movie.pricing} {movie.price}
                </h4>

                {movie.pricing == "subscription" && (
                  <>
                    <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                      {/* {movie.title} */}
                      Channel Subscriber : {props.isSub}
                    </h4>

                    <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                      {/* {movie.title} */}
                      Chillarx Subscriber : {props.isChillarxSub}
                    </h4>
                  </>
                )}

                {movie.pricing == "paid" && (
                  <>
                    <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                      {/* {movie.title} */}
                      Chillarx Subscriber : {props.isChillarxSub}
                    </h4>
                    <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                      {/* {movie.title} */}
                      Bought {movie.type} : {props.isPurchased}
                    </h4>
                  </>
                )}

                <h5 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Type : {movie.type}{" "}
                  {movie.type == "episode" ? (
                    <>
                      {/* //   pathname: `/${url}/${item.slug}?channel=${item.channel.slug}&w=${item.id}&a=${item.channel.admin.id}`, */}
                      <a href={`/chillarx/watch/series/${props.seriesId}`}>
                        <span
                          className="genres__item"
                          style={{
                            padding: "0.5rem 1.5rem",

                            color: "#fff",
                            display: "inline-block",
                            marginTop: 5,
                          }}
                        >
                          View Series
                        </span>
                        {/* <OutlineButton
                        // onClick={() => setOpen(true)}
                        // style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                           View Series 
                      </OutlineButton> */}
                        {/* <h5 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                      View Series 
                      </h5> */}
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </h5>

                <div
                  className="genres clearfix"
                  style={{ overFlow: "auto", display: "block" }}
                >
                  {movie.tags &&
                    movie.tags.slice(0, 5).map((tag, i) => (
                      <span
                        key={i}
                        className="genres__item"
                        style={{
                          padding: "0.5rem 1.5rem",

                          color: "#fff",
                          display: "inline-block",
                          marginTop: 5,
                        }}
                      >
                        {tag.name}
                      </span>
                    ))}
                  <div className="clearfix"></div>
                </div>

                <Grid item sm={12} className={classes.mobile}>
                  <div className="star d-block w-100 text-left mt-2">
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                      style={{ height: 20, width: 20 }}
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                      style={{ height: 20, width: 20 }}
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                      style={{ height: 20, width: 20 }}
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                      style={{ height: 20, width: 20 }}
                    />
                    <img
                      src="/assets/images/star-disable.png"
                      alt="star"
                      className="w15 float-left me-2"
                      style={{ height: 20, width: 20 }}
                    />
                  </div>
                  <p className="review-link font-xssss fw-600 text-white lh-3 mb-0">
                    ({ratings} ratings ) {comments.length} comments
                  </p>
                  <div className="clearfix"></div>
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                    <i className="btn-round-sm bg-greylight me-1">
                      <GroupIcon icon="eyee" size="24" color="green" />
                    </i>{" "}
                    {props.casts.length} casts
                  </h5>
                  {/* {props.seriesPricing } */}
                  {movie.pricing == "paid" && movie.type == "episode" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                        <AddCardIcon icon="eyee" size="24" />
                      </i>{" "}
                      This {movie.type} is not free. Buy the series now for N
                      {movie.price} and get access to all its episodes.
                    </h5>
                  ) : (
                    ""
                  )}
                  {movie.pricing == "paid" && movie.type !== "episode" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                        <AddCardIcon icon="eyee" size="24" />
                      </i>{" "}
                      This {movie.type} is not free. Buy and watch now for N
                      {movie.price}
                    </h5>
                  ) : (
                    ""
                  )}
                  {movie.pricing == "subscription" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                        <AddCardIcon icon="eyee" size="24" />
                      </i>{" "}
                      Pricing: This {movie.type} is only accessible to channel
                      subscribers
                    </h5>
                  ) : (
                    ""
                  )}
                  {movie.pricing == "free" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                        <AddCardIcon icon="eyee" size="24" />
                      </i>{" "}
                      Free: No payment required! This {movie.type} is free to be
                      watched by all
                    </h5>
                  ) : (
                    ""
                  )}
                  {movie.pricing == "chill_sub" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1">
                        <AddCardIcon icon="eyee" size="24" />
                      </i>{" "}
                      Pricing: This {movie.type} is only accessible to chillarx
                      subscribers
                    </h5>
                  ) : (
                    ""
                  )}
                  <br /> <br />
                  {/* <OutlineButton
                    onClick={() =>
                      // alert(props.channelSlug)
                      history.push(`/channel/${props.channelSlug}/movies`)
                    }
                  >
                    Explore Channel
                  </OutlineButton>{" "}
                  <br />
                  <br /> */}
                </Grid>

                <ShowMoreText
                  style={{ color: "#fff", fontWeight: 700 }}
                  lines={3}
                  more="Read more"
                  less="Show less"
                  className="overview text-white"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  {movie.overview}
                </ShowMoreText>

                {props.isAdmin == true && (
                  <>
                    {movie.is_approved == "false" ? (
                      <>
                        <p>This hasn't been approved yet</p>
                        {/* <OutlineButton onClick={() => approveContent(movie.id)}>
                          Approve Content
                        </OutlineButton> dialog*/}
                        <br />
                      </>
                    ) : (
                      <>
                        <p>
                          This has been approved already. To disapprove, use the
                          red icon
                        </p>
                      </>
                    )}
                  </>
                )}

                {approving ? (
                  <>
                    <br />
                    <p className="text-white">
                      {" "}
                      Approving Content... This will take a few seconds{" "}
                    </p>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}

                <div className="btns">
                  {/* <button
                  style={{
                    border: "3px solid #fff",
                    backgroundColor: "transparent",
                    color: "#fff",
                    boxShadow: "unset",
                    padding: '15px 50px',
                    borderRadius:40,
                    marginRight:10,
                    transition: "color 0.3s ease, #000 0.3s ease",
                  }}
                >
                  Watch Now
                </button> */}
                  {seriesPaymentPrompt ? (
                    <>
                      <div>
                        <h6 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                          {paymentMessage}
                        </h6>
                        <br />
                      </div>

                      {payAction ? (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={() => {
                              history.push({
                                pathname: `/chillarx/watch/series/${props.seriesId}`,
                                // pathname: {payLink},
                              });
                              window.location.reload();
                            }}
                          >
                            {payAction}
                          </OutlineButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {seriesSubPrompt ? (
                    <>
                      <div>
                        <h6 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                          {paymentMessage}
                        </h6>
                        <br />
                      </div>

                      {isAction ? (
                        <div style={{ paddingBottom: 20 }}>
                          {movie.channel.type == "free" ? (
                            <div style={{ paddingBottom: 20 }}>
                              <OutlineButton
                                onClick={() => {
                                  handleSubscription(movie.channel.id);
                                  setIsAction(false);
                                  setChannelSubSuccess(true);
                                }}
                              >
                                Subscribe To Channel Free
                              </OutlineButton>
                            </div>
                          ) : (
                            <OutlineButton
                              onClick={() => {
                                initializePaymentChannel(
                                  onSuccessChannel,
                                  onClose
                                );
                              }}
                            >
                              {subAction}
                            </OutlineButton>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {subPrompt ? (
                    <>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                        {subMessage}
                      </h4>
                      {movie.channel.type == "free" ? (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={() => {
                              handleSubscription(movie.channel.id);
                              setSubPrompt(false);
                              setChannelSubSuccess(true);
                            }}
                          >
                            Subscribe To Channel Free
                          </OutlineButton>
                        </div>
                      ) : (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={
                              () =>
                                initializePaymentChannel(
                                  onSuccessChannel,
                                  onClose
                                )
                              // history.push(`/channel/${props.channelSlug}/movies`)
                            }
                          >
                            Subscribe To Channel Now
                          </OutlineButton>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {paymentPrompt ? (
                    <>
                      <h6 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                        {paymentMessage}
                      </h6>
                      <br />
                      <div style={{ paddingBottom: 20 }}>
                        <OutlineButton
                          onClick={() => {
                            initializePayment(onSuccess, onClose);
                          }}
                        >
                          Buy Now
                        </OutlineButton>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {paymentSuccess ? (
                    <>
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        Hurray! You've purchased {movie.title} successfully! You
                        can always comeback to watch it here, or view them in
                        your purchased movies from your profile. Click Watch Now
                        and Enjoy!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  {chillarxSubSuccess ? (
                    <>
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        Awesome! You've activated your chillarx monthly
                        subscription successfully! You now have access to all
                        premium entertainments on chillarx. Enjoy!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  {channelSubSuccess ? (
                    <>
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        Awesome! You've subscribed to {movie.channel.name}{" "}
                        successfully! You now have access to all premium
                        entertainments on their channel. Enjoy!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  {chillarxPaymentPrompt ? (
                    <>
                      <h6 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                        {chillMessage}
                      </h6>
                      <br />
                      {isChillAction && (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={() => {
                              initializePaymentChillarx(
                                onSuccessChill,
                                onClose
                              );
                            }}
                          >
                            Subscribe To Chillarx Now
                          </OutlineButton>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  <div style={{ marginRight: 20, marginBottom: 20 }}>
                    {video === null || video === "null" ? (
                      ""
                    ) : (
                      <Button onClick={toogleVideo}>Watch now</Button>
                    )}
                  </div>

                  {showMovie ? (
                    <>
                      <br />
                      {/* {!movie.video.includes("youtu") ? ( */}
                      {/* <Player
                        playsInline
                        poster={movie.image}
                        src={movie.video}
                        fluid
                      /> */}
                      {/* ) : ( */}
                      <ReactPlayer
                        url={movie.video}
                        controls={true}
                        config={{
                          file: { attributes: { controlsList: "nodownload" } },
                        }}
                        playsinline={true}
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                        pip={true}
                        stopOnUnmount={false}
                        width="100%"
                        light={movie.image}
                      />
                      {/* )} */}

                      <br />
                      <br />

                      <br />
                      <Button onClick={closeVideo}>Close</Button>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item sm={12} className={classes.mobile}>
                    <br />
                  </Grid>

                  {/* {console.log(trailer)} */}
                  {trailer === null || trailer === "null" ? (
                    ""
                  ) : (
                    <>
                      {/* <p>jlllllll</p> */}
                      <OutlineButton
                        onClick={() => setOpen(true)}
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                        Watch trailer
                      </OutlineButton>
                      <br />
                      <br />

                      {isOpen ? (
                        <>
                          <br />
                          {/* {trailer.includes("youtu") ? ( */}
                          <ReactPlayer
                            url={trailer}
                            controls={true}
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            width="100%"
                            light={movie.image}
                            // imageUrl={movie.image}
                            // controlsList="nodownload"
                          />
                          {/* // ) : (
                          //   <Player
                          //     playsInline
                          //     poster={movie.cover_image}
                          //     src={trailer}
                          //     fluid
                          //   />
                          // )} */}

                          <br />
                          <br />

                          <Button onClick={() => setOpen(false)}>Close</Button>
                          <br />
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {/* <ModalVideo
                        channel="custom"
                        autoplay
                        isOpen={isOpen}
                        url={trailer}
                        onClose={() => setOpen(false)}
                      />*/}
                    </>
                  )}

                  <div>
                    <OutlineButton
                      onClick={handleClickOpen("paper")}
                      // data-uk-toggle="target: #my-id" type="button"
                      // href="/defaulthoteldetails"
                      className="d-block p-2 lh-32 text-center bg-greylight fw-600 font-xssss text-grey-900 rounded-3"
                    >
                      Add a Review / Comment
                    </OutlineButton>
                    <Dialog
                      open={openComment}
                      onClose={handleClose}
                      scroll={scroll}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                    >
                      <DialogTitle id="scroll-dialog-title">
                        Comments and Feedbacks
                      </DialogTitle>
                      <DialogContent dividers={scroll === "paper"}>
                        <br />
                        <p> {props.favComments.length} Pinned Comments </p>

                        <FavComment
                          Id={movie.id}
                          url={"movie/comment"}
                          favUrl={`${movie.id}/movie`}
                          movieId={movie.id}
                          adminId={props.adminId}
                          userId={props.userId}
                          favComments={props.favComments}
                          functionProp={props.functionProp}
                        />
                        <br />
                        <DialogContentText
                          id="scroll-dialog-description"
                          ref={descriptionElementRef}
                          tabIndex={-1}
                        >
                          {comments.map((c, id) => (
                            <CommentComponent
                              comments={c}
                              id={id}
                              url="movie"
                              favUrl={`${movie.id}/movie`}
                              deleteUrl={`comment`}
                              likeUrl={`movie`}
                              adminId={props.adminId}
                              userId={props.userId}
                              movieId={movie.id}
                              functionProp={props.functionProp}
                            />
                          ))}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        {/* <Button onClick={handleClose}>Subscribe</Button> */}
                      </DialogActions>
                    </Dialog>
                  </div>
                  <br />

                  <div style={{ display: "flex" }}>
                    {isLiking ? (
                      <Tooltip title="Like" aria-label="add">
                        <a
                          style={{ border: "#fff solid 2px" }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3"
                        >
                          <HourglassEmptyRoundedIcon
                            style={{ color: "#fff", fontSize: 30 }}
                          />
                        </a>
                      </Tooltip>
                    ) : (
                      <>
                        {props.likedStatus == "true" ? (
                          <Tooltip title="Unlike" aria-label="add">
                            <>
                              <a
                                style={{ border: "#fff solid 2px" }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3"
                              >
                                <FavoriteRoundedIcon
                                  onClick={() => likeMovie(movie.id)}
                                  style={{ color: "#fff", fontSize: 30 }}
                                />

                                <span>
                                  {" "}
                                  <p className="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss">
                                    {props.likers} likes
                                    {/* <LikersModal
                                      color="#fff"
                                      likers={movie.likers}
                                      text="likes"
                                      type="movie"
                                      class="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss"
                                    /> */}
                                  </p>{" "}
                                </span>
                              </a>
                            </>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Like" aria-label="add">
                            <>
                              <a
                                style={{ border: "#fff solid 2px" }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3"
                              >
                                <FavoriteBorderRoundedIcon
                                  onClick={() => likeMovie(movie.id)}
                                  style={{ color: "#fff", fontSize: 30 }}
                                />

                                <span>
                                  {" "}
                                  <p className="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss">
                                    {props.likers} likes
                                    {/* <LikersModal
                                      color="#fff"
                                      likers={movie.likers}
                                      text="likes"
                                      type="movie"
                                      class="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss"
                                    /> */}
                                  </p>{" "}
                                </span>
                              </a>
                            </>
                          </Tooltip>
                        )}
                      </>
                    )}

                    <Tooltip title="Views" aria-label="add">
                      <>
                        <a
                          style={{ border: "#fff solid 2px" }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3"
                        >
                          <Visibility
                            style={{
                              color: "#fff",
                              fontSize: 30,
                              marginTop: "-8px",
                            }}
                          />
                          {/* <FeatherIcon icon="eyee" size="24" color="black" /> */}
                          <i
                            className=" text-white rounded-full p-2 transition -mr-1"
                            style={{ fontSize: 25 }}
                          >
                            {/* <FeatherIcon icon="eyee" size="24"  /> */}
                          </i>{" "}
                          <span style={{ color: "#fff" }}>
                            {" "}
                            <b>
                              <h3
                                style={{ color: "#fff", fontWeight: 700 }}
                                className="text-white font-xssss fw-600"
                              >
                                {props.views} Views
                              </h3>{" "}
                            </b>
                          </span>
                        </a>
                      </>
                    </Tooltip>

                    <Tooltip title="Share Link" aria-label="add">
                      <>
                        <CopyToClipboard
                          // text={window.location.href}
                          text={`${process.env.REACT_APP_URL}/signin${window.location.pathname}?${queryParams}`}
                          onCopy={() => setCopied(true)}
                        >
                          <a
                            style={{ border: "#fff solid 2px" }}
                            className="btn-round-lg ms-2 d-inline-block rounded-3"
                          >
                            <ShareIcon
                              style={{ color: "#fff", fontSize: 30 }}
                            />
                            <FeatherIcon icon="eyee" size="24" color="black" />
                            <i
                              className=" text-white rounded-full p-2 transition -mr-1"
                              style={{ fontSize: 25, color: "#fff" }}
                            >
                              {/* <FeatherIcon icon="share2" size="24" color='white' /> */}
                            </i>{" "}
                          </a>
                        </CopyToClipboard>
                        <br />
                        {copied ? (
                          <>
                            <Snackbar
                              open={copied}
                              autoHideDuration={3000}
                              onClose={closeSnack}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Alert onClose={closeSnack} severity="success">
                                Link Copied Successfully
                              </Alert>
                            </Snackbar>
                          </>
                        ) : null}
                      </>
                    </Tooltip>

                    {/* <ShareMovie /> */}
                    {props.isFavorited == "true" ? (
                      <Tooltip title="Remove from chill list" aria-label="add">
                        <a
                          onClick={() => removeWatchLater(movie.id)}
                          // href="/defaulthoteldetails"
                          style={{
                            border: "#fff solid 2px",
                            backgroundColor: "#FF0000",
                          }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                        >
                          <BookmarkRemoveIcon
                            style={{ color: "#fff", fontSize: 30 }}
                          />
                          <FeatherIcon icon="eyee" size="24" color="black" />
                          <i
                            style={{ color: "#fff" }}
                            className=" font-sm text-white"
                          ></i>{" "}
                        </a>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add To chill list" aria-label="add">
                        <a
                          onClick={() => watchLater(movie.id)}
                          // href="/defaulthoteldetails"
                          style={{
                            border: "#fff solid 2px",
                            backgroundColor: "green",
                          }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                        >
                          <BookmarkAddedIcon
                            style={{ color: "#fff", fontSize: 30 }}
                          />
                          <FeatherIcon icon="eyee" size="24" color="black" />
                          <i
                            style={{ color: "#fff" }}
                            className=" font-sm text-white"
                          ></i>{" "}
                        </a>
                      </Tooltip>
                    )}

                    {props.isAdmin == true && (
                      <>
                        {movie.is_approved == "false" ? (
                          <>
                            <Tooltip title="Approve Movie" aria-label="add">
                              <a
                                onClick={() => approveContent(movie.id)}
                                style={{
                                  border: "#fff solid 2px",
                                  backgroundColor: "green",
                                }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                              >
                                <AddTaskIcon
                                  style={{ color: "#fff", fontSize: 30 }}
                                />
                                <FeatherIcon
                                  icon="eyee"
                                  size="24"
                                  color="black"
                                />
                                <i
                                  style={{ color: "#fff" }}
                                  className=" font-sm text-white"
                                ></i>{" "}
                              </a>
                            </Tooltip>
                            {/* <OutlineButton onClick={() => approveContent(movie.id)}>
                          Approve Content
                        </OutlineButton> */}
                            {/* <br /> */}
                          </>
                        ) : (
                          <>
                            <DisApproveMovie
                              movie={movie}
                              link="movie/dis/approve_movie"
                              functionProp={props.functionProp}
                            />
                            <ApproveMonetization
                              movie={movie}
                              link="movie/update_monetization"
                              cpvLink="movie/update_cpv"
                              sponsoredLink="movie/make_sponsored"
                              functionProp={props.functionProp}
                              url="content/analytics"
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div>
                    {saving ? (
                      <>
                        <br />
                        <br />
                        <br />
                        <p className="text-white">
                          {" "}
                          Adding to chill list... This will take a few seconds{" "}
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {saved ? (
                      <>
                        <br />
                        <br />
                        <br />
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          action={
                            <>
                              <a
                                onClick={() => setSaved(false)}
                                className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                              >
                                close
                              </a>
                            </>
                          }
                        >
                          You've added this movie to your chill list
                          successfully.
                          <a href={`/chill-list`}>
                            Click here to view it in your chill list
                          </a>
                        </Alert>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div>
                    {removing ? (
                      <>
                        <br />
                        <br />
                        <br />
                        <p className="text-white">
                          {" "}
                          Removing from chill list... This will take a few
                          seconds{" "}
                        </p>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {removed ? (
                      <>
                        <br />
                        <br />
                        <br />
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          action={
                            <>
                              <a
                                onClick={() => setRemoved(false)}
                                className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                              >
                                close
                              </a>
                            </>
                          }
                        >
                          You've sucessfully removed this movie from your chill
                          list.
                        </Alert>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <a
                    href="/defaulthoteldetails"
                    className="bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-3 d-inline-block mt-0 p-2 lh-34 text-center ls-3 w200"
                  >
                    BUY NOW
                  </a> */}
                  {/* </div> */}
                </div>

                <div className="cast">
                  <CastList id={"297761"} casts={props.casts} />
                </div>
              </div>
            </div>
            <div className="container">
              {/* <div className="section mb-3">
              <h2 style={{ color: "#fff" }}>Similar</h2>
              <CastList id={movie.id} />
            </div> */}
              {/* {seriesMovies.length === 0 ? }   */}
              <div className="section mb-3">
                {/* <h2 style={{ color: "#fff" }}>Movies In Channel Series</h2> */}
              </div>
              {movie.type == "episode" ? (
                <div className="section mb-3">
                  {/* <div className="section__header mb-2">
                    <h2 style={{ color: "#fff" }}>
                      Watch Other Episodes In This Series
                    </h2>
                  </div> */}
                  {/* <VideoListMap heading="Watch Other Episodes In This Series"
                    link={`channels/series/${movie.series_id}`} /> */}
                  <MovieListMap
                    heading="Watch Other Episodes In This Series"
                    link={`channels/series/${movie.series_id}`}
                    // route="series/episode"
                    // type="Episode"
                    // channelId={props.channelId}
                    // url="series/episode"
                    // channelSlug={props.channelSlug}
                    // adminId={props.adminId}
                    // functionProp={props.functionProp}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="section mb-3">
                <SeriesListMap
                  heading="Watch Series In Channel"
                  link={`channels/${props.channelId}/series`}
                  seriesLink="true"
                  // route="series"
                  // type="Series"
                  // channelId={props.channelId}
                  // url="series"
                  // channelSlug={props.channelSlug}
                  // adminId={props.adminId}
                  // functionProp={props.functionProp}
                />
              </div>
              <div className="section mb-3">
                {/* <div className="section__header mb-2">
                  <h2 style={{ color: "#fff" }}>Watch More Channel Movies</h2>
                </div> */}
                {/* <VideoListMap 
                 heading="Watch More Channel Contents"
                 link={`channels/${props.channelId}/contents`}/> */}
               
                <MovieListMap
                  heading="Most Viewed"
                  link={`movie/view/more/${props.channelId}`}
                  // route="movie"
                  // type="Movie"
                  // channelId={props.channelId}
                  // url="movie"
                  // channelSlug={props.channelSlug}
                  // adminId={props.adminId}
                  // functionProp={props.functionProp}
                />
              </div>
              <div className="section mb-3">
                {/* <div className="section__header mb-2">
                  <h2 style={{ color: "#fff" }}>Watch More Channel Videos</h2>
                </div> */}
                <MovieListMap
                  heading="More Channel Contents"
                  link={`channels/${props.channelId}/contents`}
                  // route="video"
                  // type="Video"
                  // channelId={props.channelId}
                  // url="video"
                  // channelSlug={props.channelSlug}
                  // adminId={props.adminId}
                  // functionProp={props.functionProp}
                />

{/* <MovieGridMap
                  heading="Watch More Channel Contents"
                  link={`channels/${props.channelId}/contents`}
                /> */}
              </div>

              <div className="section mb-3">
                <div className="section__header mb-2">
                  {/* <h1>Similar Movies</h1> */}
                </div>
                {/* get and show movies that belog to this gener or tag */}
                <MovieListMap
                  heading="Watch Similar Contents"
                  link={`movie/view/similar/${movie.type}`}
                  // movies={latestMovies}
                />
              </div>
            </div>
            <br />
            {/* <AllMovies /> */}
          </>
        )}
        {/* {video.map((item, i) => (
        <TrailerModal key={i} item={item} />
      ))} */}
      </div>
    );
  }
  //   );
};

const TrailerModal = (props) => {
  const video = props.item;

  const iframeRef = useRef(null);

  const onClose = () => iframeRef.current.setAttribute("src", "");

  return (
    <Modal active={false} id={`modal_${video.id}`}>
      <ModalContent onClose={onClose}>
        <iframe
          ref={iframeRef}
          width="100%"
          height="500px"
          title="trailer"
        ></iframe>
      </ModalContent>
    </Modal>
  );
};

export default Detail;
