import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// import Button from '@mui/material/Button';

// import { useState, useLocation, useEffect, useHistory  } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
import axios from "axios";
import axiosFetch from "../config/Interceptors";
import Header from "../components/others/Header";
// import Leftnav from "../components/Leftnav";
// import Rightchat from "../components/Rightchat";
// import Appfooter from "../components/Appfooter";
// import Popupchat from "../components/Popupchat";
// import Load from "../components/Load";
import { Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CancelIcon from "@mui/icons-material/Cancel";
// import { Select } from "antd";
import Resizer from "react-image-file-resizer";
import slugify from "react-slugify";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
// import Select from "react-select";
import { styled } from "@mui/material/styles";
import HeaderV2 from "../components/studio/navbar/HeaderV2";

export default function CreateChannel() {
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );
  const Input = styled("input")({
    display: "none",
  });
  const [isCreating, setIsCreating] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [channelDes, setChannelDes] = useState("");
  const [channelStatus, setChannelStatus] = useState("");
  const [channelKey, setChannelKey] = useState("");
  const [bankName, setBankName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [channelType, setChannelType] = useState("");
  const [channelPrice, setChannelPrice] = useState("");
  const [channelCover, setChannelCover] = useState("");
  const [channelPic, setChannelPic] = useState("");
  const [channelCoverFe, setChannelCoverFe] = useState("");
  const [channelPicFe, setChannelPicFe] = useState("");
  const [newName, setNewName] = useState(channelName);
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const name = "";

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setEmptyEmail(false);
    setIsSuccess(false);
    setEmptyFields(false);
    // setOpenAlert(false);
  };

  function closeError() {
    setError(false);
    console.log("closed");
  }

  function changeType(event) {
    console.log(`type ${event.target.value}`);
    // setChannelType(value);
    setChannelType(event.target.value);
  }
  function changeStatus(event) {
    console.log(`status ${event.target.value}`);
    // setChannelStatus(value);
    setChannelStatus(event.target.value);
  }

  function clearPagePics(value) {
    setChannelPicFe("");
    setChannelPic("");
  }
  function clearCoverImage(value) {
    setChannelCover("");
    setChannelCoverFe("");
  }

  function redirect(name) {
    // setIsSuccess(false);
    history.push(`/channel/${name}`);
  }

  async function validate() {
    console.log(`channelName ${channelName}`);
    if (!channelName) {
      setEmptyFields(true);
      return;
    } else {
      createChannel();
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          setChannelPic(uri);
        },
        "file",
        1080,
        1080
      );
    });

  const CompressImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      const image = await resizeFile(file);
      //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  const resizeCoverFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        80,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          setChannelCover(uri);
        },
        "file",
        1080,
        1080
      );
    });

  const compressCoverImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      const image = await resizeCoverFile(file);
      //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  async function validateProfileImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
    console.log(value);
    setChannelPicFe(value);
    // setInvalidFile(false);
    // }
  }

  async function validateCoverImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
    console.log(value);
    setChannelCoverFe(value);
    // setInvalidFile(false);
    // }
  }

  async function createChannel() {
    setIsCreating(true);
    const name = slugify(channelName);
    setNewName(name);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    console.log(`new name is ${newName}`);
    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("name", channelName);
    formData.append("description", channelDes);
    // formData.append("status", "public");
    formData.append("cover_pic", channelCover);
    formData.append("channel_pic", channelPic);
    // if (channelKey == "") {
    //   formData.append("public_key", process.env.REACT_APP_PAYSTACK_PUBLIC_TEST);
    // } else {
    //   formData.append("public_key", channelKey);
    // }
    formData.append("bank_name", bankName);
    formData.append("account_number", accountNumber);
    formData.append("phone_number", phoneNumber);
    formData.append("type", channelType);
    formData.append("price", channelPrice);
    formData.append("status", channelStatus);

    axiosFetch
      .post("/channels", formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          console.log(`hi ${resp.data}`);
          setChannelName("");
          setChannelDes("");
          setChannelStatus("");
          setChannelCover("");
          setChannelPic("");
          setChannelCoverFe("");
          setChannelPicFe("");
          setBankName("");
          setAccountNumber("");
          setPhoneNumber("");
          setChannelPrice("");
          setIsCreating(false);
          redirect(name);
          console.log(`response ${JSON.stringify(resp.data)}`);
          setIsSuccess(true);
        } else {
          setIsCreating(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }
  const { Option } = Select;
  const types = ["free", "paid"];
  const statusSelect = [
    { value: "public", label: "Publish" },
    { value: "private", label: "Draft" },
  ];
  return (
    <Fragment>
      {/* <Header /> */}
      <HeaderV2 />
      {/* <Leftnav /> */}
      {/* <Rightchat /> */}

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              {/* <Button
        href="/channels"
        // fullWidth
        variant="contained"
        color="primary"
      >
       Back To Channels
      </Button>{" "}
      <br /> */}

              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to="/channels" className="d-inline-block mt-2">
                    <i className="ti-arrow-left font-sm text-success"></i>

                    <h4 className="font-xs text-success fw-600 mt-2 mb-0">
                      Back To Channels
                    </h4>
                  </Link>
                </div>
                <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                  {channelCoverFe ? (
                    <img
                      src={URL.createObjectURL(channelCoverFe)}
                      alt="avater"
                      style={{
                        maxWidth: 1450,
                        maxHeight: 450,
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <img
                      src={"https://via.placeholder.com/1200x250.png"}
                      alt="avater"
                      style={{
                        maxWidth: 1450,
                        maxHeight: 450,
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
                <div className="card-body p-0 position-relative">
                  {channelPicFe ? (
                    //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                    <>
                      {/* <p> Your Channel Cover Picture </p> <br /> */}
                      <figure
                        className="avatar position-absolute w100 z-index-1"
                        style={{ top: "-40px" }}
                      >
                        <img
                          src={URL.createObjectURL(channelPicFe)}
                          alt="avater"
                          className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                        />
                      </figure>
                    </>
                  ) : (
                    <figure
                      className="avatar position-absolute w100 z-index-1"
                      style={{ top: "-40px" }}
                    >
                      <img
                        src={"/assets/images/user.png"}
                        alt="avater"
                        className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                      />
                    </figure>
                  )}
                  {/* <figure
                    className="avatar position-absolute w100 z-index-1"
                    style={{ top: "-40px" }}
                  >
                    <img
                      src={"/assets/images/user.png"}
                      alt="avater"
                      className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                    />
                  </figure> */}
                  <h4 className="fw-700 font-sm mt-2 pl-15">
                    {/* First Name{" "}
                        Last name */}
                    <span className="fw-500 font-xssss text-primary mt-1 mb-3 d-block">
                      {/* @User Name */}
                    </span>
                  </h4>
                </div>
                <div className="card-body mt-5 p-lg-5 p-4 w-100 border-0 ">
                  {/* <div className="row justify-content-center">
                    <div className="col-lg-4 text-center">
                      <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                        <img
                          src="https://via.placeholder.com/300x300.png"
                          alt="avater"
                          className="shadow-sm rounded-3 w-100"
                        />
                      </figure>

                    </div>
                  </div> */}
                  {/* <h1> Your Profile Details </h1> */}
                  <br />
                  <br />
                  <form>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font text-black fw-600 font-xsss mb-2">
                            Channel Name
                          </label>
                          {/* value={this.state.first_name} */}
                          <input
                            type="text"
                            value={channelName}
                            onChange={(e) => {
                              setChannelName(e.target.value);
                              //   const name = slugify(channelName);
                              setNewName(name);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                          What is Your Channel About?
                        </label>
                        <textarea
                          className="form-control mb-0 p-3 h100 bg-greylight lh-16"
                          rows="5"
                          value={channelDes}
                          onChange={(e) => setChannelDes(e.target.value)}
                          placeholder="Let subscribers know a bit about your contents..."
                        ></textarea>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <br />
                          <label
                            style={{ color: "#000" }}
                            className="mont-font fw-600 font-xsss mb-2"
                          >
                            Select Channel Pricing Type - Can Be Changed Later
                          </label>
                          <p style={{ color: "#000" }}>
                            Select paid if you want monetize your channel subscription flow and
                            gain extra income. Subscribers to pay a one-time fee before
                            subscribing to your channel.
                          </p>
                          <Select
                            // labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            // mode="tags"
                            // options={types}
                            value={channelType}
                            label="Select One"
                            size="large"
                            style={{ width: "100%" }}
                            // style={{color: '#000'}}
                            onChange={changeType}
                          >
                            {types.map((type) => (
                              <MenuItem
                                key={type}
                                value={type}
                                style={{ color: "#000", width: "100%" }}
                              >
                                {" "}
                                {type}{" "}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>

                    {channelType == "paid" ? (
                      <div className="form-group">
                        <br />
                        <label
                          style={{ color: "#000" }}
                          className="mont-font fw-600 font-xsss mb-2"
                        >
                          You selected paid. Type your subscription fee
                        </label>
                        <p style={{ color: "#000" }}>
                          This is a one time payment fee. It grants subscribers
                          access to contents marked as "channel subscribers" only
                        </p>
                        {/* value={this.state.first_name} */}
                        <input
                          type="text"
                          value={channelPrice}
                          // defaultValue='N500'
                          onChange={(e) => setChannelPrice(e.target.value)}
                          className="form-control"
                        />
                        <br />
                        <br />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label
                            style={{ color: "#000" }}
                            className="mont-font fw-600 font-xsss mb-2"
                          >
                            Select Channel Published Status
                          </label>
                          <p style={{ color: "#000" }}>
                            {" "}
                            {/* Draft channels can be seen only by you in your profile. They
                    aren't visible in the channels page */}
                            Leave as published to stay visible
                          </p>
                          <Select
                            // labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            // mode="tags"
                            // options={statusSelect}
                            value={channelStatus}
                            label="Select One"
                            size="large"
                            style={{ width: "100%" }}
                            // style={{color: '#000'}}
                            onChange={changeStatus}
                          >
                            {statusSelect.map((status) => (
                              <MenuItem
                                style={{ color: "#000", width: "100%" }}
                                key={status.value}
                                value={status.value}
                              >
                                {" "}
                                {status.label}{" "}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="text-dark mont-font fw-600 font-xsss mb-2">
                            Every channel is expected to have a payout bank account to recieve payments. Add your bank account details below.
                           <br/><br/> NOTE: <br/>
                            -> Channels without correct bank details will not be approved.  Kindly ensure your details are correct. 
                            <br/>
                            -> A confirmation call wil be made to your active phone number before approval. Channels without active phone numbers will not be approved.<br/>
                            -> Changing your payment details is only
                            available on request. <br/>
                            -> A charge of 10% is commissioned on channel payments.
                          </label>
                          <br />
                          <br />
                          <p style={{ color: "#000" }}> Your Bank Name</p>
                          {/* value={this.state.first_name} */}
                          <input
                            type="text"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                            className="form-control"
                          />

                          <br />
                          <p style={{ color: "#000" }}>
                            {" "}
                            Your Bank Account Number
                          </p>
                          {/* value={this.state.first_name} */}
                          <input
                            type="text"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            className="form-control"
                          />

<br />
                          <p style={{ color: "#000" }}>
                            {" "}
                            Your Active Phone Number
                          </p>
                          {/* value={this.state.first_name} */}
                          <input
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row"> */}

                    <div className="col-lg-12 mb-3">
                      <br />
                      <br />
                      {/* </div> */}

                      {channelPicFe ? (
                        //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                        <>
                          <p> Your Channel's Logo </p> <br />
                          <img
                            src={URL.createObjectURL(channelPicFe)}
                            alt="avater"
                            style={{
                              // width: 1450,
                              // maxWidth: 1450,
                              maxHeight: 450,
                              objectFit: "contain",
                              //    borderRadius: '10%'
                            }}
                          />
                          <Button onClick={clearPagePics}>Remove</Button>
                          <br />
                          <br />
                        </>
                      ) : (
                        //   <a className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                        //   {/* <i className="font-md text-success feather-image me-2"></i> */}
                        //   <label htmlFor="contained-button-file">
                        //     <Input
                        //       accept="image/*"
                        //       id="contained-button-file2"
                        //       multiple
                        //       type="file"
                        //       onChange={(e) => {
                        //           validateProfileImage(e.target.files[0])
                        //           CompressImage(e)
                        //       }}
                        //     />

                        //     <Button variant="contained" component="span">
                        //       Channel Logo
                        //     </Button>
                        //   </label>
                        // </a>
                        <div className="card mt-3 border-0">
                          <div className="card-body d-flex justify-content-between align-items-end p-0">
                            <div className="form-group mb-0 w-100">
                              <input
                                type="file"
                                accept="image/*"
                                name="file-2"
                                id="file-2"
                                className="input-file"
                                onChange={(e) => {
                                  validateProfileImage(e.target.files[0]);
                                  CompressImage(e);
                                }}
                              />
                              <label
                                htmlFor="file-2"
                                className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                              >
                                <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                <span className="js-fileName">
                                  Click to upload your channel's logo
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      {invalidFile ? <h4> {invalidFile} </h4> : ""}
                    </div>

                    {invalidFile ? <h4> {invalidFile} </h4> : ""}

                    <div>
                      {channelCoverFe ? (
                        //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                        <>
                          <p> Your Channel's Cover Picture </p> <br />
                          <img
                            src={URL.createObjectURL(channelCoverFe)}
                            alt="avater"
                            style={{
                              // width: 1450,
                              // maxWidth: 1450,
                              maxHeight: 450,
                              objectFit: "contain",
                              //    borderRadius: '10%'
                            }}
                          />
                          <Button onClick={clearCoverImage}>Remove</Button>
                          {/* //  </div> */}
                        </>
                      ) : (
                        //   <a className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                        //   {/* <i className="font-md text-success feather-image me-2"></i> */}
                        //   <label htmlFor="contained-button-file">
                        //     <Input
                        //       accept="image/*"
                        //       id="contained-button-file"
                        //       multiple
                        //       type="file"
                        //       onChange={(e) => {
                        //           validateCoverImage(e.target.files[0])
                        //           compressCoverImage(e)
                        //           }}
                        //     />

                        //     <Button variant="contained" component="span">
                        //       Channel Cover
                        //     </Button>
                        //   </label>
                        // </a>
                        <div className="col-lg-12 mb-3">
                          <div className="card mt-3 border-0">
                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                              <div className="form-group mb-0 w-100">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="file"
                                  id="file"
                                  className="input-file"
                                  onChange={(e) => {
                                    validateCoverImage(e.target.files[0]);
                                    compressCoverImage(e);
                                  }}
                                  // onChange={(e) =>
                                  //     console.log(`test ${e.target.files[0]}`)}
                                />
                                <label
                                  htmlFor="file"
                                  className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                >
                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                  <span className="js-fileName">
                                    Click to upload your channel's cover picture
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12">
                      {isCreating ? (
                        <>
                          <Button disabled variant="contained" color="primary">
                            Creating Your Channel
                          </Button>
                          <br />{" "}
                          <p style={{ color: "#000" }}>
                            This may take a quick minute. You'll be redirected
                            after
                          </p>
                        </>
                      ) : (
                        // <a className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" disabled>Updating Your Profile</a> */}
                        <Button
                          onClick={validate}
                          fullWidth
                          variant="contained"
                          color="primary"
                        >
                          Create Channel{" "}
                        </Button>
                        // <a
                        //   onClick={validate}
                        //   className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                        // >
                        //   Create Channel
                        // </a>
                      )}
                      <br />
                      <br />
                      <br />
                      {emptyFields ? (
                        <>
                          {/* <br />
                          <br /> */}
                          <Alert onClose={handleClose} severity="warning">
                            To create a channel, you need to ensure it has at
                            least a name
                          </Alert>
                        </>
                      ) : (
                        ""
                      )}

                      {error ? (
                        <div>
                          <Alert
                            severity="error"
                            onClose={handleClose}
                            action={
                              <>
                                <CancelIcon onClick={closeError} />
                              </>
                            }
                          >
                            There was an issue creating your channel. Kindly try
                            again [ {errorMessage} ]
                          </Alert>
                          {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}

                      {isSuccess ? (
                        <Snackbar
                          open={isSuccess}
                          autoHideDuration={4000}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Alert
                            onClose={handleClose}
                            variant="filled"
                            severity="success"
                            action={
                              <Button
                                onClick={redirect}
                                style={{ color: "#fff", alignSelf: "center" }}
                              >
                                {" "}
                                Check It Out
                              </Button>
                            }
                          >
                            Your channel was created sucessfully! Redirecting...
                          </Alert>
                        </Snackbar>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      {/* <Appfooter /> */}
    </Fragment>
  );
}

// export default CreateChannel;
