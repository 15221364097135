import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/swiper.min.css';
import './assets/boxicons-2.0.7/css/boxicons.min.css';

import './App.scss';
// import "./App.css";
import './main.scss';
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Route } from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Routes from './config/Routes';
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import CreateChannel from './pages/CreateChannel';
import HomeDash from './pages/dashboard/home/Home';
import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';
import { fetchToken, onMessageListener } from './firebase';
import axiosFetch from "./config/Interceptors";

function App() {
  const updateversion = window.localStorage.getItem("updateVersion");
    const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [shownNotification, setShownNotification] = useState(false);
  fetchToken(setTokenFound);
  const can_update = window.localStorage.getItem("can_update");
  const push_token = window.localStorage.getItem("token");

  onMessageListener()
  .then((payload) => {
    
    // setNotification({
    //   title: payload.notification.title,
    //   body: payload.notification.body,
    // });
    // setShownNotification(true)
    addNotification({
      title: payload.notification.title,
      subtitle: payload.notification.title,
      message:  payload.notification.body,
      theme: 'darkblue',
      duration: 8000,
      // icon: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.youtube.com%2Fchannel%2FUCIYBgiBUefVLJB9bq2dlp2g%2Ffeatured&psig=AOvVaw3bVvMDfp6nUyX3NmwD4Dd1&ust=1651175029043000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCNiwkLyAtfcCFQAAAAAdAAAAABAD',
      native: true // when using native, your OS will handle theming.

  });
    console.log('notification payload is ',payload);
    // setShownNotification(false)
    // resolve(payload);
    // return payload;
  })
  .catch((err) => console.log("failed: ", err));

  const requestNotificationPermission = async () => {
    const permission = await window.Notification.requestPermission();
    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
    if (permission !== "granted") {
      console.log("Notification Permission not granted ");
    }
  };

  async function trackToken(){
    if(push_token && can_update ){
      console.log('push id token can be stored', isTokenFound)
      console.log("updating token...");
      const formData = new FormData();
      formData.append("push_key", push_token);

      let result = await axiosFetch
        .post(`/users/save/token`, formData, {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + access_token,
          },
        })
        .then((resp) => {
          // console.log(resp.data)
          console.log("updated push token!");
        })
        .catch((error) => {
          console.log(error);
          console.log('can not update push token')
        });
    } 
    else {
      console.log("seems push token has not been found yet!");
    }

  }

  
  function checkAppVersion(){
    const envVersion = process.env.REACT_APP_VERSION
     console.log('app version is outdated')
     if(updateversion != envVersion){
       window.localStorage.setItem("updateVersion", envVersion); 
       window.location.reload(true)
       console.log('updated app version')
       
       //made a mistake
       // var retVal = window.confirm(`Opps! Seems like an update has been made since you were here. Reload now to get latest version. Click OK to reload now`);
       // if( retVal == true ) {
       //   window.localStorage.setItem("updateVersion", envVersion);
       //   window.location.reload(true)
       // } else {
       //    return false;
       // }
     }
   }


  useEffect(() => {
    trackToken()
    checkAppVersion()
    requestNotificationPermission();

  }, [isTokenFound]);

    return (
        <BrowserRouter>
        <Notifications />
            <Route render={props => (
                <div style={{backgroundColor: '#000'}}>
                    {/* <Header {...props}/> */}
                    <Routes/>
                    {/* <CreateChannel /> */}
                    {/* <HomeDash /> */}
                    {/* <Footer/> */}
                </div>
            )}/>
        </BrowserRouter>
    );
}

export default App;
