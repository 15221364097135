import React, { Component, Fragment, useState, useEffect } from "react";
import "./channel.scss";
import { Route, useParams, useHistory, useLocation } from "react-router-dom";
import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import { Link } from "react-router-dom";
import Datatable from "../../components/studio/datatable/Datatable";
import axiosFetch from "../../config/Interceptors";
import UserSavedMovies from "../../components/studio/channel/UserSavedMovies";

const ChillList = () => {
  const slug = useParams();
  const [fetching, setFetching] = useState(false);
  const [videos, setVideos] = useState([]);
  const [savedMovies, setSavedMovies] = useState([]);
  const [savedSeries, setSavedSeries] = useState([]);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  async function getVideos() {
    setFetching(true);
    // console.log(` 1 ${fetching}`)
    const access_token = await window.localStorage.getItem("access_token");
    // setUserId(window.localStorage.getItem("user_id"));
    console.log(window.localStorage.getItem("user_id"));
    axiosFetch
      .get(`/users/chill/list`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setVideos(resp.data);
        setSavedMovies(resp.data.favoriteMovies);
        setSavedSeries(resp.data.favoriteSeries);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getVideos();
  }, []);

  if (fetching) {
    return <></>;
  } else {
    return (
      <div
        className="list  col-xl-12"
        style={{ backgroundColor: "#000", minHeigh: "100%" }}
      >
        <div className="listContainer" style={{ backgroundColor: "#000", minHeight: '100%' }}>
          {/* <ChannelCard slug={slug.slug}/> */}
          <HeaderV2 />
          
          <div className="homeContainer vh-200" style={{ backgroundColor: "#000", minHeight: '100%' }}>
            <div className="main-content" style={{ backgroundColor: "#000", minHeight: '100%' }}>
              <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                  <div >
                    <div className="card border-0 shadow-xs p-0 mb-4" style={{ backgroundColor: "#000", minHeight: '100%' }}>
                    <h3 className='text-center' style={{ color: "#fff" }}><br/><br/>Your Chill List -> Contents Saved For Later</h3><br/><br/>
                      <UserSavedMovies
                        movies={savedMovies}
                        series={savedSeries}
                        url="subscriptions"
                        message="You Haven't Saved Any Movie / Series For Later"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ChillList;
