import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";

import "./movie-grid.scss";

import MovieCard from "../movie-card/MovieCard";
import MovieCardMap from "../movie-card/MovieCardMap";
import Button, { OutlineButton } from "../button/Button";
import Input from "../input/Input";

export default function SearchBar() {
  const { keyword } = useParams();

  return (
    <div className="section mb-3">
      <br />
      <br />
      <div style={{ display: "flex", alignContent:'center', justifyContent:'center' }}>
        <div className="section__header mb-2 text-center">
          <h2 className="text-center" style={{ color: "#fff", paddingRight:20 }}>
            Search Chillarx
          </h2>
        </div>
        <br/><br/>
        <MovieSearch keyword={keyword} />
      </div>
    </div>
  );
}

const MovieSearch = (props) => {
  const history = useHistory();

  const [keyword, setKeyword] = useState(props.keyword ? props.keyword : "");

  const goToSearch = useCallback(() => {
    if (keyword.trim().length > 0) {
      // alert(`keyword is ${keyword}`)
      // history.push(`/${category[props.category]}/search/${keyword}`);
      history.push(`/chillarx/search/${keyword}`);
      window.location.reload();
    }
  }, [keyword, props.category, history]);

  return (
    <div className="movie-search">
      <Input
        type="text"
        placeholder="Type keyword"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <Button className="small" onClick={goToSearch}>
        Search
      </Button>
    </div>
  );
};
