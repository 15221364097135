import React, { Component, Fragment, useState, useEffect } from "react";
import "./channel.scss";
import { Route, useParams, useHistory, useLocation } from "react-router-dom";
import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import { Link } from "react-router-dom";
import Datatable from "../../components/studio/datatable/Datatable";
import axiosFetch from "../../config/Interceptors";
import UserSavedMovies from "../../components/studio/channel/UserSavedMovies";
import UserSubscriptions from "../../components/studio/channel/UserSubscriptions";
import Load from "../../components/others/Load";

const MySubscriptions = () => {
  const slug = useParams();
  const [fetching, setFetching] = useState(false);

  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  async function getSubscriptions() {
    setFetching(true);
    // console.log(` 1 ${fetching}`)
    const access_token = await window.localStorage.getItem("access_token");
    // setUserId(window.localStorage.getItem("user_id"));
    console.log(window.localStorage.getItem("user_id"));
    axiosFetch
      .get(`/users/my/subscriptions`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setUserSubscriptions(resp.data);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getSubscriptions();
  }, []);

  if (fetching) {
    return (
      <>
        <Load bg='#fff' color='#000' />
      </>
    );
  } else {
    return (
      <div className="list  col-xl-12" style={{ backgroundColor: "#fff" }}>
        <div className="listContainer">
          {/* <ChannelCard slug={slug.slug}/> */}
          <HeaderV2 />

          <div className="main-content">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <br />
                <h3 className="text-center" style={{ color: "#000" }}>Channels Subscribed To</h3>
                <p className="text-center" style={{ color: "#000" }}>
                  {" "}
                  Start subscribing to channels to get easy access to their
                  movies and series here. Use your side menu to explore latest
                  channels
                </p>
                <UserSubscriptions
                  fetching={fetching}
                  //   user_name={user_name}
                  subscriptions={userSubscriptions}
                  url="subscriptions"
                  //   functionProp={() => getUserDetails()}
                />
              </div>
            </div>
          </div>
          <div style={{marginBottom: 400}}></div>
        </div>
      </div>
    );
  }
};

export default MySubscriptions;
