import "./channel.scss";

import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";
import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Datatable from "../../components/studio/datatable/Datatable";
import ChannelsComp from "../../components/studio/channel/ChannelsComp";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import axiosFetch from "../../config/Interceptors";
import { Button } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import ChannelsTabAdmin from "../../components/studio/channel/ChannelsTabAdmin";
import Load from "../../components/others/Load";

const AdminChannelAnalytics = () => {
  const history = useHistory();
  const [channel, setChannel] = useState([]);
  const { slug } = useParams(["slug"]);
  const [adminId, setAdminId] = useState("");
  const [adminDetails, setAdminDetails] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  // const adminId = window.localStorage.getItem('user_id')

  async function getChannelDetailsLoad() {
    setIsFetching(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/channels/show/${slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          setChannel(resp.data.channel);
          setAdminDetails(resp.data.channel.admin);
          setAdminId(resp.data.channel.user_id)
          setIsFetching(false);
        } else {
          setIsFetching(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setIsFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.response.data.message);
      });
  }

  async function checkProfile() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/profile`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data.user.is_admin);
        if (resp.data.user.is_admin !== "true") {
          history.push("/welcome");
          history.go();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    checkProfile();
    getChannelDetailsLoad();
    // alert(JSON.stringify(slug));
  }, []);

  if (channel.length == 0) {
    return (
      <div>
        <HeaderV2 />
        <div className="main-content">
          <Load bg="#fff" color="#000" />
        </div>
      </div>
    );
  }
else{
  return (
    <div className="list" style={{ backgroundColor: "#fff" }}>
      {/* <Sidebar /> */}
      <HeaderV2 />
      <div
        className="listContainer overflow-scroll main-content"
        style={{ margin: 30, height: "100%", minHeight: "100%" }}
      >
        {/* <Navbar /> */}
        {/* <div className="datatable"> */}
        <div className="top" style={{ color: "#000" }}>
          <h1>{channel.name} Statistics</h1>
        </div>

        <br />
        <br />
        <ChannelsTabAdmin
          channel={channel}
          // action={action}
          // posts={posts}
          isAdmin='true'
          admin={adminDetails}
          adminId={adminId}
          functionProp={() => getChannelDetailsLoad()}
        />
      </div>
    </div>
  );
}
};

export default AdminChannelAnalytics;
