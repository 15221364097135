import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
// A great library for fuzzy filtering/sorting items hshjh
import { matchSorter } from "match-sorter";
import Paper from "@mui/material/Paper";
import "../../pages/studio/channel.scss";
import Load from "./Load";
import { format, formatDistance, subDays } from "date-fns";

// import makeData from "./makeData.js";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axiosFetch from "../../config/Interceptors";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
// overflow: scroll;
const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    

    tr {
      :last-child {
        td {
          border-bottom: 0;
          width: "10px";
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      width: "10px";

      :last-child {
        border-right: 0;
      }
    }
  }
`;

// Define a default UI for filtering <inpu
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span style={{ paddingLeft: 10 }}>
      Search Through All Records: <br />
      <TextField
        id="outlined-basic"
        label="Global Search"
        variant="standard"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
          backgroundColor: "#fff",
          marginLeft: 10,
        }}
      />
      {/* <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
          backgroundColor: "#36454F",
        }}
      /> */}
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <TextField
      id="outlined-basic"
      label={`Search Column`}
      variant="standard"
      style={{ backgroundColor: "#fff", width: 100 }}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      //   placeholder={`Search ${count} column records...`}
    />
    // <input
    //   style={{ backgroundColor: "#36454F" }}
    //   value={filterValue || ""}
    //   onChange={(e) => {
    //     setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
    //   }}
    //   placeholder={`Search ${count} records...`}
    // />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      {/* <TextField id="outlined-basic" label={`Search Column`} variant="standard" */}
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
        style={{ backgroundColor: "#fff" }}
      />
      <button onClick={() => setFilter(undefined)} style={{ color: "#000" }}>
        Off
      </button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <TextField
        id="outlined-basic"
        label={`Min (${min})`}
        variant="standard"
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      {/* //   <input
        // value={filterValue[0] || ""}
        // type="number"
        // onChange={(e) => {
        //   const val = e.target.value;
        //   setFilter((old = []) => [
        //     val ? parseInt(val, 10) : undefined,
        //     old[1],
        //   ]);
        // }}
        // placeholder={`Min (${min})`}
        // style={{
        //   width: "150px",
        //   marginRight: "0.5rem",
        // }}
      /> */}
      to
      <TextField
        id="outlined-basic2"
        label={`Max (${max})`}
        variant="standard"
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
      {/* //   <input
    //     value={filterValue[1] || ""}
    //     type="number"
    //     onChange={(e) => {
    //       const val = e.target.value;
    //       setFilter((old = []) => [
    //         old[0],
    //         val ? parseInt(val, 10) : undefined,
    //       ]);
    //     }}
    //     placeholder={`Max (${max})`}
    //     style={{
    //       width: "150px",
    //       marginLeft: "0.5rem",
    //     }}
    //   /> */}
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },

    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy
  );

  const TAX_RATE = 0.1;

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  function priceRow(qty, unit) {
    return qty * unit;
  }
  function subtotal(items) {
    return items.map(({ amount }) => amount).reduce((sum, i) => sum + i, 0);
  }

  const [userTransactions, setUserTransactions] = useState([]);

  const invoiceSubtotal = subtotal(data);
  console.log("heloooooo ", rows);
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceTotal = invoiceSubtotal - invoiceTaxes;
  const [month, setMonth] = React.useState("1");

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);

  return (
    <div>
      <TableContainer
        component={Paper}
        className="listContainer"
        {...getTableProps()}
        style={{ width: "140%" }}
      >
        {/* <table
          className="listContainer"
          {...getTableProps()}
          style={{ width: "100%", overflow: "scroll" }}
        > */}

        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              style={{ color: "#000", maxWidth: "20px" }}
            >
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    color: "#000",
                    width: "20px",
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                  {/* Render the columns filter UI style={{ color: "blue" }} */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                  {/* </th> */}
                </TableCell>
              ))}
            </TableRow>

            // </tr>
          ))}
          {/* <TableRow>
                <TableCell>
                    Date Format
                </TableCell>
            </TableRow> */}

          {/* <TableCell>
                        <>
                        <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                </>
                </TableCell>
                      <TableCell style={{ color: "#000" }}>Between now and  month ago</TableCell>
                      <TableCell style={{ color: "#000" }}>Exactly  month ago</TableCell> */}

          {/* <TableRow>
                   
                    <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                    </TableRow> */}
        </TableHead>
        {/* <thead style={{ color: "#000" }}>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{ color: "#000", maxWidth: "20px" }}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      color: "#000",
                      width: "20px",
                      backgroundColor: "red",
                    }}
                  >
                    {column.render("Header")}
                   
                    <div style={{ color: "blue" }}>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "left",
                  color: "#000",
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>
          </thead> */}

        {/* <TableBody {...getTableBodyProps()} style={{ color: "#000" }}>
         
{firstPageRows.map((row, i) => {
              prepareRow(row);
              return (

            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              {...row.getRowProps()} style={{ color: "#000" }}
            >
              {row.cells.map((cell) => {
                    return (
              <TableCell component="th" scope="row"  {...cell.getCellProps()}
              style={{ color: "#000", backgroundColor: "blue" }}>
                {cell.render("Cell")}
              </TableCell>
              })}
            </TableRow>
            );
            })}
        </TableBody> 
          
          // <tbody {...getTableBodyProps()} style={{ color: "#000" }}>
          //   {firstPageRows.map((row, i) => {
          //     prepareRow(row);
          //     return (
                
          //       <tr {...row.getRowProps()} style={{ color: "#000" }}>
          //         {row.cells.map((cell) => {
          //           return (
                      
          //             <td
          //               {...cell.getCellProps()}
          //               style={{ color: "#000", backgroundColor: "blue" }}
          //             >
          //               {cell.render("Cell")}
          //             </td>
          //           );
          //         })}
          //       </tr>
          //     );
          //   })}
          // </tbody>  */}

        <TableBody {...getTableBodyProps()} style={{ color: "#000" }}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()} style={{ color: "#000" }}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      style={{ color: "#000" }}
                    >
                      {/* , backgroundColor: "blue"  */}
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}

                {/* {userTransactions.map((cell) => {
                  return (
                    <TableCell
                      // {...cell.getCellProps()}
                      style={{ color: "#000" }}
                    >
                     
                      {cell.created_at}
                    </TableCell>
                  );
                })} */}
              </TableRow>
            );
          })}

           <TableRow>
            <TableCell colSpan={2} />
            <TableCell colSpan={1}> Transactions Subtotal</TableCell>
            <TableCell colSpan={1}>{ccyFormat(invoiceSubtotal)}</TableCell>
            
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} />
            <TableCell colSpan={1}>Commission</TableCell>
            <TableCell>{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell>
           
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} />
            <TableCell colSpan={1}>Estimated Payout</TableCell>
            <TableCell>{ccyFormat(invoiceTotal)}</TableCell>
            
          </TableRow> 
        </TableBody>
        {/* </TableRow> */}
        {/* </table> */}
        <TableFooter>
          {headerGroups.map((headerGroup) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              style={{ color: "#000", maxWidth: "20px" }}
            >
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    color: "#000",
                    width: "20px",
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                  {/* Render the columns filter UI style={{ color: "blue" }} */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                  {/* </th> */}
                </TableCell>
              ))}
            </TableRow>

            // </tr>
          ))}
        </TableFooter>
      </TableContainer>
      <br />
      <div style={{ color: "blue" }}>Showing {rows.length} results</div>
      {/* <div>
        <pre>
          <code style={{ color: "#000" }}>
            {JSON.stringify(state.filters, null, 2)}
          </code>
        </pre>
      </div> */}
    </div>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

function MarkerTransactionTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Advanced Transactions Filtering (sort from highest to lowest, search globally, by column & more)",
        columns: [
          {
            Header: "User Name",
            accessor: "user.user_name",
          },
          {
            Header: "Type",
            accessor: "type",
            // Use our custom `fuzzyText` filter on this column ID	User	Type	Description	Reference	Transaction	Status	Amount	Date Made
            filter: "fuzzyText",
          },
          {
            Header: "Description",
            accessor: "description",
            // Use our custom `fuzzyText` filter on this column ID	User	Type	Description	Reference	Transaction	Status	Amount	Date Made
            filter: "fuzzyText",
          },
          {
            Header: "Amount (Between)",
            accessor: "amount",
            Filter: NumberRangeColumnFilter,
            filter: "between",
            // Filter: SliderColumnFilter,
            // filter: "equals",
          },
          {
            Header: "Amount (Highest)",
            accessor: "payout",
            Filter: SliderColumnFilter,
            // filter: "equals",
            filter: filterGreaterThan,
          },
          {
            Header: "Will Expire",
            accessor: "will_expire",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Status",
            accessor: "status",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Reference",
            accessor: "reference",
            filter: "fuzzyText",
            // Filter: SliderColumnFilter,
            // filter: "equals",
          },
          {
            Header: "Transaction ID",
            accessor: "transaction",
            // Use our custom `fuzzyText` filter on this column ID	User	Type	Description	Reference	Transaction	Status	Amount	Date Made
            filter: "fuzzyText",
          },
          {
            Header: "Date",
            accessor: "created_at",
            disableFilters: true,
            // sticky: 'left'
            Cell: ({ value }) => {
              const date1 = formatDistance(new Date(value), new Date());
              const date2 = format(new Date(value), "dd/MM/yyyy");
              return (
                <span>
                  {date1} - {date2}{" "}
                </span>
              );
            },
            // Cell : (props)=>{
            //     //props.value will contain your date
            //     //you can convert your date here
            //     // const custom_date = 'custom_date'+props.value
            //     const custom_date = formatDistance(new Date(props.created_at), new Date(), {
            //                 addSuffix: true,
            //               })
            //     return <span>{custom_date}</span>
            // }
            // accessor: d => {
            //     {formatDistance(new Date(created_at), new Date(), {
            //         addSuffix: true,
            //       })}

            //     return Moment(d.updated_at)
            //       .local()
            //       .format("DD-MM-YYYY hh:mm:ss a")
            //   }
            // disableFilters: true
            // sortable: false,
            // filterable: false,
            // Filter: NumberRangeColumnFilter,
            // filter: "between",
            // disableGlobalFilter: true,
          },
        ],
      },
      //   {
      //     Header: "",
      //     columns: [

      //     ],
      //   },
    ],
    []
  );

  // const data = React.useMemo(() => makeData(100000), []);
  const u_id = useParams();
  const [userTransactions, setUserTransactions] = useState([]);
  const [videoTransactions, setVideoTransactions] = useState([]);
  const [fetching, setFetching] = useState([]);
  const [month, setMonth] = React.useState("1");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  //   const data = React.useMemo(() => userTransactions, []);
  const data = userTransactions;

  const handleChange = (event) => {
    console.log(event.target.value);
    setMonth(event.target.value);
  };

  async function getChannelTransactions() {
    setFetching(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/${props.link}/${month}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log("version 2 ", JSON.stringify(resp.data));
        
        setUserTransactions(resp.data.transactions);
        setVideoTransactions(resp.data);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function switchTransactions() {
    setFetching(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/${props.link}/${month}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log("version 2 ", JSON.stringify(resp.data));
        
        setUserTransactions(resp.data.allTransactions);
        setVideoTransactions(resp.data);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  // async function getAllChannelTransactions() {
  //   setFetching(true);
  //   // console.log(` 1 ${fetchingProfile}`)
  //   const access_token = await window.localStorage.getItem("access_token");
  //   axiosFetch
  //     .get(`/${props.link}/${month}`, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + access_token,
  //       },
  //     })
  //     .then((resp) => {
  //       // console.log("version 2 ", JSON.stringify(resp.data));
  //       setUserTransactions(resp.data);
  //       setFetching(false);
  //     })
  //     .catch((error) => {
  //       setFetching(false);
  //       console.log(error);
  //       setError(true);
  //       setErrorMessage(error.message);
  //     });
  // }

  useEffect(() => {
    getChannelTransactions();
  }, [console.log(month, " -> state")]);

  return (
    <div className="overflow-scroll">
      {/* className="overflow-scroll" */}
      <Styles>
        <h5 className="text-center" style={{ color: "#000" }}>
          Hello admin! {props.title}
        </h5>{" "}
        <br />
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label">Sort</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={month}
            label="Sort"
            onChange={handleChange}
          >
            <MenuItem value={1}>One Month</MenuItem>
            <br />
            <MenuItem value={2}>Two Months</MenuItem>
            <br />
            <MenuItem value={3}>Three Months</MenuItem>
            <br />
            <MenuItem value={4}>Four Months</MenuItem>
            <br />
            <MenuItem value={5}>Five Months</MenuItem>
            <br />
            <MenuItem value={6}>Six Months</MenuItem>
            <br />
            <MenuItem value={7}>Seven Months</MenuItem>
            <br />
            <MenuItem value={8}>Eight Months</MenuItem>
            <br />
            <MenuItem value={9}>Nine Months</MenuItem>
            <br />
            <MenuItem value={10}>Ten Months</MenuItem>
            <br />
            <MenuItem value={11}>Eleven Months</MenuItem>
            <br />
            <MenuItem value={12}>Twelve Months</MenuItem>
            <br />
          </Select>
          <FormHelperText>
            Select a number to get only transactions you've made within the past
            months
          </FormHelperText>
        </FormControl>
        <Button onClick={getChannelTransactions}>Get Transactions</Button>
        <Button onClick={switchTransactions}>
          See Total Transactions
        </Button>
        <br />
        {fetching ? (
          <Load bg="#fff" color="#000" />
        ) : (
          <Table columns={columns} data={data} />
        )}
      </Styles>
    </div>
  );
}

export default MarkerTransactionTable;
