import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import Alert from "@mui/material/Alert";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import FeatherIcon from "feather-icons-react";
import Tooltip from "@mui/material/Tooltip";

export default function DisApproveMovie(props) {
  const [disApprovalMessage, setDisApprovalMessage] = useState("");
  const [openDisapproval, setOpenDisapproval] = useState(false);
  const [approving, setApproving] = useState(false);
  const [open, setOpen] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleOpenApproval = () => {
    setOpenDisapproval(true);
  };

  function handleClose() {
    setOpenDisapproval(false);
  }

  async function disApproveContent(id) {
    if (disApprovalMessage == "") {
      alert("type in your reason for dis-approving first");
    } else {
      setApproving(true);
      // alert('approving, click ok to continue')
      const access_token = await window.localStorage.getItem("access_token");
      const authId = await window.localStorage.getItem("user_id");

      const formData = new FormData();
      formData.append("message", disApprovalMessage);

      axiosFetch
        .post(`/${props.link}/${id}`, formData, {
          headers: {
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/html",
            Authorization: "Bearer " + access_token,
          },
          // method: 'POST',
        })
        .then((resp) => {
          const alerts = resp.data;
          props.functionProp();

          // alert('relaerted!')
          alert(`dis-approved successfuly. Wait few sec`);
          setApproving(false);
          setOpenDisapproval(false)
          // setSaved(true);
        })
        .catch((error) => console.error(`Error Seen : ${error}`));
    }
  }

  return (
    <>
      <Tooltip title="Dis Approve Movie" aria-label="add">
        <a
          onClick={() => handleOpenApproval()}
          style={{
            border: "#fff solid 2px",
            backgroundColor: "#FF0000",
          }}
          className="btn-round-lg ms-2 d-inline-block rounded-3"
        >
          <CancelPresentationIcon style={{ color: "#fff", fontSize: 30 }} />
          <FeatherIcon icon="eyek" size="24" color="black" />
          <i style={{ color: "#fff" }} className=" font-sm text-white"></i>{" "}
        </a>
      </Tooltip>

      <Dialog onClose={handleClose} open={openDisapproval}>
        <DialogTitle>Disapprove {props.movie.title} </DialogTitle>

        <div style={{ padding: 30 }}>
          <p>
            This has been approved already. Before disapproving, type the reason
            for first. This is what is displayed to channel admin.{" "}
          </p>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <input
                  type="text"
                  value={disApprovalMessage}
                  onChange={(e) => {
                    setDisApprovalMessage(e.target.value);
                  }}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          <Button variant='contained' color="primary" onClick={() => disApproveContent(props.movie.id)}>
            Dis Approve Now
          </Button>

          {approving ? (
            <>
              <br />
              <p className="text-green">
                {" "}
                Approving / Dis-approving Content... This will take a few
                seconds{" "}
              </p>
              <br />
              <br />
            </>
          ) : (
            ""
          )}
        </div>
      </Dialog>
    </>
  );
}
