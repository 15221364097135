import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
// import { Select } from "antd";
import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import { AutoComplete } from "antd";
import MuiAlert from "@material-ui/lab/Alert";

import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import GroupAddOutlined from "@mui/icons-material/GroupAddOutlined";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getCircularProgressUtilityClass } from "@mui/material";
import { Typography } from "@material-ui/core";
import Resizer from "react-image-file-resizer";

export default function AddCasts(props) {
  const [emptyFields, setEmptyFields] = useState(false);
  const history = useHistory();
  const [currentCast, setCurrentCast] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState("");
  const [lastName, setLastName] = useState("");
  const [castName, setCastName] = useState("");
  const [castImage, setCastImage] = useState("");
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [castRole, setCastRole] = useState("");
  const [casts, setCasts] = useState([]);
  const [role, setRole] = useState("");
  const animatedComponents = makeAnimated();
  const [open, setOpen] = React.useState(false);
  const [addedCast, setAddedCast] = React.useState(false);
  const { Option } = AutoComplete;
  const [results, setResults] = useState([]);
  const [items, setItems] = useState([]);

  const [coverImage, setCoverImage] = useState("");
  const [coverImageFe, setCoverImageFe] = useState("");

  const roleOptions = [
    { value: "actor", label: "Actor" },
    { value: "actress", label: "Actress" },
    { value: "director", label: "Director" },
  ];

  const handleClose = () => {
    setOpen(false);
    setEmptyFields(false);
  };
  const handleClose2 = () => {
    // setOpen(false);
    setEmptyFields(false);
  };

  function selectRole(value) {
    console.log(`selected ${JSON.stringify(value)}`);
    setRole(value.value);
    setCastRole(value.value);
  }
  function selectRole2(value) {
    console.log(`selected ${JSON.stringify(value)}`);
    value.map((role) => role.push(role.value));
    // value.map((castRole) => role.push(role.value));
    // console.log(`name ${role}`);
  }
  // function ChangeTag(value) {
  //   console.log(`selected ${value}`);
  //   value.map((tag) => tags.push(tag.value));
  //   console.log(`name ${tags}`);
  // }


  async function validate() {
    if (!castName) {
      // console.log(alertCategory)
      setEmptyFields(true);
      return;
    } else {
      addCast();
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onSelect = (data) => {
    console.log("onSelect", data);
    // history.push(`/profile/${data}`);
    // history.go(`/profile/${data}`);
  };

  const onChange = (data) => {
    console.log("onchange", data);
    // setValue(data);
  };

  function getCasts() {
    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .get(`/${props.getUrl}/${props.movieId}`, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setCasts(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function clearCoverImage(value) {
    setCoverImage("");
    setCoverImageFe("");
  }

  const resizeCoverFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1080,
      "WEBP",
      90,
      0,
      (uri) => {
        resolve(uri);
        console.log(uri);
        setCoverImage(uri);
      },
      "file",
      1080,
      1080
    );
  });

  const compressCoverImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      const image = await resizeCoverFile(file);
      //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };


  async function addCast() {
    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    // formData.append("channel_id", props.channelId);
    formData.append("name", castName);
    formData.append("image", coverImage);
    formData.append("movie_id", props.movieId);
    formData.append("role", role);
    formData.append("type", props.type);

    axiosFetch
      .post(`/${props.addUrl}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(`hi ${resp.data}`);

        setIsCreating(false);
        // props.functionProp();
        getCasts();
    
      
        setAddedCast(true)
        // setOpen(false);
        // props.functionProp();
        console.log(`response ${JSON.stringify(resp.data)}`);
        // setIsSuccess(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function removeCast(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .delete(
        `/${props.removeUrl}/${id}/remove`,
        // id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // props.functionProp();
        getCasts();
        setIsDeleting(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getCasts();
    // searchUsers()
  }, []);

  return (
    <>
      <Tooltip
        className="d-inline-block"
        title={`Add casts to your ${props.movieType}`}
        onClick={handleClickOpen}
      >
        <IconButton>
          <GroupAddOutlined
            //  className="btn-outline"
            className="d-inline-block"
            onClick={() => console.log("video")}
            style={{
              color: props.color,
              fontSize: 30,
              marginLeft: 5,
              marginRight: 5,
            }}
          />{" "}
        </IconButton>
      </Tooltip>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {" "}
            Add people who contributed to this {props.movieType}{" "}
            
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {" "}
              You've added {casts.length} casts to your {props.movieType}{" "}
            </DialogContentText>
            <br />{" "}
            <Typography gutterBottom variant="subtitle2">
                Start by adding major cast with the most important roles, as they appear first
            </Typography>
            {/* <p>Search for user to start. start with the most important roles</p> */}
            {/* {console.log(JSON.stringify(casts))} */}
            {casts.length == 0 ? (
              <h6 style={{color: '000'}}> Seems like you haven't addded any cast yet. Movie casts added appears here</h6>
            ) : (
              <>
                {casts.map((cast) => (
                  <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100">
                    <div className="flex items-center justify-between py-3">
                      <div className="flex flex-1 items-center space-x-4">
                        {/* <a href={`/profile/${cast.user.user_name}`}> */}
                          <img
                            src={cast.image}
                            className="bg-gray-200 rounded-full w-10 h-10"
                            // onError={fixImage}
                          />
                        {/* </a> */}
                        {/* <Link to={`/profile/${cast.user.user_name}`}> */}
                          <div className="flex flex-col">
                            <span className="block capitalize font-semibold">
                              {" "}
                              {cast.name}
                            </span>
                            <span className="block capitalize text-sm">
                              {" "}
                              [ {cast.role} ]
                            </span>
                          </div>
                        {/* </Link> */}
                      </div>

                      <a
                        onClick={() => removeCast(cast.id)}
                        // onClick={toogleFollow(user.user_name)}
                        className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-pink-600 hover:text-white hover:border-pink-600 dark:border-gray-800"
                      >
                        {" "}
                        REMOVE{" "}
                      </a>
                    </div>
                  </div>
                ))}
              </>
            )}
            {isDeleting ? (
              <>
                <br />
                <Typography gutterBottom variant="subtitle2">
                  removing cast ...
                </Typography>

                <br />
              </>
            ) : (
              ""
            )}
            <br />
            <br />
            <Typography gutterBottom variant="subtitle2">
              What is your cast full name?
            </Typography>
            <div style={{ width: 300, marginTop: 10 }}>
            
            
              <TextField
                variant="standard"
                required
                fullWidth
                // id="lastName"
                label="Cast Name"
                // name="lastName"
                // autoComplete="lname"
                // size="small"
                value={castName}
                onChange={(e) => {
                setCastName(e.target.value)
                setAddedCast(false)
              }}
              />
            </div>
         
            <br />
            <br />
            <Typography gutterBottom variant="subtitle2">
              Select user's role or type and click create in dropdown to create new
            </Typography>
            {/* <p> Select user's role or create new </p> */}
            <CreatableSelect
              isClearable
              // isMulti
              onChange={selectRole}
              options={roleOptions}
              components={animatedComponents}
              // options={options}
            ></CreatableSelect>
            <br />
            <br />

            <div>
                
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{ width: "100%" }}
                      // className={classes.postHead}
                    >
                      Use A Clear Picture
                    </Typography>{" "}
                    {coverImageFe ? (
                      <>
                        <br />
                        <p> This will be visible as your cast public image </p>
                        <br />
                        <img
                          //    src={props.cover_image}
                          src={URL.createObjectURL(coverImageFe)}
                          style={{
                            maxWidth: "100%",
                            maxHeight: 450,
                            objectFit: "contain",
                          }}
                          // style={{ maxWidth: "100%", maxHeight: 400 }}
                          alt="profile image"
                        />
                        <br />
                        <br />
                        <Button onClick={clearCoverImage}>Remove</Button>
                        <br />
                      </>
                    ) : (
                      <div className="col-lg-12 mb-1">
                        <div className="card mt-1 border-0">
                          <div className="card-body d-flex justify-content-between align-items-end p-0">
                            <div className="form-group mb-0 w-100">
                              <input
                                type="file"
                                name="file2"
                                id="file2"
                                accept="image/*"
                                className="input-file"
                                onChange={(e) => {
                                  setCoverImageFe(e.target.files[0]);
                                  compressCoverImage(e);
                                }}
                              />
                              <label
                                htmlFor="file2"
                                className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                              >
                                <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                <span className="js-fileName">
                                  Click to upload cast picture
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

            <br />
            <br />
            <br />
            {addedCast == true &&
            <h6 style={{color: '#000'}}> Added Sucessfuly! Scroll up to view and add more</h6>}
            <br />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            {isCreating ? (
              <Button disabled>Adding</Button>
            ) : (
              <Button onClick={validate}>Add To Cast</Button>
              // <Button onClick={console.log(`hi ${movieTitle}`)}>addCast</Button>
            )}
            {/* <Button onClick={validate}>Create</Button> */}
          </DialogActions>
          {emptyFields ? (
            <MuiAlert onClose={handleClose2} severity="warning">
              Add A User first
            </MuiAlert>
          ) : (
            ""
          )}
        </Dialog>{" "}
      </Box>
    </>
  );
}
