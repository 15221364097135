import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { useLongPress } from "use-long-press";
import "./movie-card.scss";
import CloseIcon from "@mui/icons-material/Close";

import { Link } from "react-router-dom";

import Button from "../button/Button";
import Modal, { ModalContent } from "../modal/Modal";
import ReactPlayer from "react-player";
import axiosFetch from "../../config/Interceptors";
// import "../hero-slide/hero-slide.scss";

import { category } from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import { hslToRgb } from "@material-ui/core";

import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import Alert from "@mui/material/Alert";
import LikersModal from "../others/LikersModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@material-ui/core";
import FeatherIcon from "feather-icons-react";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import ShareIcon from "@mui/icons-material/Share";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import AddCardIcon from "@mui/icons-material/AddCard";
import GroupIcon from "@mui/icons-material/Group";
import Tooltip from "@mui/material/Tooltip";
import { InfoOutlined, PlayArrow, Visibility } from "@material-ui/icons";

const SeriesCardMap = (props) => {
  const movie = props.series;
  const history = useHistory();
  const [redirect, setRedirect] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [promptMessage, setPromptMessage] = useState("");
  const [promptType, setPromptType] = useState("login");
  const [promptAction, setPromptAction] = useState("Login Now");
  const [stopVideo, setStopVideo] = useState(true);
  const seriesLink = props.seriesLink;

  const [open, setOpen] = React.useState(false);
  const [openPaid, setOpenPaid] = React.useState(false);
  const [prompt, setPrompt] = React.useState(false);

  const [copied, setCopied] = useState(false);
  const userName = window.localStorage.getItem("user_name");
  const [isLiking, setIsLiking] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [message, setMessage] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenPaid = () => {
    setOpenPaid(true);
  };
  const openPrompt = () => {
    setPrompt(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setOpenPaid(false);
    setPrompt(false);
    setSaved(false);
  };
  const onClose = () => {
    setVideoUrl("");
    setStopVideo(false);
    setOpen(false);
    setOpenPaid(false);
    setPrompt(false);
    setSaved(false);
  };

  function closeSnack() {
    setCopied(false);
  }

  async function watchLater(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/series/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        // props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  const location = useLocation();

  function goToLogin() {
    history.push("/signin");
    history.go();
  }

  function goToMoviePage() {
    history.push("/chillarx/watch/series/" + movie.u_id);
    history.go();
  }
  function validateFree() {
    const token = window.localStorage.getItem("access_token");
    if (!token) {
      openPrompt();
      setPromptMessage("Oops! You haven't logged in. Click Login to login now");
      setPromptType("login");
      setPromptAction("Login Now");
      // var retVal = window.confirm(
      //   `free Oops! You haven't logged in. Click OK to login now`
      // );
      // if (retVal == true) {
      //   history.push("/signin");
      //   history.go();
      // } else {
      //   return;
      // }
    } else {
      // setModalActive()
      handleClickOpen();
      markAsViewed();
    }
  }

  function validatePaid() {
    const token = window.localStorage.getItem("access_token");
    if (!token) {
      openPrompt();
      setPromptMessage("Oops! You haven't logged in. Click Login to login now");
      setPromptType("login");
      setPromptAction("Login Now");
    } else {
      // setModalActive();
      handleClickOpenPaid();
      markAsViewed();
    }
  }


  async function markAsViewed() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movie/series/mark/asviewed/${movie.id}`, {
        method: "GET",
        // delay: 500000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function validateRedirect(movie) {
    //   alert(`${redirect}/${movie.u_id}`)
    const token = window.localStorage.getItem("access_token");
    if (!token) {
      openPrompt();
      setPromptMessage("Oops! You haven't logged in. Click Login to login now");
      setPromptType("login");
      setPromptAction("Login Now");
      // var retVal = window.confirm(
      //   `Oops! You haven't logged in. Click OK to login now`
      // );
      // if (retVal == true) {
      //   history.push("/signin");
      //   history.go();
      // } else {
      //   return;
      // }
    }

    if (token && token.length > 0) {
      history.push("/chillarx/watch/series/" + movie.u_id);
      history.go();
    }
  }

  function validate(movie) {
    //   alert(`${redirect}/${movie.u_id}`)
    const token = window.localStorage.getItem("access_token");
    if (!token) {
      openPrompt();
      setPromptMessage("Oops! You haven't logged in. Click Login to login now");
      setPromptType("login");
      setPromptAction("Login Now");
      // var retVal = window.confirm(
      //   `Oops! You haven't logged in. Click OK to login now`
      // );
      // if (retVal == true) {
      //   history.push("/signin");
      //   history.go();
      // } else {
      //   return;
      // }
    }

    if (token && token.length > 0) {
      openPrompt();
      setPromptMessage(  `Hi! You just click the series title. Click view details to proceed to view more details of this series`);
      setPromptType("redirect");
      setPromptAction("View Details");
      // var retVal = window.confirm(
      //   `This series is not free. Click OK to view its page`
      // );
      // if (retVal == true) {
      //   history.push("/chillarx/watch/series/" + movie.u_id);
      //   history.go();
      // } else {
      //   console.log("canceled");
      //   return;
      // }
    }
  }

  useEffect(() => {
    // if(pathname.includes("/welcome")){
    //   alert('you are welcome')
    // }
    // validate()

    if (seriesLink) {
      setRedirect("/chillarx/watch/series");
    } else {
      setRedirect("/chillarx/watch");
    }
  }, []);

  const setModalActive = async () => {
    const modal = document.querySelector(`#modal22_${movie.id}`);

    const videSrc = "https://www.youtube.com/watch?v=M8SwF2qUtts";
    modal.classList.toggle("active");
  };

  const TrailerModal = (props) => {
    const movie = props.movie;
    const iframeRef2 = useRef(null);
    setVideoUrl(movie.video);
    const onClose = () => {
      setVideoUrl("");
      setStopVideo(false);
      // iframeRef2.current.setAttribute("url", "")
    };
    // setVideoUrl('');
    // const onClose = () => console.log("closed");

    return (
      <Modal active={false} id={`modal22_${movie.id}`}>
        <ModalContent onClose={onClose}>
          <ReactPlayer
            ref={iframeRef2}
            url={videoUrl}
            controls={true}
            playing={stopVideo}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            playsinline={true}
            pip={true}
            width="100%"
            light={movie.image}
          />
          <br />
          {/* <p style={{ color: "#fff" }}>Pause before closing</p> */}
          <h6 style={{ color: "#fff" }} onClick={onClose}>
            Click To Close
          </h6>
        </ModalContent>
      </Modal>
    );
  };

  if (movie.pricing == "free") {
    return (
      <>
        <TrailerModal key={movie.id} movie={movie} />
        <div onClick={() => validateFree()}>
          <div
            className="movie-card"
            style={{ backgroundImage: `url(${movie.image})` }}
          >
            <Button
            // onClick={() => validateFree()}
            // setModalActive()  onClick={setModalActive} {...bind(movie)}
            >
              <i className="bx bx-play"></i>
            </Button>
          </div>
          {/* <Link
            onClick={() => 
              validate(movie)
              // history.push({
              //   pathname: `${redirect}/${movie.id}`,
              // });
              // window.location.reload();
            }
          > */}
          <h6
            style={{ color: "#FFD700", textAlign: "center", cursor: 'pointer' }}
            onClick={() => validate(movie)}
          >
            {movie.title}
          </h6>
          
            <h6 style={{ color: "#FFD700", textAlign: "center" }}>
          
              [ Free! ]
            </h6>
       
          {/* </Link> */}
        </div>
        {/* <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          // open={open}
          // onClick={handleClose}
        > */}
        <Dialog
          open={open}
          onClose={handleClose}
          style={{ backgroundColor: "#282C35", opacity: "0.96" }}
        >
          {/* <DialogTitle>Chill!</DialogTitle> */}
          <DialogContent style={{ backgroundColor: "#000", width: '120%' }}>
            <DialogContentText style={{ color: "#fff" }}>
            <span style={{ color: "#FFD700" }}> {movie.title} </span>
                <br /> To view series details, click the titles instead
            </DialogContentText>

            <div style={{ backgroundColor: "#000" }}>
            {movie.trailer == '' || movie.trailer == null ?
               <h5 style={{ color: "#FFD700" }}> This series has no trailer preview. Click the link above to watch it.</h5>
                :
              <ReactPlayer
                // ref={iframeRef} FREE SERIES
                url={movie.trailer}
                controls={true}
                playing={stopVideo}
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
                playsinline={true}
                pip={true}
                width="100%"
                light={movie.image}
              />
  }
               <br />
              <div style={{ display: "flex" }}>
                <Tooltip title="Share Link" aria-label="add">
                  <>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_URL}/signin/chillarx/watch/series/${movie.u_id}`}
                      // text={`${process.env.REACT_APP_URL}/signin${window.location.pathname}?${queryParams}`}
                      onCopy={() => setCopied(true)}
                    >
                      <a
                        style={{ border: "#fff solid 2px" , cursor: 'pointer'}}
                        className="btn-round-lg ms-2 d-inline-block rounded-3"
                      >
                        <ShareIcon style={{ color: "#fff", fontSize: 30 }} />
                        <FeatherIcon icon="ee" size="24" color="black" />
                        <i
                          className=" text-white rounded-full p-2 transition -mr-1"
                          style={{ fontSize: 25, color: "#fff" }}
                        >
                          {/* <FeatherIcon icon="share2" size="24" color='white' /> */}
                        </i>{" "}
                      </a>
                    </CopyToClipboard>
                    <br />
                    {copied ? (
                      <>
                        <Snackbar
                          open={copied}
                          autoHideDuration={3000}
                          onClose={closeSnack}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Alert onClose={closeSnack} severity="success">
                            Link Copied Successfully
                          </Alert>
                        </Snackbar>
                      </>
                    ) : null}
                  </>
                </Tooltip>

                {/* <ShareMovie /> */}

                <Tooltip title="Add To chill list" aria-label="add">
                  <a
                    onClick={() => watchLater(movie.id)}
                    // href="/defaulthoteldetails"
                    style={{
                      border: "#fff solid 2px",
                      backgroundColor: "green",
                      cursor: 'pointer'
                    }}
                    className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                  >
                    <BookmarkAddedIcon
                      style={{ color: "#fff", fontSize: 30 }}
                    />
                    <FeatherIcon icon="ee" size="24" color="black" />
                    <i
                      style={{ color: "#fff" }}
                      className=" font-sm text-white"
                    ></i>{" "}
                  </a>
                </Tooltip>
              </div>

              <div>
                {saving ? (
                  <>
                    <br />
                    <br />
                    <br />
                    <p className="text-white">
                      {" "}
                      Adding to chill list... This will take a few seconds{" "}
                    </p>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
                {saved ? (
                  <>
                    <br />
                    <br />
                    <br />
                    <Alert
                      onClose={handleClose}
                      severity="success"
                      action={
                        <>
                          <a
                            onClick={() => setSaved(false)}
                            className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                          >
                            close
                          </a>
                        </>
                      }
                    >
                      You've added this series to your chill list successfully.
                      <a href={`/chill-list`}>
                      {/* <a href={`/profile/${userName}/watchlist`}> */}
                        Click here to view it in your profile
                      </a>
                    </Alert>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </DialogContent>

          <DialogActions style={{ backgroundColor: "#000", width: '120%' }}>
            {/* <Button onClick={onClose}>Close</Button> */}

            <CloseIcon
              style={{ fontSize: 30, color: "#FFD700", cursor: 'pointer' }}
              onClick={onClose}
            />
          </DialogActions>
        </Dialog>

        <Dialog
          open={prompt}
          onClose={handleClose}
          style={{ backgroundColor: "#282C35", opacity: "0.96" }}
        >
          {/* <DialogTitle>Chill!</DialogTitle> */}
          <DialogContent style={{ backgroundColor: "#000" }}>
            <DialogContentText style={{ color: "#fff" }}>
              {promptMessage}
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ backgroundColor: "#000" }}>
            {promptType == "login" ? (
              <h3 onClick={goToLogin} className='text-center' style={{ alignSelf: "center", color:'#FFD700', marginRight: 40, cursor: 'pointer' }}>
                {promptAction}
              </h3>
            ) : (
              <h3 onClick={goToMoviePage} className='text-center' style={{ alignSelf: "center", color:'#FFD700', marginRight: 40, cursor: 'pointer' }}>
                {promptAction}</h3>
            )}

            <CloseIcon
              style={{ fontSize: 30, color: "#FFD700", cursor: 'pointer' }}
              onClick={onClose}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return (
      <>
        <div onClick={() => validatePaid()}>
          <div
            className="movie-card"
            style={{ backgroundImage: `url(${movie.image})` }}
          >
            <Button>
              <i className="bx bx-play"></i>
            </Button>
          </div>
          {/* <Link
            onClick={() => 
              validate(movie)
              // history.push({
              //   pathname: `${redirect}/${movie.id}`,
              // });
              // window.location.reload();
            }
          > */}
          <h6
            style={{ color: "#FFD700", textAlign: "center", cursor: 'pointer' }}
            onClick={() => validate(movie)}
          >
            {movie.title}{" "}
          </h6>
          {/* </Link> */}
          {movie.pricing == "paid" && (
            <h6 style={{ color: "#FFD700", textAlign: "center" }}>
              {" "}
              [ Paid {movie.price} ]{" "}
            </h6>
          )}

          {movie.pricing == "subscription" && (
            <h6 style={{ color: "#FFD700", textAlign: "center" }}>
              {" "}
              [ Channel Subscribers Only ]
            </h6>
          )}
            {movie.chillarx_monetized == "approved" && (
            <h6 style={{ color: "#FFD700", textAlign: "center" }}>
              {" "}
              [ For Chillarx Subscribers  ]
            </h6>
          )}
        </div>

           {/* paidddddd */}
           <Backdrop
          sx={{
            color: "#fff",
            opacity: "0.5",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          style={{ opacity: "0.9" }}
          // open={open}
          // onClick={handleClose}
        >
          <Dialog
            open={openPaid}
            onClose={handleClose}
            style={{ backgroundColor: "#282C35", opacity: "0.96" }}
          >
            <DialogContent style={{ backgroundColor: "#000", width: '120%' }}>
              <DialogContentText style={{ color: "#fff" }}>
                <span style={{ color: "#FFD700" }}> {movie.title} </span>
                <br />
                This is just a trailer.{" "}
                <a
                  href={`/chillarx/watch/series/${movie.u_id}`}
                  style={{ color: "#FFD700",  cursor: 'pointer' }}
                >
                  {" "}
                  Click here
                </a>{" "}
                to watch the full video
              </DialogContentText>
              <br />
              <div style={{ backgroundColor: "#000",   }}>
              {movie.trailer == '' || movie.trailer == null ?
               <h5 style={{ color: "#FFD700" }}> This series has no trailer preview. Click the link above to watch it.</h5>
                :
                <ReactPlayer
                  // ref={iframeRef} #282C35 PAID SERIES
                  url={movie.trailer}
                  controls={true}
                  playing={stopVideo}
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  playsinline={true}
                  pip={true}
                  width="100%"
                  light={movie.image}
                />
  }
                 <br />
              <div style={{ display: "flex" }}>
                <Tooltip title="Share Link" aria-label="add">
                  <>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_URL}/signin/chillarx/watch/series/${movie.u_id}`}
                      // text={`${process.env.REACT_APP_URL}/signin${window.location.pathname}?${queryParams}`}
                      onCopy={() => setCopied(true)}
                    >
                      <a
                        style={{ border: "#fff solid 2px", cursor: 'pointer' }}
                        className="btn-round-lg ms-2 d-inline-block rounded-3"
                      >
                        <ShareIcon style={{ color: "#fff", fontSize: 30 }} />
                        <FeatherIcon icon="ee" size="24" color="black" />
                        <i
                          className=" text-white rounded-full p-2 transition -mr-1"
                          style={{ fontSize: 25, color: "#fff" }}
                        >
                          {/* <FeatherIcon icon="share2" size="24" color='white' /> */}
                        </i>{" "}
                      </a>
                    </CopyToClipboard>
                    <br />
                    {copied ? (
                      <>
                        <Snackbar
                          open={copied}
                          autoHideDuration={3000}
                          onClose={closeSnack}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Alert onClose={closeSnack} severity="success">
                            Link Copied Successfully
                          </Alert>
                        </Snackbar>
                      </>
                    ) : null}
                  </>
                </Tooltip>

                {/* <ShareMovie /> */}

                <Tooltip title="Add To chill list" aria-label="add">
                  <a
                    onClick={() => watchLater(movie.id)}
                    // href="/defaulthoteldetails"
                    style={{
                      border: "#fff solid 2px",
                      backgroundColor: "green",
                      cursor: 'pointer'
                    }}
                    className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                  >
                    <BookmarkAddedIcon
                      style={{ color: "#fff", fontSize: 30 }}
                    />
                    <FeatherIcon icon="ee" size="24" color="black" />
                    <i
                      style={{ color: "#fff" }}
                      className=" font-sm text-white"
                    ></i>{" "}
                  </a>
                </Tooltip>
              </div>

              <div>
                {saving ? (
                  <>
                    <br />
                    <br />
                    <br />
                    <p className="text-white">
                      {" "}
                      Adding to chill list... This will take a few seconds{" "}
                    </p>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
                {saved ? (
                  <>
                    <br />
                    <br />
                    <br />
                    <Alert
                      onClose={handleClose}
                      severity="success"
                      action={
                        <>
                          <a
                            onClick={() => setSaved(false)}
                            style={{cursor: 'pointer'}}
                            className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                          >
                            close
                          </a>
                        </>
                      }
                    >
                      You've added this series to your chill list successfully.
                      <a href={`/chill-list`} style={{cursor: 'pointer'}}>
                      {/* <a href={`/profile/${userName}/watchlist`}> */}
                        Click here to view it in your profile
                      </a>
                    </Alert>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>
              </div>
            </DialogContent>

            <DialogActions style={{ backgroundColor: "#000", width: '120%' }}>
              {/* <Button onClick={onClose}>Close</Button> */}

              <CloseIcon
                style={{ fontSize: 30, color: "#FFD700", cursor: 'pointer' }}
                onClick={onClose}
              />
            </DialogActions>
          </Dialog>
        </Backdrop>

        <Dialog
          open={prompt}
          onClose={handleClose}
          style={{ backgroundColor: "#282C35", opacity: "0.96" }}
        >
          {/* <DialogTitle>Chill!</DialogTitle> */}
          <DialogContent style={{ backgroundColor: "#000" }}>
            <DialogContentText style={{ color: "#fff" }}>
              {promptMessage}
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ backgroundColor: "#000" }}>
          {promptType == "login" ? (
              <h3 onClick={goToLogin} className='text-center' style={{ alignSelf: "center", color:'#FFD700', marginRight: 40, cursor: 'pointer' }}>
                {promptAction}
              </h3>
            ) : (
              <h3 onClick={goToMoviePage} className='text-center' style={{ alignSelf: "center", color:'#FFD700', marginRight: 40, cursor: 'pointer' }}>
                {promptAction}</h3>
            )}


            <CloseIcon
              style={{ fontSize: 30, color: "#FFD700", cursor: 'pointer' }}
              onClick={onClose}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
};

export default SeriesCardMap;
