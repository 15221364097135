import React, { Component, useState, useEffect } from "react";
import { useParams, Switch, Route, useHistory, useLocation, Link } from "react-router-dom";
import { Select } from "antd";
import axios from "axios";
import axiosFetch from '../../config/Interceptors';
// import { Tabs, Tab } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";

import UserChannels from "../studio/channel/UserChannels";
import Profiledetail from "./Profiledetail";
import UserSubscriptions from "../studio/channel/UserSubscriptions";
import Load from "./Load";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function ProfileTabs(props) {
    const [key, setKey] = useState("home");
    const location = useLocation();
    let history = useHistory();
    const { user_name } = useParams(["user_name"]); 
    const path = location.pathname;
    const [profile, setProfile] = useState([]);
    const [userAlerts, setUserAlerts] = useState([]);
    const [userChannels, setUserChannels] = useState([]);
    const [userPosts, setUserPosts] = useState([]);
    const [userSocials, setUserSocials] = useState([]);
    const [userPages, setUserPages] = useState([]);
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    const [isLoggedInUser, setIsLoggedInUser] = useState(false)
    const [fetchingProfile, setFetchingProfile] = useState(false);
    const userId = useState(window.localStorage.getItem("user_id"));
    const user_id = props.profile.id
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);
    const [value, setValue] = React.useState("home");
    // const history = useHistory();
    const pathname = window.location.pathname

    // const handleChange = (event, newValue) => {
    //   setValue(newValue);
    //   // history.push(`/${pathname}/${value}`);
    // };

    function checkUserId() {
    // console.log(`no 1 ${profile.id}`);
    // console.log(`no 2 ${userId}`);
    if (userId[0] == props.profile.id) {
      // console.log("is logged in user");
      return setIsLoggedInUser(true);
    } else {
      // console.log("is another user");
      return setIsLoggedInUser(false);
    }
  }

  function closeError() {
    setError(false);
    console.log("closed");
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    // setEmptyBody(false);
    setError(false);
  }

  const handleChange = (event, newValue) => {
    event.preventDefault();
    history.replace(`/profile/${user_name}`)
    setValue(newValue);
    // e.prevent
    event.preventDefault();
    history.replace(`/profile/${user_name}/${newValue}`)
  };

  const getPathname = () => {
    console.log(`path name ${location.pathname}`);
    if(path.includes('/home')){
      setValue('home')
    }
    if(path.includes('/alert')){
      setValue('alert')
    }
    if(path.includes('/pages')){
      setValue('pages')
    }
    if(path.includes('/channels')){
      setValue('channels')
    }
    if(path.includes('/pictures')){
      setValue('pictures')
    }
    // .slice(-3)
  };

    async function getUserDetailsLoad() {
      setFetchingProfile(true);
      // console.log(` 1 ${fetchingProfile}`)
      const access_token = await window.localStorage.getItem("access_token");
      axiosFetch
        .get(
          `/users/${user_name}/details`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + access_token,
            },
          }
        )
        .then((resp) => {
          if (resp.data.status == "success") {
          const data = JSON.stringify(resp.data);
          // console.log(`user:data 2 = ${JSON.stringify(resp.data.subscriptions)}`)
          setUserSubscriptions(resp.data.subscriptions);
          setUserChannels(resp.data.user.channels);
          setUserAlerts(resp.data.alerts);
          setUserPosts(resp.data.posts);
          setUserPages(resp.data.user.pages);
          setUserSocials(resp.data.user.socials);
          // setFollowing(resp.data.followings.length);
     
          setFetchingProfile(false);
        } else {
          setFetchingProfile(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setFetchingProfile(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
    }

    async function getUserDetails() {
      // setFetchingProfile(true);
      // console.log(` 1 ${fetchingProfile}`)
      const access_token = await window.localStorage.getItem("access_token");
      axiosFetch
        .get(
          `/users/${user_name}/details`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + access_token,
            },
          }
        )
        .then((resp) => {
          if (resp.data.status == "success") {
          const data = JSON.stringify(resp.data);
          // console.log(`user:data 2 = ${JSON.stringify(resp.data.subscriptions)}`)
          setUserSubscriptions(resp.data.subscriptions);
          setUserChannels(resp.data.user.channels);
          setUserAlerts(resp.data.alerts);
          setUserPosts(resp.data.posts);
          setUserPages(resp.data.user.pages);
          setUserSocials(resp.data.user.socials);
          // setFollowing(resp.data.followings.length);
     
          // setFetchingProfile(false);
          //  console.log(` 2 ${fetchingProfile}`)
        } else {
          // setFetchingProfile(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        // setFetchingProfile(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
    }

    useEffect(() => {
      getUserDetailsLoad();
      checkUserId();
      getPathname();
    },[]);

    if (fetchingProfile == true) {
      return (
        <>
          <Load bg='#fff' color='#000' />
        </>
      );
    } else {
    return (
        <>
       <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
       
       {error ? (
        <div><br/>
          <Alert
            severity="error"
            onClose={handleClose}
            action={
              <>
                <CancelIcon onClick={closeError} />
              </>
            }
          >
            There was an issue fetching profile details. Kindly refresh or check your network [{" "}
            {errorMessage} ]
          </Alert>

          {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
          <br />
        </div>
      ) : (
        ""
      )}
       <Box sx={{ bgcolor: "background.paper" }}>
            
            <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                // value={key}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                // centered
                allowScrollButtonsMobile
                scrollButtons="auto"
                aria-label="scrollable force tabs example"
              >
            <Tab value="home" label="Home" style={{ color: "black" }}>
             
            </Tab>

            {/* <Tab value="alerts" style={{ color: "black" }} label="Alerts" component={Link} to={`/profile/${user_name}/alerts`} >
            </Tab>
            <Tab value="pages" label="Pages" style={{ color: "black" }}>
            </Tab> */}
            <Tab value="channels" label="Channels" style={{ color: "black" }}>
            </Tab>

            {/* <Tab value="pictures" label="Pics"> 
            </Tab> */}

            </TabList>
            </Box>


            <TabPanel value="home">
              <br/>
              <div className="row">
                {/* <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0"> */}
                  <Profiledetail profile={props.profile} user_name={user_name} socials={userSocials} isLoggedInUser={isLoggedInUser} cats='' />
                  {/* <Profilephoto text="Latest Pictures" />
                  <Events /> */}
                {/* </div> */}
                
                {/* <div className="col-xl-8 col-xxl-9 col-lg-8">
                  {isLoggedInUser ? 
                  <Profiledetail profile={props.profile} user_name={user_name} socials={userSocials} isLoggedInUser={isLoggedInUser} cats='' />
                  : 
                  <Profiledetail profile={props.profile} user_name={user_name} socials={userSocials} isLoggedInUser={isLoggedInUser} cats='' />
                  }

                 
                </div> */}
              </div>

            </TabPanel>

           

            <TabPanel value="channels">
            <br/>
        
              <h3 className='text-center'> Channels Created </h3>
              <UserChannels 
              fetching={fetchingProfile}
              user_name={user_name} 
              isLoggedInUser={isLoggedInUser}
              channels={userChannels}
              type='Channel'
              url={`${user_name}/channels`}
              user_id={user_id}
              />
             
            </TabPanel>

            {/* <TabPanel value="pictures" label="Photos">
            <br/>
         
              <h3> User Pictures </h3>
              <UserPictures 
              user_name={user_name} 
              posts={userPosts}
             
              />
         
            </TabPanel> */}

            </TabContext>
          </Box>
        </div>
        </>
    );
          }
}