import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import PageHeader from '../../components/page-header/PageHeader';
import axiosFetch from "../../config/Interceptors";
// import { category as cate } from '../api/tmdbApi';
import MovieGrid from '../../components/movie-grid/MovieGrid';
import MovieList from '../../components/movie-list/MovieList';
import { OutlineButton } from '../../components/button/Button';
import { Link } from 'react-router-dom';

const Catalog = () => {

    const { category } = useParams(['url']);
    const [movies, setMovies] = useState([]);
    const [comics, setComics] = useState([]);
    const [videos, setVideos] = useState([]);
    const [episodes, setEpisodes] = useState([]);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(100);
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);
    const [fecthingMovies, setFetchingMovies] = useState(false);

    async function getMoviesCatalogue() {
        setFetchingMovies(true);
        // console.log(` 1 ${fetchingProfile}`)
        const access_token = await window.localStorage.getItem("access_token");
        axiosFetch
          .get(`/movie/catalog/view/all`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + access_token,
            },
          })
          .then((resp) => {
            
            setMovies(resp.data.movies);
            setComics(resp.data.comics);
            setVideos(resp.data.videos);
            setEpisodes(resp.data.episodes);
            setFetchingMovies(false);
          })
          .catch((error) => {
            setFetchingMovies(false);
            // console.log(error);
            setError(true);
            setErrorMessage(error.message);
          });
      }
    
      useEffect(() => {
        getMoviesCatalogue();
    }, []);

    if (fecthingMovies) {
        return (
          <>
            <br />
            <br /> <br />
            <br /> <br />
            <br />
            <h4 style={{ color: "#fff" }} className="text-center">
              {" "}
              Just A Few Seconds ...
            </h4>
            <br />
            <br />
          </>
        );
      }

    if (movies.length == 0) {
      return (
        <div style={{ marginBottom: 60 }}>
         <br />
          <br /> <br />
          <br /> <br />
          <br />
          <h4 style={{ color: "#fff" }} className="text-center">
            {" "}
            Almost Done ...
          </h4>
          <br />
          <br />
        </div>
      );
      } else {
    return (
        <>
            <PageHeader>
               <h3  style={{color:'#fff'}}>  Explore All Movie Types</h3>
            </PageHeader>
            <div className="container">
            <div className="section mb-3">
                    <div className="section__header">
                        <h2 style={{color: '#fff'}}>Latest Comics</h2>
                        <Link to="/chillarx/explore/comics">
                            <OutlineButton className="small">View All</OutlineButton>
                        </Link>
                    </div>
                    <MovieList movies={comics} />
                </div>
                <br/><br/>
                <div className="section mb-3">
                    <div className="section__header">
                        <h2 style={{color: '#fff'}}>Suggested Movies</h2>
                        <Link to="/chillarx/explore/movies">
                            <OutlineButton className="small">View All</OutlineButton>
                        </Link>
                    </div>
                    <MovieList movies={movies} />
                </div>
                <br/><br/>
                <div className="section mb-3">
                    <div className="section__header">
                        <h2 style={{color: '#fff'}}>Trending Videos</h2>
                        <Link to="/chillarx/explore/videos">
                            <OutlineButton className="small">View All</OutlineButton>
                        </Link>
                    </div>
                    <MovieList movies={videos} />
                </div>
                <br/><br/>
                <div className="section mb-3">
                    <div className="section__header">
                        <h2 style={{color: '#fff'}}>Suggested Episodes</h2>
                        <Link to="/chillarx/explore/episodes">
                            <OutlineButton className="small">View All</OutlineButton>
                        </Link>
                    </div>
                    <MovieList movies={episodes} />
                </div>

                {/* <div className="section mb-3">
                    <MovieGrid category={category} movies={movieItems}/>
                </div> */}
            </div>
        </>
    );
      }
}

export default Catalog;
