import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import "../../movie-list/movie-list.scss";
import Slider from "react-slick";
// import { SwiperSlide, Swiper } from "swiper/react";
import { Link } from "react-router-dom";

import Button from "../button/Button";

// import tmdbApi, { category } from "../../api/tmdbApi";
// import apiConfig from "../../api/apiConfig";

import MovieCard from "../movie-card/MovieCard";
import MovieCard2 from "../movie-card/MovieCard2";
import SeriesCard from "../movie-card/SeriesCard";

export default function EpisodeList(props) {
  const [content, setContent] = useState([]);
  const id = props.channelId;
  const movieType = props.movieType;

  const hotelsettings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 600,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    centerMode: false,
    variableWidth: false,
    spaceBetween: 10,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  async function getMovies() {
    const id = props.channelId;
    const link = props.link;
    // console.log(`hii ${id} ${link}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/channels/${link}`, {
        method: "GET",
        // delay: 500000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(resp.data)
        setContent(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }
 
  useEffect(() => {
    // getMovies();
  }, []);


  return (
    <div className="movie-list">
  
      <Slider {...hotelsettings}>
      {props.episodes.map((c, id) => (
        // <p>{c.title}</p>
        <SeriesCard
            movies={c}
            id={id}
            channelId={props.channelId}
            type={props.type}
            url={props.url}
            channelSlug={props.channelSlug}
            isAdmin={props.isAdmin}
            adminId={props.adminId}
            functionProp={props.functionProp}
            functionProp2={props.functionProp2}
          />
        ))}
        {/* <SeriesCard */}
        {/* {content.map((c, id) => (
          <MovieCard movies={c} id={id} route={props.route} type={props.type} 
          channelId={props.id}
          type={props.type}
          url={props.url}
          channelSlug={props.channelSlug}
          isAdmin={props.isAdmin}
          adminId={props.adminId}
          functionProp={() => props.functionProp()} />
        ))} */}
      </Slider>
    {/* <EpisodeList /> */}
    </div>
  );
}
