import React, { Component, Fragment } from "react";
// import Header from "../components/Header";
// import Leftnav from "../components/Leftnav";
import { format, formatDistance, subDays } from "date-fns";

import { Route, useParams, Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import MovieGrid from "../../movie-grid/MovieGrid";
import MyMoviesGrid from "../../movie-grid/MyMoviesGrid";
import SeriesGrid from "../../movie-grid/SeriesGrid";
import SeriesCardMap from "../../movie-card/SeriesCardMap";
import MovieCardMap from "../../movie-card/MovieCardMap";
import Button from "../button/Button";
import IconButton from "@mui/material/IconButton";

export default function UserMovies(props) {
  let history = useHistory();
  const [fetchingChannels, setFetchingChannels] = useState("");
  const [channels, setChannels] = useState([]);
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    // getAllCahnnels();
  }, []);

  if (props.movies.length == 0) {
    return (
      <>
        <Fragment>
          {/* <Header /> */}
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}
          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
            <h3 className="text-center" style={{color: '#000'}}>
                  {" "}
                  Video and Movies You've Purchased
                </h3><br/>
              <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2,color: '#000' }}
              >
                Hello {props.user_name}! You Haven't Purchased Any Channel Content Yet.
                
              </h5>
            </div>
          </div>
          {/* <Popupchat /> */}
          {/* <Appfooter /> */}
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>
        {/* <Header /> */}
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}
        <div
          className="row ps-2 pe-1"
          style={{ marginRight: 20, marginLeft: 20, }}
        >
           <h3 className="text-center" style={{color: '#fff'}}>
                  {" "}
                  Video and Movies You've Purchased
                </h3><br/>
          <h4 className="text-center" style={{ color: "#fff" }}>
            {" "}
            You've {props.message2} {props.series.length} series{" "}
          </h4>
          {/* <SeriesGrid series={props.series} /> */}
          <MyMoviesGrid movies={props.series} seriesLink='true' />
         </div>

        <div
          className="row ps-2 pe-1"
          style={{ marginRight: 20, marginLeft: 20, marginBottom: 50 }}
        >
          <h4 className="text-center" style={{ color: "#fff" }}>
            {" "}
            You've purchased {props.movies.length} movies{" "}
          </h4>
          <MyMoviesGrid movies={props.movies} />
          {/* {
             props.movies.map((movie, i) => (
              <MovieCardMap
                // seriesLink={seriesLink}
                // category={props.category}
                movie={movie.movie}
                key={i}
              />
            ))
          } */}
         </div>

      

          <br />

       
        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  }
}

// export default Channels;
