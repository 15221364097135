import React, { Component, Fragment } from "react";

// import Header from "../components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
import Alert from "@mui/material/Alert";
import { Button } from "@material-ui/core";
import Load from "../../components/others/Load";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import FeatherIcon from 'feather-icons-react';

export default function Analytics() {
  const [message, setMessage] = useState("");
  const [getting, setGetting] = useState(false);
  const [error, setError] = useState(false);
  const [analytics, setAnalytics] = useState(false);

  function getAnalytics() {
    setGetting(true);
    const access_token = window.localStorage.getItem("access_token");
    const userId = window.localStorage.getItem("user_id");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get(`/users/analytics/${userId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        setAnalytics(resp.data);
        setGetting(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getAnalytics();
  }, []);

  if (analytics.length == 0) {
    return (
      <Fragment>
        <HeaderV2 />

        <div className="main-content bg-white right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <Load />
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <HeaderV2 />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content bg-white right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <img
                          src="/assets/images/analytics-2.png"
                          alt="banner"
                          className="w-100"
                        />
                      </div>
                      <div className="col-lg-6 ps-lg-5">
                        <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">
                          Track Your Activities On Chillarx{" "}
                          {/* {analytics.user.first_name} */}
                        </h2>
                        <p className="font-xssss fw-500 text-grey-500 lh-26">
                          This is a quick overview of all you have done on
                          Chillarx.{" "}
                        </p>
                        {/* <a href="/defaultanalytics" className="btn w200 border-0 bg-success p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Analysis</a> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div> */}
                <div className="col-lg-3 pe-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e5f6ff` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-success font-xss text-white">
                              <FeatherIcon
                                icon="user-plus"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                              />
                            </i>
                      <h4 className="text-success font-xl fw-700">
                        {analytics.followers}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Followers
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#f6f3ff` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-secondary font-xss text-white">
                              <FeatherIcon
                                icon="user-check"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-secondary text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-user-check font-md text-white"></i> */}
                      <h4 className="text-secondary font-xl fw-700">
                        {analytics.following}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Following
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              

                <br />

                <div className="col-lg-3 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#fff0e9` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-warning font-xss text-white">
                              <FeatherIcon
                                icon="cast"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-warning text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-warning feather-cast font-md text-white"></i> */}
                      <h4 className="text-warning font-xl fw-700">
                        {analytics.channels}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Channels
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                {/* </div> */}

                <br />

                {/* <div> */}
                <div className="col-lg-3 pe-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e5f6ff` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-success font-xss text-white">
                              <FeatherIcon
                                icon="video"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-success feather-video font-md text-white"></i> */}
                      <h4 className="text-success font-xl fw-700">
                        {analytics.videos}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Videos Created
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#f6f3ff` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-warning font-xss text-white">
                              <FeatherIcon
                                icon="film"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-warning text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-warning feather-film font-md text-white"></i> */}
                      <h4 className="text-secondary font-xl fw-700">
                        {analytics.movies}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Movies Created
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e2f6e9` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-success font-xss text-white">
                              <FeatherIcon
                                icon="tv"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-success feather-tv font-md text-white"></i> */}
                      <h4 className="text-success font-xl fw-700">
                        {analytics.series}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Series Created
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#f6f3ff` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-secondary font-xss text-white">
                              <FeatherIcon
                                icon="gift"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-secondary text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-gift font-md text-white"></i> */}
                      <h4 className="text-secondary font-xl fw-700">
                        {analytics.totalPoints}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Reward Points
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                {/* </div> */}

                <br />

                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#f6f3ff` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-secondary font-xss text-white">
                              <FeatherIcon
                                icon="tv"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-secondary text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-tv font-md text-white"></i> */}
                      <h4 className="text-secondary font-xl fw-700">
                        {analytics.savedSeries}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Saved Series
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>


                <div className="col-lg-3 pe-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e5f6ff` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-success font-xss text-white">
                              <FeatherIcon
                                icon="shopping-bag"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-success feather-shopping-bag font-md text-white"></i> */}
                      <h4 className="text-success font-xl fw-700">
                        {analytics.puchasedMovies}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Purchased Movies
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#f6f3ff` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-secondary font-xss text-white">
                              <FeatherIcon
                                icon="shopping-bag"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-secondary text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-shopping-bag font-md text-white"></i> */}
                      <h4 className="text-secondary font-xl fw-700">
                        {analytics.puchasedSeries}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Purchased Series
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <br />
                <div className="col-lg-3 pe-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#e5f6ff` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-success font-xss text-white">
                              <FeatherIcon
                                icon="film"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-success text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-success feather-film font-md text-white"></i> */}
                      <h4 className="text-success font-xl fw-700">
                        {analytics.savedMovies}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Saved Movies
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#f6f3ff` }}
                  >
                    <div className="card-body d-flex p-0">
                      <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-lock font-md text-white"></i>
                      <h4 className="text-secondary font-xl fw-700">
                      {analytics.savedSeries}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Saved Series
                        </span>
                      </h4>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-3 pe-2 ps-2">
                  <div
                    className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                    style={{ background: `#fff0e9` }}
                  >
                    <div className="card-body d-flex p-0">
                    <i className="btn-round-lg d-inline-block me-3 bg-warning font-xss text-white">
                              <FeatherIcon
                                icon="cast"
                                size='30'
                                style={{ size: 30, fontSize: 30 }}
                                className="btn-round d-inline-block me-2 ps-2 bg-warning text-white"
                              />
                            </i>
                      {/* <i className="btn-round-lg d-inline-block me-3 bg-warning feather-cast font-md text-white"></i> */}
                      <h4 className="text-warning font-xl fw-700">
                        {analytics.subscriptions}
                        <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                          Channels Subscribed to
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
           

                <div className="col-lg-12 mb-3">
                  {/* <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                    <Chart
                                    options={this.state.options}
                                    series={this.state.series}
                                    type="bar"
                                    width="100%"
                                    />
                                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  }
}

// export default Analytics;
