import React, { Component, useState, useEffect } from "react";
import "./comment.css";
import { PermMedia, Label, Room, EmojiEmotions } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";

const useStyles = makeStyles((theme) => ({
  card: {
    // raised: true
  },
}));

export default function CreateReply(props) {
  const classes = useStyles();
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [message, setMessage] = useState("");
  const [userPic, setUserPic] = useState("");

  async function getProfile() {
    setIsFetching(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/profile", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data.user.profile_pic));
        setUserPic(resp.data.user.profile_pic);
        setIsFetching(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function postReply() {
    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    // const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("message", message);
    formData.append("status", "sent");
    // formData.append("status", channelStatus);

    axiosFetch
      .post(
        `/${props.url1}/${props.Id}/${props.url2}/${props.commentId}`,
        formData,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log(`hi ${resp.data}`)
        setMessage("");
        setIsCreating(false);
        props.functionProp();
        props.openReplyView()
        props.closeReply()
        // setReplyOpen(false)
        // alert(`response ${JSON.stringify(resp.data)}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }

  useEffect(() => {
    getProfile();
  }, []);

  return (
    // <Card className={classes.card}>
    <div className="shar" style={{ marginLeft: 30 }}>
      <div className="shareWrapper">
        <div className="shareTop">
          {/* <figure className="avatar me-3"> */}
          {isFetching ? (
            <img
              className="shareProfileImg"
              src="/assets/images/user.png"
              alt=""
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                objectFit: "cover",
                marginRight: 10,
                border: "2px solid #045de9",
              }}
            />
          ) : (
            <img
              src={userPic}
              alt="avater"
              className="shadow-sm rounded-circle w45"
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                objectFit: "cover",
                marginRight: 10,
                border: "2px solid #045de9",
              }}
              // style={{ marginRight: 10, border: "2px solid #045de9" }}
            />
          )}
          {/* </figure> */}
          {/* <img className="shareProfileImg" src="/assets/person/1.jpeg" alt="" /> */}
          {/* <input
            placeholder="Add a comment..."
            className="shareInput"
          /> */}
          <TextField
            id="standard-multiline-flexible"
            label="Reply..."
            multiline
            className="shareInput"
            // maxRows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {/* {props.alertId}
        {props.commentId} */}
          {/* {props.alert} */}
          {isCreating ? (
            <HourglassEmptyRoundedIcon />
          ) : (
            <SendRoundedIcon className="pointer" onClick={postReply} />
          )}
          {/* <HourglassEmptyRoundedIcon /> */}

          {/* <button className="shareButton">Comment</button> */}
        </div>
        {/* <div className="shareHr"> </div> */}
        {/* <hr className="shareHr"/> */}
        <div className="shareBottom">
          <div className="shareOptions">
            {/* <div className="shareOption">
                    <PermMedia htmlColor="tomato" className="shareIcon"/>
                    <span className="shareOptionText">Photo or Video</span>
                </div> */}
            {/* <div className="shareOption">
                    <Label htmlColor="blue" className="shareIcon"/>
                    <span className="shareOptionText">Tag</span>
                </div> */}
            {/* <div className="shareOption">
                    <Room htmlColor="green" className="shareIcon"/>
                    <span className="shareOptionText">Location</span>
                </div> */}
            {/* <div className="shareOption">
                    <EmojiEmotions htmlColor="goldenrod" className="shareIcon"/>
                    <span className="shareOptionText">Feelings</span>
                </div> */}
          </div>
          {/* <button className="shareButton">Comment</button> */}
        </div>
      </div>
    </div>
    // </Card>
  );
}
