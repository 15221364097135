import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import Header from "../components/Header";
// import Appfooter from "../components/Appfooter";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";

import ImageComponentDiv from "../../components/others/ImageComponentDiv";
import ImageComponent from "../../components/others/ImageComponent";
import Load from "../../components/others/Load";
import Alert from "@mui/material/Alert";
import { Button } from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";

// import Compress from "browser-image-compression";
import Resizer from "react-image-file-resizer";
import { Snackbar } from "@material-ui/core";
import Select from "react-select";
import { Country, State, City, ICity } from "country-state-city";

export default function Account() {
  const [userCountry, setUserCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [userState, setUserState] = useState([]);
  // const [lga, setLga] = useState(null);
  const [profile, setProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [fetchingCities, setFetchingCities] = useState(false);
  const [fetchedCities, setFetchedCities] = useState(false);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [user_name, setUser_name] = useState("");
  const [phone, setPhone] = useState("");
  const [lga, setLga] = useState([]);

  // const [state, setState] = useState("");
  // const [country, setCountry] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [cover_pic, setCover_pic] = useState("");
  const [profilePicFe, setProfilePicFe] = useState("");
  const [cover_pic_fe, setCover_pic_fe] = useState("");
  const [bio, setBio] = useState("");
  const [isProfilePic, setIsProfilePic] = useState("");
  const [isCoverPic, setIsCoverPic] = useState("");
  const [invalidFile, setInValidFile] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // state = {
  //   profile: [],
  //   isLoading: false,
  //   isSuccess: false,
  //   isUpdating: false,
  //   first_name: "",
  //   last_name: "",
  //   user_name: "",
  //   phone: "",
  //   lga: "",
  //   state: "",
  //   country: "",
  //   profilePic: "",
  //   cover_pic: "",
  //   profilePicFe: "",
  //   cover_pic_fe: "",
  //   bio: "",
  //   isProfilePic: "",
  //   isCoverPic: "",
  //   invalidFile: false,
  //   error: false,
  //   errorMessage: "",
  // };

  function validateProfileImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInValidFile("Please select valid image.");
    //   // setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
    console.log(value);
    setProfilePicFe(value);
    // setInValidFile(false);
    // }
  }

  function validateCoverImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInValidFile("Please select valid image.");
    //   return false;
    // } else {
    console.log(value);
    setCover_pic_fe(value);
    // setInValidFile(false);
    // }
  }

  function clearPagePics() {
    // console.log(profilePicFe)
    setProfilePicFe("");
    setProfilePic("");
    // setProfilePic("" })    // setProfilePic( "" );
  }
  function clearCoverImage() {
    setCover_pic_fe("");
    setCover_pic("");
  }

  function closeError() {
    setError(false);
    console.log("closed");
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          setProfilePic(uri);
        },
        "file",
        1080,
        1080
      );
    });

  const CompressImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      const image = await resizeFile(file);
      //   console.log(image);
    } catch (err) {
      console.log("error " + err);
    }
  };

  const resizeCoverFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        80,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          setCover_pic(uri);
          // setPageCover(uri)
        },
        "file",
        1080,
        1080
      );
    });

  const compressCoverImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      const image = await resizeCoverFile(file);
      //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  function handleClose() {
    setError(false);
    setIsSuccess(false);
    console.log("closed");
  }

  function clearProfilePic() {
    setProfilePic("");
  }
  function clearCoverPic() {
    setCover_pic("");
  }

  async function getUserProfile() {
    setIsLoading(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    axiosFetch
      // .get("https://alert-lumen-api.herokuapp.com/api/users/showId/" + userID, {
      .get("/users/profile", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        window.localStorage.setItem("user_name", resp.data.user.user_name);
        window.localStorage.setItem("first_name", resp.data.user.first_name);
        window.localStorage.setItem("last_name", resp.data.user.last_name);
        window.localStorage.setItem("profile_pic", resp.data.user.profile_pic);
        window.localStorage.setItem("friends", resp.data.friends);

        setProfile(resp.data.user);

        setIsLoading(false);

        console.log(`profile details ${JSON.stringify(resp.data)}`);
        // } else {
        //   setIsLoading( false );
        //   setError( true );
        //    setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));
  const updatedStates = () =>
    State.getStatesOfCountry(userCountry.isoCode).map((state) => ({
      label: state.name,
      value: state.id,
      ...state,
    }));

  const updatedCities = () =>
    // setFetchingCities(true)
    City.getCitiesOfState(userState.countryCode, userState.isoCode).map(
      (state) => ({
        label: state.name,
        value: state.id,
        ...state,
      })
    );

  // setFetchingCities(false);
  // setFetchedCities(true)

  function validate() {
    if (phone > "11") {
      // return ({
      setError(true);
      setErrorMessage(
        "Oops! your phone number is more than 11 digits, kindly update!"
      );

      // })
    } else {
      updateUserProfile();
    }
  }

  async function updateUserProfile() {
    console.log("started updating profle");
    setIsUpdating(true);

    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // window.localStorage.setItem("user_name",   user_name);

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("user_name", user_name);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("phone_number", phone);
    formData.append("closest_lga", lga.name);
    formData.append("state", userState.name);
    // formData.append("country",   country);
    formData.append("profile_pic", profilePic);
    formData.append("cover_pic", cover_pic);
    formData.append("bio", bio);
    formData.append("country", userCountry.name);
    formData.append("country_code", countryCode);

    let result = await axiosFetch
      .post(`/users/${userID}`, formData, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          // setProfilePic( "" );
          //setCover_pic( "" );
          setCover_pic("");
          setCover_pic_fe("");
          setProfilePic("");
          setProfilePicFe("");
          setIsSuccess(true);
          // window.localStorage.setItem("user_name",   user_name);
          // window.localStorage.setItem("first_name",   first_name);
          // window.localStorage.setItem("last_name",   last_name);
          // window.localStorage.setItem("profilePic",   profilePic);

          console.log(resp.data);
          getUserProfile();
          setIsUpdating(false);
        } else {
          setIsUpdating(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setIsUpdating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
    // }
  }

  // componentDidMount() {
  //    getUserProfile();
  // }
  useEffect(() => {
    getUserProfile();
  }, []);

  // render() {
  if (isLoading == true) {
    return (
      <Fragment>
        <HeaderV2 />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="middle-wrap">
                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                  <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                    {/* <h5 className="text-center"> Fetching Your Profile </h5> */}
                  </div>
                  <Load bg='#fff' color='#000' />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div>  */}
        {/* //  </div> */}
        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <HeaderV2 />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="middle-wrap">

       

                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                  <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                    <Link to="/settings" className="d-inline-block mt-2">
                      <i className="ti-arrow-left font-sm text-success"></i>
                    
                    <h4 className="font-xs text-success fw-600 mb-0 mt-2">
                      Back To Settings
                    </h4>
                    </Link>
                  </div>
                  <div className="card-body img-fluid h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                    <br />
                    <h4>
                      Hello {profile.first_name} you don't have to edit all.
                      Only edit what needs to be changed and click update{" "}
                    </h4>
                    <br />
                    <ImageComponent
                      image={profile.cover_pic}
                      style={{
                        width: "100%",
                        // maxWidth: 1450,
                        maxHeight: 450,
                        objectFit: "contain",
                      }}
                      alt="avater"
                    />
                    {/* <img
                        src={  profile.cover_pic}
                        alt="avater"
                        style={{
                          width:'100%',
                          // maxWidth: 1450,
                          maxHeight: 450,
                          objectFit: "contain",

                        }}
                      /> */}
                  </div>
                  <div className="card-body p-0 position-relative">
                    <figure
                      className="avatar position-absolute w100 z-index-1"
                      style={{ top: "-40px" }}
                    >
                      <ImageComponent
                        image={profile.profile_pic}
                        class="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                        style={{
                          objectFit: "cover",
                        }}
                        alt="avater"
                      />
                      {/* <img
                          src={  profile.profilePic}
                          alt="avater"
                          className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                          style={{
                            objectFit: "cover",
                          }}
                        /> */}
                    </figure>
                    <h4 className="fw-700 font-sm mt-2 pl-15">
                      {profile.first_name} {profile.last_name}
                      <span className="fw-500 font-xssss text-primary mt-1 mb-3 d-block">
                        @{profile.user_name}
                      </span>
                    </h4>
                  </div>
                  <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                    {/* <div className="row justify-content-center">
                                            <div className="col-lg-4 text-center">
              
                                                <figure className="avatar ms-auto me-auto mb-0 mt-2 w100"><img src="https://via.placeholder.com/300x300.png" alt="avater" className="shadow-sm rounded-3 w-100" /></figure>
                                                <h2 className="fw-700 font-sm text-grey-900 mt-3">{  profile.first_name} {  profile.last_name}</h2>
                                                <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">{  profile.user_name}</h4>    
                                                
                                            </div>
                                        </div> */}
                    {/* <h1> Your Profile Details </h1> */}

                    <form>
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2">
                              First Name
                            </label>
                            {/* value={  first_name} */}
                            <input
                              type="text"
                              defaultValue={profile.first_name}
                              onChange={(e) => setFirst_name(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2">
                              Last Name
                            </label>
                            <input
                              type="text"
                              defaultValue={profile.last_name}
                              onChange={(e) => setLast_name(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2">
                              User Name
                            </label>
                            <input
                              type="text"
                              defaultValue={profile.user_name}
                              onChange={(e) => setUser_name(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2">
                              Email Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={profile.email}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2">
                              Primary Phone Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={profile.phone_number}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2">
                              Country - Previous Was {profile.country}
                            </label>
                            <Select
                              id="country"
                              name="country"
                              label="country"
                              // defaultInputValue={profile.country}
                              options={updatedCountries}
                              placeholder="Search country"
                              value={userCountry}
                              onChange={(value) => {
                                console.log(
                                  "selected country is",
                                  JSON.stringify(value),
                                  "eneded here"
                                );
                                setUserCountry(value);
                                setCountryCode(value.phonecode);
                              }}
                            />
                            {/* <input
                              type="text"
                              className="form-control"
                              defaultValue={profile.country}
                              onChange={(e) => setCountry(e.target.value)}
                            />*/}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss mb-2">
                                State - Previous Was {profile.state}
                              </label>
                              <Select
                                id="state2"
                                name="state2"
                                label="state2"
                                options={updatedStates(
                                  userCountry ? userCountry.value : null
                                )}
                                // options={updatedStates()}
                                // defaultInputValue={profile.state}
                                value={userState}
                                placeholder="Your state"
                                onChange={(value) => {
                                  console.log(
                                    "selected state is ",
                                    JSON.stringify(value),
                                    "eneded"
                                  );
                                  setUserState(value);
                                }}
                                // onChange={(value) => {
                                //   setValues({ state: value, city: null }, false);
                                // }}
                              />
                              {/* <input
                              type="text"
                              className="form-control"
                              defaultValue={profile.state}
                              onChange={(e) => setState(e.target.value)}
                            />*/}
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss mb-2">
                                Closest LGA - Previous Was {profile.closest_lga}
                              </label>
                              {fetchingCities ? "Fetching your cities ..." : ""}
                              <Select
                                id="city"
                                name="city"
                                label="city"
                                options={updatedCities(
                                  userState ? userState.value : null
                                )}
                                value={lga}
                                placeholder="City / LGA"
                                onChange={(value) => {
                                  console.log(
                                    "selected lga ",
                                    JSON.stringify(value)
                                  );
                                  setLga(value);
                                }}
                                // onClick={() =>  console.log('previous state choosen is ', userState.isoCode, userState.countryCode)}
                              />
                              {/* <input
                              type="text"
                              className="form-control"
                              defaultValue={profile.closest_lga}
                              onChange={(e) => setLga(e.target.value)}
                            /> */}
                            </div>
                          </div>

                          {/* {  invalidFile ? (
                            <h4> {  invalidFile} </h4>
                          ) : (
                            ""
                          )} */}

                          {profilePicFe ? (
                            <>
                              <p> Your Profile Picture </p>
                              <br />
                              <img
                                src={URL.createObjectURL(profilePicFe)}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: 450,
                                  objectFit: "contain",
                                }}
                                // style={{ maxWidth: "100%", maxHeight: 400 }}
                                alt="profile image"
                              />
                              <br />
                              <br />
                              <Button onClick={clearPagePics}>Remove</Button>
                              <br />
                            </>
                          ) : (
                            <div className="col-lg-12 mb-1">
                              <div className="card mt-1 border-0">
                                <div className="card-body d-flex justify-content-between align-items-end p-0">
                                  <div className="form-group mb-0 w-100">
                                    <input
                                      type="file"
                                      name="file4"
                                      id="file4"
                                      accept="image/*"
                                      className="input-file"
                                      onChange={(e) => {
                                        validateProfileImage(e.target.files[0]);
                                        CompressImage(e);
                                      }}
                                    />
                                    <label
                                      htmlFor="file4"
                                      className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                    >
                                      <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                      <span className="js-fileName">
                                        Click to replace your profile picture
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* <input
                            type="file"
                            name="file2"
                            id="file2"
                            className="input-file"
                            onChange={
                              (e) =>  validateCoverImage(e.target.files[0])
                              //  setState({
                              //   cover_pic: e.target.files[0],
                              // })
                            }
                          /> */}

                          {cover_pic_fe ? (
                            <>
                              <p> Your Cover Picture </p>
                              <br />
                              <img
                                src={URL.createObjectURL(cover_pic_fe)}
                                style={{ maxHeight: 450, objectFit: "contain" }}
                                alt="cover picture"
                              />
                              <br />
                              <br />
                              <Button onClick={clearCoverImage}>Remove</Button>
                              <br />
                            </>
                          ) : (
                            <div className="col-lg-12 mb-1">
                              <div className="card mt-1 border-0">
                                <div className="card-body d-flex justify-content-between align-items-end p-0">
                                  <div className="form-group mb-0 w-100">
                                    <input
                                      type="file"
                                      name="file2"
                                      id="file2"
                                      accept="image/*"
                                      className="input-file"
                                      onChange={(e) => {
                                        validateCoverImage(e.target.files[0]);
                                        compressCoverImage(e);
                                      }}
                                    />
                                    <label
                                      htmlFor="file2"
                                      className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                    >
                                      <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                      <span className="js-fileName">
                                        Click to replace your cover picture
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {invalidFile ? <h4> {invalidFile} </h4> : ""}

                          <div className="col-lg-12 mb-3">
                            <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                              Your Bio
                            </label>
                            <textarea
                              // className="fw-500 bg-greylight  text-black-900 lh-26 font-xssss w-100 mb-2"
                              className="form-control mb-0 p-3 h100 bg-greylight lh-20"
                              rows="5"
                              defaultValue={profile.bio}
                              onChange={(e) => setBio(e.target.value)}
                              placeholder="Let others know a bit about you..."
                            ></textarea>
                          </div>

                          <div className="col-lg-12">
                            {isUpdating ? (
                              <Button
                                disabled
                                variant="contained"
                                color="primary"
                              >
                                Updating Your Profile
                              </Button>
                            ) : (
                              <Button
                                onClick={updateUserProfile}
                                fullWidth
                                variant="contained"
                                color="primary"
                              >
                                Save Profile
                              </Button>

                              // <a
                              //   onClick={updateUserProfile}
                              //   className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                              // >
                              //   Save
                              // </a>
                            )}
                            <br />
                            {isSuccess ? (
                              <>
                                <br />
                                <br />
                                <Snackbar
                                  open={isSuccess}
                                  autoHideDuration={5000}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                >
                                  <Alert
                                    onClose={handleClose}
                                    variant="filled"
                                    severity="success"
                                    action={
                                      <a
                                        href={`/profile/${profile.user_name}`}
                                        className=" d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss fw-700 ls-3"
                                      >
                                        {" "}
                                        Check It Out
                                      </a>
                                    }
                                  >
                                    Your profile was updated sucessfully!
                                  </Alert>
                                </Snackbar>
                              </>
                            ) : (
                              ""
                            )}

                            {error ? (
                              <div>
                                <br />
                                <br />
                                <Alert
                                  severity="error"
                                  onClose={handleClose}
                                  action={
                                    <>
                                      <CancelIcon onClick={closeError} />
                                    </>
                                  }
                                >
                                  There was an issue updating / geting your
                                  profile. Kindly try again [ {errorMessage} ]
                                </Alert>
                                {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                                <br />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  }
}
// }
