import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router";
import ReactPlayer from "react-player";
import tmdbApi from "../../api/tmdbApi";

const VideoList = (props) => {
  const { category } = useParams();

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    // const getVideos = async () => {
    //   const res = await tmdbApi.getVideos(category, props.id);
    //   setVideos(res.results.slice(0, 5));
    // };
    // getVideos();
  }, [category, props.id]);

  return (
    <>
      <h1>More Movies In Channel</h1>
      <br />
      {props.videos.map((video, i) => (
        <>
          <div className="video">
            <div className="video__title" style={{ color: "#fff" }}>
              <h2 style={{ color: "#fff" }}>{video.title}</h2>
            </div>

            <ReactPlayer
              url={video.video}
              controls={true}
              light={video.image}
              // light={true}
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              playsinline={true}
              width="100%"
              // height='600px'
            />
            <br />
            <br />
          </div>

          {/* <Video key={i} video={video} /> */}
        </>
      ))}
    </>
  );
};

const Video = (props) => {
  const video = props.video;

  const iframeRef = useRef(null);

  useEffect(() => {
    // const height = (iframeRef.current.offsetWidth * 9) / 16 + "px";
    // iframeRef.current.setAttribute("height", height);
  }, []);

  return (
    <div className="video">
      <div className="video__title" style={{ color: "#fff" }}>
        <h2 style={{ color: "#fff" }}>{video.title}</h2>
      </div>
      {/* <iframe
        src={`https://www.youtube.com/embed/${item.key}`}
        ref={iframeRef}
        width="100%"
        title="video"
      ></iframe>https://www.youtube.com/watch?v=M8SwF2qUtts' */}
      <ReactPlayer
        url={video.video}
        controls={true}
        light={video.image}
        // light={true}
        config={{ file: { attributes: { controlsList: "nodownload" } } }}
        playsinline={true}
        width="100%"
        // height='600px'
      />
      <br />
      <br />
    </div>
  );
};

export default VideoList;
