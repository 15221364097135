import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import PageHeader from "../../components/page-header/PageHeader";
import axiosFetch from "../../config/Interceptors";
// import { category as cate } from '../api/tmdbApi';
import MovieGrid from "../../components/movie-grid/MovieGrid";
import MovieList from "../../components/movie-list/MovieList";
import { OutlineButton } from "../../components/button/Button";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const MovieCatalog = () => {
  const { category } = useParams(["content"]);
  const contentType = useParams();
  const [movieItems, setMovieItems] = useState([]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(100);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [fecthingMovies, setFetchingMovies] = useState(false);

  async function getMoviesCatalogue() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movie/all/${contentType.content}/${skip}/${take}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // setMovieItems(resp.data);
        setMovieItems([...movieItems, ...resp.data]);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  function getNext() {
    setSkip(skip + 100);
    // alert(skip)
    getNextMovies();
  }

  async function getNextMovies() {
    setFetchingMovies(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movie/all/${contentType.content}/${skip}/${take}`, {
        // .get(`/posts/all/${ski}/${tak}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // setMovieItems(resp.data);
        setMovieItems([...movieItems, ...resp.data]);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    //   alert(JSON.stringify(contentType.content))
    getMoviesCatalogue();
  }, [skip]);

  if (fecthingMovies) {
    return (
      <>
        <br />
        <br /> <br />
        <br /> <br />
        <br />
        <h4 style={{ color: "#fff" }} className="text-center">
          {" "}
          Just A Few Seconds ...
        </h4>
        <br />
        <br />
      </>
    );
  }

  if (movieItems.length == 0) {
    return (
      <div style={{ marginBottom: 60 }}>
       <br />
        <br /> <br />
        <br /> <br />
        <br />
        <h4 style={{ color: "#fff" }} className="text-center">
          {" "}
          Almost Done ...
        </h4>
        <br />
        <br />
      </div>
    );
  } else {
    return (
      <>
        <PageHeader>
          <h3 style={{ color: "#fff" }}> Explore all {contentType.content} </h3>
        </PageHeader>
        <div className="container">
          <div className="section mb-3">
            <div className="section__header">
              <h2 style={{ color: "#fff" }}>
                All {contentType.content} {movieItems.length}
              </h2>

              {/* <OutlineButton onClick={getNext} className="small">
                Load more
              </OutlineButton> */}
            </div>
            {/* <h4 style={{color: '#fff'}} className="text-center">Loading More</h4> */}
            {fecthingMovies && (
              <>
                <br />
                <br />
                <h4 style={{ color: "#fff" }} className="text-center">
                  Loading More
                </h4>
              </>
            )}
{/* 
            <InfiniteScroll
              dataLength={movieItems.length} //This is important field to render the next data
              next={getNext}
              hasMore={true}
              // loader={<Load message='scroll down to load more' />}
              loader={""}
              // <Load message='scroll down to load more' />
              endMessage={
                <h4 style={{ textAlign: "center", color: "#fff" }}>
                  <b>Yay! You have seen it all</b>
                </h4>
              }
            >
              <MovieGrid category={category} movies={movieItems} />
            </InfiniteScroll> */}
            <MovieGrid category={category} movies={movieItems} />
          </div>

          <br />
          <br />
          <br />
          <div className="section__header">
            <h2 style={{ color: "#fff" }}>Back To Movies</h2>
            <Link to="/chillarx/movies">
              <OutlineButton className="small">Go Back</OutlineButton>
            </Link>
          </div>
          <br />
          <br />
          {/* <div className="section mb-3">
                    <div className="section__header">
                        <h2 style={{color: '#fff'}}>All Movies</h2>
                        <Link to="/chillarx/movies">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link>
                    </div>
                    <MovieList movies={movieItems} />
                </div> */}
        </div>
      </>
    );
  }
};

export default MovieCatalog;
