import React, { Component , Fragment, useState, useEffect } from "react";
import "./channel.scss"
import { Route, useParams, useLocation } from "react-router-dom";
import Sidebar from "../../components/studio/sidebar/Sidebar"
import Navbar from "../../components/studio/navbar/Navbar"
import HeaderV2 from "../../components/studio/navbar/HeaderV2"
import { Link } from "react-router-dom";
import Datatable from "../../components/studio/datatable/Datatable";
import ChannelCard from "../../components/studio/channel/ChannelCard";

const ChannelTabPage = () => {

  const slug = useParams()

  useEffect(() => {
        // alert(JSON.stringify(slug))
      }, []);

  return (
    <div className="list col-xl-12" style={{ backgroundColor: "#fff" }}>
      <div className="listContainer">
        <HeaderV2 />
          <ChannelCard slug={slug.slug}/>
      </div>
    </div>
  )
}

export default ChannelTabPage