import React, { Component, Fragment } from "react";

// import Header from "../components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
import Alert from "@mui/material/Alert";
import { Button } from "@material-ui/core";
import Load from "../../components/others/Load";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import FeatherIcon from "feather-icons-react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Chart from "../Charts/chart/Chart";
import FeaturedInfo from "../Charts/featuredInfo/FeaturedInfo";
import { userData } from "../../dummyData";
import WidgetSm from "../Charts/widgetSm/WidgetSm";
import WidgetLg from "../Charts/widgetLg/WidgetLg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TestChart from "../Charts/TestChart";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { matchSorter } from "match-sorter";

export default function ChannelAnalytics(props) {
  const [message, setMessage] = useState("");
  const [getting, setGetting] = useState(false);
  const [error, setError] = useState(false);
  const [analytics, setAnalytics] = useState([]);
  const [month, setMonth] = React.useState("1");

  function getAnalytics() {
    setGetting(true);
    const access_token = window.localStorage.getItem("access_token");
    const userId = window.localStorage.getItem("user_id");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get(`/channels/view_analytics/${props.channelId}/${month}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        setAnalytics(resp.data);
        setGetting(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  const handleTransactionChange = (event) => {
    console.log(event.target.value);
    setMonth(event.target.value);
  };

  // Define a default UI for filtering

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  // Fuzzy text search essentially means approximate string matching and is a way of looking up strings that match a pattern even if the characters are not in the correct order.

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty

  fuzzyTextFilterFn.autoRemove = (val) => !val;

  useEffect(() => {
    getAnalytics();
    // alert(props.channelId)
  }, []);

  
  if (analytics.length == 0) {
    return (
      <Fragment>
        <div className="bg-white">
          <div className="">
            <div className="">
              <Load bg="#fff" color="#000" />
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="bg-white">
          <div className="">
            <div className="">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <img
                          src="/assets/images/analytics.png"
                          alt="banner"
                          className="w-100"
                        />
                      </div>
                      <div className="col-lg-6 ps-lg-5">
                        <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">
                          Track Your Channel's Activities On Chillarx{" "}
                          {/* {analytics.user.first_name} */}
                        </h2>
                        <p className="font-xssss fw-500 text-grey-500 lh-26">
                          This is a quick overview of your channel's performance
                          on Chillarx.{" "}
                        </p>
                        {/* <a href="/defaultanalytics" className="btn w200 border-0 bg-success p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Analysis</a> */}
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="text-center" style={{ color: "#000" }}>
                  Hello admin! Below are the activities on your content over the
                  months
                </h5>{" "}
                <br />
                <div style={{ display: "flex" }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Sort
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={month}
                      // style={{width: '50%'}}
                      label="Sort"
                      onChange={handleTransactionChange}
                    >
                      <MenuItem value={1}>One Month</MenuItem>
                      <br />
                      <MenuItem value={2}>Two Months</MenuItem>
                      <br />
                      <MenuItem value={3}>Three Months</MenuItem>
                      <br />
                      <MenuItem value={4}>Four Months</MenuItem>
                      <br />
                      <MenuItem value={5}>Five Months</MenuItem>
                      <br />
                      <MenuItem value={6}>Six Months</MenuItem>
                      <br />
                      <MenuItem value={7}>Seven Months</MenuItem>
                      <br />
                      <MenuItem value={8}>Eight Months</MenuItem>
                      <br />
                      <MenuItem value={9}>Nine Months</MenuItem>
                      <br />
                      <MenuItem value={10}>Ten Months</MenuItem>
                      <br />
                      <MenuItem value={11}>Eleven Months</MenuItem>
                      <br />
                      <MenuItem value={12}>Twelve Months</MenuItem>
                      <br />
                    </Select>
                    <FormHelperText>
                      Select a number to get only transactions you've made
                      within the past months
                    </FormHelperText>
                  </FormControl>
                  <Button primary onClick={getAnalytics}>
                    Get Transactions
                  </Button>
                </div>
                <br />
                <br />
                {getting ? (
                  <>
                    <Load bg="#fff" color="#000" />
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    <div style={{ marginTop: 50 }}></div>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 450, width: "100%" }}
                        stickyHeader
                        size="small"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell>
                              Between now and {month} month ago
                            </TableCell>
                            <TableCell>Exactly {month} month ago</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {analyfeatherictics.map((analytics, index) => ( */}
                          <TableRow
                          // key={index}
                          // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#e5f6ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                                    <FeatherIcon
                                      icon="users"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-success font-xl fw-700">
                                    {analytics.totalSubscribersCount}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Total Subscribers
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                            {/* <TableCell align="right">{analytics.channel_name}</TableCell> */}
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#e5f6ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                                    <FeatherIcon
                                      icon="users"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-success font-xl fw-700">
                                    {analytics.allSubscribersCount}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Subscribers
                                      {/* between now and {month} month
                                      ago{" "} */}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#e5f6ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                                    <FeatherIcon
                                      icon="users"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-success font-xl fw-700">
                                    {analytics.subscribersCount}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Subscribers
                                      {/* {month} month ago */}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#f6f3ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                                    <FeatherIcon
                                      icon="credit-card"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-secondary font-xl fw-700">
                                    {analytics.totalPaidSub}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Total Paid Subscriptions
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#f6f3ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                                    <FeatherIcon
                                      icon="credit-card"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-secondary font-xl fw-700">
                                    {analytics.allPaidSub}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Successful paid subscriptions
                                      {/* between
                                        now and 
                                        {month} month ago{" "} */}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#f6f3ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                                    <FeatherIcon
                                      icon="credit-card"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-secondary font-xl fw-700">
                                    {analytics.paidSub}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Successful paid subscriptions
                                      {/* {month}{" "}
                                        month ago */}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#fff0e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="shopping-bag"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-warning font-xl fw-700">
                                    {analytics.totalPurchase}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Total Content Purchase
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#fff0e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="shopping-bag"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-warning font-xl fw-700">
                                    {analytics.allPurchase}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Contents purchased
                                      {/* now and{" "}
                                        {month} month ago{" "} */}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#fff0e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="shopping-bag"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-warning font-xl fw-700">
                                    {analytics.purchase}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Contents purchased
                                      {/* {month} month ago */}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#e5f6ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                                    <FeatherIcon
                                      icon="video"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-success font-xl fw-700">
                                    {analytics.totalVideos}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Total Published Videos
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#e5f6ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                                    <FeatherIcon
                                      icon="video"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-success font-xl fw-700">
                                    {analytics.allVideos}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Published Videos
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#e5f6ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                                    <FeatherIcon
                                      icon="video"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-success font-xl fw-700">
                                    {analytics.videos}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Published Videos
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#fff0e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="play-circle"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-warning font-xl fw-700">
                                    {analytics.totalComics}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Total Published Comics
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#fff0e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="play-circle"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-warning font-xl fw-700">
                                    {analytics.allComics}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Published Comics
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#fff0e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="play-circle"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-warning font-xl fw-700">
                                    {analytics.comics}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Published Comics
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#f6f3ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="film"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-secondary font-xl fw-700">
                                    {analytics.totalMovies}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Total Published Movies
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#f6f3ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="film"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-secondary font-xl fw-700">
                                    {analytics.allMovies}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Published Movies
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#f6f3ff` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="film"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-secondary font-xl fw-700">
                                    {analytics.movies}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Published Movies
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#e2f6e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                                    <FeatherIcon
                                      icon="tv"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-success font-xl fw-700">
                                    {analytics.totalSeries}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Total Published Series
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#e2f6e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                                    <FeatherIcon
                                      icon="tv"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-success font-xl fw-700">
                                    {analytics.allSeries}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Published Series
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#e2f6e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                                    <FeatherIcon
                                      icon="tv"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-success font-xl fw-700">
                                    {analytics.series}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Published Series
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#fff0e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="play-circle"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-warning font-xl fw-700">
                                    {analytics.totalEpisode}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Total Published Episodes
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#fff0e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="play-circle"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-warning font-xl fw-700">
                                    {analytics.allEpisode}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Published Episodes
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                                style={{ background: `#fff0e9` }}
                              >
                                <div className="card-body d-flex p-0">
                                  <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                                    <FeatherIcon
                                      icon="play-circle"
                                      style={{ size: 10, fontSize: 10 }}
                                      className="btn-round d-inline-block me-2 ps-2 text-white"
                                    />
                                  </i>
                                  <h4 className="text-warning font-xl fw-700">
                                    {analytics.episode}
                                    <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                      Published Episodes
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>

                          {/* ))} */}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="col-lg-12 mb-3">
                      <TestChart />
                      {/* <Chart data={userData} title="User Analytics" grid dataKey="Active User"/> */}
                      <div
                        style={{ display: "flex", margin: 10, marginTop: 50 }}
                      >
                        {/* <WidgetSm movies = {analytics.topMovies} /> */}
                        <WidgetLg
                          movies={analytics.topMovies}
                          url="content/analytics"
                        />
                      </div>
                      <div
                        style={{ display: "flex", margin: 10, marginTop: 20 }}
                      >
                        {/* for series */}
                        <WidgetLg
                          movies={analytics.topSeries}
                          url="series/analytics"
                        />
                      </div>
                      {/* <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                    <Chart
                                    options={this.state.options}
                                    series={this.state.series}
                                    type="bar"
                                    width="100%"
                                    />
                                </div> */}
                    </div>

                    {/* <div className="row" style={{ height: "100%" }}>
                      <div className="col-lg-3 pe-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e5f6ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="users"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.totalSubscribersCount}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Subscribers
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 pe-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e5f6ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="users"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.allSubscribersCount}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Subscribers between now and {month} month ago{" "}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 pe-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e5f6ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="users"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.subscribersCount}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Subscribers {month} month ago
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 pe-2 ps-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#f6f3ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                              <FeatherIcon
                                icon="credit-card"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-secondary font-xl fw-700">
                              {analytics.totalPaidSub}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Paid Subscriptions
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 pe-2 ps-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#f6f3ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                              <FeatherIcon
                                icon="credit-card"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-secondary font-xl fw-700">
                              {analytics.allPaidSub}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Successful paid subscriptions between now and{" "}
                                {month} month ago{" "}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 pe-2 ps-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#f6f3ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-secondary font-md text-white">
                              <FeatherIcon
                                icon="credit-card"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-secondary font-xl fw-700">
                              {analytics.paidSub}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Successful paid subscriptions {month} month ago
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>

                      <br />

                      <div className="col-lg-3 ps-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#fff0e9` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                              <FeatherIcon
                                icon="shopping-bag"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-warning font-xl fw-700">
                              {analytics.totalPurchase}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Content Purchase
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 ps-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#fff0e9` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                              <FeatherIcon
                                icon="shopping-bag"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-warning font-xl fw-700">
                              {analytics.allPurchase}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Contents purchased between now and {month} month
                                ago{" "}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 ps-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#fff0e9` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                              <FeatherIcon
                                icon="shopping-bag"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-warning font-xl fw-700">
                              {analytics.purchase}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Contents purchased {month} month ago
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 ps-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#fff0e9` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                              <FeatherIcon
                                icon="play-circle"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-warning font-xl fw-700">
                              {analytics.channel.comics_count}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Published Comics
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                      

                      <br />

                      
                      <div className="col-lg-3 pe-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e5f6ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="video"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.channel.videos_count}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Published Videos
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 pe-2 ps-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#f6f3ff` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning font-md text-white">
                              <FeatherIcon
                                icon="film"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-secondary font-xl fw-700">
                              {analytics.channel.movies_count}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Published Movies
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 pe-2 ps-2">
                        <div
                          className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                          style={{ background: `#e2f6e9` }}
                        >
                          <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success font-md text-white">
                              <FeatherIcon
                                icon="tv"
                                style={{ size: 10, fontSize: 10 }}
                                className="btn-round d-inline-block me-2 ps-2 text-white"
                              />
                            </i>
                            <h4 className="text-success font-xl fw-700">
                              {analytics.channel.series_count}
                              <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                Total Published Series
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>

                      <br />

                   
                    </div> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  }
}

// export default Analytics;
