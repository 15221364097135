import "./widgetLg.css";
import { useHistory, useParams } from "react-router";

export default function WidgetLg(props) {
  const history = useHistory();
  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{props.views_count}</button>;
  };
  return (
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Most Viewed Contents</h3>
      <table className="widgetLgTable">
        <tr className="widgetLgTr">
          <th className="widgetLgTh" style={{color: 'blue'}}>Content</th>
          <th className="widgetLgTh" style={{color: 'blue'}}>Type</th>
          <th className="widgetLgTh" style={{color: 'blue'}}>Prcing</th>
          <th className="widgetLgTh" style={{color: 'blue'}}>Views</th>
        </tr>

        { props.movies.map((movie, index) => (
          
        <tr className="widgetLgTr" style={{marginBottom:20}}>
          <td className="widgetLgUser pointer">
            <img
              src={movie.image}
              alt=""
              className="widgetLgImg"
            />
            <a 
            href={`/${props.url}/${movie.u_id}`}
            //  onClick={() => {
            //   history.push({
            //     pathname: `/${props.url}/${movie.u_id}`,
            //     state: {adminId: 5}
            //   });
              // window.location.reload();
            // }}
            >  <span className="widgetLgName" style={{color: 'blue'}}>{movie.title}</span> </a>
          </td>
          <td className="widgetLgDate" style={{color: '#000'}}>{movie.type}</td>
          <td className="widgetLgAmount" style={{color: '#000'}}>{movie.pricing} {movie.price}</td>
          <td className="widgetLgStatus">
          <button className="widgetLgButton Approved">
            {movie.views_count}
          </button>
            {/* <Button type="Approved" views_count={movie.views_count} /> */}
          </td>
          <br/>
        </tr>
        
       ))}
      </table>
    </div>
  );
}
