import React, { Component, Fragment } from "react";
// import Header from "../components/Header";
// import Leftnav from "../components/Leftnav";
// import Rightchat from "../components/Rightchat";
// import Pagetitle from "../components/Pagetitle";
// import Appfooter from "../components/Appfooter";
// import Popupchat from "../components/Popupchat";
import { Route, useParams, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useState, useEffect } from "react";
import Load from "../../others/Load";
import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import ImageComponent from "../../others/ImageComponent";
import ImageComponentDiv from "../../others/ImageComponentDiv";

import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";

export default function ChannelsComp(props) {
  const [fetchingChannels, setFetchingChannels] = useState("");
  const [channels, setChannels] = useState([]);
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );
  // const [userName, setUserName] = useState("");
  const userName = window.localStorage.getItem("user_name");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [subscribing, setSubscribing] = useState(false);

  function closeError() {
    setError(false);
    console.log("closed");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  async function getAllChannelsLoad() {
    // window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    // Axios.defaults.headers.common['X-CSRF-TOKEN'] = token_var;
    setFetchingChannels(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(props.link, {
        method: "GET",
        // withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        // if (resp.data.status == "success") {
        setChannels(resp.data);
        setFetchingChannels(false);
        // } else {
        //   setFetchingChannels(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        setFetchingChannels(false);
        console.log(error);
        setError(true);
        setFetchingChannels(error.message);
      });
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    // getAllChannelsLoad();
  }, []);

  if (fetchingChannels == true) {
    return (
      <Fragment>
        {/* <Header /> */}
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}
        <div
          className="main-content"
          style={{ backgroundColor: "#000", height: "100%" }}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                 
                <Load bg='#fff' color='#000' />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
        <div style={{marginBottom: 400}}></div>
      </Fragment>
    );
  }

  if (props.channels.length == 0) {
    return (
      <Fragment>
        {/* <Header /> */}
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div
          className="main-content right-chat-active"
          style={{ backgroundColor: "#fff", height: "100%" }}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  
                  <h3
                    className="middle-wrap fw-600 text-grey-900"
                    style={{ textAlign: "center", lineHeight: 2 }}
                  >
                    Hello you haven't created any channel. Create one to start
                    uploading and monetizing your contents now
                  </h3>
                  <br />
                  <Button
                    href="/create_channel"
                    // fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Create Channel{" "}
                  </Button>{" "}
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
        <div style={{marginBottom: 400}}></div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {/* <Header /> */}
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div
          className="main-content"
          style={{ backgroundColor: "#fff", height: "100%" }}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  {/* <Pagetitle
                    title="Latest Channels"
                    isChannel={"Create A Channel"}
                    channelAdmin={adminId}
                    bg="#fff"
                    color="#000"
                    searchUrl="channels/search"
                    redirect="channel"
                  /> */}
                  {/* <br />
                  <Button
                    href="/create_channel"
                    // fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Create Channel{" "}
                  </Button>{" "}
                  <br /> */}
                  <div className="row ps-2 pe-1">
                    {error ? (
                      <div>
                        <br />
                        <Alert
                          severity="error"
                          onClose={handleClose}
                          action={
                            <>
                              <CancelIcon onClick={closeError} />
                            </>
                          }
                        >
                          There was an issue fetching channels. Kindly refresh
                          or check your network [ {errorMessage} ]
                        </Alert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                        <h2 className='text-center' style={{color:'#000'}}>{props.title} </h2> 
                    {props.channels.map((channel, id) => (
                      <>
                        <div className="col-md-6 col-sm-6 pe-2 ps-2">
                          <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                            {channel.cover_pic === null ? (
                              <div
                                className="card-body position-relative h100 bg-image-cover bg-image-center"
                                style={{
                                  backgroundImage: `url('https://alert-app-v1.s3.amazonaws.com/user.png')`,
                                }}
                                onError={fixImage}
                              ></div>
                            ) : (
                              <ImageComponentDiv
                                onError={fixImage}
                                image={channel.cover_pic}
                                alt="avater"
                                class="card-body position-relative h100 bg-image-cover bg-image-center"
                              />
                            )}
                            <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                              <figure
                                className="avatar position-absolute w75 z-index-1 left-15"
                                style={{
                                  marginTop: `-40px`,
                                  marginLeft: "-10px",
                                }}
                              >
                                {channel.channel_pic === null ? (
                                  <img
                                    onError={fixImage}
                                    src={
                                      "https://alert-app-v1.s3.amazonaws.com/user.png"
                                    }
                                    alt="avater"
                                    className="float-right p-1 bg-white rounded-circle w-100 "
                                    style={{ objectFit: "contain" }}
                                  />
                                ) : (
                                  <ImageComponent
                                    // onError={fixImage}
                                    image={channel.channel_pic}
                                    alt="avater"
                                    class="float-right p-1 bg-white rounded-circle w-100 "
                                  />
                                )}
                              </figure>

                              <div className="clearfix"></div>

                              <Link
                                to={{
                                  pathname: `/channel/${channel.slug}`,
                                  state: { channelId: channel.id },
                                }}
                              >
                                <h4 className="fw-700 font-xss mt-3 mb-0">
                                  {channel.name}{" "}
                                </h4>
                              </Link>
                              <br />
                              <Link to={`/profile/${channel.admin.user_name}`}>
                                {" "}
                                <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                                  Created By:{" "}
                                  <span className="text-primary-500">
                                    {channel.admin.user_name}
                                  </span>{" "}
                                </p>{" "}
                              </Link>

                              {/* <br />
                              <br />
                              <br /> */}
                              {/* <br /> */}
                              {/* {channel.type !== "free" ? ( */}
                              <h4 className="fw-600 font-sm">
                                <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                  Channel Subscription is {channel.type}. Click
                                  button to proceed
                                </span>
                              </h4>
                              {/* ) : (
                          ""
                        )}
                        <br /> */}
                              <div
                                style={{ marginTop: 2, marginLeft: "-40px" }}
                                className="d-flex align-items-center"
                                // className="position-absolute right-5 top-10 d-flex align-items-center"
                              >
                                <ul className="d-flex align-items-center justify-content-center mt-1">
                                  <li className="m-2">
                                    <h4 className="fw-600 font-sm">
                                      {channel.subscribers_count}
                                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                        Subscribers
                                      </span>
                                    </h4>
                                  </li>
                                  <li className="m-2">
                                    <h4 className="fw-600 font-sm">
                                      {channel.videos_count}
                                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                        Videos
                                      </span>
                                    </h4>
                                  </li>
                                  <li className="m-2">
                                    <h4 className="fw-600 font-sm">
                                      {channel.movies_count}
                                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                        Movies
                                      </span>
                                    </h4>
                                  </li>
                                  <li className="m-2">
                                    <h4 className="fw-600 font-sm">
                                      {channel.comics_count}
                                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                        Comics
                                      </span>
                                    </h4>
                                  </li>
                                  <li className="m-2">
                                    <h4 className="fw-600 font-sm">
                                      {channel.series_count}
                                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                        Series
                                      </span>
                                    </h4>
                                  </li>
                                </ul>
                              </div>

                              {/* <br/> */}
                              <div
                                style={{
                                  marginTop: "-15px",
                                  marginLeft: "-10px",
                                }}
                              >
                                  {window.localStorage.au == "true" && (
                                <Button
                                href={`/channel/admin/update/${channel.slug}`}
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                >
                                  UPDATE CHANNEL
                                </Button>
                                  )}
                                <br />
                                <br />

                                {window.localStorage.au == "true" && (
                                  <Button
                                    href={`/channel/admin/analytics/${channel.slug}`}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                  >
                                    VIEW STATISTICS
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='mb-3'> </div> */}

                        {/* <div
                          key={channel.id}
                          className="col-md-4 col-sm-6 pe-2 ps-2"
                        ></div> */}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
        <div style={{marginBottom: 400}}></div>
      </Fragment>
    );
  }
}

// export default Channels;
