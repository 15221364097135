import React, { Component, useEffect, useState } from "react";
import { Select } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
import Load from "./Load";
import { format, formatDistance, subDays } from 'date-fns';
import ShowMoreText from "react-show-more-text";
import ImageComponent from "./ImageComponent";

export default function NotificationComp() {
  const [isActive, setIsActive] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [fetchingNotifications, setFetchingNotifications] = useState(false);

  async function getUnreadNotifications() {
    setFetchingNotifications(true)
    console.log('fetching notifications')
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    axiosFetch
      .get(
        `/users/show/${user_id}/unread_notifications`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log(`alerts list ${JSON.stringify(resp.data[0].created_at)}`);
        setNotifications(resp.data.slice(0, 3));
        setAllNotifications(resp.data);
        setFetchingNotifications(false)
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  // setCasts(res.cast.slice(0, 5));
  useEffect(() => {
    getUnreadNotifications();
  }, []);

  if(fetchingNotifications == true) {
    return(
            <>
                <Load />
           </>
    )
}
else {
  return (
    <>
    <h4 className="fw-700 font-xss mb-4">
      Click the bell again to close
    </h4>
    
      <h4 className="fw-700 font-xsss mb-2">
        {" "}
        Your Have {allNotifications.length} Unread Notifications
        <Link to="/notifications" >
                      
                    
        <span className="text-primary-400 font-xsssss fw-600 float-right mt-1">
              {" "}
              See All
            </span>
            </Link>
      </h4>
      {notifications.map((notification) => (
        <div className="card bg-transparent-card w-100 border-0 ps-5 mb-1 "
        key={notification.id}>
          
          <ImageComponent
                image={notification.data.user.profile_pic}
                class="w40 position-absolute left-0 rounded-circle"
                style={{paddingRight: 2}}
              />
          {/* <img
            src={notification.data.user.profile_pic}
            alt="user"
            className="w40 position-absolute left-0 rounded-circle"
            // className="shadow-sm rounded-circle w40"
          /> */}
          
          <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {/* {notification.data.alert.headline} */} {notification.data.head}
            <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">
              {" "}
              {/* 3 min */}
              {
                formatDistance(
                    new Date(notification.created_at),
                    new Date(),
                    { addSuffix: true }
                ) 
            }
            </span>
          </h5>
         
            <ShowMoreText
                  /* Default options */
                  lines={1}
                  more="Read more"
                  less="Show less"
                  className="text-grey-500 fw-500 font-xssss lh-4"
                  // className="content-css"
                  anchorClass="my-anchor-css-class"
                //   onClick={this.executeOnClick}
                  expanded={false}
                  // width={480}
                  truncatedEndingComponent={"... "}
                >
                  {/* {notification.data.alert.body} */}
                  {notification.data.data}
            </ShowMoreText>
          <h6 className="text-grey-500 fw-500 font-xssss lh-4">
            By {notification.data.user.first_name} {notification.data.user.last_name}
            <span className="text-primary" style={{paddingLeft: 20}}> <a href='/notifications'> View</a></span>
          </h6>
          {/* <br/> */}
          {/* <Link to="/notifications" >
            
          </Link> */}
         
        </div>
      ))}
    </>
  );
      }
}
