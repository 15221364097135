import React from "react";
import { Component, useState, useEffect, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import AddAlertRoundedIcon from "@material-ui/icons/AddAlertRounded";
import BoltIcon from "@mui/icons-material/Bolt";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import axios from "axios";
import axiosFetch from "../../config/Interceptors";

import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import emailjs from "@emailjs/browser";

function Copyright() {
  return (
    <>
      <br />
      <Typography variant="body2" color="textSecondary" align="center">
        {"For Help, "}
        <Link color="primary" href="/help">
          Click Here!
        </Link>{" "}
        {"."}
      </Typography>

      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="primary" target="_blank" href="https://about.alarrt.com/">
          Chillarx
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        A Product Of
        <Link
          style={{ paddingLeft: 3 }}
          color="primary"
          target="_blank"
          href="https://soaenterprise.com/"
        >
          SOA Digital Enterprise
        </Link>
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "160vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  image2: {
   
    backgroundImage:  "url(/assets/images/footer-bg.jpg)",
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'contain',
    backgroundPosition: 'cover',
    width: "100vw",
    height: "140vh",
    [theme.breakpoints.down("sm")]: {
      height: "80vh", 
      backgroundSize: 'cover',
    }, 
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#000'
    // theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    color: "#eee",
    padding: 0,
    margin: 0,
  },
  input: {
    color: "red",
    padding: 0,
    margin: 0,
  },
}));

export default function ContactUs() {
  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactMessage, setContactMessage] = useState("Sending Your Message");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [sendingError, setSendingError] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  
  const reff = "";
  const form = useRef();

  const animatedComponents = makeAnimated();
  const options = [
    { value: "login-issue", label: "Issue Logging In" },
    { value: "register-issue", label: "Can't Register" },
    { value: "application-error", label: "Application Error" },
    {
      value: "application-improvement-ideas",
      label: "Application Improvement Ideas",
    },
    { value: "application-enquiry", label: "Enquiry" },
  ];

  function ChangeSubject(value) {
    console.log(`selected ${JSON.stringify(value)}`);
    setReason(value);
    // value.map((tag) => alertTagsName.push(tag.value));
    // console.log(`name ${alertTagsName}`);

    // setSelectedTags([...new Set(alertTagsName)]);
    // console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEmptyFields(false);
    setSendingError(false);
    setSentMessage(false)

    // setOpenAlert(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  async function validate() {
    if (!firstName || !lastName || !email || !phoneNo || !message) {
      setEmptyFields(true);
      return <Alert severity="warning">Please type in all fields</Alert>;
    } else {
      // alert('hi')
      sendEmail();
    }
  }

  const sendEmail = (e) => {
    // e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        "service_chillarxv1",
        "template_chillarx-v1",
        form.current,
        process.env.REACT_APP_EMAIL_USER_ID,
        process.env.REACT_APP_EMAIL_ACCESS_TOKEN
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSending(false);
          setSentMessage(true);
        },
        (error) => {
          console.log(error.text);
          setIsSending(false);
          setSendingError(true);
          setErrorMessage(error.text);
        }
      );
  };

  async function sendMesssage() {
    setIsSending(true);

    // emailjs.send("service_alarrtv1","template_8y4d2so",{
    //     first_name: "Olumide",
    //     last_name: "Shode",
    //     phone: "+2347080315669",
    //     email: "olumideshode@gmail.com",
    //     message: "This is the first test from email js",
    //     });

    axios
      .post(`${process.env.REACT_APP_LIVE_URL}/register`, {
        method: "POST",
        email: email,
        first_name: firstName,
        message: message,
        last_name: lastName,
        phone_number: phoneNo,
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        window.localStorage.setItem("access_token", resp.data.access_token);
        window.localStorage.setItem("refresh_token", resp.data.refresh_token);
        const token = window.localStorage.getItem("access_token");
        //  console.log(`session stored token ${token}`)
        setContactMessage("Logging You In");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNo("");
      })
      .catch((error) => {
        setIsSending(false);
        setSendingError(true);
        setErrorMessage(error.response.data.message);
        console.log(error);
      });
  }

  //   function redirect() {
  //     if(window.localStorage.getItem("access_token"))
  //     {
  //       history.push("/")
  //     }

  //   }
  useEffect(() => {
    if(window.localStorage.getItem("access_token"))
    {
      setIsLoggedIn(true)
    }
  }, [console.log("reason " + reason)]);

  return (
    <Grid container component="main" className={classes.root} style={{backgroundColor: '#fff'}}>
      <CssBaseline />
      {/* <Grid item xs={12} sm={6} md={6} className={classes.image2} /> */}
      <Grid item xs={12} sm={6} md={6} >
        <br/>
        <Typography component="h1" variant="overline" className="text-center" style={{color: "#2516c7"}} > Welcome! Please always accept the notification prompt when asked for a better experience on Chillarx</Typography>
        <div className={classes.image2}></div>
      </Grid>
        <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PlayCircleOutlineIcon style={{fontSize:25, color: '#FFD700'}}/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Get In Touch With Us
          </Typography>{" "}
          <br />
          <p className="text-center">
            Do you need assitance, have a complaint? or wish to pass accross a
            vital information to us? Send us a message now and we'll respond
            shortly{" "}
          </p>{" "}
          <br />
          <div className={classes.form}>
            <form ref={form}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="form-group icon-input mb-0.5">
                  <i className="font-sm feather-user text-grey-500 pe-0"></i>

                    <input
                      style={{paddingLeft: 40}}
                      type="text"
                      name="first_name"
                      placeholder="Your First Name"
                      className="style2-input form-control text-grey-900 font-xsss ls-3"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />

                   
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="form-group icon-input mb-0.5">
                    <input
                      style={{paddingLeft: 40}}
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      className="style2-input form-control text-grey-900 font-xsss ls-3"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />

                    <i className="font-sm feather-user text-grey-500 pe-0"></i>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className="form-group icon-input mb-0.5">
                  <i className="font-sm feather-phone text-grey-500 pe-0"></i>

                    <input
                      style={{paddingLeft: 40}}
                      type="text"
                      name="phone"
                      placeholder="Your Phone"
                      className="style2-input form-control text-grey-900 font-xsss ls-3"
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                    />

                    
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className="form-group icon-input mb-0.5">
                  <i className="font-sm feather-mail text-grey-500 pe-0"></i>

                    <input
                      style={{paddingLeft: 40}}
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      className="style2-input form-control text-grey-900 font-xsss ls-3"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="col-lg-12 mb-3">
                    <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                      Select Or Create New Contact Reason
                    </label>
                    <br />
                    <CreatableSelect
                      isClearable
                      // isMulti
                      onChange={ChangeSubject}
                      components={animatedComponents}
                      options={options}
                      name="reason"
                    ></CreatableSelect>{" "}
                    {/* <br /> */}
                    {/* <div className="form-group icon-input mb-0.5">
              <input
                // type="Password"
                type="email"
                name='reason'
                placeholder="Your Email"
                className="style2-input form-control text-grey-900 font-xsss ls-3"
                value={reason}
                onChange={(e) => setEmail(e.target.value)}
              />

              <i className="font-sm feather-mail text-grey-500 pe-0"></i>
            </div> */}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="col-lg-12 mb-3">
                    <textarea
                      // className="fw-500 bg-greylight  text-black-900 lh-26 font-xssss w-100 mb-2"
                      className="form-control mb-0 p-3 h100 bg-greylight lh-20"
                      rows="5"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type your message here..."
                    ></textarea>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <p className="text-center">
                    {" "}
                   Your feedback is important to us. It helps us improve the services we offer. We are always here to help. All you need is to get in touch.{" "}
                  </p>
                </Grid>
              </Grid>
            </form>
            {isSending ? (
              <Button
                disabled
                fullWidth
                variant="contained"
                // color="primary"
                style={{backgroundColor: '#000', color: '#FFD700'}}
                className={classes.submit}
                // onClick={validate}
              >
                {contactMessage}
              </Button>
            ) : (
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                // color="primary"
                style={{backgroundColor: '#000', color: '#FFD700'}}
                className={classes.submit}
                onClick={validate}
              >
                Send Your Message
              </Button>
            )}
            {emptyFields ? (
              <div>
                <Alert severity="warning" onClose={handleClose}>
                  Please type in the fields above
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}

            {sentMessage ? (
              <div>
                <Alert severity="success" onClose={handleClose}>
                  Thanks for getting in touch. You message has been delivered
                  successfully. You've got a mail, kindly check your inbox
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}

            {sendingError ? (
              <div>
                <Alert severity="error" onClose={handleClose}>
                  There was an issue sending your message. [ {errorMessage} ].
                  You can also send us this message at support@app.chillarx.com
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}
            
              {
                isLoggedIn ? 
                <Button
                
                fullWidth
                variant="contained"
                // color="primary"
                style={{backgroundColor: '#000', color: '#FFD700'}}
                className={classes.submit}
                href='/'
                // onClick={validate}
              >
                Go Back Home
              </Button>
              :
              <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  Forgot Password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign In
                </Link>
              </Grid>
            </Grid>
              }

            <Box mt={5}>
              <Copyright />
            </Box>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
