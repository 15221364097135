import "./channel.scss";

import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";
import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Datatable from "../../components/studio/datatable/Datatable";
import ChannelsComp from "../../components/studio/channel/ChannelsComp";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import axiosFetch from "../../config/Interceptors";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import UnapprovedMovies from "./UnapprovedMovies";
import Load from "../../components/others/Load";
import PendingMovies from "./PendingMovies";
import AdminChannel from "../../components/studio/channel/AdminChannel";
import MarkerTransactionTable from "../../components/others/MarkerTransactionTable";
import ChillarxEarnings from "../../components/others/ChillarxEarnings"
import ChillarxEarningsAdvanced from "../../components/others/ChillarxEarningsAdvanced"

export default function AdminTab() {
  const history = useHistory();
  const [value, setValue] = React.useState("1");
  const [unapprovedMovies, setUnapprovedMovies] = useState([]);
  const [unapprovedSeries, setUnapprovedSeries] = useState([]);
  const [unmonetizedMovies, setUnmonetizedMovies] = useState([]);
  const [unmonetizedSeries, setUnmonetizedSeries] = useState([]);

  const [monetizedMovies, setMonetizedMovies] = useState([]);
  const [monetizedSeries, setMonetizedSeries] = useState([]);

  const [unapprovedChannels, setUnapprovedChannels] = useState([]);
  const [pendingPartnerChannels, setPendingPartnerChannels] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [fetchingMovies, setFetchingMovies] = useState(false);
  const [fetchingChannels, setFetchingChannels] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function checkProfile() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/profile`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data.user.is_admin);
        if (resp.data.user.is_admin !== "true") {
          history.push("/welcome");
          history.go();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getUnapprovedContents() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`movie/all_pending`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setUnapprovedMovies(resp.data.pendingMovies);
        setUnapprovedSeries(resp.data.pendingSeries);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getPendingContents() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`movie/view_monetization`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setUnmonetizedMovies(resp.data.pendingMovies);
        setUnmonetizedSeries(resp.data.pendingSeries);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }
  async function getApprovedContents() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`movie/view_approved`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setMonetizedMovies(resp.data.approvedMovies);
        setMonetizedSeries(resp.data.approvedSeries);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getUnapprovedChannelsLoad() {
    // window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    // Axios.defaults.headers.common['X-CSRF-TOKEN'] = token_var;
    setFetchingChannels(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get("/channels/admin/view", {
        method: "GET",
        // withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setUnapprovedChannels(resp.data.unApproved);
        setPendingPartnerChannels(resp.data.pendingPartnership);
        setFetchingChannels(false);
      })
      .catch((error) => {
        setFetchingChannels(false);
        console.log(error);
        setError(true);
        setFetchingChannels(error.message);
      });
  }

  async function getPendingChannelsLoad() {
    // window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    // Axios.defaults.headers.common['X-CSRF-TOKEN'] = token_var;
    setFetchingChannels(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get("/channels/view_partnerships", {
        method: "GET",
        // withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        // if (resp.data.status == "success") {
        setPendingPartnerChannels(resp.data);
        setFetchingChannels(false);
        // } else {
        //   setFetchingChannels(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        setFetchingChannels(false);
        console.log(error);
        setError(true);
        setFetchingChannels(error.message);
      });
  }

  useEffect(() => {
    checkProfile();
    // getPendingContents();
    // getApprovedContents();
    // getUnapprovedContents();
    // getUnapprovedChannelsLoad();
    // getPendingChannelsLoad();
  }, []);

  if (fetchingMovies == true) {
    return (
      <>
        <HeaderV2 />
        <div className="main-content" style={{ marginTop: 50 }}>
          <Load color="#000" bg="#fff" />
        </div>
      </>
    );
  }

  return (
    <>
      <HeaderV2 />
      <div className="main-content">
        <Box sx={{ width: "100%", typography: "body1", marginTop: 10 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                // centered
                allowScrollButtonsMobile
                scrollButtons="auto"
                aria-label="scrollable force tabs example"
              >
                <Tab
                  label="All Transactions"
                  value="1"
                  style={{ color: "black", paddingRight: 20 }}
                />
                <Tab
                  label="Chillarx Subscriptions"
                  value="2"
                  style={{ color: "black", marginRight: 20 }}
                />
                <Tab
                  label="Chillarx Payouts"
                  value="3"
                  style={{ color: "black", marginRight: 20 }}
                />
                <Tab
                  label="Past Payments"
                  value="4"
                  style={{ color: "black", marginRight: 20 }}
                />
                 {/*<Tab
                  label="Pending Movies"
                  value="4"
                  style={{ color: "black", marginRight: 20 }}
                />
                <Tab
                  label="Monetized Movies"
                  value="5"
                  style={{ color: "black", marginRight: 20 }}
                /> */}
              </TabList>
            </Box>
            <TabPanel value="1">
              <div style={{ color: "#000" }}>
                <h5 className="text-center">
                  All Transactions Made On Chillarx
                </h5>{" "}
                <br />
                
                <MarkerTransactionTable link={`users/chillarx/all_transactions`} title='Below are the transactions Chillarx has recieved'  />
              </div>
              {/* <AllChannels channels={unapprovedChannels} /> */}
            </TabPanel>
            <TabPanel value="2">
              <div style={{ color: "#000" }}>
                <h5 className="text-center">
                  All Chillarx Subscriptions
                </h5>{" "}
                <br />
                <MarkerTransactionTable link={`users/chillarx/all_subscriptions`} title='Below are the subscriptions Chillarx has recieved'  />
               
              </div>
              {/* <PendingChannelPartners channels={pendingPartnerChannels} /> */}
            </TabPanel>
             <TabPanel value="3">
             <h3 className="text-center">
                  All Chillarx Payouts Per Month
                </h3>
                {/* <MarkerTransactionTable link={`channels/chillarx/view_payouts`} title='Below are the estimated payouts to creators'  /> */}
                {/* <ChillarxEarnings link={`channels/chillarx/view_payouts`} title={`Below are the estimated payouts to creators` } /> */}
                <ChillarxEarningsAdvanced link={`channels/chillarx/view_payouts`} title={`Below are the estimated payouts to creators` } />
            </TabPanel>
            {/*
            <TabPanel value="4">
              
              {monetizedMovies.length == 0 ? 
                ''
                :
              <PendingMovies
                movies={unmonetizedMovies}
                series={unmonetizedSeries}
                title="Watch And Approve Pending Contents For Chillarx Monetization Now. Only approve episodes if its series has been approved as well."
              />
              }
            </TabPanel> */}

            
          </TabContext>
        </Box>
      </div>
    </>
  );
}
