import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import axiosFetch from "../../config/Interceptors";
import "../../components/comment/comment.css";
import CommentComponent from "../../components/comment/CommentComponent";
import tmdbApi from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";

import Button, { OutlineButton } from "../../components/button/Button";
import Header from "../../components/header/Header";

import "./detail.scss";
import CastList from "./CastList";
import VideoList from "./VideoList";
import MovieList from "../../components/movie-list/MovieList";
import { formatDistance } from "date-fns";
import { useHistory, useLocation } from "react-router";
import { Grid, makeStyles } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import ShowMoreText from "react-show-more-text";
import { Button as But } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import { usePaystackPayment } from "react-paystack";
import Alert from "@mui/material/Alert";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import LikersModal from "../../components/others/LikersModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@material-ui/core";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import ImageComponentDiv from "../../components/others/ImageComponentDiv";
import FavComment from "../../components/comment/FavComments";
import { usePaystackPayment } from "react-paystack";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
  pc: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const Detail = () => {
  const classes = useStyles();
  const { category, id } = useParams();

  const [item, setItem] = useState(null);
  const [copied, setCopied] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [showMovie, setShowMovie] = useState(false);
  const [paymentPrompt, setPaymentPrompt] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");
  const [seriesPaymentPrompt, setSeriesPaymentPrompt] = useState(false);
  const [seriesSubPrompt, setSeriesSubPrompt] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [subAction, setSubAction] = useState("");
  const [subLink, setSubLink] = useState("");
  const [payAction, setPayAction] = useState("");
  const [payLink, setPayLink] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [subPrompt, setSubPrompt] = useState(false);
  const [subMessage, setSubMessage] = useState("");
  //   const url = "https://image.tmdb.org/t/p/w300";
  const [openComment, setOpenComment] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [message, setMessage] = useState("");
  const userEmail = window.localStorage.getItem("email");
  const userPic = window.localStorage.getItem("profile_pic");
  const userId = window.localStorage.getItem("user_id");
  const userName = window.localStorage.getItem("user_name");

  const { u_id } = useParams(["u_id"]);
  const [gottonQuery, setGottonQuery] = useState(false);
  const [refreshed, setRefreshed] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);
  const [movie, setMovie] = useState([]);
  const [favComments, setFavComments] = useState([]);
  const [series, setSeries] = useState([]);
  const [video, setVideo] = useState("");
  const [views, setViews] = useState("");
  const [trailer, setTrailer] = useState("");
  const [comments, setComments] = useState([]);
  const [casts, setCasts] = useState([]);
  const [likers, setLikers] = useState([]);
  const [likedStatus, setLikedStatus] = useState("");
  const [ratings, setRatings] = useState("");
  const [isSub, setIsSub] = useState("");
  const [isPurchased, setIsPurchased] = useState("");
  const [seriesSlug, setSeriesSlug] = useState("");
  const [seriesId, setSeriesId] = useState("");
  const [purchasedSeries, setPurchasedSeries] = useState("");
  const [seriesPricing, setSeriesPricing] = useState("");
  const [publicKey, setPublicKey] = useState("");

  const { slug } = useParams(["slug"]);
  const { url } = useParams(["url"]);
  const location = useLocation();
  let history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log(`hiii ${queryParams}`);
  console.log(location.pathname);
  const pathname = location.pathname;

  const localAdminId = queryParams.get("a");
  localStorage.setItem("a", localAdminId);
  const localChannelSlug = queryParams.get("channel");
  localStorage.setItem("channel", localChannelSlug);
  const localMovieId = queryParams.get("w");
  localStorage.setItem("w", localMovieId);

  const adminId = localStorage.getItem("a");
  const channelSlug = localStorage.getItem("channel");
  const movieId = localStorage.getItem("w");

  const handleClickOpen = (scrollType) => () => {
    setOpenComment(true);
    setScroll(scrollType);
  };

  function closeSnack() {
    setCopied(false);
  }

  async function watchLater(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;

        getMovieDetails();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  const handleClose = () => {
    setOpenComment(false);
    setSubPrompt(false);
    setPaymentSuccess(false);
    setSaved(false);
  };
  const closeVideo = () => {
    setShowMovie(false);
  };

  const toogleVideo = () => {
    if (movie.type == "episode") {
      // alert('this is a episode')
      if (movie.type == "episode" && seriesPricing == "free") {
        // setShowMovie(true);
        setPaymentMessage(` Series is free! Enjoy its episodes below`);
        setIsAction(false);
        setShowMovie(true);
      }
      if (
        movie.type == "episode" &&
        seriesPricing == "paid" &&
        purchasedSeries == "true"
      ) {
        // setShowMovie(true);
        setSeriesPaymentPrompt(true);
        setPaymentMessage(
          ` Hurray! You've purchased this series already. See more Episodes below and
          enjoy!`
        );
        setShowMovie(true);
      }
      if (
        movie.type == "episode" &&
        seriesPricing == "paid" &&
        purchasedSeries == "false"
      ) {
        setPayAction("");
        setPayLink("");
        setSeriesPaymentPrompt(true);
        setPaymentMessage(
          `This ${movie.type} is not free. Buy and watch the series first to enjoy this episode and more`
        );
        setIsAction(true);
        setPayAction("Buy Series");
        // pathname: `/channel/${movie.channel.slug}/series/${movie.series.slug}?channel=${movie.channel.slug}&w=${movie.series.id}&a=${movie.channel.admin.id}`,
        setPayLink(`/chillarx/series/${seriesId}`);
      }
      if (
        movie.type == "episode" &&
        seriesPricing == "subscription" &&
        isSub == "false"
      ) {
        setSubAction("");
        setSubLink("");
        setSeriesSubPrompt(true);
        setPaymentMessage(
          `This ${movie.type} is for subscribers only. You need to
          subscribe first to enjoy this. Click go to channel
          button to subscribe now.`
        );
        setIsAction(true);
        setSubAction("Go To Channel");
        setSubLink(`/channel/${channelSlug}`);
      }

      if (
        movie.type == "episode" &&
        seriesPricing == "subscription" &&
        isSub == "true"
      ) {
        setSeriesSubPrompt(true);
        setPaymentMessage(
          ` Hurray! Series is for subscribers only and you are subscribed to this channel. See more episodes below and
          enjoy!`
        );
        setIsAction(false);
        // setShowMovie(true);
        setShowMovie(true);
      }

      // alert('this is a episode')
    } else {
      if (movie.pricing == "free") {
        setShowMovie(true);
      }
      if (movie.pricing == "paid" && isPurchased == "false") {
        setPaymentPrompt(true);
        setPaymentMessage(`This ${movie.type} is not free. Buy and watch now for N
      ${movie.price}`);
      }
      if (movie.pricing == "paid" && isPurchased == "true") {
        setShowMovie(true);
      }
      if (movie.pricing == "subscription" && isSub == "false") {
        // if (isSub == "false") {
        setSubPrompt(true);
        setSubMessage(
          `This ${movie.type} is for subscribers only. You need to
          subscribe first to enjoy this. Click go to channel
          button to subscribe now.`
        );
        setShowMovie(false);
      }

      if (movie.pricing == "subscription" && isSub == "true") {
        setShowMovie(true);
      }
    }
  };

  async function purchasedMovie(id) {
    // setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("movie_id", movie.id);
    formData.append("type", "movie");

    axiosFetch
      .post(`/movie_buyers/${movie.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        getMovieDetails();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  const config = {
    reference: new Date().getTime().toString(),
    email: userEmail,
    amount: movie.price * 100,
    publicKey: publicKey,
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    purchasedMovie();
    setPaymentPrompt(false);
    setPaymentSuccess(true);
    // window.location.reload();
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const descriptionElementRef = useRef(null);
  const initializePayment = usePaystackPayment(config);

  async function getMovieDetailsLoad() {
    const access_token = await window.localStorage.getItem("access_token");

    console.log("it is a movie or video!");
    setIsFetching(true);
    axiosFetch
      .get(`/movie/show/single/${u_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // alert('gotten movie 1')
        // console.log(JSON.stringify(resp.data))
        setMovie(resp.data.movie);
        setLikedStatus(resp.data.likedStatus);
        setLikers(resp.data.likers);
        setFavComments(resp.data.favComments);
        setVideo(resp.data.movie.video);
        setViews(resp.data.views);
        setIsSub(resp.data.isSubscribed);
        setIsPurchased(resp.data.purchased);
        setSeriesSlug(resp.data.seriesSlug);
        setSeriesId(resp.data.seriesId);
        setPurchasedSeries(resp.data.purchasedSeries);
        setSeriesPricing(resp.data.seriesPricing);
        setPublicKey(resp.data.public_key);
        setTrailer(resp.data.movie.trailer);
        setComments(resp.data.comments);
        setCasts(resp.data.casts);
        setRatings(resp.data.rates);
        setIsFetching(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function getMovieDetails() {
    const access_token = await window.localStorage.getItem("access_token");

    console.log("it is a movie or video!");
    // setIsFetching(true);
    axiosFetch
      .get(`/movie/show/single/${u_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data))
        // alert('gotten movie 2')
        // console.log(JSON.stringify(resp.data))
        setMovie(resp.data.movie);
        setLikedStatus(resp.data.likedStatus);
        setLikers(resp.data.likers);
        setFavComments(resp.data.favComments);
        setVideo(resp.data.movie.video);
        setViews(resp.data.views);
        setIsSub(resp.data.isSubscribed);
        setSeriesSlug(resp.data.seriesSlug);
        setSeriesId(resp.data.seriesId);
        setIsPurchased(resp.data.purchased);
        setPurchasedSeries(resp.data.purchasedSeries);
        setPublicKey(resp.data.public_key);
        setTrailer(resp.data.movie.trailer);
        setComments(resp.data.comments);
        setCasts(resp.data.casts);
        setRatings(resp.data.rates);
        //   setIsFetching(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function likeMovie(id) {
    setIsLiking(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/like/movie/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        getMovieDetails();

        setIsLiking(false);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  function handleContextMenu(e) {
    e.preventDefault();
  }

  const [movieItems, setMovieItems] = useState([]);
  const [latestMovies, setLatestMovies] = useState([]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [fecthingMovies, setFetchingMovies] = useState(false);

  async function getLatestMovies() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movie/${skip}/${take}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        // console.log(JSON.stringify(resp.data));
        setLatestMovies(resp.data);
        setMovieItems(resp.data);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getMovieDetailsLoad();
    getLatestMovies();
    document.addEventListener("contextmenu", handleContextMenu);
    // const getDetail = async () => {
    //   const response = await tmdbApi.detail(category, id, { params: {} });
    //   setItem(response);
    //   window.scrollTo(0, 0);
    // };
    // getDetail();

    if (openComment) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [openComment]);

  if (movie.length == 0) {
    return "";
  } else {
    return (
      <>
      <Header />
        {movie && (
          <>
            <ImageComponentDiv class="banner" image={movie.image} />
            {/* <div className="banner" style={{backgroundImage: `url(${apiConfig.originalImage(item.backdrop_path || item.poster_path)})`}}></div> */}
            <div style={{ paddingTop: 60 }}></div>
            <div className="mb-3 movie-content container">
              <div className="movie-content__poster">
                <ImageComponentDiv
                  class="movie-content__poster__img"
                  image={movie.cover_image}
                />
                {/* <div className="movie-content__poster__img" style={{backgroundImage: `url(${apiConfig.originalImage(item.poster_path || item.backdrop_path)})`}}></div> */}
                <br />
                <br />
                <div className="clearfix"></div>
                <div className="star d-block w-100 text-left mt-2">
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star-disable.png"
                    alt="star"
                    className="w15 float-left me-2"
                    style={{ height: 20, width: 20 }}
                  />
                </div>

                <p className="review-link font-xssss fw-600 text-white lh-3 mb-0">
                  ({ratings} ratings ) {comments.length} comments
                </p>
                <div className="clearfix"></div>
                <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                  <i className="btn-round-sm bg-greylight feather-users text-grey-500 me-1"></i>{" "}
                  {casts.length} casts
                </h5>

                {movie.pricing == "paid" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                    This {movie.type} is not free. Buy and watch now for N
                    {movie.price}
                  </h5>
                ) : (
                  ""
                )}

                {movie.pricing == "subscription" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                    Pricing: This {movie.type} is only accessible to channel
                    subscribers
                  </h5>
                ) : (
                  ""
                )}

                {movie.pricing == "free" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                    Free: No payment required! This {movie.type} is free to be
                    watched by all
                  </h5>
                ) : (
                  ""
                )}
              </div>

              <div className="movie-content__info">
                <Grid item sm={12} className={classes.mobile}>
                  <ImageComponentDiv
                    class="movie-content__poster__img"
                    image={movie.image}
                  />
                  <br />
                </Grid>
                <h1
                  style={{
                    color: "#fff",

                    fontSize: "4em",
                    lineHeight: 1,
                    textAlign: "start",
                  }}
                >
                  {movie.title}
                </h1>

                <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Status : {movie.status}{" "}
                  {formatDistance(new Date(movie.created_at), new Date(), {
                    addSuffix: true,
                  })}
                </h4>

                <h5 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Type : {movie.type}{" "}
                  {movie.type == "episode" ? (
                    <>
                      {/* //   pathname: `/${url}/${item.slug}?channel=${item.channel.slug}&w=${item.id}&a=${item.channel.admin.id}`, */}
                      <a href={`/chillarx/series/${seriesId}`}>
                        <span
                          className="genres__item"
                          style={{
                            padding: "0.5rem 1.5rem",

                            color: "#fff",
                            display: "inline-block",
                            marginTop: 5,
                          }}
                        >
                          View Series
                        </span>
                        {/* <OutlineButton
                        // onClick={() => setOpen(true)}
                        // style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                           View Series 
                      </OutlineButton> */}
                        {/* <h5 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                      View Series 
                      </h5> */}
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </h5>

                {/* <div className="genres">
                {movie.tags &&
                  movie.tags.slice(0, 5).map((tag, i) => (
                    <span key={i} className="genres__item">
                      {tag.name}
                    </span>
                  ))}
              </div> */}

                <div
                  className="genres clearfix"
                  style={{ overFlow: "auto", display: "block" }}
                >
                  {movie.tags &&
                    movie.tags.slice(0, 5).map((tag, i) => (
                      <span
                        key={i}
                        className="genres__item"
                        style={{
                          padding: "0.5rem 1.5rem",

                          color: "#fff",
                          display: "inline-block",
                          marginTop: 5,
                        }}
                      >
                        {tag.name}
                      </span>
                    ))}
                  <div className="clearfix"></div>
                </div>

                <Grid item sm={12} className={classes.mobile}>
                  <div className="star d-block w-100 text-left mt-2">
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                      style={{ height: 20, width: 20 }}
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                      style={{ height: 20, width: 20 }}
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                      style={{ height: 20, width: 20 }}
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                      style={{ height: 20, width: 20 }}
                    />
                    <img
                      src="/assets/images/star-disable.png"
                      alt="star"
                      className="w15 float-left me-2"
                      style={{ height: 20, width: 20 }}
                    />
                  </div>
                  <br />
                  <p className="review-link font-xssss fw-600 text-white lh-3 mb-0">
                    ({ratings} ratings ) {comments.length} comments
                  </p>
                  <div className="clearfix"></div>
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                    <i className="btn-round-sm bg-greylight feather-users text-grey-500 me-1"></i>{" "}
                    {casts.length} casts
                  </h5>
                  {movie.pricing == "paid" && movie.type == "episode" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                      This {movie.type} is not free. Buy the series now for N
                      {movie.price} and get access to all its episodes.
                    </h5>
                  ) : (
                    ""
                  )}
                  {movie.pricing == "paid" && movie.type !== "episode" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                      This {movie.type} is not free. Buy and watch now for N
                      {movie.price}
                    </h5>
                  ) : (
                    ""
                  )}
                  {movie.pricing == "subscription" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                      Pricing: This {movie.type} is only accessible to channel
                      subscribers
                    </h5>
                  ) : (
                    ""
                  )}
                  {movie.pricing == "free" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                      Free: No payment required! This {movie.type} is free to be
                      watched by all
                    </h5>
                  ) : (
                    ""
                  )}
                  <br /> <br />
                </Grid>
                {/* <p className="overview">{item.overview}</p> */}
                <ShowMoreText
                  style={{ color: "#fff", fontWeight: 700 }}
                  lines={3}
                  more="Read more"
                  less="Show less"
                  className="overview"
                  //   anchorClass="my-anchor-css-class"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  {movie.overview}
                </ShowMoreText>

                <div className="btns">
                  {seriesPaymentPrompt ? (
                    <>
                      <div>
                        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                          {paymentMessage}
                        </h4>
                        <br />
                      </div>

                      {payAction ? (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={() => {
                              history.push({
                                pathname: `/chillarx/series/${seriesId}`,
                              });
                              window.location.reload();
                            }}
                          >
                            {payAction}
                          </OutlineButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {seriesSubPrompt ? (
                    <>
                      <div>
                        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                          {paymentMessage}
                        </h4>
                        <br />
                      </div>

                      {isAction ? (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={() => {
                              history.push({
                                pathname: `/channel/${channelSlug}/movies`,
                                pathname: { subLink },
                              });
                            }}
                          >
                            {subAction}
                          </OutlineButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {paymentPrompt ? (
                    <>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                        {paymentMessage}
                      </h4>
                      <br />
                      <div style={{ paddingBottom: 20 }}>
                        <OutlineButton
                          onClick={() => {
                            initializePayment(onSuccess, onClose);
                          }}
                        >
                          Buy Now
                        </OutlineButton>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {paymentSuccess ? (
                    <>
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        Hurray! You've purchased {movie.title} successfully! You
                        can always comeback to watch it here, or view them in
                        your purchased movies from your profile. Click Watch Now
                        and Enjoy!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  <div style={{ marginRight: 20, marginBottom: 20 }}>
                    {video === null || video === "null" ? (
                      ""
                    ) : (
                      <Button onClick={toogleVideo}>Watch now</Button>
                    )}
                  </div>

                  {subPrompt ? (
                    <>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                        {subMessage}
                      </h4>
                      <div style={{ paddingBottom: 20 }}>
                        <OutlineButton
                          onClick={() =>
                            history.push(`/channel/${channelSlug}/movies`)
                          }
                        >
                          Go To Channel
                        </OutlineButton>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {showMovie ? (
                    <>
                      <br />
                      {/* {!movie.video.includes("youtu") ? ( */}
                      {/* <Player
                        playsInline
                        poster={movie.image}
                        src={movie.video}
                        fluid
                      /> */}
                      {/* ) : ( */}
                      <ReactPlayer
                        url={movie.video}
                        controls={true}
                        config={{
                          file: { attributes: { controlsList: "nodownload" } },
                        }}
                        playsinline={true}
                        width="100%"
                        // light={true}
                        // imageUrl={movie.image}
                        // height='360px'
                        //  controlsList="nodownload"
                      />
                      {/* )} */}

                      <br />
                      <br />

                      <br />
                      <Button onClick={closeVideo}>Close</Button>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item sm={12} className={classes.mobile}>
                    <br />
                  </Grid>

                  {/* {console.log(trailer)} */}
                  {trailer === null || trailer === "null" ? (
                    ""
                  ) : (
                    <>
                      {/* <p>jlllllll</p> */}
                      <OutlineButton
                        onClick={() => setOpen(true)}
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                        Watch trailer
                      </OutlineButton>
                      <br />
                      <br />

                      {isOpen ? (
                        <>
                          <br />
                          {/* {trailer.includes("youtu") ? ( */}
                          <ReactPlayer
                            url={trailer}
                            controls={true}
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            width="100%"
                            // light={true}
                            // imageUrl={movie.image}
                            // controlsList="nodownload"
                          />
                          {/* // ) : (
                          //   <Player
                          //     playsInline
                          //     poster={movie.cover_image}
                          //     src={trailer}
                          //     fluid
                          //   />
                          // )} */}

                          <br />
                          <br />

                          <Button onClick={() => setOpen(false)}>Close</Button>
                          <br />
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {/* <ModalVideo
                        channel="custom"
                        autoplay
                        isOpen={isOpen}
                        url={trailer}
                        onClose={() => setOpen(false)}
                      />*/}
                    </>
                  )}

                  <div>
                    <OutlineButton
                      onClick={handleClickOpen("paper")}
                      // data-uk-toggle="target: #my-id" type="button"
                      // href="/defaulthoteldetails"
                      className="d-block p-2 lh-32 text-center bg-greylight fw-600 font-xssss text-grey-900 rounded-3"
                    >
                      Add a Review / Comment
                    </OutlineButton>
                    <Dialog
                      open={openComment}
                      onClose={handleClose}
                      scroll={scroll}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                    >
                      <DialogTitle id="scroll-dialog-title">
                        Comments and Feedbacks
                      </DialogTitle>
                      <DialogContent dividers={scroll === "paper"}>
                        <br />
                        <p> {favComments.length} Pinned Comments </p>

                        <FavComment
                          Id={movie.id}
                          url={"movie/comment"}
                          favUrl={`${movie.id}/movie`}
                          movieId={movie.id}
                          adminId={adminId}
                          userId={userId}
                          favComments={favComments}
                          functionProp={() => getMovieDetails()}
                        />
                        <br />
                        <DialogContentText
                          id="scroll-dialog-description"
                          ref={descriptionElementRef}
                          tabIndex={-1}
                        >
                          {comments.map((c, id) => (
                            <CommentComponent
                              comments={c}
                              id={id}
                              url="movie"
                              favUrl={`${movie.id}/movie`}
                              deleteUrl={`comment`}
                              likeUrl={`movie`}
                              adminId={adminId}
                              userId={userId}
                              movieId={movie.id}
                              functionProp={() => getMovieDetails()}
                            />
                          ))}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        {/* <Button onClick={handleClose}>Subscribe</Button> */}
                      </DialogActions>
                    </Dialog>
                  </div>
                  <br />

                  <div style={{ display: "flex" }}>
                    {isLiking ? (
                      <Tooltip title="Like" aria-label="add">
                        <a
                          style={{ border: "#fff solid 2px" }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3"
                        >
                          <HourglassEmptyRoundedIcon
                            style={{ color: "#fff", fontSize: 30 }}
                          />
                        </a>
                      </Tooltip>
                    ) : (
                      <>
                        {likedStatus == "true" ? (
                          <Tooltip title="Unlike" aria-label="add">
                            <>
                              <a
                                style={{ border: "#fff solid 2px" }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3"
                              >
                                <FavoriteRoundedIcon
                                  onClick={() => likeMovie(movie.id)}
                                  style={{ color: "#fff", fontSize: 30 }}
                                />

                                <span>
                                  {" "}
                                  <h3 className="text-white">
                                    <LikersModal
                                      color="#fff"
                                      likers={movie.likers}
                                      text="likes"
                                      type="movie"
                                      class="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss"
                                    />
                                  </h3>{" "}
                                </span>
                              </a>
                            </>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Like" aria-label="add">
                            <>
                              <a
                                style={{ border: "#fff solid 2px" }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3"
                              >
                                <FavoriteBorderRoundedIcon
                                  onClick={() => likeMovie(movie.id)}
                                  style={{ color: "#fff", fontSize: 30 }}
                                />

                                <span>
                                  {" "}
                                  <h3 className="text-white">
                                    <LikersModal
                                      color="#fff"
                                      likers={movie.likers}
                                      text="likes"
                                      type="movie"
                                      class="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss"
                                    />
                                  </h3>{" "}
                                </span>
                              </a>
                            </>
                          </Tooltip>
                        )}
                      </>
                    )}

                    <Tooltip title="Views" aria-label="add">
                      <>
                        <a
                          style={{ border: "#fff solid 2px" }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3"
                        >
                          <i
                            className="feather-eye text-white rounded-full p-2 transition -mr-1"
                            style={{ fontSize: 25 }}
                          ></i>{" "}
                          <span>
                            {" "}
                            <h3 className="text-white font-xssss fw-600">
                              {views} Views
                            </h3>{" "}
                          </span>
                        </a>
                      </>
                    </Tooltip>

                    <Tooltip title="Share Link" aria-label="add">
                      <>
                        <CopyToClipboard
                          // text={window.location.href}
                          text={`https://alarrt.com/signin${window.location.pathname}?${queryParams}`}
                          onCopy={() => setCopied(true)}
                        >
                          <a
                            style={{ border: "#fff solid 2px" }}
                            className="btn-round-lg ms-2 d-inline-block rounded-3"
                          >
                            <i
                              className="feather-share-2 text-white rounded-full p-2 transition -mr-1"
                              style={{ fontSize: 25 }}
                            ></i>{" "}
                          </a>
                        </CopyToClipboard>
                        <br />
                        {copied ? (
                          <>
                            <Snackbar
                              open={copied}
                              autoHideDuration={3000}
                              onClose={closeSnack}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Alert onClose={closeSnack} severity="success">
                                Link Copied Successfully
                              </Alert>
                            </Snackbar>
                          </>
                        ) : null}
                      </>
                    </Tooltip>

                    {/* <ShareMovie /> */}

                    <Tooltip title="Add To Watch List" aria-label="add">
                      <a
                        onClick={() => watchLater(movie.id)}
                        // href="/defaulthoteldetails"
                        style={{ border: "#fff solid 2px" }}
                        className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                      >
                        <i className="feather-bookmark font-sm text-white"></i>{" "}
                      </a>
                    </Tooltip>
                  </div>

                  <div>
                    {saving ? (
                      <>
                        <br />
                        <br />
                        <p className="text-white">
                          {" "}
                          Adding to watch list... This will take a few seconds{" "}
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                    {saved ? (
                      <>
                        <br />
                        <br />
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          action={
                            <>
                              <a
                                onClick={() => setSaved(false)}
                                className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                              >
                                close
                              </a>
                            </>
                          }
                        >
                          You've added this movie to your watch list
                          successfully.
                          <a href={`/profile/${userName}`}>
                            Click here to view it in your profile
                          </a>
                        </Alert>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <a
                    href="/defaulthoteldetails"
                    className="bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-3 d-inline-block mt-0 p-2 lh-34 text-center ls-3 w200"
                  >
                    BUY NOW
                  </a> */}
                  {/* </div> */}
                </div>

                {/* <a
                 onClick={() => alert("comments pops up here")}
                className="d-block p-2 lh-32 text-center bg-greylight fw-600 font-xssss text-grey-900 rounded-3"
              >
                Add a Review / Comment
              </a> */}

                <div className="cast">
                  <div className="section__header">
                    <h2>Casts</h2>
                  </div>
                  <CastList id={"297761"} casts={casts} />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="section mb-3">
                {/* show other movies of auther here */}
                <VideoList id={"297761"} />
              </div>
              <div className="section mb-3">
                <div className="section__header mb-2">
                  <h1>Similar Movies</h1>
                </div>
                {/* get and show movies that belog to this gener or tag */}
                <MovieList
                 heading="Watch Series In Channel"
                link={`channels/${movie.channel_id}/series`}
                movies={latestMovies}
                seriesLink='true'

                  // category={category}
                  // type="similar"
                  // id={297761}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
};

export default Detail;
