import React, { Component, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import axiosFetch from '../../config/Interceptors';
import Load from "./Load";
import ShowMoreText from "react-show-more-text";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import MuiAlert from "@material-ui/lab/Alert";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";

export default function Profiledetail(props) {
  const [isFollowing, setIsFollowing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const userName = window.localStorage.getItem("user_name");
  const [showSocials, setShowSocials] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [copied, setCopied] = useState(false);
  function closeSnack() {
    setCopied(false);
  }

  function executeOnClick(isExpanded) {
    // console.log(isExpanded);
  }
  function toggleSocials() {
    showSocials == false ? setShowSocials(true) : setShowSocials(false);
  }

  const handleClose = () => {
    // setOpen(false);
    setConfirmDelete(false);
    // setEmptyFields(false);
  };
  function confirmDeletion(id) {
    if (confirmDelete == true) {
      setConfirmDelete(false);
      setDeleteId("");
    } else {
      setConfirmDelete(true);
      setDeleteId(id);
    }
  }

  async function removeCat(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .delete(
        `/post_category/delete/${id}`,
        // id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        setIsDeleting(false);
        setConfirmDelete(false);
        props.functionProp();
        //   props.functionProp();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }
  // render() {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">Short Bio</h4>
        {/* <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{props.profile.bio}</p> */}
        {/* <ShowMoreText
         
          lines={3}
          more="Read more"
          less="Show less"
          className="fw-500 text-black-900 lh-26 font-xssss w-100 mb-2"
          // className="content-css"
          anchorClass="my-anchor-css-class"
          onClick={executeOnClick}
          expanded={false}
          // width={480}
          truncatedEndingComponent={"... "}
        >
          {props.profile.bio}
        </ShowMoreText> */}
        <p style={{color: 'black'}}> {props.profile.bio}</p>
      </div>

      <div className="card-body d-flex pt-0">
        <i className="feather-users text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          Social Networks
        </h4>
      </div>

      <div className="card-body border-top-xs d-flex">
        <i className="feather-zap text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          <span style={{ paddingRight: 20 }}> Alarrt </span>
          <CopyToClipboard
            text={`${process.env.REACT_APP_URL}/profile/${props.user_name}`}
            onCopy={() => setCopied(true)}
          >
            <button>Copy Here</button>
          </CopyToClipboard>
          {/* <a href={`https://alarrt.com/profile/${userName}`} target='_blank'> */}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            https://chillarx.com/profile/{props.user_name}
          </span>
          {/* </a> */}
        </h4>
      </div>

      {props.socials == null ? (
        ""
      ) : (
        <>
          {/* // ""
      )} */}
          {props.socials.instagram ? (
            <div className="card-body border-top-xs d-flex">
              <i className="feather-instagram text-grey-500 me-3 font-lg"></i>
              <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                <span style={{ paddingRight: 20 }}> Instagram </span>
                <CopyToClipboard
                  text={props.socials.instagram}
                  onCopy={() => setCopied(true)}
                >
                  <button>Copy Here</button>
                </CopyToClipboard>

                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {props.socials.instagram}
                </span>
              </h4>
            </div>
          ) : (
            ""
          )}
          <Button onClick={toggleSocials}>Toogle Socials</Button>

          {showSocials ? (
            <>
              {props.socials.linkedIn ? (
                <div className="card-body border-top-xs d-flex">
                  <i className="feather-linkedin text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                    <span style={{ paddingRight: 20 }}> LinkedIn </span>
                    <CopyToClipboard
                      text={props.socials.linkedIn}
                      onCopy={() => setCopied(true)}
                    >
                      <button>Copy Here</button>
                    </CopyToClipboard>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {props.socials.linkedIn}
                    </span>
                  </h4>
                </div>
              ) : (
                ""
              )}
              {props.socials.youtube ? (
                <div className="card-body border-top-xs d-flex">
                  <i className="feather-youtube text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                    <span style={{ paddingRight: 20 }}> Youtube </span>
                    <CopyToClipboard
                      text={props.socials.youtube}
                      onCopy={() => setCopied(true)}
                    >
                      <button>Copy Here</button>
                    </CopyToClipboard>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {props.socials.youtube}
                    </span>
                  </h4>
                </div>
              ) : (
                ""
              )}
              {props.socials.facebook ? (
                <div className="card-body border-top-xs d-flex">
                  <i className="feather-facebook text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                    <span style={{ paddingRight: 20 }}> Facebook </span>
                    <CopyToClipboard
                      text={props.socials.facebook}
                      onCopy={() => setCopied(true)}
                    >
                      <button>Copy Here</button>
                    </CopyToClipboard>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {props.socials.facebook}
                    </span>
                  </h4>
                </div>
              ) : (
                ""
              )}

              {props.socials.whatsapp ? (
                <div className="card-body border-top-xs d-flex">
                  <i className="feather-message-circle text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                    <span style={{ paddingRight: 20 }}> Whatsapp </span>{" "}
                    <CopyToClipboard
                      text={props.socials.whatsapp}
                      onCopy={() => setCopied(true)}
                    >
                      <button>Copy Here</button>
                    </CopyToClipboard>
                    {/* <a href={props.socials.instagram} target='_blank'> */}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {props.socials.whatsapp}
                    </span>
                    {/* </a> */}
                  </h4>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}

      {copied ? (
        <>
          <Snackbar
            open={copied}
            autoHideDuration={3000}
            onClose={closeSnack}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={closeSnack} severity="success">
              Copied Successfully
            </Alert>
          </Snackbar>
        </>
      ) : null}

      {/* <div className="card-body d-flex pt-0">
        <i className="feather-eye text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          Visible{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            Anyone can find you
          </span>
        </h4>
      </div> */}
      {props.isLoggedInUser ? (
        <div className="card-body d-flex pt-0">
          <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
          <h4 className="fw-700 text-grey-900 font-xssss mt-1">
            Location:
            {!props.profile.state && props.profile.country === null ? (
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                Update Your Location In Your Profile Settings
              </span>
            ) : (
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                {props.profile.state}, {props.profile.country}
              </span>
            )}
          </h4>
        </div>
      ) : (
        <div className="card-body d-flex pt-0">
          <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
          <h4 className="fw-700 text-grey-900 font-xssss mt-1">
            Location:
            {!props.profile.state && props.profile.country === null ? (
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                Location Not Set Yet
              </span>
            ) : (
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                {props.profile.state}, {props.profile.country}
              </span>
            )}
          </h4>
        </div>
      )}

    </div>
  );
  // }
}

// export default Profiledetail;
