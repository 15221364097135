import React from 'react';
import  { Component, useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, useHistory, useLocation  } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

export default function ProtectedRoute2(props) {
   const history = useHistory();
   const location = useLocation();
   const pathname = location.pathname


  useEffect(() => {
    // console.log(`route ${pathname}`);
    if(!window.localStorage.getItem("access_token") ){
      history.push("/welcome");
      history.go()
      // history.push("/signin");
    }
    if(pathname.includes('/verify') ){
      const verLink = pathname.slice(8)
      window.localStorage.setItem("verification_link", verLink);
      // window.localStorage.setItem("verification_link")
      // alert(pathname.slice(8))
      history.push('/complete_verification');
      history.go()
    
    }  // else{
    //   history.push("/signin");
    // http://localhost:3000/verify/http://alert-lumen-api.herokuapp.com/api/verify-email/45/9261935fe143033d000397d6090d0f9708db5ffa?expires=1645624065&signature=a68569bbdbc20a483a5772c786103806742b42556775a843b93f931afddfa935

  },[])
  let Cmp = props.Cmp
  let Cmp2 = props.Cmp2
  let Cmp3 = props.Cmp3
  return (
      <div>
      <Cmp />
      <Cmp2 />
      <Cmp3 />
      </div>
  );
}