import React, { Component, Fragment } from "react";

import { format, formatDistance, subDays } from "date-fns";

import { Route, useParams, Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
// import MovieGrid from "../movie-grid/MovieGrid";
import MovieGrid from "../../movie-grid/MovieGrid";
import SeriesGrid from "../../movie-grid/SeriesGrid";
import SeriesCardMap from "../../movie-card/SeriesCardMap";
import MovieCardMap from "../../movie-card/MovieCardMap";
import Button from "../button/Button";
import IconButton from "@mui/material/IconButton";

export default function UserSavedMovies(props) {
  let history = useHistory();
  const [fetchingChannels, setFetchingChannels] = useState("");
  const [channels, setChannels] = useState([]);
  // const [url, setUrl] = useState('');
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );
  // const url = '';

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    // getAllCahnnels();
  }, []);

  if (props.movies.length == 0 || props.series.length == 0 ) {
    return (
      <>
        <Fragment>
          {/* <Header /> */}
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}
          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
            <h3 className="text-center text-grey-900"> Contents You've Saved For Later</h3> <br/>
              <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello! You Haven't Saved Any At The Moment
              </h5>
            </div>
          </div>
          {/* <Popupchat /> */}
          {/* <Appfooter /> */}
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>
        {/* <Header /> */}
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div
          className="row ps-2 pe-1"
          style={{ marginRight: 20, marginLeft: 20, marginBottom: 50 }}
        >
          <div
            className="row ps-2 pe-1"
            style={{ marginRight: 20, marginLeft: 20 }}
          >
            <h4 className="text-center" style={{ color: "#fff" }}>
              {" "}
              You have saved {props.series.length} series saved for later{" "}
            </h4>
            <br/><br/>
            <SeriesGrid series= {props.series} />
           
          </div>
         
          <br />

          <h4 className="text-center" style={{ color: "#fff" }}>
            {" "}
            You have saved {props.movies.length} movies for later{" "}
          </h4><br/><br/>
          <MovieGrid movies={props.movies} />
         
        </div>

        <br />

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  }
}

// export default Channels;
