import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";

import Load from "../../components/others/Load";
import { Button } from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";
import { Select } from "antd";

export default function ChannelUpdate() {
  const userId = useState(window.localStorage.getItem("user_id"));
  const [isCreating, setIsCreating] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [channelDes, setChannelDes] = useState("");
  const [channelStatus, setChannelStatus] = useState("");
  const [channelKey, setChannelKey] = useState("");
  const [channelType, setChannelType] = useState("");
  const [channelPrice, setChannelPrice] = useState("");
  const [channelCover, setChannelCover] = useState("");
  const [channelPic, setChannelPic] = useState("");
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const { slug } = useParams(["slug"]);
  const [value, setValue] = React.useState("1");
  const location = useLocation();
  const adminDetails = location.state.adminDetails.id;
  const channelId = location.state.channel.id;
  const channel = location.state.channel;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setEmptyEmail(false);
    setIsSuccess(false);
    setEmptyFields(false);
    // setOpenAlert(false);
  };

  function closeError() {
    setError(false);
    console.log("closed");
  }

  function changeType(value) {
    console.log(`type ${value}`);
    setChannelType(value);
  }
  function changeStatus(value) {
    console.log(`status ${value}`);
    setChannelStatus(value);
  }

  function redirect() {
    setIsSuccess(false);
    history.push("/channels");
  }

  async function validate() {
    console.log(`channelName ${channelName}`);
    if (!channelName) {
      setEmptyFields(true);
      return;
    } else {
      createChannel();
    }
  }

  async function validateProfileImage(value) {
    if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setInvalidFile("Please select valid image.");
      return false;
    } else {
      console.log(value);
      setChannelPic(value);
      setInvalidFile(false);
    }
  }

  async function validateCoverImage(value) {
    if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setInvalidFile("Please select valid image.");
      return false;
    } else {
      console.log(value);
      setChannelCover(value);
      setInvalidFile(false);
    }
  }

  async function createChannel() {
    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    // formData.append("user_id", userID);
    formData.append("name", channelName);
    formData.append("description", channelDes);
    formData.append("status", "public");
    formData.append("cover_pic", channelCover);
    formData.append("channel_pic", channelPic);
    formData.append("public_key", channelKey);
    formData.append("type", channelType);
    formData.append("price", channelPrice);
    formData.append("status", channelStatus);

    axiosFetch
      .post(
        `/channels/update/${channelId}`,
        formData,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        if (resp.data.status == "success") {
          console.log(`hi ${resp.data}`);
          setChannelName("");
          setChannelDes("");
          setChannelStatus("");
          setChannelCover("");
          setChannelPic("");
          setChannelKey("");
          setChannelPrice("");
          setIsCreating(false);
          console.log(`response ${JSON.stringify(resp.data)}`);
          setIsSuccess(true);
        } else {
          setIsCreating(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }
  const { Option } = Select;
  const types = ["free", "paid"];
  const statusSelect = [
    { value: "public", label: "Publish" },
    { value: "private", label: "Draft" },
  ];

  function guard() {
    if (userId[0] == adminDetails) {
      console.log("yess");
    } else {
      console.log("nooo");
    }
  }

  function guard2() {
    if (adminDetails == "") {
      history.push("/");
    }
  }

  useEffect(() => {
    console.log(channelId);
    //  guard2();
    guard();
  }, []);

  return (
    <Fragment>
 

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  {/* <Button onClick={() => history.goBack()} className="d-inline-block mt-2">
                    <i className="ti-arrow-left font-sm text-white"></i>
                  </Button> */}
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                    Update Channel {channel.name}
                  </h4>
                </div>

                <div className="card-body mt-1 p-lg-5 p-4 w-100 border-0 ">
                  <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                    {channelCover ? (
                      <img
                        src={URL.createObjectURL(channelCover)}
                        alt="avater"
                        style={{
                          maxWidth: 1450,
                          maxHeight: 450,
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <img
                        src={channel.cover_pic}
                        alt="avater"
                        style={{
                          maxWidth: 1450,
                          maxHeight: 450,
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </div>
                  <div className="card-body p-0 position-relative">
                    {channelPic ? (
                      //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                      <>
                        {/* <p> Your Channel Cover Picture </p> <br /> */}
                        <figure
                          className="avatar position-absolute w100 z-index-1"
                          style={{ top: "-40px" }}
                        >
                          <img
                            src={URL.createObjectURL(channelPic)}
                            alt="avater"
                            className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                          />
                        </figure>
                      </>
                    ) : (
                      <figure
                        className="avatar position-absolute w100 z-index-1"
                        style={{ top: "-40px" }}
                      >
                        <img
                          src={channel.channel_pic}
                          alt="avater"
                          className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                        />
                      </figure>
                    )}

                    <h4 className="fw-700 font-sm mt-2 pl-15">
                      {/* First Name{" "}
                        Last name */}
                      <span className="fw-500 font-xssss text-primary mt-1 mb-3 d-block">
                        {/* @User Name */}
                      </span>
                    </h4>
                  </div>
                  <div className="card-body mt-5 p-lg-5 p-4 w-100 border-0 ">
                    {/* <div className="row justify-content-center">
                    <div className="col-lg-4 text-center">
                      <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                        <img
                          src="https://via.placeholder.com/300x300.png"
                          alt="avater"
                          className="shadow-sm rounded-3 w-100"
                        />
                      </figure>

                    </div>
                  </div> */}
                    <br />
                    <b>
                      <h4>
                        Hello {channel.admin.first_name} you don't have to edit
                        all. Only edit what needs to be changed and click update{" "}
                      </h4>
                    </b>

                    <form>
                      <div className="row">
                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <br />
                            <br />
                            <label className="mont-font fw-600 font-xsss mb-2">
                              Channel Name
                            </label>
                            {/* value={this.state.first_name} */}
                            <input
                              type="text"
                              defaultValue={channel.name}
                              onChange={(e) => setChannelName(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 mb-3">
                          <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                            What is Your Channel About?
                          </label>
                          <textarea
                            className="form-control mb-0 p-3 h100 bg-greylight lh-16"
                            rows="5"
                            defaultValue={channel.description}
                            onChange={(e) => setChannelDes(e.target.value)}
                            placeholder="Let subscribers know a bit about your contents..."
                          ></textarea>
                        </div>

                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Your Paystack Public Key (Live / Test)
                          </label>
                          <p>
                            {" "}
                            Create an account on paystack to start monetizing
                            your content. To recieve real payments, you are
                            advised to use your live key{" "}
                          </p>
                          {/* value={this.state.first_name} */}
                          <input
                            type="text"
                            defaultValue={channel.public_key}
                            onChange={(e) => setChannelKey(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <br />
                            <label className="mont-font fw-600 font-xsss mb-2">
                              Select Channel Pricing Type - Can be changed later
                            </label>
                            <p>
                              Select paid if you want monetize your content and
                              gain extra income. Subscribers to pay a fee before
                              subscribing
                            </p>
                            <Select
                              // labelId="demo-simple-select-label"
                              // id="demo-simple-select"
                              // mode="tags"
                              defaultValue={channel.type}
                              value={channelType}
                              label="Select One"
                              size="large"
                              style={{ width: "90%" }}
                              onChange={changeType}
                              defaultInputValue={channel.type}
                            >
                              {types.map((type) => (
                                <Option key={type} value={type}>
                                  {" "}
                                  {type}{" "}
                                </Option>
                              ))}
                            </Select>
                            <br />
                            <p>Pricing currently set to {channel.type}</p>
                          </div>
                        </div>
                      </div>

                      {channelType == "paid" ? (
                        <div className="form-group">
                          <br />
                          <label className="mont-font fw-600 font-xsss mb-2">
                            You selected paid. Update your subscription fee
                          </label>
                          <p>
                            This is a one time payment fee. It grants
                            subscribers access to contents for subscribers only
                          </p>
                          {/* value={this.state.first_name} */}
                          <input
                            type="text"
                            value={channelPrice}
                            // defaultValue='N500'
                            onChange={(e) => setChannelPrice(e.target.value)}
                            className="form-control"
                          />
                          <br />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="row">
                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2">
                              Select Channel Published Status
                            </label>
                            <p>
                              {" "}
                              Draft channels can be seen only by you in your
                              profile. They aren't visible in the channels page
                            </p>
                            <Select
                              // labelId="demo-simple-select-label"
                              // id="demo-simple-select"
                              // mode="tags"
                              defaultValue={channel.type}
                              value={channelStatus}
                              label="Select One"
                              size="large"
                              style={{ width: "90%" }}
                              onChange={changeStatus}
                              defaultInputValue={channel.status}
                            >
                              {statusSelect.map((status) => (
                                <Option key={status.value} value={status.value}>
                                  {" "}
                                  {status.label}{" "}
                                </Option>
                              ))}
                            </Select>
                            <br />
                            <p>Pricing currently set to {channel.status}</p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row"> */}

                      <div className="col-lg-12 mb-3">
                        {/* </div> */}

                        {channelPic ? (
                          //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                          <>
                            <p> Your Channel Profile Picture </p> <br />
                            <img
                              src={URL.createObjectURL(channelPic)}
                              alt="avater"
                              style={{
                                // width: 1450,
                                // maxWidth: 1450,
                                maxHeight: 450,
                                objectFit: "contain",
                                //    borderRadius: '10%'
                              }}
                            />
                            <Button onClick={() => setChannelPic("")}>
                              Remove
                            </Button>
                            <br />
                            <br />
                          </>
                        ) : (
                          //  </div>
                          <div className="card mt-3 border-0">
                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                              <div className="form-group mb-0 w-100">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="file-2"
                                  id="file-2"
                                  className="input-file"
                                  onChange={(e) =>
                                    validateProfileImage(e.target.files[0])
                                  }
                                />
                                <label
                                  htmlFor="file-2"
                                  className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                >
                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                  <span className="js-fileName">
                                    Drag and drop or click to replace your
                                    channel profile picture
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        )}

                        {invalidFile ? <h4> {invalidFile} </h4> : ""}
                      </div>

                      {invalidFile ? <h4> {invalidFile} </h4> : ""}

                      <div>
                        {channelCover ? (
                          //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                          <>
                            <p> Your Channel Cover Picture </p> <br />
                            <img
                              src={URL.createObjectURL(channelCover)}
                              alt="avater"
                              style={{
                                // width: 1450,
                                // maxWidth: 1450,
                                maxHeight: 450,
                                objectFit: "contain",
                                //    borderRadius: '10%'
                              }}
                            />
                            <Button onClick={() => setChannelCover("")}>
                              Remove
                            </Button>
                            {/* //  </div> */}
                          </>
                        ) : (
                          <div className="col-lg-12 mb-3">
                            <div className="card mt-3 border-0">
                              <div className="card-body d-flex justify-content-between align-items-end p-0">
                                <div className="form-group mb-0 w-100">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    name="file"
                                    id="file"
                                    className="input-file"
                                    onChange={(e) =>
                                      validateCoverImage(e.target.files[0])
                                    }
                                    // onChange={(e) =>
                                    //     console.log(`test ${e.target.files[0]}`)}
                                  />
                                  <label
                                    htmlFor="file"
                                    className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                  >
                                    <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                    <span className="js-fileName">
                                      Drag and drop or click to upload your
                                      channel cover picture
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-lg-12">
                        {isCreating ? (
                          <Button disabled variant="contained" color="primary">
                            Updating Your Channel
                          </Button>
                        ) : (
                          // <a className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" disabled>Updating Your Profile</a> */}
                          <a
                            onClick={validate}
                            className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                          >
                            Update Channel
                          </a>
                        )}
                        <br />
                        <br />
                        <br />
                        {emptyFields ? (
                          <>
                            {/* <br />
                          <br /> */}
                            <Alert onClose={handleClose} severity="warning">
                              To create a channel, you need to ensure it has at
                              least a name
                            </Alert>
                          </>
                        ) : (
                          ""
                        )}

                        {error ? (
                          <div>
                            <Alert
                              severity="error"
                              onClose={handleClose}
                              action={
                                <>
                                  <CancelIcon onClick={closeError} />
                                </>
                              }
                            >
                              There was an issue creating your channel. Kindly
                              try again [ {errorMessage} ]
                            </Alert>
                            {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                            <br />
                          </div>
                        ) : (
                          ""
                        )}

                        {isSuccess ? (
                          <Alert
                            onClose={handleClose}
                            variant="filled"
                            severity="success"
                            action={
                              
                              <Button
                               onClick={() => history.goBack()}
                                // onClick={history.goBack}
                                style={{ color: "#fff", alignSelf: "center" }}
                              >
                                {" "}
                                Check It Out
                              </Button>
                            }
                          >
                            Your channel was updated sucessfully!
                          </Alert>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      {/* <Appfooter /> */}
    </Fragment>
  );
}

// export default CreateChannel;
