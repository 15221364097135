import "./channel.scss";

import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";

import { Link } from "react-router-dom";
import { useState } from "react";
import Datatable from "../../components/studio/datatable/Datatable";
import ChannelsComp from "../../components/studio/channel/ChannelsComp";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import { Button } from "@mui/material";
import React, { useEffect, useRef } from "react";

const Channels = () => {

  useEffect(() => {
    window.localStorage.setItem('can_update', 'yes')

  }, []);

 
  return (
    <div className="list" style={{ backgroundColor: "#fff" }}>
      {/* <Sidebar /> */}
      <HeaderV2 />
      <div className="listContainer">
        {/* <Navbar /> */}
        {/* <div className="datatable"> */}
        {/* <div className="top" style={{ color: "#000" }}>
          <h1>Your Channels</h1>
        </div>

        <div
          className="datatableTitle"
          style={{ color: "#000", marginBottom: 20 }}
        >
          Organized Your Contents
          <Link
            to="/channel/add/new"
            className="link"
            style={{
              textDecoration: "none",
              color: "green",
              fontSize: "16px",
              fontWeight: "400",
              border: "1px solid green",
              padding: "5px",
              borderRadius: "5px",
              cursor: "pointer",
              marginLeft: 30,
            }}
          >
            Add New
          </Link>
        </div> */}

        {/* </div> */}
        {/* <div className="top main-content" style={{ color: "#000" }}>
          <h3 className='text-center'>Your Channels On Chillarx</h3>
        </div> */}
     
        <ChannelsComp link={`/users/my/channels`} title='Your Channels On Chillarx' />
        {/* <Datatable/> */}
      </div>
    </div>
  );
};

export default Channels;
