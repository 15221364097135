import React, { useRef, useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router";

import "./header.scss";

import logo from "../../assets/tmovie.png";

const accessToken = window.localStorage.getItem("access_token");

const headerNav = [
  {
    display: "Home",
    path: "/",
  },
  {
    display: "Movies",
    path: "/chillarx/movies",
  },
  {
    display: "Series",
    path: "/chillarx/series",
  },
  {
    display: "Account",
    path: "/dashboard",
  },
];

const guestNav = [
  {
    display: "Welcome",
    path: "/welcome",
  },
  {
    display: "Sign In",
    path: "/signin",
  },
  {
    display: "Sign Up",
    path: "/signup",
  },
  // {
  //     display: 'Download App',
  //     path: 'https://play.google.com/store/apps/details?id=com.its_soa.ChillarxDemo',
  //     target:'_blank'
  // },
];

const Header = () => {
  const { pathname } = useLocation();
  const headerRef = useRef(null);
  const history = useHistory();
  const accessToken = window.localStorage.getItem("access_token");
  // const [active, setActive] = useState({});
  const active = headerNav.findIndex((e) => e.path === pathname);
  const guestActive = headerNav.findIndex((e) => e.path === pathname);

  if (!accessToken) {
    const headerNav = [
      {
        display: "Home",
        path: "/",
      },
      {
        display: "Movies",
        path: "/chillarx/movies",
      },
      {
        display: "Series",
        path: "/chillarx/series",
      },

      {
        display: "Login",
        path: "/login",
      },
    ];
  } else {
    const headerNav = [
      {
        display: "Home",
        path: "/",
      },
      {
        display: "Movies",
        path: "/chillarx/movies",
      },
      {
        display: "Series",
        path: "/chillarx/series",
      },

      {
        display: "Account",
        path: "/dashboard",
      },
    ];
  }

  // const active = headerNav.findIndex(e => e.path === pathname);

  useEffect(() => {
    const accessToken = window.localStorage.getItem("access_token");

    if (!accessToken) {
      const headerNav = [
        {
          display: "Home",
          path: "/",
        },
        {
          display: "Movies",
          path: "/chillarx/movies",
        },
        {
          display: "Series",
          path: "/chillarx/series",
        },

        {
          display: "Login",
          path: "/login",
        },
      ];
    } else {
      const headerNav = [
        {
          display: "Home",
          path: "/",
        },
        {
          display: "Movies",
          path: "/chillarx/movies",
        },
        {
          display: "Series",
          path: "/chillarx/series",
        },

        {
          display: "Account",
          path: "/dashboard",
        },
      ];
    }

    const shrinkHeader = () => {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        headerRef.current.classList.add("shrink");
      } else {
        headerRef.current.classList.remove("shrink");
      }
    };
    window.addEventListener("scroll", shrinkHeader);
    return () => {
      window.removeEventListener("scroll", shrinkHeader);
    };
  }, []);

  return (
    <div ref={headerRef} className="header">
      <div className="header__wrap container">
        <div className="logo">
          <img src={logo} alt="" />
          <Link to="/" style={{ color: "#fff" }}>
            Chillarrx!
          </Link>
        </div>
        {accessToken ? (
          <ul className="header__nav">
            {headerNav.map((e, i) => (
              <li
                key={i}
                style={{ color: "#fff" }}
                className={`${i === active ? "active" : ""}`}
              >
                <Link
                  to={e.path}
                  onClick={() => {
                    history.push({
                      pathname: e.path,
                    });
                    history.go();
                    // window.location.reload();
                  }}
                >
                  {e.display}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <ul className="header__nav">
            {guestNav.map((e, i) => (
              <li
                key={i}
                style={{ color: "#fff" }}
                className={`${i === active ? "active" : ""}`}
              >
                <Link
                  to={e.path}
                  onClick={() => {
                    history.push({
                      pathname: e.path,
                      //   target: e.target
                    });
                    history.go();
                    // window.location.reload();
                  }}
                >
                  {e.display}
                </Link>
              </li>
            ))}
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.its_soa.ChillarxDemo"
                target="_blank"
                style={{ color: "#fff" }}
                // className={`${i === active ? "active" : ""}`}
              >
                {" "}
                Download App
              </a>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Header;
