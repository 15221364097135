import React, { Component, Fragment } from "react";

import { Route, useParams, Link } from "react-router-dom";
import ImageComponentDiv from "../../others/ImageComponentDiv";
import ImageComponent from "../../others/ImageComponent";
import Load from "../../others/Load";
import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import { Button } from "@mui/material";

export default function ChannelComp2(props) {
  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function subscribeToogle(id) {
    // console.log(user_name);
    const access_token = await window.localStorage.getItem("access_token");
    const userName = await window.localStorage.getItem("user_name");
    let result = await axiosFetch
      .post(`/channels/${id}/subscribe`, userName, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // getAllChannels();
        props.functionProp();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  //       if (props.subscriptions.length == 0) {
  //             return <Load />
  //       }
  // else{
  return (
    //   <div>
    <>
      {/* <Button
        href="/create_channel"
        // fullWidth
        variant="contained"
        color="primary"
      >
        Create Channel{" "}
      </Button>{" "}
      <br /> */}
      {props.subscriptions.map((subscription, id) => (
        <div className="col-md-6 col-sm-6 pe-2 ps-2">
          <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
            {subscription.cover_pic === null ? (
              <div
                className="card-body position-relative h100 bg-image-cover bg-image-center"
                style={{
                  backgroundImage: `url('https://alert-app-v1.s3.amazonaws.com/user.png')`,
                }}
                onError={fixImage}
              ></div>
            ) : (
              <ImageComponentDiv
                onError={fixImage}
                image={subscription.cover_pic}
                alt="avater"
                class="card-body position-relative h100 bg-image-cover bg-image-center"
              />
            )}
            <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
              <figure
                className="avatar position-absolute w75 z-index-1 left-15"
                style={{
                  marginTop: `-40px`,
                  marginLeft: "-10px",
                }}
              >
                {subscription.channel_pic === null ? (
                  <img
                    onError={fixImage}
                    src={"https://alert-app-v1.s3.amazonaws.com/user.png"}
                    alt="avater"
                    className="float-right p-1 bg-white rounded-circle w-100 "
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <ImageComponent
                    // onError={fixImage}
                    image={subscription.channel_pic}
                    alt="avater"
                    class="float-right p-1 bg-white rounded-circle w-100 "
                  />
                )}
              </figure>

              <div className="clearfix"></div>

              <Link
                to={{
                  pathname: `/channel/${subscription.slug}`,
                  state: { channelId: subscription.id },
                }}
              >
                <h4 className="fw-700 font-xss mt-3 mb-0">
                  Channel: {subscription.name}{" "}
                </h4>
              </Link>
              <Link
              //  to={`/profile/${subscription.admin.user_name}`}
              >
                {" "}
                <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                  Created By:{" "}
                  <span className="text-primary-500">
                    {subscription.admin.user_name}
                  </span>{" "}
                </p>{" "}
              </Link>

              {/* {channel.type !== "free" ? ( */}
              <h4 className="fw-600 font-sm">
                <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                  Channel Subscription is {subscription.type}. Click button to
                  proceed
                </span>
              </h4>
              {/* ) : (
                          ""
                        )} */}
              {/* <br />
              <br />
              <br />
              <br />
              <br /> */}
              <div
                style={{ marginTop: 2, marginLeft: "-40px" }}
                className="d-flex align-items-center"
                // className="position-absolute right-5 top-10 d-flex align-items-center"
              >
                <ul className="d-flex align-items-center justify-content-center mt-1">
                  <li className="m-2">
                    <h4 className="fw-600 font-sm">
                      {subscription.subscribers_count}
                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                        Subscribers
                      </span>
                    </h4>
                  </li>
                  <li className="m-2">
                    <h4 className="fw-600 font-sm">
                      {subscription.videos_count}
                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                        Videos
                      </span>
                    </h4>
                  </li>
                  <li className="m-2">
                    <h4 className="fw-600 font-sm">
                      {subscription.movies_count}
                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                        Movies
                      </span>
                    </h4>
                  </li>
                  <li className="m-2">
                    <h4 className="fw-600 font-sm">
                      {subscription.comics_count}
                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                        Comics
                      </span>
                    </h4>
                  </li>
                  <li className="m-2">
                    <h4 className="fw-600 font-sm">
                      {subscription.series_count}
                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                        Series
                      </span>
                    </h4>
                  </li>
                </ul>
              </div>

              {/* <br/>
              <div
                style={{ marginTop: "-15px", marginLeft: "-10px" }}
                // className="position-absolute right-15 top-10 d-flex align-items-center"
              >
                <Link
                  to={{
                    pathname: `/channel/${subscription.slug}`,
                    state: { channelId: subscription.id },
                  }}
                >
                  <br/><br/>
                  <Button
                    // href="/create_channel"
                    // fullWidth
                    variant="contained"
                    color="primary"
                  >
                   VIEW CHANNEL
                  </Button>
                  {/* <a
                // onClick={() => subscribeToogle(subscription.id)}
                style={{ alignSelf: "center" }}
                className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
              >
               VIEW
              </a> */}
              {/* </Link> */}
            </div>
          </div>
        </div>
        // </div>
      ))}
    </>
    // </div>
  );
  // }
}
