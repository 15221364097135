import React, { Component, Fragment } from "react";
// import Header from "../components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
import Alert from "@mui/material/Alert";
import { Button } from "@material-ui/core";
// import ImageComponent from "../components/ImageComponent";
import ImageComponent from "../../components/others/ImageComponent";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";

export default function Verification() {
  const profile_pic = window.localStorage.getItem("profile_pic");
  const first_name = window.localStorage.getItem("first_name");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [response, setResponse] = useState(false);
  const verified = window.localStorage.getItem("verified")

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setResponse(false);
    setMessage("");
  }

  async function sendVerification() {
    setSending(true);
    const access_token = await window.localStorage.getItem("access_token");

    let result = await axiosFetch
      .post(
        "/email/verification-notification",
        access_token,
        {
          headers: {
            Accept: "*/*",
            // 'Content-Type': 'multipart/form-data',
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        setResponse(true);
        setMessage(resp.data.message);
        setSending(false);
        // window.location.reload()
      })
      .catch((error) => {
        setSending(false);
        console.log(error);
        setResponse(true);
        setMessage(error.message);
      });
  }

  // render() {
  return (
    <Fragment>
      <HeaderV2 />
      <br />
      <br />
      <div className="main-content pt-0 bg-white ps-0 pe-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 text-center default-page vh-140 align-items-center d-flex">
              <div className="card border-0 text-center d-block p-0">
                <br /> <br />
                <ImageComponent
                  // onError={fixImage}
                  // image={profile_pic}
                  image="/assets/images/verification.png"
                  alt="avater"
                  class="w200 mb-4 ms-auto rounded-circle me-auto pt-md-5"
                />
                {/* <img
                  src={profile_pic}
                  alt="icon"
                  className="w200 mb-4 ms-auto rounded-circle me-auto pt-md-5"
                /> */}
                <h3 className="fw-700 text-grey-900 display3-size display4-md-size">
                  Verify Your Email {first_name}
                </h3>
                <p className="text-grey-500 font-xsss">
                  It is important you verify your email. Only verified emails
                  can have access to restricted resources and actions on Alarrt
                </p>

                {verified !== "null" ?
                <Button
                disabled
                variant="contained"
                color="primary"
                // onClick={this.createPost}
              >
                Congrats! You Are Verified
                
              </Button>
              :
               <>

                {sending ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    // onClick={this.createPost}
                  >
                    SENDING TO YOUR MAIL
                  </Button>
                ) : (
                  // {/* <a className="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3">SENDING TO YOUR MAIL</a> */}
                  <Button
                    onClick={sendVerification}
                    variant="contained"
                    color="primary"
                    // className="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3"
                  >
                    SEND LINK TO EMAIL
                    </Button>
                )}
                <br />
                <br />
                {response ? (
                  <>
                    <br />
                    <Alert
                      onClose={handleClose}
                      variant="filled"
                      severity="success"
                    >
                      {message}
                    </Alert>
                    <br/><br/>
                  </>
                ) : (
                  ""
                )}

                </>

}
                <br/><br/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
  // }
}

// export default Verification;
