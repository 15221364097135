import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import PageHeader from "../../components/page-header/PageHeader";
import axiosFetch from "../../config/Interceptors";
// import { category as cate } from '../api/tmdbApi';
import MovieGrid from "../../components/movie-grid/MovieGrid";
import SeriesGrid from "../../components/movie-grid/SeriesGrid";
import MovieList from "../../components/movie-list/MovieList";
import SeriesList from "../../components/movie-list/SeriesList";
import { OutlineButton } from "../../components/button/Button";
import { Link } from "react-router-dom";

const SearchCatalog = () => {
  const { category } = useParams(["content"]);
  const contentType = useParams();
  const [movieItems, setMovieItems] = useState([]);
  const [seriesItems, setSeriesItems] = useState([]);
  const [latestMovies, setLatestMovies] = useState([]);

  const [freeMovies, setFreeMovies] = useState([]);
  const [freeSeries, setFreeSeries] = useState([]);
  const [movies, setMovies] = useState([]);
  const [comics, setComics] = useState([]);
  const [trendingSeries, setTrendingSeries] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [series, setSeries] = useState([]);

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(50);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [fecthingMovies, setFetchingMovies] = useState(false);

  async function searchMoviesCatalogue() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movie/search/find/${contentType.keyword}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setMovieItems(resp.data.movies);
        setSeriesItems(resp.data.series);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function searchSeriesCatalogue() {
    // setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movie/series/search/find/${contentType.keyword}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setSeriesItems(resp.data);
        // setFetchingMovies(false);
      })
      .catch((error) => {
        // setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getMoreMoviesCatalogue() {
    // setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movie/${skip}/${take}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setLatestMovies(resp.data.latestMovies);
        setSeries(resp.data.latestSeries);

        setFreeMovies(resp.data.freeMovies);
        setFreeSeries(resp.data.freeSeries);

        setMovies(resp.data.trending);
        setTrendingSeries(resp.data.trendingSeries);

        setComics(resp.data.comics);
        setEpisodes(resp.data.episodes);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    // alert(JSON.stringify(contentType))
    searchMoviesCatalogue();
    searchSeriesCatalogue();
    getMoreMoviesCatalogue();
  }, [skip]);

  if (fecthingMovies) {
    return (
      <>
        <br />
        <br /> <br />
        <br /> <br />
        <br />
        <h4 style={{ color: "#fff" }} className="text-center">
          {" "}
          Just A Few Seconds ...
        </h4>
        <br />
        <br />
      </>
    );
  }

  if (movieItems.length == 0) {
    return (
      <div style={{ marginBottom: 60 }}>
        <br />
        <br /> <br />
        <br /> <br />
        <br />
        <h4 style={{ color: "#fff" }} className="text-center">
          {" "}
          Almost Done ...
        </h4>
        <br />
        <br />
      </div>
    );
  } else {
    return (
      <>
        <PageHeader>
          <h3 style={{ color: "#fff" }}>
            {" "}
            Explore contents similar to {contentType.keyword}{" "}
          </h3>
        </PageHeader>
        <div className="container">
          {movieItems.length > 0 && (
            <div className="section mb-3">
              <div className="section__header">
                <h2 style={{ color: "#fff" }}>
                  Movies similar to {contentType.keyword} {movieItems.length}
                </h2>
                <Link to="/">
                  <OutlineButton className="small">Load more</OutlineButton>
                </Link>
              </div>

              <MovieGrid category={category} movies={movieItems} />
            </div>
          )}

          <br />
          <br />

          {seriesItems.length !== 0 && (
            <div className="section mb-3">
              <div className="section__header">
                <h2 style={{ color: "#fff" }}>
                  Series similar to {contentType.keyword} {seriesItems.length}
                </h2>
                <Link to="/">
                  <OutlineButton className="small">Load more</OutlineButton>
                </Link>
              </div>

              <SeriesGrid category={category} series={seriesItems} />
            </div>
          )}

          <br />
          <br />
          {latestMovies.length !== 0 && (
            <div className="section mb-3">
              <div className="section__header">
                <h2 style={{ color: "#fff" }}>Explore Latest Movies</h2>
                <Link to="/chillarx/movies">
                  <OutlineButton className="small">View All</OutlineButton>
                </Link>
              </div>
              <MovieList movies={latestMovies} />
            </div>
          )}

          {series.length !== 0 && (
            <div className="section mb-3">
              <div className="section__header ">
                <h2 style={{ color: "#fff" }}>Latest Series</h2>
                <Link to="/chillarx/series">
                  <OutlineButton className="small">View more</OutlineButton>
                </Link>
              </div>
              <SeriesList series={series} />
            </div>
          )}

          {freeMovies.length !== 0 && (
            <div className="container" style={{ backgroundColor: "#000" }}>
              <div className="section mb-3">
                <div className="section__header ">
                  <h2 style={{ color: "#fff" }}>Free To Watch</h2>
                  <Link to="/chillarx/movies">
                    <OutlineButton className="small">View more</OutlineButton>
                  </Link>
                </div>
                <h3 style={{ color: "#fff" }}>Free Series</h3>
                <SeriesList series={freeSeries} />
                <br />
                <br />
                <h3 style={{ color: "#fff" }}>Free Movies</h3>
                <MovieList movies={freeMovies} />
              </div>
              </div>
          )}

              {trendingSeries.length !== 0 && (
                <div className="section mb-3">
                  <div className="section__header ">
                    <h2 style={{ color: "#fff" }}>Trending Series</h2>
                    <Link to="/chillarx/series">
                      <OutlineButton className="small">View more</OutlineButton>
                    </Link>
                  </div>
                  <SeriesList series={trendingSeries} />
                </div>
              )}
              <br />
              <br />
              {movies.length !== 0 && (
                <div className="section mb-3">
                  <div className="section__header">
                    <h2 style={{ color: "#fff" }}>Trending Movies</h2>
                    <Link to="/chillarx/movies">
                      <OutlineButton className="small">View more</OutlineButton>
                    </Link>
                  </div>
                  <MovieList movies={movies} />
                </div>
              )}
             
              <br />
              <br />
              {comics.length !== 0 && (
                <div className="section mb-3">
                  <div className="section__header">
                    <h2 style={{ color: "#fff" }}>Suggested For You</h2>
                    <Link to="/chillarx/movies">
                      <OutlineButton className="small">View more</OutlineButton>
                    </Link>
                  </div>
                  <MovieList movies={comics} />
                </div>
              )}
              <br />
              <br />
              <div className="section mb-3">
                <div className="section__header ">
                  <h2 style={{ color: "#fff" }}>More Suggestions</h2>
                  <Link to="/chillarx/movies">
                    <OutlineButton className="small">View more</OutlineButton>
                  </Link>
                </div>
                {episodes.length !== 0 && (
                  <>
                    <h3 style={{ color: "#fff" }}> Latest Episodes</h3>
                    <MovieList movies={episodes} />
                  </>
                )}
              </div>
            </div>
          {/* // )} */}

        
        {/* </div> */}
      </>
    );
  }
};

export default SearchCatalog;
