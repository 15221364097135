import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";

import "./movie-grid.scss";

import SeriesCard from "../movie-card/SeriesCard";
import SeriesCardMap from "../movie-card/SeriesCardMap";
import Button, { OutlineButton } from "../button/Button";
import Input from "../input/Input";

import tmdbApi, { category, movieType, tvType } from "../../api/tmdbApi";

const MySeriesGrid = (props) => {
  const [items, setItems] = useState([]);
  const movies = props.series;
  const seriesLink = props.seriesLink;

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const { keyword } = useParams();

  useEffect(() => {
    // const getList = async () => {
    //   let response = null;
    //   if (keyword === undefined) {
    //     const params = {};
    //     switch (props.category) {
    //       case category.movie:
    //         response = await tmdbApi.getMoviesList(movieType.upcoming, {
    //           params,
    //         });
    //         break;
    //       default:
    //         response = await tmdbApi.getTvList(tvType.popular, { params });
    //     }
    //   } else {
    //     const params = {
    //       query: keyword,
    //     };
    //     response = await tmdbApi.search(props.category, { params });
    //   }
    //   setItems(response.results);
    //   setTotalPage(response.total_pages);
    // };
    // getList();
  }, []);

  const loadMore = async () => {
    let response = null;
    if (keyword === undefined) {
      const params = {
        page: page + 1,
      };
      switch (props.category) {
        case category.movie:
          response = await tmdbApi.getMoviesList(movieType.upcoming, {
            params,
          });
          break;
        default:
          response = await tmdbApi.getTvList(tvType.popular, { params });
      }
    } else {
      const params = {
        page: page + 1,
        query: keyword,
      };
      response = await tmdbApi.search(props.category, { params });
    }
    setItems([...items, ...response.results]);
    setPage(page + 1);
  };

  if (props.series.length == 0) {
    return "";
  } else {

  return (
    <>
      <div className="section mb-3">
        <br />
        <br />
        <div style={{ display: "flex" }}>
          <div className="section__header mb-2 text-center">
            <h2 className="text-center" style={{ color: "#fff" }}>
              {props.heading}
            </h2>
          </div>
          {/* <br/> */}
          <MovieSearch category={props.category} keyword={keyword} />
        </div>
      </div>
      <div className="movie-grid">
        {
          //    <SeriesCardMap category={props.category} movies={movies}/>
          // movies.map((movie, i) => <p category={props.category} movie={movie} key={i} > </p>)
          props.series.map((movie, i) => (
            <SeriesCardMap
              seriesLink={seriesLink}
              category={props.category}
              series={movie.series}
              key={i}
            />
          ))
        }
      </div>
      {page < totalPage ? (
        <div className="movie-grid__loadmore">
          <OutlineButton className="small" onClick={loadMore}>
            Load more
          </OutlineButton>
        </div>
      ) : null}
    </>
  );
      }
};

const MovieSearch = (props) => {
  const history = useHistory();

  const [keyword, setKeyword] = useState(props.keyword ? props.keyword : "");

  const goToSearch = useCallback(() => {
    if (keyword.trim().length > 0) {
      // history.push(`/${category[props.category]}/search/${keyword}`);
      history.push(`/chillarx/search/${keyword}`);
      window.location.reload()
    }
  }, [keyword, props.category, history]);

  // useEffect(() => {
  //     const enterEvent = (e) => {
  //         e.preventDefault();
  //         if (e.keyCode === 13) {
  //             goToSearch();
  //         }
  //     }
  //     document.addEventListener('keyup', enterEvent);
  //     return () => {
  //         document.removeEventListener('keyup', enterEvent);
  //     };
  // }, [keyword, goToSearch]);

  return (
    <div className="movie-search">
      <Input
        type="text"
        placeholder="Enter keyword"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <Button className="small" onClick={goToSearch}>
        Search
      </Button>
    </div>
  );
};

export default MySeriesGrid;
