import { Component, useState, useEffect } from "react";
import React from "react";
import "./online.css";
import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import Load from "../../others/Load";
import {
  useHistory,
  Link,
} from "react-router-dom";

export default function ChannelSubscribers(props) {
  const [following, setFollowing] = useState([]);
  const user_name = window.localStorage.getItem("user_name");
  const [fetching, setFetching] = useState(false);

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function removeSubscriber(id) {
    const access_token = await window.localStorage.getItem("access_token");
    console.log(user_name)
    axiosFetch
      .post(
        `/channels/${props.channelId}/remove/${id}`,
        id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
      props.functionProp();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

//   useEffect(() => {
//     getFollowing();
//     // getTags();
//     // getAlertCategories();
//   }, []);

 if (props.subscribers.length == 0) {
    return (
        <div className="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3">
        <p style={{color: 'black'}} className="text-center">Seems like you have no subscribers yet. No worries, this is about to change soon! </p>
        {/* <Load bg='#fff' color='#000'  /> */}
        </div>
    );
  } else {
    return (
      <>
        <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
          <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 flex items-baseline justify-between py-4 px-6 dark:border-gray-800">
            <h2 className="font-semibold text-lg">Recently Subscribed </h2>
            <h4>Total -> {props.subscribers.length} </h4>
            {/* <a href={`/${user_name}/following`}> View All</a> */}
          </div>

          {props.subscribers.map((user) => (
          <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100">
            <div className="flex items-center justify-between py-3">
              <div className="flex flex-1 items-center space-x-4">
                
                <a href={`/profile/${user.user_name}`}>
                  <img
                    src={user.profile_pic}
                    className="bg-gray-200 rounded-full w-10 h-10"
                    onError={fixImage}
                  />
                </a>
                <Link to={`/profile/${user.user_name}`}>
                <div className="flex flex-col" style={{color:'blue'}}>
                  <span className="block capitalize font-semibold">
                    {" "}
                    {user.first_name} {user.last_name}
                  </span>
                  <span className="block capitalize text-sm"> @{user.user_name} </span>
                </div>
                </Link>
              </div>

              <a
                onClick={() => removeSubscriber(user.id)}
                // onClick={toogleFollow(user.user_name)}
                // style={{backgroundColor: 'blue'}}
                className="border border-gray-200 text-black font-semibold px-4 py-1 rounded-full hover:bg-pink-600 hover:text-white hover:border-pink-600 dark:border-gray-800"
              >
                {" "}
                REMOVE{" "}
              </a>
            </div>
           
          </div>
           ))} 
        </div>

        {/* <h4 className="rightbarTitle">Recently Following</h4>
        <ul className="rightbarFriendList">
      
          <Online user={following} />
        
        </ul> */}
      </>
    );
  }
}
