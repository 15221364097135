import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { format, formatDistance, subDays } from "date-fns";

import axiosFetch from "../../../config/Interceptors";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';

export default function ReportModal(props) {
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [isReported, setIsReported] = useState(false);
  const [isReporting, setIsReporting] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  function handleClose() {
    setOpen(false);
  }

  function handleClose2() {
    setIsReported(false);
    setIsReporting(false);
  }

  async function reportItem() {
    setIsReporting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .post(
        `/${props.url}/report/${props.id}`,
        props.id,
        {
          headers: {
            Accept: "*/*",
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/html",
            Authorization: "Bearer " + access_token,
          },
          // method: 'POST',
        }
      )
      .then((resp) => {
        // const alerts = resp.data;
        // props.functionProp();
        setIsReporting(false);
        setIsReported(true);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  // {console.log(JSON.stringify(props.comments))}
  return (
    <>
      <a
        // href="#"
        onClick={handleClickOpen}
        className=" px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
        // className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
      >
         <ReportOutlinedIcon style={{fontSize: 25, color: "red" }}/> {props.text}
        {/* <i className="uil-trash-alt mr-1"></i> {props.text} */}
      </a>
      {/* <Button onClick={handleClickOpen} >
    <i className="uil-trash-alt mr-1"></i> Report
    </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Report {props.type}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Only report {props.type}s that have inappropriate contents
          </DialogContentText>
          <br />

          <Button variant="contained" color="primary" onClick={reportItem}>
            Click To Report
          </Button>

          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>

          <br />
          <br />
          <>
            {isReporting ? (
              <p>Reporting {props.type}, this will take a few seconds</p>
            ) : (
              ""
            )}

            {isReported ? (
              <Alert onClose={handleClose2} variant="filled" severity="success">
                Reported {props.type} successfully! Thank you for informing us
              </Alert>
            ) : (
              ""
            )}
            <br />
          </>

          {/* {props.title} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>{" "}
      {/* //   ))} */}
    </>
  );
}
