import React, { Component, Fragment } from "react";
// import Header from "../components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
import Alert from "@mui/material/Alert";
import { Button } from "@material-ui/core";
import ImageComponent from "../../components/others/ImageComponent";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";

export default function VerificationComplete() {
  const profile_pic = window.localStorage.getItem("profile_pic");
  const first_name = window.localStorage.getItem("first_name");
  const verLink = window.localStorage.getItem("verification_link");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const verified = window.localStorage.getItem("verified")
  const [response, setResponse] = useState(false);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setResponse(false);
    setMessage("");
    setError(false);
  }

  function getProfile() {
    const access_token = window.localStorage.getItem("access_token");
    console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/profile", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        //   console.log(resp.data);
        window.localStorage.setItem(
          "verified",
          resp.data.user.email_verified_at
        );
        window.location.reload()
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function completeVerification() {
    const access_token = await window.localStorage.getItem("access_token");
    if (!verLink) {
      setError(true);
      setMessage(
        "You are not allowed to do this. Kindly go back and click on the verification link in your email"
      );
      // alert('Kindly go back and click on the verification link in your email')
    } else {
      setSending(true);
      // alert(verLink)
      // http://localhost:3000/verify/http://alert-lumen-api.herokuapp.com/api/verify-email/45/9261935fe143033d000397d6090d0f9708db5ffa?expires=1645626550&signature=20914c77a88e7e8afe6587392afcf7d510e606e04adc00be9336c4140af45351
      // localStorage.removeItem('reloadCount');
      let result = await axios
        .get(
          `${verLink}`,
          // access_token,
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + access_token,
            },
          }
        )
        .then((resp) => {
          setResponse(true);
          setMessage(resp.data.message);
          getProfile();
          setSending(false);
          localStorage.removeItem("verification_link");
          
        })
        .catch((error) => {
          setSending(false);
          console.log(error);
          setResponse(true);
          setMessage(error.message);
        });
    }
  }
 
  useEffect(()=> {
  
    // redirect()
  },[console.log(verLink)])
  return (
    <Fragment>
      <HeaderV2 />
      <br />
      <br />
      <div className="main-content pt-0 bg-white ps-0 pe-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 text-center default-page vh-140 align-items-center d-flex">
              <div className="card border-0 text-center d-block p-0">
                <br /> <br />
                <ImageComponent
                  // onError={fixImage}
                  image={profile_pic}
                  alt="avater"
                  class="w200 mb-4 ms-auto rounded-circle me-auto pt-md-5"
                />
                {/* <img
                  src={profile_pic}
                  alt="icon"
                  className="w200 mb-4 ms-auto rounded-circle me-auto pt-md-5"
                /> */}
                <h3 className="fw-700 text-grey-900 display3-size display4-md-size">
                  Complete Your Email Verification {first_name}
                </h3>
                <p className="text-grey-500 font-xsss">
                  Thanks for starting your email verification. Click the button below to complete this process
                </p>

                {verified !== "null" ?
                <Button
                disabled
                variant="contained"
                color="primary"
                // onClick={this.createPost}
              >
                Congrats! You've verified your email Already
                
              </Button>
              :
               <>
                {sending ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    // onClick={this.createPost}
                  >
                    PLEASE HOLD ON
                  </Button>
                ) : (
                  <Button
                    // disabled
                    variant="contained"
                    color="primary"
                    onClick={completeVerification}
                    // className="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3"
                  >
                    CLICK TO COMPLETE
                    </Button>
                )}
                <br />
                <br />
                {response ? (
                  <>
                    <br />
                    <Alert
                      onClose={handleClose}
                      variant="filled"
                      severity="success"
                    >
                      {message}
                    </Alert>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
                <br />
                {error ? (
                  <>
                    <br />
                    <Alert
                      onClose={handleClose}
                      variant="filled"
                      severity="error"
                    >
                      {message}
                    </Alert>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}

                </>
                }
              </div>
              <br />
              <br />
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </Fragment>
  );
  // }
}

// export default Verification;
