import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router';

import tmdbApi from '../../api/tmdbApi';
import apiConfig from '../../api/apiConfig';
import Slider from "react-slick";
import ImageComponentDiv from '../../components/others/ImageComponentDiv';

const CastList = props => {

    // const {category} = useParams();
    const category = "movie"
    const [casts, setCasts] = useState([]);
    const hotelsettings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        centerMode: false,
        variableWidth: false,
        spaceBetween: 10,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 420,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

    useEffect(() => {
        const getCredits = async () => {
            const res = await tmdbApi.credits(category, props.id);
            setCasts(res.cast.slice(0, 5));
            console.log(tmdbApi.credits(category, props.id));
        }
        getCredits();
    }, [category, props.id]);
    return (
        <>
         <br />
        <div className="section__header">
          <h2 style={{ color: "#fff", marginTop:30 }}>Casts</h2> <br />
          <br />
          </div>
        <div className="casts">
       
      
            {
                casts.map((cast, i) => (
                    <div key={i} className="casts__item">
                      <ImageComponentDiv image= {apiConfig.w500Image(cast.profile_path)} class="casts__item__img" />
                        {/* <div className="casts__item__img" style={{backgroundImage: `url(${apiConfig.w500Image(cast.profile_path)})`}}></div> */}
                        <p className="casts__item__name">{cast.name}</p>
                    </div>
                ))
            }
        </div>

        <div>
        <div className="section__header">
          <h2 style={{ color: "#fff", marginTop:30 }}>Casts</h2> <br />
          <br />
        </div>
        <Slider {...hotelsettings} style={{ color: "#fff" }}>
         {props.casts.map((cast, i) => (
                    <div key={i} className="casts__item">
                       <ImageComponentDiv image= {cast.image} class="casts__item__img" />
                        {/* <div className="casts__item__img" style={{backgroundImage: `url(${cast.image})`}}></div> */}
                        <p className="casts__item__name">{cast.name}</p>
                    </div>
                ))}
        </Slider>
       
      </div>
        </>
    );
}

export default CastList;
