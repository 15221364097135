import React, { Component, Fragment } from "react";

import { Route, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";

import axios from "axios";
import ChannelComp2 from "./ChannelComp2";

export default function UserSubscriptions(props) {
  const [fetchingChannels, setFetchingChannels] = useState("");
  const [channels, setChannels] = useState([]);
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    // getAllCahnnels();
  }, []);

  if (props.subscriptions.length == 0) {
    return (
      <>
        <Fragment>

          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
              <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello {props.user_name}! You Haven't Subscribed To Any Channel.
              </h5>
            </div>
           
          </div>
         
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>


        {/* <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0"> */}
        <div className="row">
          <div className="col-xl-12">
            <div className="row ps-2 pe-1">
              <ChannelComp2 subscriptions={props.subscriptions} functionProp={props.functionProp}/>
             
              {/* {props.subscriptions.map((subscription, id) => (
                <div key={id} className="col-md-4 col-sm-6 pe-2 ps-2">
                  <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                    <div className="card-body d-block w-100 p-4 text-center">
                      <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1">
                        {subscription.channel.channel_pic === null ? (
                          <img
                            onError={fixImage}
                            src={
                              "https://alert-app-v1.s3.amazonaws.com/user.png"
                            }
                            alt="avater"
                            className="float-right p-1 bg-white rounded-circle w-100"
                          />
                        ) : (
                          <img
                            onError={fixImage}
                            src={subscription.channel.channel_pic}
                            alt="avater"
                            className="float-right p-1 bg-white rounded-circle w-100"
                          />
                        )}
                      </figure>
                      <div className="clearfix"></div>
                      <Link
                        to={{
                          pathname: `/channel/${subscription.channel.slug}`,
                          state: { id: subscription.channel.id },
                        }}
                      >
                        <h4 className="fw-700 font-xss mt-3 mb-0">
                          {subscription.channel.name}{" "}
                        </h4>
                      </Link>
                      <Link to={`/profile/${subscription.channel.admin.user_name}`}>
                        {" "}
                        <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                          Created By:{" "}
                          <span className="text-primary-500">
                            {subscription.channel.admin.user_name}
                          </span>{" "}
                        </p>{" "}
                      </Link>
                      <ul className="d-flex align-items-center justify-content-center mt-1">
                        <li className="m-2">
                          <h4 className="fw-700 font-sm">
                            100{" "}
                            <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                              Connections
                            </span>
                          </h4>
                        </li>
                        <li className="m-2">
                          <h4 className="fw-700 font-sm">
                            50{" "}
                            <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                              Follower
                            </span>
                          </h4>
                        </li>
                        <li className="m-2">
                          <h4 className="fw-700 font-sm">
                            13{" "}
                            <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                              Followings
                            </span>
                          </h4>
                        </li>
                      </ul>
                      {/* <ul className="d-flex align-items-center justify-content-center mt-1">
                                                        {subscription.channel.badge1 ? <li className="m-1"><img src={`/assets/images/${channel.badge1}`} alt="icon" /></li> : ''}
                                                        {channel.badge2 ? <li className="m-1"><img src={`/assets/images/${channel.badge2}`} alt="icon" /></li> : ''}
                                                        {channel.badge3 ? <li className="m-1"><img src={`/assets/images/${channel.badge3}`} alt="icon" /></li> : ''}
                                                        {channel.badge4 ? <li className="m-1"><img src={`/assets/images/${channel.badge4}`} alt="icon" /></li> : ''}
                                                    </ul> */}
                      {/* <a
                        href="#follow"
                        className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                      >
                        SUBSCRIBE
                      </a>
                    </div>
                  </div>
                </div>
              ))} */}
            {/* </div> */} 
            {/* {/* </div> */}
            {/* </div> */}
          </div> 
          </div>
        </div>
       
      </Fragment>
    );
  }
}

{/* // export default Channels; */}
