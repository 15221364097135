import React, { Component, Fragment, useState, useEffect } from "react";
import "./channel.scss";
import { Route, useParams, useHistory, useLocation } from "react-router-dom";
import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import { Link } from "react-router-dom";
import Datatable from "../../components/studio/datatable/Datatable";
import axiosFetch from "../../config/Interceptors";
import UserSavedMovies from "../../components/studio/channel/UserSavedMovies";
import UserMovies from "../../components/studio/channel/UserMovies";
import Load from "../../components/others/Load";

const MyMovies = () => {
  const slug = useParams();
  const [fetching, setFetching] = useState(false);
  const [videos, setVideos] = useState([]);
  const [savedMovies, setSavedMovies] = useState([]);
  const [savedSeries, setSavedSeries] = useState([]);
  const [userMovies, setUserMovies] = useState([]);
  const [userSeries, setUserSeries] = useState([]);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  async function getVideos() {
    setFetching(true);
    // console.log(` 1 ${fetching}`)
    const access_token = await window.localStorage.getItem("access_token");
    // setUserId(window.localStorage.getItem("user_id"));
    console.log(window.localStorage.getItem("user_id"));
    axiosFetch
      .get(`/users/purchased/movies`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
          console.log(JSON.stringify(resp.data))
          setUserMovies(resp.data.userMovies);
          setUserSeries(resp.data.userSeries);
        // setSavedMovies(resp.data.favoriteMovies);
        //   setSavedSeries(resp.data.favoriteSeries);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getVideos();
  }, []);

  if(fetching){
      return(
          <>
          <Load bg="#fff" color="#000" />
          </>
      )
  }
  else{

  return (
    <div className="list  col-xl-12" style={{ backgroundColor: "#000" }}>
      <div className="listContainer">
        {/* <ChannelCard slug={slug.slug}/> */}
        <HeaderV2 />
        <h3 style={{ color: "blue" }}>Movies and Series You've Purchased</h3>
       
        <div className="main-content" style={{ backgroundColor: "#000", minHeight: '100%' }}>
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0"> 
            <UserMovies
                //   user_name={user_name}
                movies={userMovies}
                  series={userSeries}
                  url="subscriptions"
                  message="You Haven't Purchased Any Channel Content Yet."
                  message2="purchased"
                />
        
          </div>
          </div>
        </div>
      </div>
    </div>
  );
  }
};

export default MyMovies;
