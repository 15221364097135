// import React from "react";

// import { Route, Switch } from "react-router-dom";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { useState, useEffect, lazy, Suspense } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";
import ProtectedRoute from "./ProtectedRoute";
import ProtectedRoute2 from "./ProtectedRoute2";
import HomeDash from "../pages/dashboard/home/Home";
import HomeDash2 from "../pages/dashboard/home/HomeV2";
import Login from "../pages/dashboard/login/Login";
import List from "../pages/dashboard/list/List";
import Single from "../pages/dashboard/single/Single";
import New from "../pages/dashboard/new/New";
import { productInputs, userInputs } from "../formSource";

import Home from "../pages/Home";
import Welcome from "../pages/Welcome";
import Catalog from "../pages/movies/Catalog";
import MovieCatalog from "../pages/movies/MovieCatalog";
import SearchCatalog from "../pages/movies/SearchCatalog";
import SeriesCatalogue from "../pages/movies/SeriesCatalogue";
import GenreCatalogue from "../pages/movies/GenreCatalogue";
import Detail from "../pages/detail/Detail";
import Studio from "../pages/studio/Studio";
import Channels from "../pages/studio/Channels";
import AllChannels from "../pages/studio/AllChannels";
import AdminTab from "../pages/studio/AdminTab";
import AdminChillarxTransactions from "../pages/studio/AdminChillarxTransactions";
import AllMovies from "../pages/studio/UnapprovedMovies";
import SingleChannelAdmin from "../pages/studio/SingleChannelAdmin";
import ChannelTabPage from "../pages/studio/ChannelTabPage";
import ChillList from "../pages/studio/ChillList";
import ChillHistory from "../pages/studio/ChillHistory";
import MyMovies from "../pages/studio/MyMovies";
import MySubscriptions from "../pages/studio/MySubscriptions";
import CreateChannel from "../pages/CreateChannel";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import FooterGuest from "../components/footer/FooterGuest";
import NewChannel from "../pages/dashboard/new/NewChannel";

import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ContactUs from "../pages/auth/ContactUs";
import ResetPassword from "../pages/auth/ResetPassword";
import Helpbox from "../pages/auth/Helpbox";
import Verification from "../pages/auth/Verirfication";
import VerificationComplete from "../pages/auth/VerificationComplete";

import Settings from "../pages/auth/Settings";
import Account from "../pages/auth/Account";
import Notification from "../pages/dashboard/Notification";
import Notfound from "../pages/dashboard/Notfound";
import Authorpage from "../pages/dashboard/Authorpage";
import Analytics from "../pages/dashboard/Analytics";
import VideoAnalytics from "../pages/studio/VideoAnalytics";
import AdminVideoAnalytics from "../pages/studio/AdminVideoAnalytics";
import SeriesAnalytics from "../pages/studio/SeriesAnalytics";
// import AdminSeriesAnalytics from "../pages/studio/AdminSeriesAnalytics";

import AdminChannelAnalytics from "../pages/studio/AdminChannelAnalytics";
import SingleMovie from "../components/movies/SingleMovie";
import SingleSeries from "../components/movies/SingleSeries";
import ChannelUpdate from "../pages/studio/ChannelUpdate";

const Routes = () => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {

    // if(pathname.includes("/welcome")){
    //   alert('youa; are welcome')
    // }

    if (
      window.localStorage.getItem("access_token") &&
      pathname.includes("/signin")
    ) {
      if (pathname === "/signin") {
        history.push("/");
        history.go()
        console.log("pathname is on signin");
      } else {
        const queryParams = new URLSearchParams(location.search);
        console.log(`hiii ${queryParams}`);
        localStorage.setItem("params", queryParams);
        console.log("it has a parameter");
        const newPath = pathname.slice(7);
        console.log("new path is " + newPath);
        localStorage.setItem("newPath", newPath);
        console.log("new path stored");

        history.push(`${newPath}?${queryParams}`);
        history.go();
      }
    }

    // if (
    //   !window.localStorage.getItem("access_token") &&
    //   pathname.includes("/signin")
    // ) {
    //   if (pathname === "/signin") {
    //     history.push("/signin");
    //     console.log("pathname is on signin");
    //   } else {
    //     const reloadCount = localStorage.getItem("reloadCount");
    //     if (reloadCount < 1) {
    //       localStorage.setItem("reloadCount", String(reloadCount + 1));
    //       console.log("reload count is less than one");

    //       const queryParams = new URLSearchParams(location.search);
    //       console.log(`hiii ${queryParams}`);
    //       localStorage.setItem("params", queryParams);
    //       console.log("it has a parameter");
    //       const newPath = pathname.slice(7);
    //       console.log("new path is " + newPath);
    //       localStorage.setItem("newPath", newPath);
    //       console.log("new path stored");

    //       history.push(`/signin/redirect-after-signin`);
    //       history.go();
    //       // window.location.reload();
    //     } else {
    //       localStorage.removeItem("reloadCount");
    //     }

    //     // history.push(`/signin/redirect-after-sigin`)
    //     // history.go()
    //   }
    // }

    if (pathname.includes("/verify")) {
      const vLink = pathname.slice(15);
      const verLink = "https://" + vLink;
      window.localStorage.setItem("verification_link", verLink);
      // alert(pathname.slice(8))
      history.push("/complete_verification");
      history.go();
    }
  }, []);

  return (
    <BrowserRouter >
      <Switch>
        {/* <Route
          exact
          path="/"
          name="Home"
          render={(props) => (
            <div style={{ backgroundColor: "#0f0f0f" }}>
              <Header {...props} />
              <Home {...props} />
              <Footer />
            </div>
          )}
        /> */}
        <Route exact path="/">
          <ProtectedRoute2
            Cmp={Header}
            Cmp2 = { Home}
            Cmp3 = {Footer}
          />
        </Route>

        <Route
          exact
          path="/welcome"
          name="Welcome"
          render={(props) => (
            <div style={{ backgroundColor: "#000" }}>
              <Header {...props} />
              <Welcome {...props} />
              <FooterGuest />
            </div>
          )}
        />

        <Route
          exact
          path="/signin"
          name="SignIn Page"
          render={(props) => <SignIn {...props} />}
        />
        <Route
          exact
          path="/signin/:id"
          name="SignIn Page"
          render={(props) => <SignIn {...props} />}
        />
        <Route
          exact
          path="/signup"
          name="SignUp Page"
          render={(props) => <SignUp {...props} />}
        />

        <Route
          exact
          path="/login"
          name="login"
          render={(props) => <Login {...props} />}
        />

        <Route
          exact
          path="/forgot-password"
          name="Forgot Password Page"
          render={(props) => <ForgotPassword {...props} />}
        />

        <Route
          exact
          path="/reset-password/:id"
          name="Reset Password Page"
          render={(props) => <ResetPassword {...props} />}
        />

        <Route
          exact
          path="/help"
          name="Help Page"
          render={(props) => <Helpbox {...props} />}
        />

        <Route
          exact
          path={"/contact-us"}
          name="Help Page"
          render={(props) => <ContactUs {...props} />}
        />

        <Route exact path="/email_verification" name="Verify Email">
          <ProtectedRoute Cmp={Verification} />
        </Route>
        <Route exact path="/complete_verification" name="Verify Email">
          <ProtectedRoute Cmp={VerificationComplete} />
        </Route>

        <Route exact path="/account" name="Account">
          <ProtectedRoute Cmp={Account} />
        </Route>
        {/* <Route
          exact
          path="/account"
          name="Account"
          render={(props) => <Account {...props} />}
        /> */}

        {/* <Route
        exact
        path="/movies"
        name="Movies"
        render={(props) => (
          <div style={{ backgroundColor: "#0f0f0f" }}>
            <Header />
            <Catalog {...props} />
            <Footer />
          </div>
        )}
      /> */}

        {/* <Route
          exact
          path="/chillarx/movies"
          name="Movies"
          render={(props) => (
            <div style={{ backgroundColor: "#0f0f0f" }}>
              <Header {...props} />
              <Catalog {...props} />
              <Footer />
            </div>
          )}
        /> */}
        <Route exact path="/chillarx/movies">
          <ProtectedRoute2
         Cmp={Header }
         Cmp2 = { Catalog }
         Cmp3 = {Footer}
          />
        </Route>

        <Route exact path="/chillarx/explore/:content">
          <ProtectedRoute2
         Cmp={Header }
         Cmp2 = { MovieCatalog }
         Cmp3 = {Footer}
          />
          
        </Route>

        {/* <Route
          exact
          path="/chillarx/series"
          name="Series"
          render={(props) => (
            <div style={{ backgroundColor: "#0f0f0f" }}>
              <Header />
              <SeriesCatalogue {...props} />
              <Footer />
            </div>
          )}
        /> */}

        <Route exact path="/chillarx/series">
          <ProtectedRoute2
            Cmp={Header }
            Cmp2 = { SeriesCatalogue }
            Cmp3 = {Footer }
        
          />
        </Route>

        {/* <Route
          exact
          path="/chillarx/genre/:url"
          name="Series"
          render={(props) => (
            <div style={{ backgroundColor: "#0f0f0f" }}>
              <Header {...props} />
              <GenreCatalogue {...props} />
              <Footer />
            </div>
          )}
        /> */}

        <Route exact path="/chillarx/genre/:url">
          <ProtectedRoute2
            Cmp={Header }
            Cmp2 = { GenreCatalogue }
            Cmp3 = {Footer }
         
          />
        </Route>

        {/* <Route
          exact
          path="/movie/:id"
          name="Movie"
          render={(props) => (
            <div style={{ backgroundColor: "#0f0f0f" }}>
              <Header {...props} />
              <Detail {...props} />
              <Footer />
            </div>
          )}
        /> */}

        <Route exact path="/movie/:id">
          <ProtectedRoute2
            Cmp={Header }
            Cmp2 = {Detail }
            Cmp3 = {Footer }
         
          />
        </Route>

        {/* <Route
          exact
          path="/:category/search/:keyword"
          name="Movie"
          render={(props) => (
            <div style={{ backgroundColor: "#0f0f0f" }}>
              <Header {...props} />
              <Catalog {...props} />
              <Footer />
            </div>
          )}
        /> */}

<Route exact path="/chillarx/search/:keyword">
          <ProtectedRoute2
              Cmp={Header }
              Cmp2 = { SearchCatalog}
              Cmp3 = {Footer}
          
          />
        </Route>

        <Route exact path="/:category/search/:keyword">
          <ProtectedRoute2
              Cmp={Header }
              Cmp2 = { Catalog}
              Cmp3 = {Footer}
          
          />
        </Route>

        <Route exact path="/dashboard2">
          <ProtectedRoute Cmp={HomeDash} />
        </Route>

        <Route exact path="/dashboard">
          <ProtectedRoute Cmp={Channels} />
          {/* <ProtectedRoute Cmp={HomeDash2} /> */}
        </Route>

        <Route exact path="/create_channel">
          <ProtectedRoute Cmp={CreateChannel} />
        </Route>

        {/* <Route
          exact
          path="/users"
          name="users"
          render={(props) => <List {...props} />}
        /> */}

        {/* <Route
          exact
          path="/users/:userId"
          name="users"
          render={(props) => <Single {...props} />}
        /> */}

        {/* <Route
          exact
          path="/users/add/new"
          name="users"
          render={(props) => <New inputs={userInputs} title="Add New User" />}
        /> */}

        {/* <Route
          exact
          path="/products"
          name="users"
          render={(props) => <List {...props} />}
        /> */}

        {/* <Route
          exact
          path="/users/:productId"
          name="products"
          render={(props) => <Single {...props} />}
        /> */}

        {/* <Route
          exact
          path="/products/add/new"
          name="products"
          render={(props) => (
            <New inputs={userInputs} title="Add New Product" />
          )}
        /> */}

        {/* <Route exact path="/dashboard" render={(props) => <HomeDash {...props} />} />
      <Route path="login" exact render={(props) => <Login {...props} />}/> */}
        {/* <Route path="users">
          <Route index element={<List />} />
          <Route path=":userId" element={<Single />} />
          <Route
            path="new"
            element={<New inputs={userInputs} title="Add New User" />}
          />
        </Route> */}

        {/* <Route path="/:category/search/:keyword" component={Catalog} />
      <Route path="/:category/:id" component={Detail} />
      <Route path="/:category" component={Catalog} /> */}
        {/* <Route path="/account" component={Studio} /> */}

        <Route exact path={"/notfound"}>
          <ProtectedRoute Cmp={Notfound} />
        </Route>

        <Route exact path={"/chillarx/2/watch/:u_id"}>
          <ProtectedRoute Cmp={Detail} />
        </Route>

        <Route exact path={"/chillarx/watch/:u_id"}>
          <ProtectedRoute Cmp={SingleMovie} />
        </Route>

        <Route exact path={"/chillarx/watch/:url/:u_id"}>
          <ProtectedRoute Cmp={SingleMovie} />
        </Route>

        <Route exact path={"/content/analytics/:u_id"}>
          <ProtectedRoute Cmp={VideoAnalytics} />
        </Route>
        <Route exact path={"/content/admin/analytics/:u_id"}>
          <ProtectedRoute Cmp={VideoAnalytics} />
        </Route>

        <Route exact path={"/series/analytics/:u_id"}>
          <ProtectedRoute Cmp={SeriesAnalytics} />
        </Route>
        <Route exact path={"/series/admin/analytics/:u_id"}>
          <ProtectedRoute Cmp={SeriesAnalytics} />
        </Route>

        <Route exact path={"/video/analytics/:u_id"}>
          <ProtectedRoute Cmp={AdminVideoAnalytics} />
        </Route>
        
        <Route exact path={"/channel/admin/analytics/:slug"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>
        <Route exact path={"/channel/admin/:slug"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>
        <Route exact path={"/channel/admin/:slug/series"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>
        <Route exact path={"/channel/admin/:slug/comics"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>
        <Route exact path={"/channel/admin/:slug/analytics"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>
        <Route exact path={"/channel/admin/:slug/transactions"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>
        <Route exact path={"/channel/admin/:slug/earnings"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>
        <Route exact path={"/channel/admin/:slug/subscribers"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>
        <Route exact path={"/channel/admin/:slug/videos"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>
        <Route exact path={"/channel/admin/:slug/movies"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>
        <Route exact path={"/channel/admin/:slug/details"}>
          <ProtectedRoute Cmp={AdminChannelAnalytics} />
        </Route>

        <Route exact path="/profile/:user_name">
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/home"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/channels"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/transactions"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/due-soon"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/movies"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/watchlist"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/subscriptions"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/referrals"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/points"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>

        <Route exact path={"/channels"}>
          <ProtectedRoute Cmp={Channels} />
        </Route>
        <Route exact path={"/all/channels"}>
          <ProtectedRoute Cmp={AllChannels} />
        </Route>
        <Route exact path={"/all_channels/admin"}>
          <ProtectedRoute Cmp={AdminTab} />
        </Route>
        <Route exact path={"/all_transactions/admin"}>
          <ProtectedRoute Cmp={AdminChillarxTransactions} />
        </Route>
        
        <Route exact path={"/all_movies"}>
          <ProtectedRoute Cmp={AllMovies} />
        </Route>

        <Route exact path="/channel/admin/update/:slug">
          <ProtectedRoute Cmp={SingleChannelAdmin} />
        </Route>

        <Route exact path="/channel/:slug">
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>

        <Route exact path="/channel/add/new">
          <ProtectedRoute Cmp={NewChannel} />
        </Route>

        <Route exact path="/channel/add/new2">
          <ProtectedRoute Cmp={CreateChannel} />
        </Route>

        {/* <Route
          exact
          path="/channel/add/new"
          name="products"
          render={(props) => <NewChannel inputs={userInputs} />}
        /> */}
        {/* <Route
          exact
          path="/channel/add/new2"
          name="products"
          render={(props) => <CreateChannel inputs={userInputs} />}
        /> */}

<Route exact path={"/chill-list"}>
          <ProtectedRoute Cmp={ChillList} />
        </Route>
        <Route exact path={"/chill-history"}>
          <ProtectedRoute Cmp={ChillHistory} />
        </Route>
        <Route exact path={"/my-movies"}>
          <ProtectedRoute Cmp={MyMovies} />
        </Route>
        <Route exact path={"/my-subscriptions"}>
          <ProtectedRoute Cmp={MySubscriptions} />
        </Route>

        <Route exact path={"/channel/:slug"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/series"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/comics"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/analytics"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/transactions"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/earnings"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/subscribers"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/videos"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/video"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/movies"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/movie"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/details"}>
          <ProtectedRoute Cmp={ChannelTabPage} />
        </Route>
        <Route exact path={"/channel/:slug/update"}>
          <ProtectedRoute Cmp={ChannelUpdate} />
        </Route>

        <Route exact path={"/settings"}>
          <ProtectedRoute Cmp={Settings} />
        </Route>

        <Route exact path={"/analytics"}>
          <ProtectedRoute Cmp={Analytics} />
        </Route>

        <Route exact path={"/profileinformation"}>
          <ProtectedRoute Cmp={Account} />
        </Route>
        <Route exact path={"/notifications"}>
          <ProtectedRoute Cmp={Notification} />
        </Route>

        <Route
          render={(props) => (
            <div style={{ backgroundColor: "#fff" }}>
              <Header {...props} />
              <Notfound {...props} />
              <Footer />
            </div>
          )}
        />
        {/* <Route component={
          <>
          Notfound
          </>
        } /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
