import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  useLocation,
  Link,
} from "react-router-dom";
import { Select } from "antd";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
// import { Tabs, Tab } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";
import Profiledetail from "./Profiledetail";
import UserChannels from "../studio/channel/UserChannels";

import UserSubscriptions from "../studio/channel/UserSubscriptions";

import UserMovies from "../studio/channel/UserMovies";
import Load from "../others/Load";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import UserReferrals from "./UserReferrals";
import Points from "./Points";

import UserSavedMovies from "../studio/channel/UserSavedMovies";

import StarredMovies from "../studio/channel/StarredMovies";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "@mui/material";
import UserTransactions from "./UserTransactions";
import UserDueSoon from "./UserDueSoon";

export default function ProfileTabsAuth(props) {
  const [key, setKey] = useState("home");
  const { user_name } = useParams(["user_name"]);
  const location = useLocation();
  let history = useHistory();
  const path = location.pathname;
  const [profile, setProfile] = useState([]);
  const [userAlerts, setUserAlerts] = useState([]);
  const [userCats, setUserCats] = useState([]);
  const [userChannels, setUserChannels] = useState([]);
  const [userMovies, setUserMovies] = useState([]);
  const [userSeries, setUserSeries] = useState([]);
  const [movieCasts, setMovieCasts] = useState([]);
  const [userPosts, setUserPosts] = useState([]);
  const [userSocials, setUserSocials] = useState([]);
  const [userReferrals, setUserReferrals] = useState([]);
  const [userPages, setUserPages] = useState([]);
  const [userTransactions, setUserTransactions] = useState([]);
  const [dueSoon, setDueSoon] = useState([]);
  const [chillarxDue, setChillarxDue] = useState([]);
  const [userPoints, setUserPoints] = useState([]);
  const [totalPoints, setTotalPoints] = useState([]);
  const [savedAlerts, setSavedAlerts] = useState([]);
  const [savedPosts, setSavedPosts] = useState([]);
  const [savedMovies, setSavedMovies] = useState([]);
  const [savedSeries, setSavedSeries] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const userId = useState(window.localStorage.getItem("user_id"));
  const user_id = props.profile.id;
  const [fetchingProfile, setFetchingProfile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [value, setValue] = React.useState("home");

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  function checkUserId() {
    // console.log(`no 1 ${profile.id}`);
    // console.log(`no 2 ${userId}`);
    if (userId[0] == props.profile.id) {
      // console.log("is logged in user");
      return setIsLoggedInUser(true);
    } else {
      // console.log("is another user");
      return setIsLoggedInUser(false);
    }
  }

  function closeError() {
    setError(false);
    console.log("closed");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    history.replace(`/profile/${user_name}`);
    setValue(newValue);
    // e.prevent
    event.preventDefault();
    history.replace(`/profile/${user_name}/${newValue}`);
  };

  const getPathname = () => {
    console.log(`path name ${location.pathname}`);
    if (path.includes("/home")) {
      setValue("home");
    }
    if (path.includes("/alert")) {
      setValue("alert");
    }
    if (path.includes("/pages")) {
      setValue("pages");
    }
    if (path.includes("/starredMovies")) {
      setValue("starredMovies");
    }
    if (path.includes("/channels")) {
      setValue("channels");
    }
    if (path.includes("/pictures")) {
      setValue("pictures");
    }
    if (path.includes("/chill-list")) {
      setValue("chill-list");
    }
    if (path.includes("/transactions")) {
      setValue("transactions");
    }
    if (path.includes("/due-soon")) {
      setValue("due-soon");
    }
    if (path.includes("/points")) {
      setValue("points");
    }
    if (path.includes("/subscriptions")) {
      setValue("subscriptions");
    }
    if (path.includes("/movies")) {
      setValue("movies");
    }
    if (path.includes("/saved_posts")) {
      setValue("saved_posts");
    }
    if (path.includes("/saved_alerts")) {
      setValue("saved_alerts");
    }
    if (path.includes("/referrals")) {
      setValue("referrals");
    }
    // .slice(-3)
  };

  async function getUserDetailsLoad() {
    setFetchingProfile(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/${user_name}/details/auth`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          const data = JSON.stringify(resp.data);
          // console.log(`user:data 2 = ${JSON.stringify(resp.data.subscriptions)}`)
          // setUserPosts(resp.data.posts);
          // setUserSubscriptions(resp.data.subscriptions);
          // setUserMovies(resp.data.user.movies);
          // setUserSeries(resp.data.user.series);
          // setUserPages(resp.data.user.pages);
          // setUserTransactions(resp.data.user.transactions);
          setDueSoon(resp.data.dueSoon);
          setChillarxDue(resp.data.chillarxDue);
          // setUserSocials(resp.data.user.socials);
          // setUserCats(resp.data.user.post_cat);
          setUserChannels(resp.data.user.channels);
          setUserReferrals(resp.data.user.referrals);
          // setUserAlerts(resp.data.alerts);
          setUserPoints(resp.data.user.reward_points);
          setTotalPoints(resp.data.user.points);
          // setSavedPosts(resp.data.favoritePosts);
          // setSavedAlerts(resp.data.favoriteAlerts);
          // setMovieCasts(resp.data.movieCasts);
          // setSavedMovies(resp.data.favoriteMovies);
          // setSavedSeries(resp.data.favoriteSeries);
          setFetchingProfile(false);

          if (userId[0] == resp.data.user.id) {
            return setIsLoggedInUser(true);
          } else {
            return setIsLoggedInUser(false);
          }
        } else {
          setFetchingProfile(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setFetchingProfile(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getUserDetails() {
    // setFetchingProfile(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/${user_name}/details/auth`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          const data = JSON.stringify(resp.data);
          // console.log(`user:data 2 = ${JSON.stringify(resp.data.subscriptions)}`)
          // setUserPosts(resp.data.posts);
          // setUserSubscriptions(resp.data.subscriptions);
          // setUserMovies(resp.data.moviePurchase);
          setUserChannels(resp.data.user.channels);
          // setUserPages(resp.data.user.pages);
          setUserTransactions(resp.data.user.transactions);
          setDueSoon(resp.data.dueSoon);
          setChillarxDue(resp.data.chillarxDue);
          // setUserCats(resp.data.user.post_cat);
          // setUserAlerts(resp.data.alerts);
          setUserReferrals(resp.data.user.referrals);
          // setSavedPosts(resp.data.favoritePosts);
          // setUserSocials(resp.data.user.socials);
          // setSavedAlerts(resp.data.favoriteAlerts);
          setUserPoints(resp.data.user.reward_points);
          setTotalPoints(resp.data.user.points);
          // setSavedMovies(resp.data.favoriteMovies);
          // setMovieCasts(resp.data.movieCasts);
          // setSavedSeries(resp.data.favoriteSeries);
          if (userId[0] == resp.data.user.id) {
            return setIsLoggedInUser(true);
          } else {
            return setIsLoggedInUser(false);
          }
          // setFetchingProfile(false);
        } else {
          // setFetchingProfile(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        // setFetchingProfile(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getUsersTransactions() {
    // setFetchingProfile(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/my_transactions/1`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
          setUserTransactions(resp.data);
      })
      .catch((error) => {
        // setFetchingProfile(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getUserDetailsLoad();
    // getUsersTransactions();
    checkUserId();
    getPathname();
    console.log(JSON.stringify(`effect: ${props.profile.id}`));
    // checkUserId();
  }, []);

  if (fetchingProfile == true) {
    return (
      <>
        <Load bg='#fff' color='#000' />
      </>
    );
  } else {
    return (
      <>
        <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
          {error ? (
            <div>
              <br />
              <Alert
                severity="error"
                onClose={handleClose}
                action={
                  <>
                    <CancelIcon onClick={closeError} />
                  </>
                }
              >
                There was an issue fetching your profile. Kindly refresh or
                check your network [ {errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}
          <Box sx={{ bgcolor: "background.paper" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  // value={key}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  scrollButtons="auto"
                  aria-label="scrollable force tabs example"
                >
                  <Tab
                    value="home"
                    label="Home"
                    title="Home"
                    style={{ color: "black" }}
                  ></Tab>

                  <Tab
                    value="channels"
                    label="Channels"
                    title="Channels"
                    style={{ color: "black" }}
                  ></Tab>
                  <Tab
                    value="referrals"
                    label="Referrals"
                    title="Referrals"
                    style={{ color: "black" }}
                  ></Tab>
                  <Tab
                    value="points"
                    style={{ color: "black" }}
                    label="Points"
                    title="Points"
                  ></Tab>

                  {/* <Tab
                    value="subscriptions"
                    label="Subscriptions"
                    title="Subscriptions"
                    style={{ color: "black" }}
                  ></Tab> */}

                  {/* <Tab
                    value="chill-list"
                    label="Chill List"
                    title="Chill List"
                    style={{ color: "black" }}
                  ></Tab> */}

                  {/* <Tab
                    value="movies"
                    label="Movies"
                    title="Movies"
                    style={{ color: "black" }}
                  ></Tab> */}

                  <Tab
                    value="transactions"
                    label="Transactions"
                    title="Transactions"
                    style={{ color: "black", marginRight:20 }}
                  ></Tab>
                   <Tab
                    value="due-soon"
                    label="Expiring Soon"
                    title="Expiring Soon"
                    style={{ color: "black" }}
                  ></Tab>

                  {/* <Tab
                    value="starredMovies"
                    label="starred Movies"
                    title="starred Movies"
                    style={{ color: "black" }}
                  ></Tab> */}
                  {/* <Tab value="pictures" label="Photos" title="Photos"></Tab> */}
                </TabList>
              </Box>

              <TabPanel value="home">
                <br />
                <div className="row">
                  {/* <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0"> */}
                  <Profiledetail
                    profile={props.profile}
                    socials={userSocials}
                    cats={userCats}
                    user_name={user_name}
                    isLoggedInUser={isLoggedInUser}
                    functionProp={() => getUserDetails()}
                  />
                  {/* <Profilephoto text="Latest Pictures" />
                  <Events /> */}
                </div>
                {/* <div className="col-xl-8 col-xxl-9 col-lg-8">
                    {isLoggedInUser ? (
                      // <Createpost user_name={user_name} />
                      <p style={{ color: "#000" }}>hiiiiii</p>
                    ) : (
                      ""
                    )}

                    <p style={{ color: "#000" }}>hiooooi</p> */}

                {/* <PostComponent
                      posts={userPosts}
                      type="post"
                      functionProp={() =>getUserDetails()}
                    /> */}
                {/* </div> */}
                {/* </div> */}
              </TabPanel>
              {/* <TabPanel value="pictures" label="Photos">
            <br/>
         
              <h3> User Post Pictures </h3>
              <UserPictures 
              user_name={user_name} 
              posts={userPosts}
      
              /> */}

              <TabPanel value="channels">
                {/* <br />
                <br /> */}
                <Button
                  href="/create_channel"
                  // fullWidth
                  variant="contained"
                  color="primary"
                >
                  Create Channel{" "}
                </Button>
                {/* <a
                  href="/create_channel"
                  style={{ marginLeft: 30 }}
                  className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                >
                  Create Channel
                </a> */}
                <br />
                <br />
                <p className="text-center">
                  Are you a content creator? You can monetize your contents
                  better by creating them in your channel. Create one or more
                  channels now to start monetizing. Ps-> Videos, movies and
                  series you create in your channel also appear in Chillarx.
                </p>
                <br />
                {/* <h3> Channels You've Created </h3> */}
                <UserChannels
                  fetching={fetchingProfile}
                  isLoggedInUser={isLoggedInUser}
                  user_name={user_name}
                  channels={userChannels}
                  type="Channel"
                  url={`${user_name}/channels`}
                  user_id={user_id}
                  functionProp={() => getUserDetails()}
                />
              </TabPanel>

              <TabPanel value="referrals">
                <br />
                {/* {console.log(JSON.stringify(userReferrals))} */}
                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0"></div>
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  {/* <UserReferrals */}
                  <UserReferrals
                    referrals={userReferrals}
                    totalPoints={totalPoints}
                    functionProp={() => getUserDetails()}
                  />
                </div>
              </TabPanel>

              <TabPanel value="points">
                <br />
                {/* {console.log(JSON.stringify(userPoints))} */}
                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0"></div>
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  {/* <UserReferrals */}
                  <Points
                    points={userPoints}
                    totalPoints={totalPoints}
                    functionProp={() => getUserDetails()}
                  />
                </div>
              </TabPanel>

              {/* <TabPanel value="subscriptions">
                <br /> <br />
                <h3 className="text-center"> Channels Subscribed To </h3>
                <br />
                <p className="text-center">
                  {" "}
                  Start subscribing to channels to get easy access to their
                  movies and series here. Use your side menu to explore latest
                  channels
                </p> */}
                {/* <UserSubscriptions
                  fetching={fetchingProfile}
                  user_name={user_name}
                  subscriptions={userSubscriptions}
                  url="subscriptions"
                  functionProp={() => getUserDetails()}
                />
              </TabPanel> */}
              {/* <TabPanel value="movies">
                <br /> <br />
               
                <UserMovies
                  user_name={user_name}
                  movies={userMovies}
                  series={userSeries}
                  url="subscriptions"
                  message="You Haven't Purchased Any Channel Content Yet."
                  message2="purchased"
                />
              </TabPanel> */}

              {/* <TabPanel value="starredMovies">
                <br /> <br />
                <h3 className="text-center">
                  {" "}
                  Channel Movies You've Featured In{" "}
                </h3>
                <StarredMovies
                  fetching={fetchingProfile}
                  user_name={user_name}
                  starredMovies={movieCasts}
                  url="subscriptions"
                  functionProp={() => getUserDetails()}
                />
              </TabPanel> */}

              {/* <TabPanel value="chill-list">
                <br /> <br />
                <h3 className="text-center"> Movies You've Saved For Later</h3>
                <UserSavedMovies
                  // <UserMovies
                  user_name={user_name}
                  movies={savedMovies}
                  series={savedSeries}
                  url="subscriptions"
                  message="You Haven't Saved Any Movie For Later"
                  message2="saved"
                />
              </TabPanel> */}

              <TabPanel value="transactions">
                <br /> <br />
                <h3 className="text-center">Transactions You've Made On Chillarx</h3>
                <br />
                <UserTransactions userName={user_name} transactions={userTransactions}  />
              </TabPanel>

            <TabPanel value="due-soon">
                <br /> <br />
                <h3 className="text-center">Transactions Expiring Soon</h3>
                <br />
                <UserDueSoon userName={user_name} chillarxDue={chillarxDue} dueSoon={dueSoon}  />
              </TabPanel>

            </TabContext>
          </Box>
        </div>
      </>
    );
  }
}

