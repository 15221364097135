// import React from 'react';
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

import { OutlineButton } from "../components/button/Button";
import HeroSlide from "../components/hero-slide/HeroSlide";
import MovieList from "../components/movie-list/MovieList";
import MovieGrid from "../components/movie-grid/MovieGrid";
import { useHistory, useLocation } from "react-router-dom";

import SeriesList from "../components/movie-list/SeriesList";
import SeriesGrid from "../components/movie-grid/SeriesGrid";
import axiosFetch from "../config/Interceptors";

import { category, movieType, tvType } from "../api/tmdbApi";

const Welcome = () => {
  // const [movieItems, setMovieItems] = useState([]);
  const history = useHistory();
  const [latestMovies, setLatestMovies] = useState([]);
  const [freeMovies, setFreeMovies] = useState([]);
  const [freeSeries, setFreeSeries] = useState([]);
  const [sponsoredMovies, setSponsoredMovies] = useState([]);
  const [trending, setTrending] = useState([]);
  const [trendingSeries, setTrendingSeries] = useState([]);
  const [series, setSeries] = useState([]);
  const [movies, setMovies] = useState([]);
  const [comics, setComics] = useState([]);
  const [videos, setVideos] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(20);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [fecthingMovies, setFetchingMovies] = useState(false);

  async function getMoviesCatalogue() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movies/welcome/${skip}/${take}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setLatestMovies(resp.data.latestMovies);
        setFreeMovies(resp.data.freeMovies);
        setFreeSeries(resp.data.freeSeries);
        setTrending(resp.data.trending);
        setTrendingSeries(resp.data.trendingSeries);
        setSeries(resp.data.latestSeries);
        setMovies(resp.data.movies);
        setComics(resp.data.comics);
        setVideos(resp.data.videos);
        setEpisodes(resp.data.episodes);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getSeries() {
    // setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/series/welcome/${skip}/${take}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        // console.log(JSON.stringify(resp.data));
        setSeries(resp.data);
        // setMovieItems(resp.data);
        // setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getSponsoredMovies() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`welcome/sponsored/view/all`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setSponsoredMovies(resp.data);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  function closeError() {
    setError(false);
    console.log("closed");
  }

  function redirect() {
    if (window.localStorage.getItem("access_token")) {
      history.push("/");
      history.go();
    }
  }

  useEffect(() => {
    redirect();
    getMoviesCatalogue();
    getSponsoredMovies();
    // getSeries();
  }, []);

  if (fecthingMovies) {
    return (
      <>
        <br />
        <br /> <br />
        <br /> <br />
        <br />
        <h4 style={{ color: "#fff" }} className="text-center">
          {" "}
          Just A Few Seconds ...
        </h4>
        <br />
        <br />
      </>
    );
  }

  if (latestMovies.length == 0) {
    return (
      <div style={{ marginBottom: 60 }}>
        <br />
        <br /> <br />
        <br /> <br />
        <br />
        <h4 style={{ color: "#fff" }} className="text-center">
          {" "}
          Almost Done ...
        </h4>
        <br />
        <br />
      </div>
    );
  } else {
    return (
      <>
        <HeroSlide movieItems={sponsoredMovies} isloggedIn="false" />
        <div className="container" style={{ backgroundColor: "#000" }}>
          <div className="section mb-3">
            <div className="section__header ">
              <h2 style={{ color: "#fff" }}>
                Welcome! To chill on these, you need to sign in first.
              </h2>
              <Link
                onClick={() => {
                  history.push("/signin");
                  history.go();
                }}
              >
                <OutlineButton className="small">Sign In Now</OutlineButton>
              </Link>
            </div>
            {freeSeries.length !== 0 && (
              <>
                <h3 style={{ color: "#fff" }}>Free Series</h3>
                <SeriesList
                  series={freeSeries}
                  category={category.movie}
                  type={movieType.popular}
                />
              </>
            )}
            <br />
            <br />
            {freeMovies.length !== 0 && (
              <>
                <h3 style={{ color: "#fff" }}>Free Movies</h3>
                <MovieList movies={freeMovies} category={category} />
              </>
            )}
          </div>

          {trendingSeries.length !== 0 && (
            <>
              <div className="section mb-3">
                <div className="section__header ">
                  <h2 style={{ color: "#fff" }}>Trending Series</h2>
                  <Link to="/chillarx/series">
                    <OutlineButton className="small">View more</OutlineButton>
                  </Link>
                </div>
                <SeriesList
                  series={trendingSeries}
                  category={category.movie}
                  type={movieType.top_rated}
                />
              </div>
            </>
          )}
          <br />
          <br />
          {trending.length !== 0 && (
            <>
              <div className="section mb-3">
                <div className="section__header">
                  <h2 style={{ color: "#fff" }}>Trending Movies</h2>
                  <Link to="/chillarx/movies">
                    <OutlineButton className="small">View more</OutlineButton>
                  </Link>
                </div>
                <MovieList
                  movies={trending}
                  category={category.tv}
                  type={tvType.popular}
                />
              </div>
            </>
          )}
          <br />
          <br />
          {latestMovies.length !== 0 && (
            <>
              <div className="section mb-3">
                <div className="section__header ">
                  <h2 style={{ color: "#fff" }}>Explore Latest </h2>
                  <Link to="/chillarx/series">
                    <OutlineButton className="small">View more</OutlineButton>
                  </Link>
                </div>
                <MovieList
                  movies={latestMovies}
                  category={category.tv}
                  type={tvType.top_rated}
                />
              </div>
            </>
          )}
          {comics.length !== 0 && (
            <>
              <div className="section mb-3">
                <div className="section__header">
                  <h2 style={{ color: "#fff" }}>Suggested For You</h2>
                  <Link to="/chillarx/movies">
                    <OutlineButton className="small">View more</OutlineButton>
                  </Link>
                </div>
                <MovieList
                  movies={comics}
                  category={category.tv}
                  type={tvType.popular}
                />
              </div>
            </>
          )}
          <br />
          <br />

          <div className="section mb-3">
            <div className="section__header ">
              <h2 style={{ color: "#fff" }}>Watch More</h2>
              <Link to="/chillarx/movies">
                <OutlineButton className="small">View more</OutlineButton>
              </Link>
            </div>
            {videos.length !== 0 && (
              <>
                <h3 style={{ color: "#fff" }}>Videos</h3>
                <MovieList
                  movies={videos}
                  category={category.movie}
                  type={movieType.popular}
                />
              </>
            )}
            <br />
            <br />
            {episodes.length !== 0 && (
              <>
                <h3 style={{ color: "#fff" }}>Episodes</h3>
                <MovieList movies={episodes} category={category} />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default Welcome;
