import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Component, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import axios from 'axios';
import axiosFetch from "../../config/Interceptors";
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// import AddAlertRoundedIcon from '@material-ui/icons/AddAlertRounded';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from "@material-ui/lab/Alert";
import BoltIcon from '@mui/icons-material/Bolt';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

function Copyright() {
  return (
    <>
    <br/>
      <Typography variant="body2" color="textSecondary" align="center">
      {'For Help, '}
      <Link color="primary" href="/help">
        Click Here!
      </Link>{' '}
    
      {'.'}
    </Typography>
   
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="primary" target="_blank" href="https://about.chillarx.com/">
       Chillarx 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    <Typography variant="body2" color="textSecondary" align="center">
    A Product Of 
      <Link style={{paddingLeft:3}} color="primary" target="_blank" href="https://soaenterprise.com/">
         SOA Digital Enterprise
      </Link>
    </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '120vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  image2: {
    backgroundImage:  "url(/assets/images/footer-bg.jpg)",
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'contain',
    backgroundPosition: 'cover',
    width: "100vw",
    height: "110vh", 
    [theme.breakpoints.down("sm")]: {
      height: "80vh", 
      backgroundSize: 'cover',
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#000'
    // theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = ('')

  const location = useLocation();
  const history = useHistory();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setPasswordMismatch(false);
    setEmptyFields(false);
    setResetError(false);
    setIsSuccess(false)
    // setOpenAlert(false);
  };

  async function validate() {
    if (!email) {
      setEmptyFields(true);
      return <Alert severity="warning">Please fill in both passwords</Alert>;
    }
 else {
      // alert(email)
      sendReset()
    }
  }

  async function sendReset()  {
    setIsRequesting(true)
    axios.post(`${process.env.REACT_APP_LIVE_URL}/password/forgot`, {
      method: 'POST',
      email: email,    
    }).then(resp => {
     
      if (resp.data.status == 'error') {
         console.log(resp.data,email);
      
         setResetError(true)
         setErrorMessage(resp.data.message);
      
        setIsRequesting(false);
      }
      else{
      setIsSuccess(true)
      setErrorMessage(resp.data.message);
      setEmail('');
    
      setIsRequesting(false);
      }
    })
    .catch(error =>{
        setIsRequesting(false);
        setResetError(true)
        setErrorMessage(error.response.data.message);
        console.log(error);
    })
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function redirect() {
    if(window.localStorage.getItem("access_token"))
    {
      history.push("/")
    }
  }

  useEffect(()=> {
  
    redirect()
  },[])
 

  return (
    <Grid container component="main" className={classes.root} style={{backgroundColor: '#fff'}}>
      <CssBaseline />
      {/* <Grid item xs={12} sm={6} md={6} className={classes.image2} /> */}
      <Grid item xs={12} sm={6} md={6} >
        <br/>
        <Typography component="h1" variant="overline" className="text-center" style={{color: "#2516c7"}} > Welcome! Please always accept the notification prompt when asked for a better experience on Chillarx</Typography>
        <div className={classes.image2}></div>
      </Grid>
        <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PlayCircleOutlineIcon style={{fontSize:25, color: '#FFD700'}}/>
          </Avatar><br/>
        
          <Typography component="h1" variant="h5">
            Forgot Password?
          </Typography>
          <br/>
          <p className='text-center'> Type your email. Link to reset password will be sent to you  </p>
          <form className={classes.form} noValidate>
          <br/>
            <div className="form-group icon-input mb-1">
            <i
                  className="font-sm feather-mail text-grey-500 pe-5"
                
                ></i>
              <input
                // type="Password"
                style={{paddingLeft: 40}}
                type="email"
                placeholder="Type Your Email"
                className="style2-input form-control text-grey-900 font-xsss ls-3"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            
                
            
            </div>
           {isRequesting ? 
            <Button
             disabled
              fullWidth
              variant="contained"
              // color="primary"
              style={{backgroundColor: '#000', color: '#FFD700'}}
              className={classes.submit}
              // onClick={validate}
            >
              Sending Request
            </Button>

            :

            <Button
              // type="submit"
              fullWidth
              variant="contained"
              // color="primary"
              style={{backgroundColor: '#000', color: '#FFD700'}}
              className={classes.submit}
              onClick={validate}
            >
              Send Password Reset Link
            </Button>

           }

            {emptyFields ? (
              <div>
                <Alert severity="warning" onClose={handleClose}>
                  Please type in your email first
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}
             {isSuccess ? (
              <div>
                <Alert severity="success" onClose={handleClose}>
                 {errorMessage} 
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}
            {resetError ? (
              <div>
                <Alert severity="error" onClose={handleClose}>
                {errorMessage} [ Ensure your email is correct.  ]
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link href="/signin" variant="body2">
                  {"Remembered password? Sign In"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}