import React, { useState, useEffect, useRef } from "react";

import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Button, { OutlineButton } from "../button/Button";
import Modal, { ModalContent } from "../modal/Modal";
import ReactPlayer from "react-player";
import axiosFetch from "../../config/Interceptors";
import CloseIcon from "@mui/icons-material/Close";
import ImageComponentDiv from "../others/ImageComponentDiv";
import ImageComponent from "../others/ImageComponent";
import ShowMoreText from "react-show-more-text";
import tmdbApi, { category, movieType } from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import { Grid, makeStyles } from "@material-ui/core";

import "./hero-slide.scss";
import { useHistory } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const useStyles = makeStyles((theme) => ({
  pc: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const HeroSlide = (props) => {
  SwiperCore.use([Autoplay]);

  useEffect(() => {
    // getLatestMovies();
    // getMovies();
  }, []);

  if (props.movieItems.length == 0) {
    return (
      <div style={{ marginTop: 30, marginBottom: 20 }}>
        {/* <p className="text-white">Just A Sec</p> */}
      </div>
    );
  } else {
    return (
      <div className="hero-slide" style={{ backgroundColor: "#000" }}>
        <Swiper
          modules={[Autoplay]}
          grabCursor={true}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 7000 }}
          // autoplay={true}
        >
          {props.movieItems.map((movie, i) => (
            <SwiperSlide key={i}>
              {({ isActive }) => (
                <>
                  <HeroSlideItem
                    movie={movie}
                    className={`${isActive ? "active" : ""}`}
                  />
                </>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        {props.movieItems.map((movie, i) => (
          <TrailerModal key={i} movie={movie} />
        ))}
      </div>
    );
  }
};

const HeroSlideItem = (props) => {
  let history = useHistory();
  const movie = props.movie;
  const classes = useStyles();
  const [promptMessage, setPromptMessage] = useState("");
  const [promptType, setPromptType] = useState("login");
  const [promptAction, setPromptAction] = useState("Login Now");

  const background = movie.cover_image;
  // apiConfig.originalImage(item.backdrop_path ? item.backdrop_path : item.poster_path);

  const setModalActive = async () => {
    const modal = document.querySelector(`#modal2_${movie.id}`);
    const videSrc = "https://www.youtube.com/watch?v=M8SwF2qUtts";
    modal.classList.toggle("active");
  };

  const [prompt, setPrompt] = React.useState(false);

  const openPrompt = () => {
    setPrompt(true);
  };

  const handleClose = (value) => {
    setPrompt(false);
  };
  const onClose = () => {
    setPrompt(false);
  };

  function goToLogin() {
    history.push("/signin");
    history.go();
  }
  function goToMoviePage() {
    history.push("/chillarx/watch/" + movie.u_id);
    history.go();
  }
  function goToChannelPage() {
    history.push("/channel/" + movie.channel.slug + "/comics");
    history.go();
  }

  // var retVal = window.confirm(
  //   `This movie is not free. Click OK to view movie's page`
  // );
  // if (retVal == true) {
  //   history.push({
  //     pathname: "/chillarx/watch/" + movie.u_id,
  //   });
  //   history.go();
  // } else {
  //   console.log("canceled");
  //   return;
  // }

  function validate(movie) {
    const token = window.localStorage.getItem("access_token");
    if (!token) {
      openPrompt();
      setPromptMessage("Oops! You haven't logged in. Click Login to login now");
      setPromptType("login");
      setPromptAction("Login Now");
      // var retVal = window.confirm(
      //   `Oops! You haven't logged in. Click OK to login now`
      // );
      // if (retVal == true) {
      //   history.push('/signin')
      //   history.go()
      // } else {
      //   return
      // }
    }

    if (token && token.length > 0) {
      openPrompt();
      setPromptMessage(
        `Hi! You just click the ${movie.type} title. Click view details to proceed to view more details of this ${movie.type}`
      );
      setPromptType("redirect");
      setPromptAction("View Details");
    }

    // else {
    //   history.push("/chillarx/watch/" + movie.u_id);
    //   window.location.reload();
    // }
  }

  function validateChannel(movie) {
    const token = window.localStorage.getItem("access_token");
    if (!token) {
      openPrompt();
      setPromptMessage("Oops! You haven't logged in. Click Login to login now");
      setPromptType("login");
      setPromptAction("Login Now");
      // var retVal = window.confirm(
      //   `Oops! You haven't logged in. Click OK to login now`
      // );
      // if (retVal == true) {
      //   history.push('/signin')
      //   history.go()
      // } else {
      //   return
      // }
    }

    if (token && token.length > 0) {
      openPrompt();
      setPromptMessage(
        `Hi! You just click the ${movie.type} channel. Click view channel to proceed to channel page`
      );
      setPromptType("channel");
      setPromptAction("View Channel");
    } else {
      // history.push(`/chillarx/search/${movie.channel.name}`);
      // window.location.reload()
      // history.push("/channel/" + movie.channel.slug + "/comics");
      // window.location.reload();
    }
  }

  return (
    <>
      <div
        className={`hero-slide__item ${props.className}`}
        style={{ backgroundImage: `url(${background})` }}
      >
        {/* you can use imag div here */}
        <div className="hero-slide__item__content container">
          <div className="hero-slide__item__content__info">
            <h2
              // className="title"
              style={{
                color: "#fff",
                fontSize: "4rem",
                lineHeight: 1,
                textAlign: "start",
              }}
            >
              {movie.title}
            </h2>
            <br />
            {/* {props.isloggedIn == "true" ? ( */}
            <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
              {/* {movie.title} */}
              Channel :{" "}
              <span style={{ textDecoration: "underline", cursor: 'pointer' }}>
                {" "}
                <a
                  onClick={() => {
                    validateChannel(movie);
                  }}
                >
                  {" "}
                  {movie.channel.name}
                </a>{" "}
              </span>
            </h4>
            {/* // ) : (
            //   <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                
            //     Channelalert :{" "}
            //     <span style={{ textDecoration: "underline" }}>
            //       {" "}
            //       <a
            //         onClick={() => {
            //           alert("Please login first");
            //         }}
            //       >
            //         {" "}
            //         {movie.channel.name}
            //       </a>{" "}
            //     </span>
            //   </h4>
            // )} */}

            <ShowMoreText
              style={{ color: "#fff", fontWeight: 700 }}
              lines={4}
              more="Read more"
              less="Show less"
              className="text-white overview"
              //   anchorClass="my-anchor-css-class overview"
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              {movie.overview}
            </ShowMoreText>
            {/* <div className="overview">{movie.overview}</div> */}
            <div className="btns">
              <Button
                onClick={() => {
                  validate(movie);
                }}
              >
                Watch now
              </Button>
              {movie.trailer ? (
                <OutlineButton onClick={setModalActive}>
                  Watch trailer
                </OutlineButton>
              ) : (
                ""
              )}
            </div>
            <p className="overview" style={{color: '#fff'}}> Swipe >> </p>
          </div>
          <div className="hero-slide__item__content__poster">
            <ImageComponent
              class="banner"
              // class="movie-content__poster__img"
              style={{ height: "100%" }}
              image={movie.image}
            />
            {/* <img src={movie.image} alt="" /> */}
          </div>
        </div>
      </div>

      <Dialog
        open={prompt}
        onClose={handleClose}
        style={{ backgroundColor: "#282C35", opacity: "0.96" }}
      >
        {/* <DialogTitle>Chill!</DialogTitle> */}
        <DialogContent style={{ backgroundColor: "#000" }}>
          <DialogContentText style={{ color: "#fff" }}>
            {promptMessage}
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ backgroundColor: "#000" }}>
          {promptType == "login" ? (
            <h3
              onClick={goToLogin}
              className="text-center"
              style={{ alignSelf: "center", color: "#FFD700", marginRight: 40, cursor: 'pointer' }}
            >
              {promptAction}
            </h3>
          ) : (
            <>
              {promptType == "channel" ? (
                <h3
                  onClick={goToChannelPage}
                  className="text-center"
                  style={{
                    alignSelf: "center",
                    color: "#FFD700",
                    marginRight: 40,
                    cursor: 'pointer'
                  }}
                >
                  {promptAction}
                </h3>
              ) : (
                <h3
                  onClick={goToMoviePage}
                  className="text-center"
                  style={{
                    alignSelf: "center",
                    color: "#FFD700",
                    marginRight: 40,
                    cursor: 'pointer'
                  }}
                >
                  {promptAction}
                </h3>
              )}
            </>
          )}

          <CloseIcon
            style={{ fontSize: 30, color: "#FFD700", cursor: 'pointer' }}
            onClick={onClose}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const TrailerModal = (props) => {
  const movie = props.movie;
  // const [videoUrl, setVideoUrl] = useState('');
  // const [stopVideo, setStopVideo] = useState(true);

  const iframeRef = useRef(null);
  // setVideoUrl(movie.video);

  // const onClose = () => {
  //     setVideoUrl('')
  //     setStopVideo(false)
  //     // iframeRef.current.setAttribute("url", "")
  //   };
  // const onClose = () => iframeRef.current.setAttribute("src", "");
  const onClose = () => console.log("closed");

  return (
    <Modal active={false} id={`modal2_${movie.id}`}>
      <ModalContent onClose={onClose}>
        <ReactPlayer
          ref={iframeRef}
          url={movie.trailer}
          controls={true}
          // playing={stopVideo}
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          playsinline={true}
          pip={true}
          width="100%"
          light={movie.image}
        />
        <br />
        <p style={{ color: "#fff" }}>Pause before closing</p>
        {/* <iframe
          ref={iframeRef}
          width="100%"
          height="500px"
          title="trailer"
        ></iframe> */}
      </ModalContent>
    </Modal>
  );
};

export default HeroSlide;
