import "./channel.scss";

import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";
import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Datatable from "../../components/studio/datatable/Datatable";
import ChannelsComp from "../../components/studio/channel/ChannelsComp";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import axiosFetch from "../../config/Interceptors";
import MovieGrid from "../../components/movie-grid/MovieGrid";
import SeriesGrid from "../../components/movie-grid/SeriesGrid";
import { Button } from "@mui/material";

const UnapprovedMovies = (props) => {
  const history = useHistory();
  const [movies, setMovies] = useState([]);
  const [series, setSeries] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [fecthingMovies, setFetchingMovies] = useState(false);

//   Route::get('/all_pending', 'MoviesController@getPending');
//   Route::post('/approve_movie/{id}', 'MoviesController@approveMovie');
//   Route::post('/series/approve_series/{id}', 'MoviesController@approveSeries');

  async function checkProfile() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/profile`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data.user.is_admin);
        if (resp.data.user.is_admin !== "true") {
          history.push("/welcome");
          history.go();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getPendingContents() {
    setFetchingMovies(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`movie/all_pending`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setMovies(resp.data.pendingMovies);
        setSeries(resp.data.pendingSeries);
        setFetchingMovies(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        // console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    // checkProfile();
    // getPendingContents();
  }, []);

  return (
    <div className="list" style={{ backgroundColor: "#fff" }}>
      {/* <Sidebar /> */}
      {/* <HeaderV2  className="listContainer" /> */}
      <div>

      <div>
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
        <div className="row">
          <div className="col-xl-12">
        {/* <Navbar /> */}
        {/* <div className="datatable"> */}
        <div className="top" style={{ color: "#000" }}>
          <h1>All Movies</h1>
        </div>

        <div
          className="datatableTitle"
          style={{ color: "#000", marginBottom: 20 }}
        >
          Watch And Approve Pending Contents Now. Only approve episodes if its series has been approved as well.
        </div>

        {/* </div> */}
        <MovieGrid movies={props.movies} />

        <h3> Pending Series</h3>
        <SeriesGrid series={props.series} />
        <br/><br/><br/>
        {/* <MoviesGridMap /> */}
        {/* <Datatable/> */}
        </div>
        </div>
        </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default UnapprovedMovies;
