import React, { Component, Fragment, useState, useEffect } from "react";
import {
  Route,
  useParams,
  useLocation,
  useHistory,
  Link,
} from "react-router-dom";
import axios from "axios";
import axiosFetch from "../../../config/Interceptors";
import Load from "../../others/Load";
// import ChannelsTab from "./ChannelsTab";
import ChannelsTabAdmin from "./ChannelsTabAdmin";
import Alert from "@mui/material/Alert";
import ChannelsTabs from "./ChannelsTab";
import ImageComponent from "../../others/ImageComponent";
import ImageComponentDiv from "../../others/ImageComponentDiv";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import UnfoldLessOutlinedIcon from "@mui/icons-material/UnfoldLessOutlined";
import ChannelUpdateModal from "./ChannelUpdateModal";
import { usePaystackPayment } from "react-paystack";
import CancelIcon from "@mui/icons-material/Cancel";
import ShareChannel from "./ShareChannel";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReportModal from "./ReportModal";
// import Alert from "@mui/material/Alert";
import FeatherIcon from "feather-icons-react";

import { Snackbar } from "@material-ui/core";

export default function ChannelCard(props) {
  const userId = useState(window.localStorage.getItem("user_id"));
  const [copied, setCopied] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  
  const [channel, setChannel] = useState([]);
  const [posts, setPosts] = useState([]);
  const [movies, setMovies] = useState("");
  const [videos, setVideos] = useState("");
  const [clicked, setClicked] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [adminDetails, setAdminDetails] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [subscribePromt, setSubscribePromt] = useState(false);
  const [unSubscribePromt, setUnSubscribePromt] = useState(false);
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const [unSubscribeSuccess, setUnSubscribeSuccess] = useState(false);
  const [action, setAction] = useState("");
  const [subscribing, setSubscribing] = useState(false);
  // const [userEmail, setUserEmail] = window.localStorage.getItem("email");
  const userEmail = window.localStorage.getItem("email");
  const [subscribers, setSubscribers] = useState([]);
  const { slug } = useParams(["slug"]);
  const [isfullScreen, setIsfullScreen] = useState(false);
  let history = useHistory();
  const location = useLocation();
  const channelId = "";
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSubscribePromt(false);
    setSubscribeSuccess(false);
    setUnSubscribeSuccess(false);
    setUnSubscribePromt(false);
    setError(false);
  };

  function closeError() {
    setError(false);
    console.log("closed");
  }

  function closeSnack() {
    setCopied(false);
  }

  async function getChannelDetailsLoad() {
    setIsFetching(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/channels/show/${slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          setChannel(resp.data.channel);
          setPosts(resp.data.posts);
          // setSubscribers(resp.data.channel.subscribers.length);
          setAction(resp.data.action);
          setVideos(resp.data.videos);
          setMovies(resp.data.movies);
          setAdminDetails(resp.data.channel.admin);
          setAdminId(resp.data.channel.admin.id);
          setIsFetching(false);

          if (userId[0] == resp.data.channel.admin.id) {
            return setIsLoggedInUser(true);
          } else {
            return setIsLoggedInUser(false);
          }
        } else {
          setIsFetching(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setIsFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.response.data.message);
      });
  }
  async function getChannelDetails() {
    // setIsFetching(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/channels/show/${slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          setChannel(resp.data.channel);
          setPosts(resp.data.posts);
          // setSubscribers(resp.data.channel.subscribers.length);
          setAction(resp.data.action);
          setVideos(resp.data.videos);
          setMovies(resp.data.movies);
          setAdminDetails(resp.data.channel.admin);
          setAdminId(resp.data.channel.admin.id);
          // refreshToken()
          // setIsFetching(false);

          if (userId[0] == resp.data.channel.admin.id) {
            return setIsLoggedInUser(true);
          } else {
            return setIsLoggedInUser(false);
          }
        } else {
          // setIsFetching(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        // setIsFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.response.data.message);
      });
  }


  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function getProfile() {
    setIsFetching(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/profile", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data.user.profile_pic));
        // setUserEmail(resp.data.user.email);
        setIsFetching(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function handleSubscription(id) {
    setSubscribing(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(`/channels/${id}/subscribe`, id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);

        getChannelDetails();
        setSubscribeSuccess(true);
        setSubscribing(false);
        // window.location.reload()
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function recordTransaction(type, description, amount, reference, transaction, status, commission, payout) {
    const access_token = await window.localStorage.getItem("access_token");
    // alert([type, description, amount, reference, commission, payout])
   const formData = new FormData();

    formData.append("channel_id", channel.id);
    formData.append("channel_name", channel.name);
    formData.append("type", type);
    formData.append("description", description);
    formData.append("amount", amount);
    formData.append("reference", reference);
    formData.append("transaction", transaction);
    formData.append("status", status);
    formData.append("commission", commission);
    formData.append("payout", payout);
    formData.append("will_expire", true);
    formData.append("pay_type", 'channel');

   
    axiosFetch
      .post(`/users/add_transaction`, formData, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);

        // props.functionProp();
        // setChannelSubSuccess(true);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function unSubscribe(id) {
    if (channel.type == "paid") {
      setUnSubscribePromt(true);
    } else {
      handleUnSubscribe(id);
    }
  }

  async function handleUnSubscribe(id) {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(`/channels/${id}/unsubscribe`, id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);

        getChannelDetails();
        setUnSubscribePromt(false);
        setUnSubscribeSuccess(true);
        // window.location.reload()
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function toogle() {
    if (isfullScreen == true) {
      return setIsfullScreen(false);
    } else {
      return setIsfullScreen(true);
    }
  }

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    // console.log(`channel id is == ${channel.id}`)
    handleSubscription(channel.id);
    recordTransaction('Paid Channel Subscripion', `Subsciption to get access to ${channel.name}'s premium contents`, channel.price, reference.reference, reference.transaction, `${reference.status } - ${reference.message}`, '10', channel.price)
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
    setSubscribePromt(true);
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: userEmail,
    amount: channel.price * 100,
    // publicKey: channel.public_key,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
    // 'pk_live_cc47dc307b9d60385a97247bb1438f1ef7138b03',
    // props.publicKey subaccount_code,
    subaccount: channel.subaccount_code,
  };

  const initializePayment = usePaystackPayment(config);

  function subscribe() {}

  useEffect(() => {
    getChannelDetailsLoad();
    // alert(userId[0])
    // getProfile();
    // getById()
    // console.log(props.channelId);
    {
      console.log(window.location.href);
    }
  }, []);

  if (isFetching == true) {
    // console.log(`final ${this.state.fetchingAlerts}`)
    return (
      <>
        {/* <h3> No Alert Has Been Recently Created </h3> */}
        <div className="main-content">
        <Load bg='#fff' color='#000' />
        </div>
      </>
    );
  }

  if (isFetching.length == 0) {
    // console.log(`final ${this.state.fetchingAlerts}`)
    return (
      <>
        {/* <h3> No Alert Has Been Recently Created </h3> */}
        <div className="main-content">
        <Load bg='#fff' color='#000' />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="main-content" style={{ height: "100%" }}>
          <div className="middle-sidebar-bottom" style={{ height: "100%" }}>
            <div className="middle-sidebar-left pe-0" style={{ height: "100%" }}>
              <div style={{ height: "100%" }}>
                <div
                  style={{ height: "100%" }}
                  className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 mt-3 pb-3"
                >
                  {isfullScreen ? (
                    ""
                  ) : (
                    <div>
                      {channel.cover_pic === null ? (
                        <>
                          <div
                            className="card-body position-relative h240 bg-image-cover bg-image-center"
                            style={{
                              backgroundImage: `url("https://via.placeholder.com/1200x250.png")`,
                            }}
                          ></div>
                        </>
                      ) : (
                        <>
                          <ImageComponentDiv
                            image={channel.cover_pic}
                            class="card-body position-relative h240 bg-image-cover bg-image-center"
                          />
                          {/* <div
                className="card-body position-relative h240 bg-image-cover bg-image-center"
                style={{ backgroundImage: `url(${channel.cover_pic})` }}
              >
              </div> */}
                        </>
                      )}
                      {/* {clicked ? <ImageComponent isOpen='true' image={channel.cover_pic} class='card-body position-relative h240 bg-image-cover bg-image-center'/> : ''} */}

                      <div className="card-body d-block pt-4 text-center">
                        <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
                          {channel.channel_pic === null ? (
                            <img
                              onError={fixImage}
                              src={
                                "https://alert-app-v1.s3.amazonaws.com/user.png"
                              }
                              alt="avater"
                              className="p-1 bg-white rounded-xl w-100"
                            />
                          ) : (
                            <ImageComponent
                              image={channel.channel_pic}
                              class="p-1 bg-white rounded-xl w-100"
                            />
                            // <img
                            //   onError={fixImage}
                            //   src={channel.channel_pic}
                            //   alt="avater"
                            //   className="p-1 bg-white rounded-xl w-100"
                            // />
                          )}
                          {/* <img src={channel.channel_pic} alt="avater" className="p-1 bg-white rounded-xl w-100" /> */}
                        </figure>
                        <h4 className="font-xs ls-1 fw-700 text-grey-900">
                          {channel.name}{" "}
                          <span className="d-block font-xss fw-500 mt-1 lh-3 text-green">
                            Is Approved: {channel.is_approved}
                          </span>
                          <Link to={`/profile/${adminDetails.user_name}`}>
                            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                              Admin: @{adminDetails.user_name}
                            </span>
                          </Link>
                          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                            Type: {channel.type} {channel.price}
                          </span>
                         
                        </h4>
                      </div>
                      <div className="card-body d-flex justify-content-center align-items-center ps-4 pe-4 pt-0">
                        <Link>
                          {" "}
                          <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2">
                            <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
                              {movies}
                            </b>{" "}
                            Movies
                          </h4>{" "}
                        </Link>
                        <Link>
                          {" "}
                          <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2">
                            <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
                              {videos}
                            </b>{" "}
                            Videos
                          </h4>{" "}
                        </Link>
                        <Link>
                          {" "}
                          <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2">
                            <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
                              {channel.subscribers_count}
                            </b>{" "}
                            Subscribers
                          </h4>{" "}
                        </Link>
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0">
                      { channel.is_approved == 'false' ? (
                             <a
                             onClick={() => {
                              //  handleSubscription(channel.id);
                              alert('kindly check back later')
                             }}
                             className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                           >
                             {/* free  */}
                             Not Yet Approved
                           </a>

                             ) : (
                               <>
                        
                        {subscribing ? (
                          <a
                            disabled
                            // onClick={() => {
                            // handleSubscription(channel.id);
                            // }}
                            className="bg-grey p-3 z-index-1 rounded-3 text-primary font-xsssss text-uppercase fw-700 ls-3"
                          >
                            {/* free  */}
                            Just A Sec...
                          </a>
                        ) : (
                          <>
                           

                            {action == "SUBSCRIBE" ? (
                              <>
                                {channel.type == "paid" ? (
                                  <a
                                    onClick={() => {
                                      initializePayment(onSuccess, onClose);
                                    }}
                                    className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                                  >
                                    {/* paid  */}
                                    {action} (3 months)
                                  </a>
                                ) : (
                                  <a
                                    onClick={() => {
                                      handleSubscription(channel.id);
                                    }}
                                    className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                                  >
                                    {/* free  */}
                                    {action}
                                  </a>
                                )}
                              </>
                            ) : (
                              <a
                                onClick={() => {
                                  unSubscribe(channel.id);
                                  // initializePayment(onSuccess, onClose);
                                }}
                                // onClick={toogleSubscription(channel.id)}
                                className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                              >
                                {/* unsub */}
                                {action}
                              </a>
                            )}
                          </>
                        )}
                         </>
                        )}

                        {console.log(``)}

                        {isLoggedInUser ? (
                          <>
                            <ChannelUpdateModal
                              channel={channel}
                              adminDetails={adminDetails}
                              functionProp={() => getChannelDetails()}
                            />
                          </>
                        ) : (
                          ""
                        )}
                        <Tooltip title="Share Channel" aria-label="add">
                          <>
                            <CopyToClipboard
                              text={`${process.env.REACT_APP_URL}/signin${window.location.pathname}`}
                              onCopy={() => setCopied(true)}
                            >
                              {/* <div className="d-flex align-items-center justify-content-center position-absolute right-15 top-10 mt-4 me-2"> */}
                                <a
                                style={{marginLeft: 5}}
                                  // href="/profileinformation"
                                  className=" d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                                >
                                  Share
                                </a>
                              {/* </div> */}
                              {/* <a
                        style={{ border: "#eee solid 2px" }}
                        className="btn-round-lg ms-2 d-inline-block rounded-3"
                      >
                        <FeatherIcon icon="share" size="24" color='blue' />
                        
                      </a> */}
                            </CopyToClipboard>
                            <br />
                            {copied ? (
                              <>
                                <Snackbar
                                  open={copied}
                                  autoHideDuration={3000}
                                  onClose={closeSnack}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                >
                                  <Alert
                                    onClose={closeSnack}
                                    severity="success"
                                  >
                                    Link Copied Successfully
                                  </Alert>
                                </Snackbar>
                              </>
                            ) : null}
                          </>
                        </Tooltip>

                        {isLoggedInUser ? (
                          ""
                        ) : (
                          <a
                            // href="/home"
                            className="bg-greylight theme-white-bg btn-round-lg ms-2 rounded-3 text-grey-700"
                          >
                            <ReportModal
                              type="channel"
                              url="channels"
                              id={channel.id}
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                    <br />
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "center",
                        justifyContent: "center",
                      }}
                    >
                      {isfullScreen ? (
                        <UnfoldMoreOutlinedIcon style={{color:'#000'}} onClick={toogle} />
                      ) : (
                        <UnfoldLessOutlinedIcon style={{color:'#000'}} onClick={toogle} />
                      )}
                    </div>
                  </div>
                  <br />
                  {subscribeSuccess ? (
                    <>
                      {/* <br />
                          <br /> */}
                      <Alert onClose={handleClose} severity="success">
                        Hurray!!! You've been subscribed to {channel.name}{" "}
                        successfully! Unlimited contents coming your way, Enjoy!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  {unSubscribeSuccess ? (
                    <>
                      {/* <br />
                          <br /> */}
                      <Alert onClose={handleClose} severity="success">
                        You've been unsubscribed from {channel.name}{" "}
                        successfully!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  {unSubscribePromt ? (
                    <>
                      {/* <br />
                          <br /> */}
                      <Alert
                        onClose={handleClose}
                        severity="error"
                        action={
                          <>
                            <a
                              onClick={handleClose}
                              className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              No, undo.
                            </a>

                            <a
                              onClick={() => handleUnSubscribe(channel.id)}
                              className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              Yes, cancel it.
                            </a>
                          </>
                        }
                      >
                        Are you sure you want to cancel your subscription. This
                        is a paid channel!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  {subscribePromt ? (
                    <>
                      {/* <br />
                          <br /> */}
                      <Alert onClose={handleClose} severity="warning">
                        You just canceled your subscription process. Click the
                        subscribe button to restart.
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  {error ? (
                    <div>
                      <br />
                      <Alert
                        severity="error"
                        onClose={handleClose}
                        action={
                          <>
                            <CancelIcon onClick={closeError} />
                          </>
                        }
                      >
                        There was an issue fetching channel details. Kindly
                        refresh or check your network. If this continues, kindly
                        login again [ {errorMessage} ]
                      </Alert>
                      {/* <admin severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                      <br />
                    </div>
                  ) : (
                    ""
                  )}


                  {isLoggedInUser ? (
                    <ChannelsTabAdmin
                      channel={channel}
                      action={action}
                      posts={posts}
                      admin={adminDetails}
                      adminId={adminId}
                      functionProp={() => getChannelDetails()}
                    />
                  ) : (
                    <ChannelsTabs
                      channel={channel}
                      action={action}
                      posts={posts}
                      admin={adminDetails}
                      adminId={adminId}
                      functionProp={() => getChannelDetails()}
                    />
                  )}

                  <div style={{ marginBottom: 100 }}></div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// }

// export default ChannelCard;
