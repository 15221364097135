import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
// import axiosFetch from "../Interceptors2";
import Button from "@material-ui/core/Button";
// import Autocomplete from "@mui/material/Autocomplete";
// import { Dropdown } from "semantic-ui-react";
import Tooltip from "@mui/material/Tooltip";
// import Darkbutton from "../components/Darkbutton";
// import { useHistory, useParams } from "react-router";
// import SearchBarV2 from "../components/SearchBarV2";
import NotificationComp from "../../others/NotificationComp";
// import Test4 from "../components/Test4";
// import DarkMode from "../components/Darkbutton";
import BoltIcon from "@mui/icons-material/Bolt";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import LiveTvIcon from "@mui/icons-material/LiveTv";
// import { loadAnimation, lottie } from "lottie-web";
// import { defineLordIconElement } from "lord-icon-element";

import { Notifications } from "react-push-notification";
import addNotification from "react-push-notification";
import FeatherIcon from "feather-icons-react";

class HeaderV2 extends Component {
  state = {
    isOpen: false,
    isActive: false,
    isNoti: false,
    user_name: window.localStorage.getItem("user_name"),
    profile_pic: window.localStorage.getItem("profile_pic"),
    verified: window.localStorage.getItem("verified"),
    searchItem: "",
    // headerStyle:'#fff',
    result: [],
    users: [],
    // history: useHistory()
  };

  // register lottie and define custom element
  // Bolt(loadAnimation)

  buttonClick = () => {
    addNotification({
      title: "Warning",
      subtitle: "This is a subtitle",
      message: "This is a very long message",
      theme: "darkblue",
      icon: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.youtube.com%2Fchannel%2FUCIYBgiBUefVLJB9bq2dlp2g%2Ffeatured&psig=AOvVaw3bVvMDfp6nUyX3NmwD4Dd1&ust=1651175029043000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCNiwkLyAtfcCFQAAAAAdAAAAABAD",
      native: true, // when using native, your OS will handle theming.
      duration: 8000,
    });
  };

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  toggleActive = () => this.setState({ isActive: !this.state.isActive });
  toggleisNoti = () => this.setState({ isNoti: !this.state.isNoti });
  // defineLordIconElement(loadAnimation);
  logout = async () => {
    // const access_token = await window.localStorage.getItem("access_token");
    // let result = await axios
    //   .post(
    //     `https://alert-lumen-api.herokuapp.com/api/logout`, access_token,
    //     {
    //       headers: {
    //         Accept: "*/*",
    //         // 'Content-Type': 'multipart/form-data',
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + access_token,
    //       },
    //     }
    //   )
    //   .then((resp) => {
    window.localStorage.clear();
    // window.sessionStorage.clear();

    var now = new Date();
    var date = now.getFullYear() + (now.getMonth() + 1) + now.getDate();
    // var date = now.getFullYear() + now.getMonth() + now.getDate();
    var time = now.getHours() + now.getMinutes();
    //  + now.getSeconds();
    var dateTime = date + time;
    console.log(dateTime);
    window.localStorage.setItem("last_login", dateTime);

    window.location.href = "/welcome";
    // })
    // .catch((error) => console.log(`Error Seen : ${error}`));
  };

  // onSelect = (data) => {
  //   console.log("onSelect", data);
  //   history.push(`/profile/${data}`);
  //   history.go(`/profile/${data}`);
  // };

  // search = async (e) => {
  //   e.preventDefault();
  //   console.log(`testing ${this.state.searchItem}`);
  //   // const search = this.state.searchItem;
  //   const access_token = await window.localStorage.getItem("access_token");
  //   let result = await axiosFetch
  //     .get(`/users/search/${this.state.searchItem}`, {
  //       headers: {
  //         Accept: "*/*",
  //         // 'Content-Type': 'multipart/form-data',
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + access_token,
  //       },
  //     })
  //     .then((resp) => {
  //       // uploadImage();
  //       console.log(resp.data);

  //       // window.localStorage.setItem("alertSearch", resp.data);
  //       // const alertsList = window.localStorage.getItem("alertSearch");
  //       this.setState({ result: resp.data });
  //       // console.log(alertsList)
  //     })
  //     .catch((error) => console.log(`Error Seen : ${error}`));
  // };

  // searchUsers = async () => {
  //   // e.preventDefault()
  //   // const search = this.state.searchItem;
  //   const access_token = await window.localStorage.getItem("access_token");
  //   let result = await axiosFetch
  //     .get("/users", {
  //       headers: {
  //         Accept: "*/*",
  //         // 'Content-Type': 'multipart/form-data',
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + access_token,
  //       },
  //     })
  //     .then((resp) => {
  //       // uploadImage();
  //       // console.log(JSON.stringify(resp.data));
  //       this.setState({ users: JSON.stringify(resp.data) });
  //     })
  //     .catch((error) => console.log(`Error Seen : ${error}`));
  // };

  componentDidMount() {
    // this.searchUsers();
  }
  render() {
    const navClass = `${this.state.isOpen ? " nav-active" : ""}`;
    const buttonClass = `${this.state.isOpen ? " active" : ""}`;
    const searchClass = `${this.state.isActive ? " show" : ""}`;
    const notiClass = `${this.state.isNoti ? " show" : ""}`;
    // const theme = localStorage.getItem("theme")
    // if (theme === 'theme-dark') {
    //   this.setState({ headerStyle: "#152238"});
    // } else {
    //   this.setState({ headerStyle: "#fff"});
    // }

    return (
      <>
        <div
          style={{ backgroundColor: "#fff" }}
          className="nav-header bg-white shadow-xs border-0"
        >
          <div className="nav-top">
            {/* zIndx */}
            <Link to="/" style={{ backgroundColor: "#fff", marginTop: -7 }}>
              {/* <BoltIcon style={{ fontSize: 35 }} /> */}
              {/* <OfflineBoltIcon style={{ fontSize: 40, color: '#3080e8' }}/> */}
              {/* <i
                className=" btn-round-md me-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: '#000'
                }}
              > */}
                {/* <LiveTvIcon style={{ fontSize: 30, color: '#FFD700' }} /> */}
              {/* </i> */}
              <img src="/assets/images/chill512.png" style={{height: 30, width: 30, marginLeft:'-35', paddingRight:10 }} /> 
              {/* <lord-icon trigger="loop"  src="https://cdn.lordicon.com/lusqsztk.js"></lord-icon> style={{backgroundColor: '#000', color: '#FFD700'}} */}
              {/* <i className="feather-zap text-success display2-size me-3 ms-0"></i> */}
              <span
                style={{ color: "#daa520", fontFamily:'Verdana', }}
                className="d-inline-block ls-3 fw-800 font-xs  mb-0"

              >
            
                CHILLARX!
              </span>{" "}
            </Link>
            {/* <Darkbutton />
            <DarkMode /> */}

            <Link
              id="dropdownMenu3"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={this.toggleisNoti}
              // to="/chats"
              className="mob-menu ms-auto me-2 chat-active-btn"
            >
              <span className="dot-count bg-success"></span>
              {/* <span className="circle-count bg-success mt-0">23</span> */}
              {/* <span className="text-grey-900 font-sm btn-round-md bg-greylight" > */}
              <img src="/assets/images/notification.webp" />
              {/* </span> */}
              {/* <i className="feather-bell text-grey-900 font-sm btn-round-md bg-greylight"></i> */}
            </Link>
            <div
              className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${notiClass}`}
              aria-labelledby="dropdownMenu3"
            >
              <NotificationComp />
            </div>

            {/* <Link to="/profileinformation" className="mob-menu me-2">
              <i className="feather-user text-grey-900 font-sm btn-round-md bg-greylight"></i>
            </Link> */}
            <span
              // onClick={this.toggleActive}
              className="me-2 menu-search-icon mob-menu"
            >
              {/* <i className="feather-search text-grey-900 font-sm btn-round-md bg-greylight" style={{display: 'flex', fontSize: 20, justifyContent: 'center', alignItems:'center'}}></i> */}
              <Link to="/settings" className="p-0 ms-3 menu-icon">
                {/* <i className="feather-settings font-xl text-current"></i> */}
                <img
                  src={this.state.profile_pic}
                  alt="user"
                  className="mt--1 shadow-sm rounded-circle w45"
                />
              </Link>
            </span>

            <button
              onClick={this.toggleOpen}
              className={`nav-menu me-0 ms-2 ${buttonClass}`}
            ></button>
          </div>

          <form
            onSubmit={this.search}
            className="float-left header-search ms-3"
          >
            <div
              className="form-group mb-0 icon-input text-black d-block w-100"
              style={{ color: "#000" }}
            >
              {/* <div> */}
              {/* <Test4 /> */}
              {/* <SearchBarV2
                searchUrl="users/search"
                redirect="user"
                className="d-block w-100"
              /> */}

              {/* <i className="feather-search font-sm text-grey-400"></i>
              <input
                type="text"
                placeholder="Search For Users.."
                onChange={(e) => this.setState({ searchItem: e.target.value })}
                className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg"
              /> */}
            </div>
          </form>

          {/* <div className="p-2 text-center ms-3 ">
            {this.state.verified === "null" ? (
              <a
                href="/email_verification"
                class="bg-current flex font-bold hidden hover:bg-current hover:text-white inline-block items-center lg:block mr-4 px-4 py-2 rounded shado text-white"
              >
                Verify Email
              </a>
            ) : (
              ""
            )}
          </div>  */}

          {/* <NavLink
            activeClassName="active"
            to="/channels"
            className="p-2 text-center ms-0 menu-icon center-menu-icon"
          >
            <i className="feather-camera font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 ">
            <FeatherIcon icon="tv" size="22" />
            </i>
          </NavLink> */}
          {/* <NavLink
            activeClassName="active"
            to="/"
            className="p-2 text-center ms-0 menu-icon center-menu-icon"
          >
            <i className="feather-tv font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
          </NavLink> */}
          {/* <a href={`/profile/${this.state.user_name}`} className="p-2 text-center ms-0 menu-icon center-menu-icon" > */}
          {/* <NavLink
            activeClassName="active"
            to={`/profile/${this.state.user_name}`}
            className="p-2 text-center ms-0 menu-icon center-menu-icon"
          >
            <i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 ">
            <FeatherIcon icon="user" size="22" />
            </i>
          </NavLink> */}
          {/* </a> */}
          {/* <NavLink activeClassName="active" to="/shop2" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-shopping-bag font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> */}
          {/* <img src='/assests/images/notification.webp' /> */}
          <span
            className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`}
            id="dropdownMenu3"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={this.toggleisNoti}
          >
            <span className="dot-count bg-success"></span>
            {/* <span className="circle-count bg-success mt-0">23</span> */}
            <img src="/assets/images/notification.webp" />
            {/* <i className="feather-bell font-xl text-current"></i> */}
          </span>
          <div
            className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${notiClass}`}
            aria-labelledby="dropdownMenu3"
          >
            <NotificationComp />
          </div>
          {/* <Link
            to="/chats"
            className="p-2 text-center ms-3 menu-icon chat-active-btn"
          >
            <i className="feather-message-circle font-xl text-current"></i>
          </Link> */}
          {/* <Darkbutton /> */}
          <Link to="/settings" className="p-0 ms-3 menu-icon">
            {/* <i className="feather-settings font-xl text-current"></i> */}
            <img
              src={this.state.profile_pic}
              alt="user"
              className="mt--1 shadow-sm rounded-circle w45"
            />
          </Link>

          <nav
            className={`navigation scroll-bar ${navClass}`}
            style={{ backgroundColor: "#fff" }}
          >
            <div
              className="container ps-0 pe-0"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="nav-content">
                <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                  <div className="nav-caption fw-600 font-xssss text-grey-500">
                    <span>Quick </span>Links
                  </div>
                  <ul className="mb-1 top-content">
                    <li className="logo d-none d-xl-block d-lg-block"></li>

                    {window.localStorage.au == 'true' && (
                      <>
                      <li>
                        <a
                          href="/all_channels/admin"
                          className="nav-content-bttn open-font"
                        >
                          <i
                            // className=" btn-round-md bg-gold-gradiant me-3"
                            style={{
                              display: "flex",
                              fontSize: 20,
                              color: '#36454F',
                              paddingRight: 10,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FeatherIcon icon="monitor" size="22" />
                          </i>
                          <Tooltip title="Admin Tabs" arrow placement="right">
                            <span style={{color: '#36454F'}}>Admin</span>
                          </Tooltip>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/all_transactions/admin"
                          className="nav-content-bttn open-font"
                        >
                          <i
                            // className=" btn-round-md bg-gold-gradiant me-3"
                            style={{
                              display: "flex",
                              fontSize: 20,
                              color: '#36454F',
                              paddingRight: 10,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FeatherIcon icon="credit-card" size="22" />
                          </i>
                          <Tooltip title="Admin Tabs" arrow placement="right">
                            <span style={{color: '#36454F'}}>Transactions</span>
                          </Tooltip>
                        </a>
                      </li>
                      </>
                      )}

                       <li>
                        <a
                          href="/all/channels"
                          className="nav-content-bttn open-font"
                        >
                          <i
                            // className=" btn-round-md bg-gold-gradiant me-3"
                            style={{
                              display: "flex",
                              fontSize: 20,
                              color: '#36454F',
                              paddingRight: 10,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FeatherIcon icon="video" size="22" />
                          </i>
                          <Tooltip title="All Channels" arrow placement="right">
                            <span style={{color: '#36454F'}}>All Channels</span>
                          </Tooltip>
                        </a>
                      </li> 
                    
                    

                    <li>
                      <a
                        href="/channels"
                        className="nav-content-bttn open-font"
                      >
                        <i
                          // className=" btn-round-md bg-black-gradiant me-3"
                          style={{
                            display: "flex",
                            fontSize: 20,
                            color: '#36454F',
                              paddingRight: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FeatherIcon icon="monitor" size="22" />
                        </i>
                        <Tooltip title="Your Channels" arrow placement="right">
                          <span style={{color: '#36454F'}}>My Channels</span>
                        </Tooltip>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/chill-list"
                        className="nav-content-bttn open-font"
                      >
                        <i
                          // className="btn-round-md bg-gold-gradiant me-3"
                          style={{
                            display: "flex",
                            fontSize: 20,
                            color: '#36454F',
                              paddingRight: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FeatherIcon icon="film" size="22" />
                        </i>
                        <Tooltip
                          title="Your Saved Videos For Later"
                          arrow
                          placement="right"
                        >
                          <span style={{color: '#36454F'}}>Chill List </span>
                        </Tooltip>
                      </a>
                    </li>

                    <li>
                      <a
                        href="/chill-history"
                        className="nav-content-bttn open-font"
                      >
                        <i
                          // className="btn-round-md bg-gold-gradiant me-3"
                          style={{
                            display: "flex",
                            fontSize: 20,
                            color: '#36454F',
                              paddingRight: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FeatherIcon icon="eye" size="22" />
                        </i>
                        <Tooltip
                          title="Contents You've Explored"
                          arrow
                          placement="right"
                        >
                          <span style={{color: '#36454F'}}>Chill History </span>
                        </Tooltip>
                      </a>
                    </li>

                    <li>
                      <a
                        href="/my-movies"
                        className="nav-content-bttn open-font"
                      >
                        <i
                          // className=" btn-round-md bg-gold-gradiant me-3"
                          style={{
                            display: "flex",
                            fontSize: 20,
                            color: '#36454F',
                              paddingRight: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FeatherIcon icon="credit-card" size="22" />
                        </i>
                        {/* <i className="feather-award btn-round-md bg-red-gradiant me-3"></i> */}
                        <Tooltip
                          title="Movies You've Purchased"
                          arrow
                          placement="right"
                        >
                          <span style={{color: '#36454F'}}>My Movies</span>
                        </Tooltip>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/my-subscriptions"
                        className="nav-content-bttn open-font"
                      >
                        <i
                          // className="btn-round-md bg-gold-gradiant me-3"
                          style={{
                            display: "flex",
                            fontSize: 20,
                            color: '#36454F',
                              paddingRight: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FeatherIcon icon="video" size="22" />
                        </i>
                        {/* <i className="feather-award btn-round-md bg-red-gradiant me-3"></i> */}
                        <Tooltip
                          title="Explore Your Channel Subscriptions"
                          arrow
                          placement="right"
                        >
                          <span style={{color: '#36454F'}}>My Subscritions</span>
                        </Tooltip>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`/profile/${this.state.user_name}`}
                        className="nav-content-bttn open-font"
                      >
                        <i
                          // className=" btn-round-md bg-gold-gradiant me-3"
                          style={{
                            display: "flex",
                            fontSize: 20,
                            color: '#36454F',
                              paddingRight: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FeatherIcon icon="user" size="22" />
                        </i>
                        {/* <i className="feather-award btn-round-md bg-red-gradiant me-3"></i> */}
                        <Tooltip
                          title="View Your Profile"
                          arrow
                          placement="right"
                        >
                          <span style={{color: '#36454F'}}>Profile</span>
                        </Tooltip>
                      </a>
                    </li>
                 
                    {/* <li>
                      <a href="/pages" className="nav-content-bttn open-font">
                        <i className="feather-layout btn-round-md bg-gold-gradiant me-3" style={{display: 'flex', fontSize: 20, justifyContent: 'center', alignItems:'center'}}></i>
                       
                        <span>Pages</span>
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        href={`/profile/${this.state.user_name}`}
                        className="nav-content-bttn open-font"
                      >
                        <i className="feather-user btn-round-md bg-gold-gradiant me-3"></i>
                         <i className="feather-user btn-round-md bg-primary-gradiant me-3"></i> 
                        <Tooltip
                          title="Update Your Profile"
                          arrow
                          placement="right"
                        >
                          <span>Profile </span>
                        </Tooltip>
                      </a>
                    </li> */}
                  </ul>
                </div>

                <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
                  <div className="nav-caption fw-600 font-xssss text-grey-500">
                    <span></span> Account
                  </div>
                  <ul className="mb-1">
                    <li className="logo d-none d-xl-block d-lg-block"></li>
                   
                    <li>
                      <Link
                        to="/settings"
                        className="nav-content-bttn open-font h-auto pt-2 pb-2"
                      >
                        <i style={{paddingRight: 10, color: '#36454F'}}>
                        <FeatherIcon icon="settings"  size="20" />
                        </i>
                        {/* <i className="font-sm feather-settings me-3 text-grey-500"></i> */}
                        <span style={{color: '#36454F'}}>Settings</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/analytics"
                        className="nav-content-bttn open-font h-auto pt-2 pb-2"
                      >
                        <i style={{paddingRight: 10, color: '#36454F'}}>
                        <FeatherIcon icon="pie-chart" size="20" />
                        </i>
                        {/* <i className="font-sm feather-pie-chart me-3 text-grey-500"></i> */}
                        <span style={{color: '#36454F'}}>Analytics</span>
                      </Link>
                    </li> 
                    {this.state.verified === "null" ? (
                    <li>
                      <a
                        href="/email_verification"
                        className="nav-content-bttn open-font h-auto pt-2 pb-2"
                      >
                          <i style={{paddingRight: 10, color: '#36454F'}}>
                          <FeatherIcon icon="mail" size="20"/>
                          </i>
                        {/* <i className="font-sm feather-user me-3 text-grey-500"></i> */}
                        <span style={{color: '#36454F'}}>Verify Email</span>
                      </a>
                    </li>
                     ) : (
                      ""
                    )}
                    <li>
                      <a
                         href="https://wa.me/message/3VHYR6VAI3MSD1"
                         target="_blank"
                        className="nav-content-bttn open-font h-auto pt-2 pb-2"
                      >
                        <i style={{paddingRight: 10, color: '#36454F'}}>
                          <FeatherIcon icon="users" size="20"/>
                          </i>
                        {/* <i className="font-sm feather-user me-3 text-grey-500"></i> */}
                        <span style={{color: '#36454F'}}>Whatsapp Support</span>
                      </a>
                    </li>
                    {/* <li>
                      <Link
                        to="/chats"
                        className="nav-content-bttn open-font h-auto pt-2 pb-2"
                      >
                        <i className="font-sm feather-message-circle me-3 text-grey-500"></i>
                        <span>Chats</span>
                        <span className="circle-count bg-success mt-0">23</span>
                      </Link>
                    </li> */}
                    <br />
                    <br />

                    {/* <div className="p-2 text-center ms-3 ">
                      {this.state.verified === "null" ? (
                        <Link
                          to="/email_verification"
                          // target='_blank'
                          style={{ marginBottom: 10 }}
                          className="bg-gold-gradiant font-bold hover:bg-current hover:text-white inline-block items-center lg:block  mr-4 px-4 py-2 rounded shado text-white"
                        >
                          <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
                            Verify Email
                          </span>{" "}
                        </Link>
                      ) : (
                        ""
                      )}
                    </div> */}

                    {/* <Button onClick={() => this.buttonClick()}>Show Toast</Button> */}
                    {/* Updated this so i can make a change */}

                    {/* <a
                      // to="/support"
                      href="https://wa.me/message/3VHYR6VAI3MSD1"
                      target="_blank"
                      style={{
                        marginBottom: 10,
                        display: "flex",
                        justifyContent: "center",
                        justifySelf: "center",
                      }}
                      className="bg-gold-gradiant text-white hover:bg-current hover:text-white font-bold inline-block justify-center text-center items-center lg:block  mr-4 px-4 py-2 rounded shado "
                    >
                      <span className="d-inline-block ls-3 fw-600 text-center text-white font-xxs mb-0">
                        Whatsapp Support
                      </span>{" "}
                      <i className="font-sm feather-arrow-up-right text-white-500 pe-0"></i>
                    </a> */}

                    {/* <Button
                      variant="contained"
                      color="secondary"
                      href="/technical-support"
                      target='_blank'
                    >
                     Tech Support
                    </Button><br/> */}
                     <Button
                      // type="submit"
                      fullWidth
                      // disabled
                      variant="contained"
                      color="primary"
                      style={{backgroundColor: '#000', color: '#FFD700'}}
                      target='_blank'
                      href="https://play.google.com/store/apps/details?id=com.its_soa.ChillarxDemo"
                    >
                     Download App
                    </Button>
                    <br/><br/>

                    <Button
                      // type="submit"
                      fullWidth
                      // disabled
                      variant="contained"
                      color="primary"
                      style={{backgroundColor: '#000', color: '#FFD700'}}
                      // className="bg-current"
                      onClick={this.logout}
                    >
                      Sign Out
                    </Button>
                    {/* <li><Link to="/defaultmessage" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-message-square me-3 text-grey-500"></i><span>Chats</span><span className="circle-count bg-warning mt-0">23</span></Link></li> */}
                  </ul>
                </div>

                {/* <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
                                <div className="nav-caption fw-600 font-xssss text-grey-500"><span></span> Account</div>
                                <ul className="mb-1">
                                    <li className="logo d-none d-xl-block d-lg-block"></li>
                                    <li><Link to="/defaultsettings" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-settings me-3 text-grey-500"></i><span>Settings</span></Link></li>
                                    <li><Link to="/defaultanalytics" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-pie-chart me-3 text-grey-500"></i><span>Analytics</span></Link></li>
                                    <li><Link to="/defaultmessage" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-message-square me-3 text-grey-500"></i><span>Chat</span><span className="circle-count bg-warning mt-0">23</span></Link></li>
                                </ul>
                            </div> */}
              </div>
            </div>
          </nav>

          <div className={`app-header-search ${searchClass}`}>
            {/* <SearchBarV2 searchUrl="users/search" redirect="user" /> */}
            <span className="ms-1 mt-1 d-inline-block close searchbox-close">
              <i className="ti-close font-xs" onClick={this.toggleActive}></i>
            </span>
            {/* <form className="search-form" onSubmit={this.search}>
              <div className="form-group searchbox mb-0 border-0 p-1">
                <input
                  type="text"
                  className="form-control border-0"
                  onChange={(e) =>
                    this.setState({ searchItem: e.target.value })
                  }
                  placeholder="Search..."
                />
                <i className="input-icon">
                  <ion-icon
                    name="search-outline"
                    role="img"
                    className="md hydrated"
                    aria-label="search outline"
                  ></ion-icon>
                </i>
                <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                  <i
                    className="ti-close font-xs"
                    onClick={this.toggleActive}
                  ></i>
                </span>
                {this.state.result.map((result) => (
                 <Link
                 to={`/profile/${result.user_name}`}
                >
                <p> @{result.user_name} - {result.first_name} {result.last_name}</p>
                </Link>
                ))}
              </div>
            </form> */}
          </div>
        </div>
        {/* <br/><br/><br/><br/>
            <h1>HELLOOOO  </h1> */}
      </>
    );
  }
}

export default HeaderV2;
