import React, { Component, useState, Fragment, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  useLocation,
  Link,
} from "react-router-dom";
import axios from "axios";
import axiosFetch from "../../config/Interceptors";
import "./singleMovie.css";

import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { category, movieType, tvType } from "../../api/tmdbApi";

import Detail from "../../pages/movies/detail/Detail";
import SeriesDetail from "../../pages/movies/detail/SeriesDetail";
import Load from "../others/Load";
// import HeroSlide from "../../moviesComponents/hero-slide/HeroSlide";
import queryString from "query-string";

export default function SingleMovie(props) {
  const userId = useState(window.localStorage.getItem("user_id"));
  const { u_id } = useParams(["u_id"]);
  const [gottonQuery, setGottonQuery] = useState(false);
  const [channelName, setChannelName] = useState("");
  // const [adminId, setAdminId] = useState("");
  // const [movieId, setMovieId] = useState("");
  const [refreshed, setRefreshed] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [movie, setMovie] = useState([]);
  const [favComments, setFavComments] = useState([]);
  const [series, setSeries] = useState([]);
  const [video, setVideo] = useState("");
  const [views, setViews] = useState("");
  const [trailer, setTrailer] = useState("");
  const [comments, setComments] = useState([]);
  const [casts, setCasts] = useState([]);
  const [likers, setLikers] = useState('');
  const [likedStatus, setLikedStatus] = useState("");
  const [isFavorited, setIsFavorited] = useState("");
  const [ratings, setRatings] = useState("");
  const [isSub, setIsSub] = useState("");
  const [isChillarxSub, setIsChillarxSub] = useState("");
  const [subAccount, setSubAccount] = useState("");
  const [isPurchased, setIsPurchased] = useState("");
  const [seriesSlug, setSeriesSlug] = useState("");
  const [seriesId, setSeriesId] = useState("");
  const [purchasedSeries, setPurchasedSeries] = useState("");
  const [seriesPricing, setSeriesPricing] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [channelSlug, setChannelSlug] = useState("");
  const [channelPrice, setChannelPrice] = useState("");
  const { slug } = useParams(["slug"]);
  const { url } = useParams(["series"]);
  const location = useLocation();
  let history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log(`hiii ${queryParams}`);
  console.log(location.pathname);
  const pathname = location.pathname;

  const localAdminId = queryParams.get("a");
  localStorage.setItem("a", localAdminId);
  const localChannelSlug = queryParams.get("channel");
  localStorage.setItem("channel", localChannelSlug);
  const localMovieId = queryParams.get("w");
  localStorage.setItem("w", localMovieId);

  const adminId = localStorage.getItem("a");
  // const channelSlug = localStorage.getItem("channel");
  const movieId = localStorage.getItem("w");
  //   const url = 'movie';
  // const { url, slug } = useParams();

  // const adminId = location.state.adminId;
  const [query1, setQuery1] = useState("");
  const [query2, setQuery2] = useState("");
  const [query3, setQuery3] = useState("");

  async function checkProfile() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/profile`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data.user.is_admin);
        if (resp.data.user.is_admin == "true") {
          setIsAdmin(true)
          // history.push("/welcome");
          // history.go();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getMovieDetailsLoad() {
    const access_token = await window.localStorage.getItem("access_token");

    if (url == "series") {
      axiosFetch
        .get(`/movie/series/show/v2/${u_id}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        })
        .then((resp) => {
          // console.log(resp.data.series)
          setSeries(resp.data.series);
          setLikedStatus(resp.data.likedStatus);
          setIsFavorited(resp.data.isFavorited);
          setChannelSlug(resp.data.channelSlug);
          setChannelPrice(resp.data.channelPrice);
          setChannelName(resp.data.channelName);
          setLikers(resp.data.likers);
          setFavComments(resp.data.favComments);
          setMovie(resp.data);
          setIsSub(resp.data.isSubscribed);
          setSubAccount(resp.data.subAccount)
          setIsChillarxSub(resp.data.isChillSubscribed);
          setIsPurchased(resp.data.purchased);
          setViews(resp.data.views);
          setPublicKey(resp.data.public_key);
          setVideo(resp.data.series.video);
          setTrailer(resp.data.series.trailer);
          setComments(resp.data.comments);
          setCasts(resp.data.casts);
          setRatings(resp.data.rates);
          setIsFetching(false);

          //   setTrailer(resp.data.movie.trailer);
        })
        .catch((error) => console.log(`Error Seen : ${error}`));
    } else {
      console.log("it is a movie or video!");
      setIsFetching(true);
      axiosFetch
        .get(`/movie/show/single/${u_id}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        })
        .then((resp) => {
          // alert('gotten movie 1')
          // console.log(JSON.stringify(resp.data))
          setMovie(resp.data.movie);
          setLikedStatus(resp.data.likedStatus);
          setIsFavorited(resp.data.isFavorited);
          setChannelSlug(resp.data.channelSlug);
          setChannelPrice(resp.data.channelPrice);
          setChannelName(resp.data.channelName);
          setLikers(resp.data.likers);
          setFavComments(resp.data.favComments);
          setVideo(resp.data.movie.video);
          setViews(resp.data.views);
          setIsSub(resp.data.isSubscribed);
          setSubAccount(resp.data.subAccount)
          setIsChillarxSub(resp.data.isChillSubscribed);
          setIsPurchased(resp.data.purchased);
          setSeriesSlug(resp.data.seriesSlug);
          setSeriesId(resp.data.seriesId);
          setPurchasedSeries(resp.data.purchasedSeries);
          setSeriesPricing(resp.data.seriesPricing);
          setPublicKey(resp.data.public_key);
          setTrailer(resp.data.movie.trailer);
          setComments(resp.data.comments);
          setCasts(resp.data.casts);
          setRatings(resp.data.rates);
          setIsFetching(false);
        })
        .catch((error) => console.log(`Error Seen : ${error}`));
    }
  }

  async function getMovieDetails() {
    const access_token = await window.localStorage.getItem("access_token");
    if (url == "series") {
      axiosFetch
        .get(`/movie/series/show/v2/${u_id}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        })
        .then((resp) => {
          // console.log(resp.data.series)
          setSeries(resp.data.series);
          setLikedStatus(resp.data.likedStatus);
          setIsFavorited(resp.data.isFavorited);
          setChannelSlug(resp.data.channelSlug);
          setChannelPrice(resp.data.channelPrice);
          setChannelName(resp.data.channelName);
          setLikers(resp.data.likers);
          setFavComments(resp.data.favComments);
          setMovie(resp.data);
          setViews(resp.data.views);
          setIsSub(resp.data.isSubscribed);
          setSubAccount(resp.data.subAccount)
          setIsChillarxSub(resp.data.isChillSubscribed);
          setIsPurchased(resp.data.purchased);
          setPublicKey(resp.data.public_key);
          setVideo(resp.data.series.video);
          setTrailer(resp.data.series.trailer);
          setComments(resp.data.comments);
          setCasts(resp.data.casts);
          setRatings(resp.data.rates);
          //   setIsFetching(false);
          //   setTrailer(resp.data.movie.trailer);
        })
        .catch((error) => console.log(`Error Seen : ${error}`));
    } else {
      console.log("it is a movie or video!");
      // setIsFetching(true);
      axiosFetch
        .get(`/movie/show/single/${u_id}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        })
        .then((resp) => {
          // console.log(JSON.stringify(resp.data))
          // alert('gotten movie 2')
          // console.log(JSON.stringify(resp.data))
          setMovie(resp.data.movie);
          setLikedStatus(resp.data.likedStatus);
          setIsFavorited(resp.data.isFavorited);
          setChannelSlug(resp.data.channelSlug);
          setChannelPrice(resp.data.channelPrice);
          setChannelName(resp.data.channelName);
          setLikers(resp.data.likers);
          setFavComments(resp.data.favComments);
          setVideo(resp.data.movie.video);
          setViews(resp.data.views);
          setIsSub(resp.data.isSubscribed);
          setSubAccount(resp.data.subAccount)
          setIsChillarxSub(resp.data.isChillSubscribed);
          setSeriesSlug(resp.data.seriesSlug);
          setSeriesId(resp.data.seriesId);
          setIsPurchased(resp.data.purchased);
          setSeriesPricing(resp.data.seriesPricing);
          setPurchasedSeries(resp.data.purchasedSeries);
          setPublicKey(resp.data.public_key);
          setTrailer(resp.data.movie.trailer);
          setComments(resp.data.comments);
          setCasts(resp.data.casts);
          setRatings(resp.data.rates);
          //   setIsFetching(false);
        })
        .catch((error) => console.log(`Error Seen : ${error}`));
    }
  }

  useEffect(() => {
    getMovieDetailsLoad();
    checkProfile()
    // alert(url);
  }, []);

  // if (movieId === null) {
  //   getQuery()
  // }
  // else{
  //   getMovieDetailsLoad()
  // }
  if (isFetching == true) {
    return (
      <Fragment>
        {/* <div
          className="main-content right-chat-active"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0"> */}
        <div className="row" style={{backgroundColor: '#000'}}>
          <div className="col-xl-12">
            <Load bg='#000' color='#fff'/>
            {/* <h1> helloooo</h1> */}
          </div>
        </div>
        {/* </div>
          </div>
        </div> */}
        {/* </div> */}

        {/* <Appfooter /> */}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {/* <div
          className="main-content right-chat-active"
          style={{ backgroundColor: "#0f0f0f" }}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0"> */}
        <div className="row" style={{ backgroundColor: "#0f0f0f" }}>
          <div className="col-xl-12">
            <div
              //   className="row ps-2 pe-1"
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              {/* <h1>admin id {adminId}</h1> */}
              {/* <HeroSlide /> */}

              {url == "series" ? (
               <SeriesDetail
               series={series}
               movieType={url}
               likers={likers}
               likedStatus={likedStatus}
               isFavorited={isFavorited}
               channelId={series.channel_id}
               seriesMovies={series.movies}
               channelSlug={channelSlug}
               channelPrice={channelPrice}
               channelName={channelName}
               video={video}
               trailer={trailer}
               views={views}
               isSub={isSub}
               subAccount={subAccount}
               isChillarxSub={isChillarxSub}
               isPurchased={isPurchased}
               isAdmin={isAdmin}
              
                purchasedSeries={purchasedSeries}
               publicKey={publicKey}
                // channelId ={channelId}
               comments={comments}
               casts={casts}
               ratings={ratings}
               favComments={favComments}
               adminId={adminId}
               userId={userId}
               functionProp={() => getMovieDetails()}
             
             />
                ) : (
                  <Detail
                movie={movie}
                likers={likers}
                likedStatus={likedStatus}
                isFavorited={isFavorited}
                movieType={url}
                seriesType=""
                channelId={movie.channel_id}
                video={video}
                trailer={trailer}
                comments={comments}
                isSub={isSub}
                subAccount={subAccount}
                isChillarxSub={isChillarxSub}
                isPurchased={isPurchased}
                seriesSlug={seriesSlug}
                seriesId={seriesId}
                purchasedSeries={purchasedSeries}
                seriesPricing={seriesPricing}
                publicKey={publicKey}
                ratings={ratings}
                views={views}
                channelSlug={channelSlug}
                channelPrice={channelPrice}
                channelName={channelName}
                favComments={favComments}
                casts={casts}
                adminId={adminId}
                userId={userId}
                functionProp={() => getMovieDetails()}
                isAdmin={isAdmin}
              />
                      
                     )}
                     {/* {console.log(isPurchased)} */}
            </div>
          </div>
        </div>
        {/* </div>
          </div>
        </div> */}

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  }
}
