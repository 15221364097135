import "./new.scss";
import Sidebar from "../../../components/studio/sidebar/Sidebar";
import Navbar from "../../../components/studio/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axiosFetch from "../../../config/Interceptors";
import { Button } from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";
import { Select } from "antd";
import Resizer from "react-image-file-resizer";
import slugify from "react-slugify";
import { Snackbar } from "@material-ui/core";
import CreateChannel from "../../CreateChannel";

const NewChannel = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [channelDes, setChannelDes] = useState("");
  const [channelStatus, setChannelStatus] = useState("");
  const [channelKey, setChannelKey] = useState("");
  const [channelType, setChannelType] = useState("");
  const [channelPrice, setChannelPrice] = useState("");
  const [channelCover, setChannelCover] = useState("");
  const [channelPic, setChannelPic] = useState("");
  const [channelCoverFe, setChannelCoverFe] = useState("");
  const [channelPicFe, setChannelPicFe] = useState("");
  const [newName, setNewName] = useState(channelName);
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setEmptyEmail(false);
    setIsSuccess(false);
    setEmptyFields(false);
    // setOpenAlert(false);
  };

  function closeError() {
    setError(false);
    console.log("closed");
  }

  function changeType(value) {
    console.log(`type ${value}`);
    setChannelType(value);
  }
  function changeStatus(value) {
    console.log(`status ${value}`);
    setChannelStatus(value);
  }

  function clearPagePics(value) {
    setChannelPicFe("");
    setChannelPic("");
  }
  function clearCoverImage(value) {
    setChannelCover("");
    setChannelCoverFe("");
  }

  function redirect(name) {
    // setIsSuccess(false);
    history.push(`/channel/${name}`);
  }

  async function validate() {
    console.log(`channelName ${channelName}`);
    if (!channelName) {
      setEmptyFields(true);
      return;
    } else {
      createChannel();
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          setChannelPic(uri);
        },
        "file",
        1080,
        1080
      );
    });

  const CompressImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      const image = await resizeFile(file);
      //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  const resizeCoverFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        80,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          setChannelCover(uri);
        },
        "file",
        1080,
        1080
      );
    });

  const compressCoverImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      const image = await resizeCoverFile(file);
      //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  async function validateProfileImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
    console.log(value);
    setChannelPicFe(value);
    // setInvalidFile(false);
    // }
  }

  async function validateCoverImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
    console.log(value);
    setChannelCoverFe(value);
    // setInvalidFile(false);
    // }
  }

  async function createChannel() {
    setIsCreating(true);
    const name = slugify(channelName);
    setNewName(name);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    console.log(`new name is ${newName}`);
    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("name", channelName);
    formData.append("description", channelDes);
    // formData.append("status", "public");
    formData.append("cover_pic", channelCover);
    formData.append("channel_pic", channelPic);
    if (channelKey == "") {
      formData.append("public_key", process.env.REACT_APP_PAYSTACK_PUBLIC_TEST);
    } else {
      formData.append("public_key", channelKey);
    }

    formData.append("type", channelType);
    formData.append("price", channelPrice);
    formData.append("status", channelStatus);

    axiosFetch
      .post("/channels", formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          console.log(`hi ${resp.data}`);
          setChannelName("");
          setChannelDes("");
          setChannelStatus("");
          setChannelCover("");
          setChannelPic("");
          setChannelCoverFe("");
          setChannelPicFe("");
          setChannelKey("");
          setChannelPrice("");
          setIsCreating(false);
          redirect(name);
          console.log(`response ${JSON.stringify(resp.data)}`);
          setIsSuccess(true);
        } else {
          setIsCreating(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }
  const { Option } = Select;
  const types = ["free", "paid"];
  const statusSelect = [
    { value: "public", label: "Publish" },
    { value: "private", label: "Draft" },
  ];

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          {/* <h1>Create A Channennl</h1> */}
          <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
            Create A Channel
          </h4>
          <Link to="/channels" className="link" style={{
              textDecoration: 'none',
              color: 'green',
              fontSize: '16px',
              fontWeight: '400',
              border: '1px solid green',
              padding: '5px',
              borderRadius: '5px',
              cursor: 'pointer',
              marginLeft: 30
          }}>
              Back To Channels
            </Link>
          
        </div>
        {/* <div className="datatable"> */}
       
        <h5 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
          Create a channel now to start uploading and monetizing your content
        </h5>

        <CreateChannel />

        {/* <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput">
                  <label style={{ color: "#000" }}>Hiiii</label>
                  <input type='text' placeholder='placeholder' />
                </div>
               ))} 
              <button>Create</button>
            </form>
          </div>
        </div> */}
      </div>
    </div>


    // </div>
  );
};

export default NewChannel;
