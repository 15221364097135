import "./channel.scss";

import Sidebar from "../../components/studio/sidebar/Sidebar";
import Navbar from "../../components/studio/navbar/Navbar";
import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import Datatable from "../../components/studio/datatable/Datatable";
import ChannelsComp from "../../components/studio/channel/ChannelsComp";
import HeaderV2 from "../../components/studio/navbar/HeaderV2";
import Load from "../../components/others/Load";
import axiosFetch from "../../config/Interceptors";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from "../../datatablesource";
// import DynamicChart from "../../components/Charts/DynamicChart";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./channel.scss";

import { format, formatDistance, subDays } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SeriesAnalytics2 from "./SeriesAnalytics2";
import SeriesAnalytics1 from "./SeriesAnalytics1";
import SeriesTransactions from "./SeriesTransactions";
import MarkerTransactionTable from "../../components/others/MarkerTransactionTable";

const SeriesAnalytics = (props) => {
  const history = useHistory();
  const location = useLocation();
  const u_id = useParams();
  const [data, setData] = useState(userRows);
  const [value, setValue] = React.useState("1");
  const adminId = window.localStorage.getItem("cad");
  const userId = window.localStorage.getItem("user_id");
  const [month, setMonth] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const [message, setMessage] = useState("");
  const [getting, setGetting] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [analytics, setAnalytics] = useState([]);

  function getAnalytics() {
    setGetting(true);
    const access_token = window.localStorage.getItem("access_token");
    const userId = window.localStorage.getItem("user_id");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get(`/movie/series_analytics/get/${u_id.u_id}/${month}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        setAnalytics(resp.data);
        setGetting(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getAnalytics();
    // getChannelTransactions();
    console.log("admin is ", window.localStorage.getItem("cad"));
    // alert(JSON.stringify(u_id.u_id))
  }, []);

  if (analytics.length == 0) {
    return (
      <Fragment>
        <HeaderV2 />
        <div className="bg-white main-content">
          <div className="">
            <div className="" style={{ marginTop: 100 }}>
              <Load bg="#fff" color="#000" />
             
            </div>
             <div className="" style={{ marginBottom: 400 }}></div>
          </div>
        </div>

        {/* <Popupchat /> */}
        {/* <Appfooter /> */}
      </Fragment>
    );
  }

  // if(adminId !== userId){
  //   <Fragment>
  //   <div className="bg-white">
  //     <div className="">
  //       <div className="">
  //         <h5> Kindly view your analytics from your profile</h5>
  //         <Load bg="#fff" color="#000" />
  //       </div>
  //     </div>
  //   </div>

  //   {/* <Popupchat /> */}
  //   {/* <Appfooter /> */}
  // </Fragment>
  // }
  else {
    return (
      <div className="list" style={{ backgroundColor: "#fff", height: "100%" }}>
        {/* <Sidebar /> */}
        <HeaderV2 />
        <br />
        <br />

        {adminId != analytics.movie.user_id ? (
          <div className="bg-white main-content text-center">
            {/* {adminId} - {analytics.movie.user_id} */}
            <h5 style={{ marginTop: 100 }}>
              {" "}
              Oops! You're not meant to be here! View this from your dashboard{" "}{adminId} - {analytics.movie.user_id} 
            </h5>
            <Load bg="#fff" color="#000" message="Kindly go back" />
          </div>
        ) : (
          <>
        

            <div className="bg-white main-content" style={{ padding: "20" }}>
              <div className="">
                <div className="">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              src="/assets/images/cash.png"
                              alt="banner"
                              className="w-100"
                            />
                          </div>
                          <div className="col-lg-6 ps-lg-5">
                            <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">
                              Track Your Series Activities On Chillarx{" "}
                              {/* {analytics.user.first_name} */}
                            </h2>
                            {analytics.movie.title && (
                              <p className="font-xssss fw-500 text-grey-500 lh-26">
                                This is a quick overview of{" "}
                                {analytics.movie.title} performance on Chillarx.{" "}
                              </p>
                            )}

                            <br />
                            <Button onClick={() => history.goBack()}>
                              Click To Go Back
                            </Button>
                            {/* <a href="/defaultanalytics" className="btn w200 border-0 bg-success p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Analysis</a> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <TabList
                            onChange={handleChange}
                            variant="scrollable"
                scrollButtons
                // centered
                allowScrollButtonsMobile
                scrollButtons="auto"
                aria-label="scrollable force tabs example"
                          >
                            <Tab
                              label="Series Analytics"
                              value="1"
                              style={{ color: "black", marginLeft:10 }}
                            />
                            <Tab
                              label="User Transactions"
                              value="2"
                              style={{ color: "black", marginLeft:10 }}
                            />
                            <Tab
                              label="Visuals"
                              value="3"
                              style={{ color: "black" }}
                            />
                          </TabList>
                        </Box>

                        <TabPanel value="1">
                          <>
                            <SeriesAnalytics1 />
                          </>
                        </TabPanel>
                        <TabPanel value="2">
                        <h3 className='text-center'>Profits Gotten From {analytics.movie.title} </h3>
                        <MarkerTransactionTable link={`movie/series_transactions/get/${analytics.movie.u_id}`} title='Below are the transactions your series has recieved'  />
                        </TabPanel>
                        {/* <TabPanel value="3">
                          <div
                            className="list"
                            style={{
                              width: "500%",
                              minWidth: "300%",
                              maxWidth: "500%",
                              // backgroundColor: "blue",
                              // overflow: "scroll",
                            }}
                          >
                             <SeriesTransactions title={analytics.movie.title} />
                            <SeriesAnalytics2 />
                          </div>
                        </TabPanel> */}
                      </TabContext>
                    </Box>
                    {/* <div> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* <div className="listContainer" style={{ color: "#000", height: "100%" }}>
        <div className="main-content">
          <div className="top" style={{ color: "#000", padding: '20' }}>
            <h1>Your Video Analytics</h1>
          </div>

          <div
            className="datatableTitle"
            style={{ color: "#000", marginBottom: 20 }}
          >
            See How It's Doing -> Coming Soon!
          </div>

        
        </div>
      </div> */}
        {/* <BarChart /> */}
      </div>
    );
  }
};

export default SeriesAnalytics;
